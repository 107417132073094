.c-section-twocols {
    padding: 160px 0;

    &--small {
        padding: 80px 0;
    }

    &--darkgrey {
        color: $dark-grey;
    }

    .c-section-twocols__left {
        position: relative;
        height: 830px;

        @media screen and (max-width: 992px) {
            height: auto;
        }

        .c-section-twocols__left__image {
            height: 470px;
            width: 470px;
            background-color: $black;
            background-position: center;
            background-size: cover;
            position: absolute;

            &[data-index="1"] {
                top: 0;
                left: 110px;

                @media screen and (max-width: 992px) {
                    left: calc(110px + 50%);
                }

                @include media-breakpoint-down(md) {
                    left: 50%;
                }
            }

            &[data-index="2"] {
                top: 360px;
                left: 0;

                @media screen and (max-width: 992px) {
                    left: 50%;
                }
            }

            @media screen and (max-width: 1250px) {
                position: static;
                margin: 10px auto;
                max-width: 100%;
                height: 50%;
                width: auto;
                transform: translateX(0);
                left: 0;
            }

            @media screen and (max-width: 992px) {
                width: 50%;
                height: 470px;
            }

            @include media-breakpoint-down(md) {
                width: 90%;
            }
        }
    }

    .c-section-twocols__right {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);

        @media screen and (max-width: 992px) {
            position: static;
            top: 0;
            transform: translateY(0);
        }

        @include media-breakpoint-down(md) {
            width: 90%;
            margin: 0 auto;
        }

        .c-section-twocols__right__title {
            color: $blue;
            margin-bottom: 24px;
        }

        .c-section-twocols__right__text {
            margin-bottom: 56px;

            ol, ul{

                li{

                    font-size: 16px;

                }

            }

        }
    }

}