/*
|--------------------------
|      DEFAULT & RESET
|--------------------------
*/
html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

body {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

*{
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body{
    font-family: $font-family-default;
    color: $default-color;
    font-size: $default-font-size;
    line-height: $default-line-height;
    font-weight: $default-font-weight;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    overflow-x: hidden;
}

h1, h2, h3, h4, h5, h6{
    @each $property, $value in $default-titles{
        #{$property}: $value;
    }
}


h1,
h2 {
  font-size: 70px;
  line-height: 80px;

  @media screen and (max-width: 1500px) {
    font-size: 60px;
    line-height: 65px;
  }

  @include media-breakpoint-down(md) {
    font-size: 48px;
    line-height: 60px;
  }

  @include media-breakpoint-down(sm) {
    font-size: 36px;
    line-height: 42px;
  }
}


button, input, textarea{
  &:focus{
    outline: none !important;
    box-shadow: none !important;
  }
}

button{
    -webkit-appearance: none;
    padding: 0;
    background-color: transparent;
    border: none;
}

img{
  max-width: inherit;
}

a, img, span, button{
    display: inline-block;
}

a{
    color:$blue;

    &:hover{
        color: $blue;
    }
}

ul{
    margin: 0;
    padding: 0;
    
    li{
        list-style-type: none;
    }
}

    ul,
    ol {
        margin-left: 30px;

        @include media-breakpoint-down(sm) {
            margin-left: 15px;
        }
    }

    ol {
        list-style: none;
        counter-reset: li;
    }

    ul > li {
        position: relative;

        padding: {
            left: 1em;
        }

        &::before {
            content: "◦";
            color: $blue;

            position: absolute;
            top: 0;
            left: 0;

            width: 1em;
            margin-right: 1em;
        }
    }

    ol > li {
        counter-increment: li;

        &::before {
            content: "."counter(li);
            color: $blue;
            display: inline-block;
            width: 1em;
            margin-left: -1.5em;
            margin-right: 0.5em;
            text-align: right;
            direction: rtl
        }
    }

    li {
        margin-bottom: 15px;
    }