.c-section-page-bottom {
    .c-section-page-bottom__top {
        height: 300px;

        @include media-breakpoint-down(lg) {
            height: auto;
        }

        .c-section-page-bottom__top__left,
        .c-section-page-bottom__top__right  {
            position: relative;
            height: 300px;
            background-size: cover;
            background-position: center;

            .c-section-page-bottom__top__left__link,
            .c-section-page-bottom__top__right__link {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }

            .c-section-page-bottom__top__left__cover,
            .c-section-page-bottom__top__right__cover {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background-color: $red;

                transition: opacity .2s ease-out;

                @include media-breakpoint-down(md) {
                    opacity: .5;
                }
            }

            .c-section-page-bottom__top__left__content,
            .c-section-page-bottom__top__right__content {
                position: relative;
                height: 300px;
                display: flex;
                flex-direction: column;
                justify-content: center;

                .c-section-page-bottom__top__left__content__question,
                .c-section-page-bottom__top__right__content__question {
                    color: $white;
                    font-weight: 500;
                    line-height: 24px;
                    font-size: 16px;
                    margin-bottom: 5px;
                }

                .c-section-page-bottom__top__left__content__title,
                .c-section-page-bottom__top__right__content__title {
                    color: $white;
                    margin-bottom: 17px;
                }

                .c-section-page-bottom__top__left__content__arrow,
                .c-section-page-bottom__top__right__content__arrow {
                    height: 40px;
                    width: 40px;
                    border-radius: 100%;
                    background-color: $white;
                    justify-content: center;
                    align-items: center;
                    display: flex;
                }

                .c-section-page-bottom__top__left__content__arrow {
                    transform: rotate(180deg);
                }
            }

            .c-section-page-bottom__top__left__content {
                margin-left: calc((100vw - 1300px) / 2);

                @media screen and (max-width: 1300px) {
                    margin-left: 10px;
                }
            }
    
            .c-section-page-bottom__top__right__content {
                margin-right: calc((100vw - 1300px) / 2);
                align-items: flex-end;

                @media screen and (max-width: 1300px) {
                    margin-right: 10px;
                }
            }
        }

        .c-section-page-bottom__top__right {
            border-left: 1px solid $white;

            @include media-breakpoint-down(lg) {
                border-left: none;
            }

            &:hover {
                .c-section-page-bottom__top__right__cover {
                    opacity: .5;
                }
            }
        }

        .c-section-page-bottom__top__left {
            border-right: 1px solid $white;

            @include media-breakpoint-down(lg) {
                border-right: none;
            }

            &:hover {
                .c-section-page-bottom__top__left__cover {
                    opacity: .5;
                }
            }
        }        
    }
}