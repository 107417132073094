/*
|--------------------
|      BUTTONS
|--------------------
*/

.btn {
    background-color: $red;
    border-radius: 30px;
    color: $white;
    font-size: 12px;
    line-height: 14px;
    font-weight: 700;
    text-transform: uppercase;
    border: 2px solid $red;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
    padding: 4px 10px 4px 24px;

    transition: background-color .2s ease-out, color .2s ease-out;
    
    .btn__text {
        margin-right: 20px;
    }
    
    .btn__plus,
    .btn__arrow {
        min-width: 40px;
        height: 40px;
        background-color: $white;
        border-radius: 100%;
    }

    .btn__plus {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &:hover {
        background-color: $white;
        color: $red;

        .btn__arrow {
            background-color: $red;

            .long-arrow-right--red {
                border-color: $white;

                &:after {
                    background-color: $white;
                }
            }
        }
    }

    &--reverse {
        background-color: $white;
        color: $red;

        .btn__arrow {
            background-color: $red;

            .long-arrow-right {
                border-color: $white;

                &:after {
                    background-color: $white;
                }
            }
        }

        &:hover {
            background-color: $red;
            color: $white;

            .btn__arrow {
                background-color: $white;
    
                .long-arrow-right {
                    border-color: $red;
    
                    &:after {
                        background-color: $red;
                    }
                }
            }
        }
    }

    &--lightblue {
        background-color: $light-blue;
        color: $red;

        .btn__plus,
        .btn__arrow {
            background-color: $red;
        }
    }

    &--green {
        background-color: $green;
        color: $white;
        border: 2px solid $white;

        &:hover {
            background-color: $white;
            color: $green;

            .btn__arrow {
                background-color: $green;
    
                .long-arrow-right--green {
                    border-color: $white;
    
                    &:after {
                        background-color: $white;
                    }
                }
            }
        }
    }
    
}

.long-arrow-right {
    display: block;
    margin: 14px auto;
    width: 11px;
    height: 10px;
    border-top: 2px solid $white;
    border-left: 2px solid $white;
    transform: rotate(135deg);

    &:after {
        content: "";
        display: block;
        width: 2px;
        height: 12px;
        background-color: $white;
        transform: rotate(-45deg) translate(3.5px, 1px);
        left: 0;
        top: 0;
    }

    &--red {
        border-top: 2px solid $red;
        border-left: 2px solid $red;

        &:after {
            background-color: $red;
        }
    }

    &--green {
        border-top: 2px solid $green;
        border-left: 2px solid $green;

        &:after {
            background-color: $green;
        }
    }
}

.plus {
    height: 14px;
    width: 14px;
    position: relative;

    .plus__horizontal,
    .plus__vertical {
        position: absolute;
        width: 2px;
        height: inherit;
        background-color: $white;
        left: 50%;
    }

    .plus__vertical {
        left: 50%;
        transform: translateX(-50%);
    }

    .plus__horizontal {
        transform-origin: center;
        transform: translateX(-50%) rotate(90deg);
    }
}