.c-section-text-title {
    padding-top: 95px;
    
    .c-section-text-title__title {
        color: $blue;
        font-size: 40px;
        line-height: 50px;
        margin-bottom: 40px;
    }

    .c-section-text-title__text {
        color: $dark-grey;

        ol, ul{

            li{

                font-size: 16px;

            }

        }

    }
}