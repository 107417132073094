.c-section_team_list_members {
    position: relative;

    .c-section_team_list_members__cover {
        position: absolute;
        top: 227px;
        left: 0;
        width: 100vw;
        height: calc(100% - 227px);
        background-color: $green;
    }
}