.c-header-pitch {
    margin-top:60px;

    .c-header-pitch__title {
        color: $blue;
        margin-bottom: 16px;
    }

    .c-header-pitch__text {
        max-width: 640px;
        padding-bottom:80px;
    }
}