.c-header-scaling {
    padding-bottom: 120px;
    padding-top: 80px;

    @include media-breakpoint-down(lg) {
        padding-top: 0;
    }

    .c-header-scaling__title {
        margin-bottom: 32px;
        color: $blue;
    }

    .c-header-scaling__decoration {
        position: absolute;
        top: 80px;
        right: 0;
        height: 424px;
        width: 435px;
        background-position: center;
        background-size: cover;

        @include media-breakpoint-down(lg) {
            display: none;
        }
    }
}