.c-section-scaling-list-programs {
    padding-bottom: 80px;
    
    .c-section-scaling-list-programs__title {
        color: $blue;
        margin-bottom: 32px;
    }

    .c-section-scaling-list-programs__slider {
        margin-top: 120px;
        margin-bottom: 100px;
        position: relative;

        .swiper-container {
            overflow: unset !important;

            @include media-breakpoint-down(lg) {
                overflow: hidden !important;
            }

            &:after {
                content: '';
                height: 100%;
                width: 100vw;
                background-color: $light-blue;
                position: absolute;
                left: 0;
                top: 0;
                z-index: 2;
                transform: translateX(-100%);
            }
        }

        .swiper-slide {
            width: 470px !important;

            @include media-breakpoint-down(md) {
                width: 300px !important;
            }

            @include media-breakpoint-down(sm) {
                width: 250px !important;
            }
        }

        .c-section-scaling-list-programs__slider__nav {
            position: absolute;
            bottom: 0;
            right: 0;
            display: flex;
            z-index: 2;
            transform: translateY(calc(100% + 44px));

            .c-section-scaling-list-programs__slider__nav__prev,
            .c-section-scaling-list-programs__slider__nav__next {
                height: 64px;
                width: 64px;
                background-color: $red;
                border-radius: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                border: 2px solid $red;

                transition: background-color $white .2s ease-out;

                &:hover {
                    background-color: $white;
                    .long-arrow-right {
                        border-color: $red;
                        &:after {
                            background-color: $red;
                        }
                    }
                }
            }

            .c-section-scaling-list-programs__slider__nav__prev {
                margin-right: 5px;
                transform: rotate(-180deg);
            }
        }
    }
}