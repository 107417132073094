.c-section-portfolio-filter {
    .c-section-portfolio-filter__header {
        display: flex;
        align-items: center;
        padding-bottom: 96px;

        @include media-breakpoint-down(md) {
            padding-bottom: 0;
        }

        @include media-breakpoint-down(md) {
            flex-direction: column;
        }

        .c-section-portfolio-filter__header__title {
            color: $green;
            font-size: 24px;
            line-height: 28px;
            font-weight: 400;
            margin-right: 38px;

            @include media-breakpoint-down(md) {
                margin-right: 0;
            }
        }

        .c-section-portfolio-filter__header__element {
            display: flex;
            align-items: center;
            cursor: pointer;
            margin-right: 48px;
            transition: color .2s ease-out;

            .c-section-portfolio-filter__header__element__arrow {
                margin: 0 10px;
            }

            &.active,
            &:hover {
                color: $green;
            }

            @include media-breakpoint-down(md) {
                margin-right: 10px;
                margin-left: 10px;
            }
        }

        .c-section-portfolio-filter__header__element-container {
            display: flex;
        }
    }

    .c-section-portfolio-filter__body {
        position: relative;
        padding-bottom: 110px;

        .c-section-portfolio-filter__body__left {
            background-color: $green;
            padding-bottom: 56px;
            position: relative;

            @include media-breakpoint-down(lg) {
                position: fixed;
                top: 0;
                left: 0;
                z-index: 2;
                transform: translateY(calc(100% - 65px));
                max-height: 100vh;
                overflow-y: scroll;
                transition: transform .2s ease-out;
                width: 100%;
                padding-left: 24px;

                &.active {
                    transform: translateY(calc(0% + 110px));
                }
            }

            @include media-breakpoint-down(md) {
                padding-right: 24px;
            }

            &:after {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                transform: translateX(100%);
                background-color: $green;
                height: 100%;
                width: 40px;

                @include media-breakpoint-down(md) {
                    display: none;
                }
            }

            .c-section-portfolio-filter__body__left__cover {
                height: 100%;
                width: 72px;
                background-color: $green;
                transform: translateX(-100%);
                position: absolute;
                top: 0;
                left: 0;

                @include media-breakpoint-down(lg) {
                    display: none;
                }
            }
                
            .c-section-portfolio-filter__body__left__content {
                padding-top: 24px;

                &:after,
                &:before {
                    content: "";
                    position: absolute;
                    width: 1px;
                    height: 60px;
                    top: 0;
                    left: 0;

                    @include media-breakpoint-down(lg) {
                        display: none;
                    }
                }

                &:after {
                    background-color: $white;
                }

                &:before {
                    background-color: $green;
                    transform: translateY(-100%);
                }

                @include media-breakpoint-down(md) {
                    padding-left: 20px;
                    padding-top: 50px;
                }

                .c-section-portfolio-filter__body__left__content__header {
                    color: $white;
                    text-transform: uppercase;
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    transition: color .2s ease-out;

                    &--filter {
                        display: none;

                        @include media-breakpoint-down(lg) {
                            display: block;
                        }
                    }

                    
                    .c-section-portfolio-filter__body__left__content__header__text--filter {
                        display: block;
                        margin: 0 auto;

                        @include media-breakpoint-down(md) {
                            text-align: center;
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 40px;
                            padding-top: 10px;
                        }
                    }

                    .c-section-portfolio-filter__body__left__content__header__image {
                        margin-right: 16px;
                        margin-left: 54px;

                        @include media-breakpoint-down(lg) {
                            margin-left: 0;
                        }
                    }

                    &:hover {
                        color: $blue;
                    }
                }

                .c-section-portfolio-filter__body__left__content__body {
                    .c-section-portfolio-filter__body__left__content__body__section {
                        .c-section-portfolio-filter__body__left__content__body__section__title {
                            color: $white;
                            font-size: 24px;
                            line-height: 28px;
                            font-weight: 400;
                            margin-top: 40px;
                            margin-bottom: 30px;
                        }

                        .c-section-portfolio-filter__body__left__content__body__section__list {
                            display: flex;
                            flex-wrap: wrap;

                            select {
                                background-color: transparent;
                                color: $white;
                                border: none;
                                font-size: 12px;
                                height: 24px;
                                width: 175px;
                                background-size: 15px;
                            }

                            .c-section-portfolio-filter__body__left__content__body__section__list__element {
                                color: $white;
                                border: 1px solid $white;
                                border-radius: 20px;
                                text-transform: uppercase;
                                font-size: 12px;
                                line-height: 24px;
                                padding: 8px 24px;
                                margin-right: 10px;
                                margin-bottom: 10px;
                                cursor: pointer;

                                transition: background-color .2s ease-out, color .2s ease-out;

                                &.active:hover,
                                &.active {
                                    background-color: $white;
                                    color: $green;
                                }

                                &:hover {
                                    background-color: rgba(255, 255, 255, .8);
                                    color: $green;
                                }
                            }
                        }
                    }
                }
            }

        }

        .c-section-portfolio-filter__body__right {
            padding-top: 20px;
            padding-bottom: 60px;

            .c-section-portfolio-filter__body__right__column {
                width: 196px;

                @include media-breakpoint-down(lg) {
                    width: 150px;
                }

                @include media-breakpoint-down(sm) {
                    width: 196px;
                }

                &:not(:last-child) {
                    margin-right: 10px;

                    @include media-breakpoint-down(md) {
                        margin-right: auto;
                    }

                }

                @include media-breakpoint-down(md) {
                    margin: 0px auto;
                }

                @media screen and (max-width: 400px) {
                    margin: 24px auto;
                }

                &--lower {
                    transform: translateY(50px);
                }

                .c-section-portfolio-filter__body__right__column__logo {
                    width: 196px;
                    height: 196px;
                    background-color: $white;
                    background-position: center;
                    background-size: contain;
                    margin-bottom: 24px;
                    position: relative;
                    filter: saturate(0.5);
                    transition: filter .2s ease-out;

                    @include media-breakpoint-down(lg) {
                        width: 150px;
                        height: 150px;
                    }

                    @include media-breakpoint-down(sm) {
                        width: 196px;
                        height: 196px;
                    }

                    &:hover {
                        filter: saturate(1);
                    }

                    a {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        left: 0;
                    }
                }
            }
        }

        .c-section-portfolio-filter__body__bottom {
            display: flex;
            justify-content: center;
        }
    }
}