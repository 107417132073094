.c-header-portfolio {
    padding-top: 70px;
    padding-bottom: 70px;

    @include media-breakpoint-down(md) {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .c-header-portfolio__title {
        color: $blue;
        margin-bottom: 20px;
    }    
}