.c-card-news {
    position: relative;
    height: 288px;
    margin-bottom: 24px;
    overflow: hidden;

    background-size: cover;
    background-position: center;

    &--big {
        height: 600px;
        margin-bottom: 0;

        @include media-breakpoint-down(lg) {
            margin-bottom: 24px;
        }

        @include media-breakpoint-down(md) {
            height: 288px;
        }
    }

    .c-card-news__image,
    .c-card-news__cover,
    .c-card-news__link {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .c-card-news__image {
        background-size: cover;
        background-position: center;

        transition: transform .5s ease-out;
    }

    .c-card-news__cover {
        background-color: $black;
        opacity: .5;

        transition: opacity .2s linear;
    }

    .c-card-news__content {
        position:absolute;
        color: $white;
        width: calc(100% - 60px);
        bottom: 30px;
        left: 30px;

        .c-card-news__content__date {
            font-weight: 500;
            font-size: 16px;
            margin-bottom: 8px;
        }

        .c-card-news__content__title {
            font-size: 24px;
            line-height: 28px;
            font-family: $font-family-default;
            margin-bottom: 8px;
        }

        .c-card-news__content__intro {
            font-size: 16px;
            line-height: 24px;
            font-weight: 500;
            margin-bottom: 16px;
        }

        .c-card-news__content__arrow {
            height: 40px;
            width: 40px;
            background-color: $white;
            border-radius: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    &:hover {
        .c-card-news__cover {
            opacity: .2;
        }

        .c-card-news__image {
            transform: scale(1.01);
        }

        .c-card-news__content__arrow {
            background-color: $red;

            .long-arrow-right--red {
                border-color: $white;

                &:after {
                    background-color: $white;
                }
            }
        }
    }
}