/*
|--------------------
|      HEADER
|--------------------
*/

/*
|
| Header
|---------
*/
.link-menu{
    color: $very-dark-grey;
    font-size: 14px;
    font-weight: 400;
    text-decoration: none;
    transition: color .2s ease-out;

    &:hover {
        color: $red;
    }
}

#header{
    width: 100%;
    max-width: 100vw;
    position: fixed;
    z-index: 10000;
    transform: translateY(0);
    transition: background-color .2s ease-out, transform .2s ease-out;

    &.active {
        background-color: $white;
    }

    &.translated {
        transform: translateY(-100%);
    }

    .header-band {
        position: relative;
        width: 100vw;
        background-color: $blue;
        color: $white;
        text-align: center;
        padding: 5px 0;

        opacity: 1;
        visibility: visible;
        transition: visibility .2s ease-out, opacity .2s ease-out;

        &.hide {
            opacity: 0;
            visibility: hidden;
            display: none;
        }

        .header-band__close {
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            cursor: pointer;

            svg {
                fill: $white;
            }
        }

        .header-band__text {
            padding: 0 30px;
        }
    }

    .header-container{
        @extend .container;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 25px;
        padding-bottom: 25px;
        

        .item-logo{
            img {
                width: 100px;
            }
            
            svg{
                path, polygon{
                    fill: $black;
                }
            }
        }

        .nav-drop {
            display: none;
            opacity: 0;
            visibility: visible;

            position: absolute;
            bottom: -20px;
            left: 0;
            transform: translate(-22%, 100%);
            background-color: $green;
            width: 250px;
            padding: 40px 55px;
            font-size: 14px;

            box-shadow: 0px 6px 20px 10px rgba(55,191,181,0.2);

            transition: visibility .2s ease-out, opacity .2s ease-out;

            li {
                margin-right: 0 !important;
                display: block !important;

                &:not(:last-child) {
                    margin-bottom: 24px;
                }
                
                a {
                    color: $white;
                    text-decoration: none;

                    &:hover {
                        text-shadow: 0 0 0.01px $white;
                    }
                }
            }

            &.active {
                display: block;
                opacity: 1;
                visibility: visible;
            }
        }

        .item-nav{
            display: none;

            .item-menu{
                display: flex;

                li{
                    
                    margin-right: 67px;
                    padding-left: 0;
                    margin-bottom: 0 !important;

                    list-style-type: none;
                    
                    .item-link {
                        @extend .link-menu;
                        
                    }

                    &::before {
                        display: none;
                    }

                    @include media-breakpoint-down(lg) { 
                        margin-right: 40px;
                    }
                }   
            }

            @include media-breakpoint-up(lg) {
                display: flex;
                align-items: center;

                .item-menu{
                    margin-right: 0;
                }
            }
        }
    }

    .lang-container{
        position: relative;
        display: inline-flex;
        text-transform: uppercase;
        top: 1px;
        @extend .link-menu;
        cursor: pointer;
        margin-right: 67px;
        margin-bottom: 0 !important;

        ul{
            position: absolute;
            z-index: 1;
            top: 100%;
            left: 0;
            opacity: 0;
            visibility: hidden;
            transform: translateX(10px);
            transition: opacity 1.2s $easeSmooth, transform 2s $easeSmooth;
            margin-left: 0;

            li {
                padding-left: 0;

                &::before {
                    display: none;
                }

                a {
                    text-decoration: none;
                }
            }
        }

        &:hover{
            ul{
                opacity: 1!important;
                visibility: visible!important;
                transform: translateX(0)!important;
            }
        }
    }

    .btn-menu {
        position: relative;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        //width: 56px;
        //height: 56px;
        margin-top: 5px;
        padding: 0;
        border: none;
        background-color: transparent;
        cursor: pointer;

        &>.item-burger {
            display: block;
            width: 25px;

            &>span {
                display: block;
                width: 100%;
                height: 2px;
                background-color: $very-dark-grey;
                border-radius: 2px;

                &:nth-child(2) {
                    margin: 6px 0;
                }
            }
        }

        @include media-breakpoint-up(lg) {
            display: none;
        }
    }
        
}

/*
|
| Menu
|-------
*/
#mobile-menu{
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100vh;
    background-color: $white;
    overflow: auto;
    opacity: 0;
    visibility: hidden;
    
    .item-container{
        @extend .container;
        //padding-top: 150px;
        //padding-bottom: 50px;

        .nav-drop {
            max-height: 0;
            overflow: hidden;

            transition: max-height .2s ease-out;

            &.active {
                max-height: 1000000px;
            }

            li {
                a {
                    font-size: 16px;
                    text-decoration: none;
                }
                
                padding-left: 20px;
            }

            .nav-drop-li {
                margin-bottom: 5px !important;
            }
        }

        ul {
            & > li {
                &:not(:last-child){
                    margin-bottom: 20px;
                }

                &:before {
                    content: "";
                }

                a {
                    text-transform: uppercase;
                    text-decoration: none;
                    color: $very-dark-grey;
                    font-size: 20px;
                    font-weight: 500;
                    -webkit-font-smoothing: antialiased;

                    @include media-breakpoint-down(xs) {
                        font-size: 26px;
                    }
                }
            }
        }
    }

    .item-socials-container{
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;

        .item-socials{
            display: flex;
            align-items: center;
            margin-bottom: 25px;

            a{
                font-size: 25px;
                text-decoration: none;

                &:not(:last-child){
                    margin-right: 25px;
                }
            }

            @include media-breakpoint-down(xs) {
                margin-bottom: 15px;

                a{
                    text-decoration: none;
                    font-size: 20px;
                }
            }
        }
    }
    
}