.c-section-faq {
    position: relative;
    background-color: $green;
    padding-top: 110px;
    padding-bottom: 110px;

    &--nobkg {
        background-color: unset;
    }

    .c-section-faq__line {
        &::after,
        &::before {
            content: '';
            height: 80px;
            width: 1px;
            position: absolute;
            left: 50%;
        }

        &--bottom::after,
        &--bottom::before {
            bottom: 0;
        }

        &--bottom::after {
            background-color: $white;
            transform: translate(320px);
        }

        &--bottom::before {
            background-color: $green;
            transform: translate( 320px, 100%);
        }

        &--top::after,
        &--top::before {
            top: 0;
        }

        &--top::after {
            background-color: $white;
            transform: translate( -320px);
        }

        &--top::before {
            background-color: $green;
            transform: translate( -320px, -100%);
        }

        @include media-breakpoint-down(lg) {
            display: none;
        }
    }

    .c-section-faq__decoration {
        position: absolute;
        top: 80px;
        right: -60px;
        height: 806px;
        width: 323px;
        background-position: center;
        background-size: cover;

        @include media-breakpoint-down(lg) {
            display: none;
        }
    }

    .c-section-faq__decoration__container {
        background-color: $green;
        padding: 60px 110px 80px 110px;
        position: relative;

        @include media-breakpoint-down(md) {
            padding: 60px 20px 80px 20px;
        }

        &::after,
        &::before {
            content: "";
            position: absolute;
            width: 1px;
            height: 100px;
            left: 110px;
            top: 0;

            @include media-breakpoint-down(md) {
                display: none;
            }
        }

        &::before {
            background-color: $white;
        }

        &::after {
            background-color: $green;
            transform: translateY(-100%);
        }
    }

    .c-section-faq__title {
        color: $white;
        margin-bottom: 85px;
    }

    .c-section-faq__terms {
        display: flex;
        margin-bottom: 50px;
        margin-left: 40px;

        @include media-breakpoint-down(md) {
            margin-left: 0;
        }

        @include media-breakpoint-down(md) {
            flex-direction: column;
            align-items: center;
        }

        .c-section-faq__terms__element {
            color: $white;
            border: 1px solid $white;
            border-radius: 20px;
            text-transform: uppercase;
            font-size: 12px;
            line-height: 24px;
            padding: 6px 30px;
            margin-right: 10px;
            margin-bottom: 10px;
            cursor: pointer;
            transition: background-color .2s ease-out, color .2s ease-out;
            &.active:hover,
            &.active {
                background-color: $white;
                color: $green;
            }
            &:hover {
                background-color: rgba(255, 255, 255, .8);
                color: $green;
            }

            @include media-breakpoint-down(md) {
                text-align: center;
                margin-right: 0;
                max-width: 300px;
            }
        }
    }

    .c-section-faq__content {
        .c-section-faq__content__element {
            transition: opacity .2s ease-out;

            &.hidden {
                height: 0 !important;
                opacity: 0 !important;
                visibility: hidden !important;
            }

            .c-section-faq__content__element__titleContainer {
                display: flex;
                justify-content: space-between;
                align-items: center;
                cursor: pointer;
                padding-bottom: 25px;
                border-bottom: 1px solid $white;
            
                .c-section-faq__content__element__titleContainer__title {
                    font-family: $font-family-default;
                    color: $white;
                    font-size: 24px;
                    line-height: 28px;
                    text-transform: none;
                    font-weight: 500;
                    padding-right: 20px;

                    transition: color .2s $blue;
                }
          
                .c-section-faq__content__element__titleContainer__arrow {
                    min-height: 40px;
                    min-width: 40px;
                    background-color: $white;
                    border-radius: 100%;
                    transform: rotate(90deg);
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    transition: transform .2s ease-out, background-color .2s ease-out;
                }
          
                &.active {
                    // .c-section-faq__content__element__titleContainer__title {
                    //     color: $light-blue;
                    // }
 
                    // .c-section-faq__content__element__titleContainer__arrow {
                    //     background-color: $light-blue;
                    // }

                    .c-section-faq__content__element__titleContainer__arrow {
                        transform: rotate(-90deg);
                    }
                }
            }
        
            .c-section-faq__content__element__textContainer {
                overflow: hidden;
                max-height: 0;
            
                transition: max-height .3s ease-out;
            
                .c-section-faq__content__element__textContainer__text {
                    font-size: 20px;
                    color: $white;
                    line-height: 28px;
                    transform: translateY(-100%);
                    transition: transform .3s ease-out;
                }
          
                &.active {
                    max-height: 2000px;
                    
                    .c-section-faq__content__element__textContainer__text {
                        transform: translateY(0);
                    }
                }
            }

            &:hover {
                opacity: 1 !important;
            }
        }
    }    
}