/*
|--------------------
|      FOOTER
|--------------------
*/
#footer {
    background-color: $white;
    padding-top: 55px;
    margin-bottom: 55px;

    .footer__logo {
        border-right: 1px solid $black;
        height: 100%;

        img {
            width: 100px;
        }

        @include media-breakpoint-down(lg) {
            margin-bottom: 20px;
            border-right: 0;
        }
    }

    .footer__menus {
        display: flex;
        justify-content: space-between;
        margin-bottom: 50px;

        @include media-breakpoint-down(md) {
            flex-direction: column;
        }

        .footer__menus__col {

            @include media-breakpoint-down(md) {
                margin-bottom: 20px;
            }

            .footer__menus__col__title {
                color: $red;
                font-size: 16px;
                line-height: 20px;
                margin-bottom: 16px;
                font-family: $font-family-default;
                font-weight: bold;
            }

            .footer__menus__col__links {
                display: flex;
                flex-direction: column;

                a {
                    font-size: 14px;
                    margin-bottom: 6px;
                    line-height: 24px;
                    font-weight: 300;
                    text-decoration: none;

                    transition: color .2s ease-out;

                    &:hover {
                        color: $red;
                    }
                }
            }

            &--social {
                display: flex;
                justify-content: space-between;

                a {
                    border-radius: 100%;
                    border: 2px solid $black;
                    display: flex;
                    height: 44px;
                    width: 43px;
                    background-color: $black;
                    text-decoration: none;

                    img {
                        transition: filter .2s ease-out;
                    }

                    &:not(:last-child) {
                        margin-right: 16px;
                    }

                    &:hover {
                        img {
                            filter: invert(1);
                        }
                    }
                }
            }
        }
    }
}

.footer__newsletter {
    $el: &;

    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 10;

    width: 350px;

    padding: 20px;

    background-color: white;

    transition: transform .8s;

    &.--down {
        transform: translateY(calc(100% - 55px));

        #{$el}__toggle {
            &:before {
                width: 10px;

                top: 10px;
                left: -6px;
            }

            &:after {
                width: 10px;

                top: 10px;
                left: 6px;
            }
        }
    }

    &__toggle {
        position: absolute;
        top: 10px;
        right: 10px;

        width: 30px;
        height: 30px;

        cursor: pointer;

        &:before {
            content: '';

            position: absolute;
            inset: 0;

            margin: auto;

            width: 20px;
            height: 2px;

            background-color: $red;

            transform: rotate(-45deg);

            transition: top .8s, left .8s, width .8s;
        }

        &:after {
            content: '';

            position: absolute;
            inset: 0;

            margin: auto;

            width: 20px;
            height: 2px;

            background-color: $red;

            transform: rotate(45deg);

            transition: top .8s, left .8s, width .8s;
        }
    }

    .footer__newsletter__title {
        color: $red;
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 24px;
        font-family: $font-family-default;
        font-weight: bold;

        cursor: pointer;
    }

    .footer__newsletter__form {
        position: relative;

        .footer__newsletter__form__btn {
            margin: {
                top: 10px
            }

            float: right;
        }

        .validation_error {
            .gform_submission_error {
                font-family: $font-family-default;
                font-size: 16px;
                line-height: 20px;
            }
        }

        form {
            ul {
                margin-left: 0;
            }

            li.gfield {
                padding-left: 0;

                &::before {
                    display: none;
                }
            }

            .gfield_required {
                display: none;
            }

            .gform_fields {
                display: flex;
                flex-direction: column;
            }

            .gfield_label {
                font-weight: 300;
                text-transform: uppercase;
                font-size: 14px;
                letter-spacing: .2px;
                margin-bottom: 5px;
                display: none;
            }

            input[type="text"] {
                width: 100%;
                border: none;
                border-bottom: 2px solid $green;
                margin-right: 24px;
                height: 30px;
                padding-bottom: 10px;
                font-size: 14px;
                color: $blue;

                @include media-breakpoint-down(md) {
                    width: 100%;
                }

                &::placeholder {
                    color: $blue;
                }
            }

            select {
                width: 100%;
                border: none;
                border-bottom: 2px solid $green;
                height: 33px;
                font-size: 14px;
                color: $blue;
                padding-bottom: 10px;

                background-image: url('../img/icon/bottom-green.svg');
                background-repeat: no-repeat;
                background-size: 15px;
                background-position: calc(100%) center;

                &::placeholder {
                    color: $blue;
                }

                @include media-breakpoint-down(md) {
                    width: 100%;
                }
            }

            input[type="submit"] {
                display: none;
            }

            .validation_message.gfield_validation_message {
                color: $red;
            }
        }
    }
}