.c-header-manifesto {
    position: relative;
    z-index: 2;
    padding-top: 70px;
    
    .c-header-manifesto__title {
        margin-bottom: 32px;
        color: $blue;
    }

    .c-header-manifesto__text {
        margin-bottom: 56px;
        max-width: 750px;
    }

    .c-header-manifesto__image {
        max-width: 100%;
    }
}