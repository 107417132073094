.c-section-scaling-team {
    padding-bottom: 110px;
    
    .c-section-scaling-team__title {
        color: $blue;
        margin-bottom: 32px;
        padding-top: 43px;
    }

    .c-section-scaling-team__text {
        @include media-breakpoint-down(lg) {
            margin-bottom: 40px;
        }
    }
}