.c-section-content-list {
    padding-top: 72px;

    .c-section-content-list__cover {
        height: calc(100% + 80px);
        width: 1000vw;
        position: absolute;
        right: 300px;
        top: -72px;
        background-color: $green;

        @include media-breakpoint-down(lg) {
            width: 100%;
            right: 0;
        }
    }

    .c-section-content-list__top {
        position: relative;
        padding-bottom: 84px;
        display: flex;

        @include media-breakpoint-down(lg) {
            flex-direction: column;
        }

        .custom-select-wrapper {
            @include media-breakpoint-down(lg) {
                margin-bottom: 15px;
            }
        }

        select {
            background-color: $green;
            font-size: 24px;
            color: $white;
            border: none;
            margin-right: 94px;
            margin-bottom: 10px;
            padding-right: 40px;
            line-height: 30px;

            optgroup { font-size: 16px; }
        }
    }

    .c-section-content-list__body {
        padding-bottom: 80px;
        position: relative;

        .loaded-posts-no-result {
            color: $white;
            font-size: 20px;
        }
    }

    .c-section-content-list__cta {
        position: relative;
        display: table;
        margin: 0 auto;
        padding-bottom: 80px;
    }

}