/*
|--------------------
|      CONTENT
|--------------------
*/

.site-container {
    overflow-x: hidden
}

/*
|
| Banner
|---------
*/
.banner{
    @extend .bg-cover;
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 100vh;
    background-color: $black;

    .item-content{
        width: 100%;
        padding: 100px 0;
    }
}

/*
|
| Custom card
|
| Use in news archive page
|--------------
*/
.custom-card{

    .custom-card-link {
        display: block;
    }

    /*
    * Card img
    */
    .card-img-container {
        position: relative;
        overflow: hidden;

        &:after {
            content: '';
            display: block;
            padding-bottom: 100%;
        }

        .card-img {
            position: absolute;

            top: 0;
            left: 0;

            width: 100%;
            height: 100%;

            transition: transform 2s $easeOutExpo;
            will-change: transform;
        }
    }

    /*
    * Card content
    */
    .card-content {

        .date {
            color: rgba($very-dark-grey, 0.6);
        }
    }

    /*
    * Hover state
    */
    &:hover {

        .card-img-container {
    
            .card-img {
                transform: scale(1.05);
            }
        }
    }
    
    
}


/*
|
| Cookie banner
|----------------
*/
.cookie-banner {
    display: none;
    position: fixed;
    z-index: 1;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 30px 0;

    .item-close {
        font-size: 24px;
        cursor: pointer;
    }


    @include media-breakpoint-down(sm) {
        padding: 20px 0;
        font-size: 12px;
    }

    @include media-breakpoint-down(xs) {
        padding: 10px 0 20px 0;

        .item-close {
            font-size: 18px;
        }
    }
}


/*
|
| Pagination
|---------------
*/
.pagination-container {
    li {
        display: inline-block;

        a {
            transition: all 0.3s ease-out;
            padding: 0 8px;

            &:hover {
                color: $grey;
            }
        }

        .current {
            padding: 0 8px;
            font-weight: 800;
            color: $grey;
        }
    }
}


/*
|
| CMS
|------
*/
.cms{
    span{
        display: inline;
    }

    h1, h2, h3, h4, h5, h6{
        margin-bottom: 30px;
    }

    h1{
        @extend .title-xxl;
    }
    h2 {
        @extend .title-xl;
    }
    h3 {
        @extend .title-lg;
    }
    h4 {
        @extend .title-md;
    }
    h5 {
        @extend .title-sm;
    }
    h6 {
        @extend .title-xs;
    }

    img{
        display: block;
        width: 100%;
        height: auto;
    }

    strong{
        font-weight: 800;
    }

    p{
        margin-bottom: 20px;
    }

    &.cms-no-break{
        p{
            margin-bottom: 0;
        }
    }

    blockquote {
        position: relative;
        
        width: 80%;
        
        margin-right: 0;
        margin-left: auto;
        padding: 130px 0;

        font-size: 30px;
        line-height: 1.5em;
        font-weight: 300;

        @include media-breakpoint-down(md){
            margin: auto;
        }

        &:before {
            content: "";

            position: absolute;
            
            background-image: url('../img/icon/quote-left.svg');
            
            width: 50px;
            height: 50px;
            

            background-repeat: no-repeat;
            background-size: contain;
            background-position: center center;

            transform: translateX(-60px) translateY(-30px);
        }

        &:after {
            content: "";

            position: absolute;
            right: 0;
            
            background-image: url('../img/icon/quote-right.svg');
            
            width: 50px;
            height: 50px;
            

            background-repeat: no-repeat;
            background-size: contain;
            background-position: center center;

            transform: translateX(0) translateY(-50px);

            @include media-breakpoint-down(md){
                transform: translateX(60px) translateY(-50px);
            }
        }
    }
}


/*
|
| Page offset
|--------------
*/
.page-offset {
    padding-top: $headerHeight;

    @include media-breakpoint-down(lg) {
        padding-top: $headerHeightSm;
    }
}

.no-scroll {
    overflow: hidden;
}

/*
|
| Custom Loader
|---------------
*/
.custom-loader {
    width: 100px;
    display: inline-block;

    &.cl-center {
        margin-left: auto;
        margin-right: auto;
    }

    svg {
        display: inline-block;
        width: 100%;
        height: auto;
        fill: $black;

        circle {
            stroke: $black;
        }
    }

    &.ajax-loader {
        display: none;
    }
}

#wcb_p {
    display: flex;
}


/*
|
| Custom Select
|---------------
*/

.custom-select-wrapper {
    position: relative;
    user-select: none;
    margin-right: 50px;
}

.custom-select {
     position: relative;
     display: flex;
     flex-direction: column;
}

.custom-select__trigger {
     position: relative;
     display: flex;
     align-items: center;
     justify-content: space-between;
     padding: 0;
     font-size: 24px;
     font-weight: 300;
     color: $white;
     width: 340px;
     height: 30px;
     line-height: 60px;
     background: $green;
     cursor: pointer;
     padding-bottom: 10px;
     font-weight: 400;

     &--small {
         width: 200px;
         height: 24px;
         font-size: 14px;
     }
}

.custom-options {
     position: absolute;
     display: block;
     top: 100%;
     left: 0;
     right: 0;
     background: $white;
     transition: all 0.5s;
     opacity: 0;
     width: 340px;
     visibility: hidden;
     pointer-events: none;
     z-index: 2;
     font-size: 16px;

     &--small {
        width: 200px;
        height: 24px;
    }
}
 .custom-select.open .custom-options {
     opacity: 1;
     visibility: visible;
     pointer-events: all;
}
 .custom-option {
     position: relative;
     display: block;
     padding: 0;
     padding-left: 10px;
     font-size: 16px;
     font-weight: 300;
     color: $dark-grey;
     line-height: 30px;
     cursor: pointer;
     transition: all 0.5s;
     background-color: $white;

     &--small {
        font-size: 14px;
     }
}
 .custom-option:hover {
     cursor: pointer;
     background-color: $light-grey;
}
 .custom-option.selected {
     color: $white;
     background-color: $blue;
}

.arrow {
  position: relative;
  height: 15px;
  width: 15px;
  
  &--small {
      height: 10px;
  }
}
.arrow::before, .arrow::after {
    content: "";
    position: absolute;
    bottom: 0px;
    width: 0.15rem;
    height: 100%;
    transition: all 0.5s;
}
.arrow::before {
    left: -5px;
    transform: rotate(-45deg);
    background-color: $white;
}
.arrow::after {
    left: 5px;
    transform: rotate(45deg);
    background-color: $white;
}
.open .arrow::before {
    left: -5px;
    transform: rotate(45deg);
}
.open .arrow::after {
    left: 5px;
    transform: rotate(-45deg);
}

.arrow--small::before {
    left: -3px;
    transform: rotate(-45deg);
    background-color: $white;
}
.arrow--small::after {
    left: 3px;
    transform: rotate(45deg);
    background-color: $white;
}
.open .arrow--small::before {
    left: -3px;
    transform: rotate(45deg);
}
.open .arrow--small::after {
    left: 3px;
    transform: rotate(-45deg);
}
