.c-header-home {
    position: relative;
    z-index: 10;

    .c-header-home__title {
        margin-top: 84px;
        color: $blue;
        margin-bottom: 16px;
    }

    .c-header-home__text {
        margin-bottom: 32px;
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
    }

    .c-header-home__image {
        width: 100%;
        display: none;

        @include media-breakpoint-down(md) {
            display: block;
        }
    }

    .c-header-home__container {
        margin-bottom: 240px;

        @include media-breakpoint-down(md) {
            margin-bottom: 150px;
        }
    }

    .c-header-home__badge {
        margin-bottom: 100px;
        display: flex;
        align-items: center;

        .c-header-home__badge__text {
            margin-left: 10px;
            font-weight: 500;
        }
    }

    .c-header-home__decoration {
        position: absolute;
        top: 0;
        right: 0;
        width: 582px;
        height: 100%;
        transform: translateY(-75px);

        @include media-breakpoint-down(md) {
            display: none;
        }

        .c-header-home__decoration__element {
            background-color: $red;
            background-size: cover;
            position: absolute;
            height: 604px;

            &[data-index="1"] {
                width: 97px;
                right: 822px;
                top: 17px;
                background-position-x: 5px;
            }

            &[data-index="2"] {
                width: 139px;
                right: 671px;
                background-position-x: -93px;
            }

            &[data-index="3"] {
                width: 185px;
                top: 17px;
                right: 475px;
                background-position-x: -231px;
            }

            &[data-index="4"] {
                width: 50px;
                right: 415px;
                background-position-x: -461px;
            }

            &[data-index="5"] {
                width: 405px;
                top: 17px;
                right: 0;
                background-position-x: -521px;
            }
        }
    }
}