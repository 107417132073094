.c-section-jobs-join {
    margin: 140px 0;
    padding: 120px 0;
    background-color: $green;
    color: $white;
    position: relative;

    @include media-breakpoint-down(md) {
        margin: 40px 0;
    }

    .c-section-jobs-join__image-container {
        position: absolute;
        left: 0;
        top: -140px;
        height: 806px;
        width: 323px;
        background-color: $blue;
        background-position: center;
        background-size: cover;

        @include media-breakpoint-down(lg) {
            display: none;
        }
    }

    .c-section-jobs-join__title {
        margin-bottom: 32px;
    }

    .c-section-jobs-join__text {
        margin-bottom: 40px;
        max-width: 600px;
    }
}