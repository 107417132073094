@charset "UTF-8";
/* Bootstrap lightweight */
/*!
 * Bootstrap Grid v4.2.1 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%; }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  text-align: left; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0; }

p {
  margin-top: 0; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bold; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

th {
  text-align: inherit; }

label {
  display: inline-block; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

.container, #header .header-container, #mobile-menu .item-container {
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container, #header .header-container, #mobile-menu .item-container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container, #header .header-container, #mobile-menu .item-container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container, #header .header-container, #mobile-menu .item-container {
      max-width: 960px; } }
  @media (min-width: 1275px) {
    .container, #header .header-container, #mobile-menu .item-container {
      max-width: 1275px; } }

.container-fluid {
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
  margin-right: auto;
  margin-left: auto; }

.row, .gravity-form-bootstrap .gform_fields {
  display: flex;
  flex-wrap: wrap;
  margin-right: -12px;
  margin-left: -12px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col-13, .col-14, .col-15, .col-16, .col-17, .col-18, .col-19, .col-20, .col-21, .col-22, .col-23, .col-24, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-13, .col-sm-14, .col-sm-15, .col-sm-16, .col-sm-17, .col-sm-18, .col-sm-19, .col-sm-20, .col-sm-21, .col-sm-22, .col-sm-23, .col-sm-24, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md-13, .col-md-14, .col-md-15, .col-md-16, .col-md-17, .col-md-18, .col-md-19, .col-md-20, .col-md-21, .col-md-22, .col-md-23, .col-md-24, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-13, .col-lg-14, .col-lg-15, .col-lg-16, .col-lg-17, .col-lg-18, .col-lg-19, .col-lg-20, .col-lg-21, .col-lg-22, .col-lg-23, .col-lg-24, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-13, .col-xl-14, .col-xl-15, .col-xl-16, .col-xl-17, .col-xl-18, .col-xl-19, .col-xl-20, .col-xl-21, .col-xl-22, .col-xl-23, .col-xl-24, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 4.16667%;
  max-width: 4.16667%; }

.col-2 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-3 {
  flex: 0 0 12.5%;
  max-width: 12.5%; }

.col-4 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-5 {
  flex: 0 0 20.83333%;
  max-width: 20.83333%; }

.col-6 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-7 {
  flex: 0 0 29.16667%;
  max-width: 29.16667%; }

.col-8 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-9 {
  flex: 0 0 37.5%;
  max-width: 37.5%; }

.col-10 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-11 {
  flex: 0 0 45.83333%;
  max-width: 45.83333%; }

.col-12 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-13 {
  flex: 0 0 54.16667%;
  max-width: 54.16667%; }

.col-14 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-15 {
  flex: 0 0 62.5%;
  max-width: 62.5%; }

.col-16 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-17 {
  flex: 0 0 70.83333%;
  max-width: 70.83333%; }

.col-18 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-19 {
  flex: 0 0 79.16667%;
  max-width: 79.16667%; }

.col-20 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-21 {
  flex: 0 0 87.5%;
  max-width: 87.5%; }

.col-22 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-23 {
  flex: 0 0 95.83333%;
  max-width: 95.83333%; }

.col-24 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 25; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.order-13 {
  order: 13; }

.order-14 {
  order: 14; }

.order-15 {
  order: 15; }

.order-16 {
  order: 16; }

.order-17 {
  order: 17; }

.order-18 {
  order: 18; }

.order-19 {
  order: 19; }

.order-20 {
  order: 20; }

.order-21 {
  order: 21; }

.order-22 {
  order: 22; }

.order-23 {
  order: 23; }

.order-24 {
  order: 24; }

.offset-1 {
  margin-left: 4.16667%; }

.offset-2 {
  margin-left: 8.33333%; }

.offset-3 {
  margin-left: 12.5%; }

.offset-4 {
  margin-left: 16.66667%; }

.offset-5 {
  margin-left: 20.83333%; }

.offset-6 {
  margin-left: 25%; }

.offset-7 {
  margin-left: 29.16667%; }

.offset-8 {
  margin-left: 33.33333%; }

.offset-9 {
  margin-left: 37.5%; }

.offset-10 {
  margin-left: 41.66667%; }

.offset-11 {
  margin-left: 45.83333%; }

.offset-12 {
  margin-left: 50%; }

.offset-13 {
  margin-left: 54.16667%; }

.offset-14 {
  margin-left: 58.33333%; }

.offset-15 {
  margin-left: 62.5%; }

.offset-16 {
  margin-left: 66.66667%; }

.offset-17 {
  margin-left: 70.83333%; }

.offset-18 {
  margin-left: 75%; }

.offset-19 {
  margin-left: 79.16667%; }

.offset-20 {
  margin-left: 83.33333%; }

.offset-21 {
  margin-left: 87.5%; }

.offset-22 {
  margin-left: 91.66667%; }

.offset-23 {
  margin-left: 95.83333%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 4.16667%;
    max-width: 4.16667%; }
  .col-sm-2 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-3 {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .col-sm-4 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-5 {
    flex: 0 0 20.83333%;
    max-width: 20.83333%; }
  .col-sm-6 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-7 {
    flex: 0 0 29.16667%;
    max-width: 29.16667%; }
  .col-sm-8 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-9 {
    flex: 0 0 37.5%;
    max-width: 37.5%; }
  .col-sm-10 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-11 {
    flex: 0 0 45.83333%;
    max-width: 45.83333%; }
  .col-sm-12 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-13 {
    flex: 0 0 54.16667%;
    max-width: 54.16667%; }
  .col-sm-14 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-15 {
    flex: 0 0 62.5%;
    max-width: 62.5%; }
  .col-sm-16 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-17 {
    flex: 0 0 70.83333%;
    max-width: 70.83333%; }
  .col-sm-18 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-19 {
    flex: 0 0 79.16667%;
    max-width: 79.16667%; }
  .col-sm-20 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-21 {
    flex: 0 0 87.5%;
    max-width: 87.5%; }
  .col-sm-22 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-23 {
    flex: 0 0 95.83333%;
    max-width: 95.83333%; }
  .col-sm-24 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 25; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .order-sm-13 {
    order: 13; }
  .order-sm-14 {
    order: 14; }
  .order-sm-15 {
    order: 15; }
  .order-sm-16 {
    order: 16; }
  .order-sm-17 {
    order: 17; }
  .order-sm-18 {
    order: 18; }
  .order-sm-19 {
    order: 19; }
  .order-sm-20 {
    order: 20; }
  .order-sm-21 {
    order: 21; }
  .order-sm-22 {
    order: 22; }
  .order-sm-23 {
    order: 23; }
  .order-sm-24 {
    order: 24; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 4.16667%; }
  .offset-sm-2 {
    margin-left: 8.33333%; }
  .offset-sm-3 {
    margin-left: 12.5%; }
  .offset-sm-4 {
    margin-left: 16.66667%; }
  .offset-sm-5 {
    margin-left: 20.83333%; }
  .offset-sm-6 {
    margin-left: 25%; }
  .offset-sm-7 {
    margin-left: 29.16667%; }
  .offset-sm-8 {
    margin-left: 33.33333%; }
  .offset-sm-9 {
    margin-left: 37.5%; }
  .offset-sm-10 {
    margin-left: 41.66667%; }
  .offset-sm-11 {
    margin-left: 45.83333%; }
  .offset-sm-12 {
    margin-left: 50%; }
  .offset-sm-13 {
    margin-left: 54.16667%; }
  .offset-sm-14 {
    margin-left: 58.33333%; }
  .offset-sm-15 {
    margin-left: 62.5%; }
  .offset-sm-16 {
    margin-left: 66.66667%; }
  .offset-sm-17 {
    margin-left: 70.83333%; }
  .offset-sm-18 {
    margin-left: 75%; }
  .offset-sm-19 {
    margin-left: 79.16667%; }
  .offset-sm-20 {
    margin-left: 83.33333%; }
  .offset-sm-21 {
    margin-left: 87.5%; }
  .offset-sm-22 {
    margin-left: 91.66667%; }
  .offset-sm-23 {
    margin-left: 95.83333%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 4.16667%;
    max-width: 4.16667%; }
  .col-md-2 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-3 {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .col-md-4 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-5 {
    flex: 0 0 20.83333%;
    max-width: 20.83333%; }
  .col-md-6 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-7 {
    flex: 0 0 29.16667%;
    max-width: 29.16667%; }
  .col-md-8 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-9 {
    flex: 0 0 37.5%;
    max-width: 37.5%; }
  .col-md-10 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-11 {
    flex: 0 0 45.83333%;
    max-width: 45.83333%; }
  .col-md-12 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-13 {
    flex: 0 0 54.16667%;
    max-width: 54.16667%; }
  .col-md-14 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-15 {
    flex: 0 0 62.5%;
    max-width: 62.5%; }
  .col-md-16 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-17 {
    flex: 0 0 70.83333%;
    max-width: 70.83333%; }
  .col-md-18 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-19 {
    flex: 0 0 79.16667%;
    max-width: 79.16667%; }
  .col-md-20 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-21 {
    flex: 0 0 87.5%;
    max-width: 87.5%; }
  .col-md-22 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-23 {
    flex: 0 0 95.83333%;
    max-width: 95.83333%; }
  .col-md-24 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 25; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .order-md-13 {
    order: 13; }
  .order-md-14 {
    order: 14; }
  .order-md-15 {
    order: 15; }
  .order-md-16 {
    order: 16; }
  .order-md-17 {
    order: 17; }
  .order-md-18 {
    order: 18; }
  .order-md-19 {
    order: 19; }
  .order-md-20 {
    order: 20; }
  .order-md-21 {
    order: 21; }
  .order-md-22 {
    order: 22; }
  .order-md-23 {
    order: 23; }
  .order-md-24 {
    order: 24; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 4.16667%; }
  .offset-md-2 {
    margin-left: 8.33333%; }
  .offset-md-3 {
    margin-left: 12.5%; }
  .offset-md-4 {
    margin-left: 16.66667%; }
  .offset-md-5 {
    margin-left: 20.83333%; }
  .offset-md-6 {
    margin-left: 25%; }
  .offset-md-7 {
    margin-left: 29.16667%; }
  .offset-md-8 {
    margin-left: 33.33333%; }
  .offset-md-9 {
    margin-left: 37.5%; }
  .offset-md-10 {
    margin-left: 41.66667%; }
  .offset-md-11 {
    margin-left: 45.83333%; }
  .offset-md-12 {
    margin-left: 50%; }
  .offset-md-13 {
    margin-left: 54.16667%; }
  .offset-md-14 {
    margin-left: 58.33333%; }
  .offset-md-15 {
    margin-left: 62.5%; }
  .offset-md-16 {
    margin-left: 66.66667%; }
  .offset-md-17 {
    margin-left: 70.83333%; }
  .offset-md-18 {
    margin-left: 75%; }
  .offset-md-19 {
    margin-left: 79.16667%; }
  .offset-md-20 {
    margin-left: 83.33333%; }
  .offset-md-21 {
    margin-left: 87.5%; }
  .offset-md-22 {
    margin-left: 91.66667%; }
  .offset-md-23 {
    margin-left: 95.83333%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 4.16667%;
    max-width: 4.16667%; }
  .col-lg-2 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-3 {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .col-lg-4 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-5 {
    flex: 0 0 20.83333%;
    max-width: 20.83333%; }
  .col-lg-6 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-7 {
    flex: 0 0 29.16667%;
    max-width: 29.16667%; }
  .col-lg-8 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-9 {
    flex: 0 0 37.5%;
    max-width: 37.5%; }
  .col-lg-10 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-11 {
    flex: 0 0 45.83333%;
    max-width: 45.83333%; }
  .col-lg-12 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-13 {
    flex: 0 0 54.16667%;
    max-width: 54.16667%; }
  .col-lg-14 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-15 {
    flex: 0 0 62.5%;
    max-width: 62.5%; }
  .col-lg-16 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-17 {
    flex: 0 0 70.83333%;
    max-width: 70.83333%; }
  .col-lg-18 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-19 {
    flex: 0 0 79.16667%;
    max-width: 79.16667%; }
  .col-lg-20 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-21 {
    flex: 0 0 87.5%;
    max-width: 87.5%; }
  .col-lg-22 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-23 {
    flex: 0 0 95.83333%;
    max-width: 95.83333%; }
  .col-lg-24 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 25; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .order-lg-13 {
    order: 13; }
  .order-lg-14 {
    order: 14; }
  .order-lg-15 {
    order: 15; }
  .order-lg-16 {
    order: 16; }
  .order-lg-17 {
    order: 17; }
  .order-lg-18 {
    order: 18; }
  .order-lg-19 {
    order: 19; }
  .order-lg-20 {
    order: 20; }
  .order-lg-21 {
    order: 21; }
  .order-lg-22 {
    order: 22; }
  .order-lg-23 {
    order: 23; }
  .order-lg-24 {
    order: 24; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 4.16667%; }
  .offset-lg-2 {
    margin-left: 8.33333%; }
  .offset-lg-3 {
    margin-left: 12.5%; }
  .offset-lg-4 {
    margin-left: 16.66667%; }
  .offset-lg-5 {
    margin-left: 20.83333%; }
  .offset-lg-6 {
    margin-left: 25%; }
  .offset-lg-7 {
    margin-left: 29.16667%; }
  .offset-lg-8 {
    margin-left: 33.33333%; }
  .offset-lg-9 {
    margin-left: 37.5%; }
  .offset-lg-10 {
    margin-left: 41.66667%; }
  .offset-lg-11 {
    margin-left: 45.83333%; }
  .offset-lg-12 {
    margin-left: 50%; }
  .offset-lg-13 {
    margin-left: 54.16667%; }
  .offset-lg-14 {
    margin-left: 58.33333%; }
  .offset-lg-15 {
    margin-left: 62.5%; }
  .offset-lg-16 {
    margin-left: 66.66667%; }
  .offset-lg-17 {
    margin-left: 70.83333%; }
  .offset-lg-18 {
    margin-left: 75%; }
  .offset-lg-19 {
    margin-left: 79.16667%; }
  .offset-lg-20 {
    margin-left: 83.33333%; }
  .offset-lg-21 {
    margin-left: 87.5%; }
  .offset-lg-22 {
    margin-left: 91.66667%; }
  .offset-lg-23 {
    margin-left: 95.83333%; } }

@media (min-width: 1275px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 4.16667%;
    max-width: 4.16667%; }
  .col-xl-2 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-3 {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .col-xl-4 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-5 {
    flex: 0 0 20.83333%;
    max-width: 20.83333%; }
  .col-xl-6 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-7 {
    flex: 0 0 29.16667%;
    max-width: 29.16667%; }
  .col-xl-8 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-9 {
    flex: 0 0 37.5%;
    max-width: 37.5%; }
  .col-xl-10 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-11 {
    flex: 0 0 45.83333%;
    max-width: 45.83333%; }
  .col-xl-12 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-13 {
    flex: 0 0 54.16667%;
    max-width: 54.16667%; }
  .col-xl-14 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-15 {
    flex: 0 0 62.5%;
    max-width: 62.5%; }
  .col-xl-16 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-17 {
    flex: 0 0 70.83333%;
    max-width: 70.83333%; }
  .col-xl-18 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-19 {
    flex: 0 0 79.16667%;
    max-width: 79.16667%; }
  .col-xl-20 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-21 {
    flex: 0 0 87.5%;
    max-width: 87.5%; }
  .col-xl-22 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-23 {
    flex: 0 0 95.83333%;
    max-width: 95.83333%; }
  .col-xl-24 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 25; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .order-xl-13 {
    order: 13; }
  .order-xl-14 {
    order: 14; }
  .order-xl-15 {
    order: 15; }
  .order-xl-16 {
    order: 16; }
  .order-xl-17 {
    order: 17; }
  .order-xl-18 {
    order: 18; }
  .order-xl-19 {
    order: 19; }
  .order-xl-20 {
    order: 20; }
  .order-xl-21 {
    order: 21; }
  .order-xl-22 {
    order: 22; }
  .order-xl-23 {
    order: 23; }
  .order-xl-24 {
    order: 24; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 4.16667%; }
  .offset-xl-2 {
    margin-left: 8.33333%; }
  .offset-xl-3 {
    margin-left: 12.5%; }
  .offset-xl-4 {
    margin-left: 16.66667%; }
  .offset-xl-5 {
    margin-left: 20.83333%; }
  .offset-xl-6 {
    margin-left: 25%; }
  .offset-xl-7 {
    margin-left: 29.16667%; }
  .offset-xl-8 {
    margin-left: 33.33333%; }
  .offset-xl-9 {
    margin-left: 37.5%; }
  .offset-xl-10 {
    margin-left: 41.66667%; }
  .offset-xl-11 {
    margin-left: 45.83333%; }
  .offset-xl-12 {
    margin-left: 50%; }
  .offset-xl-13 {
    margin-left: 54.16667%; }
  .offset-xl-14 {
    margin-left: 58.33333%; }
  .offset-xl-15 {
    margin-left: 62.5%; }
  .offset-xl-16 {
    margin-left: 66.66667%; }
  .offset-xl-17 {
    margin-left: 70.83333%; }
  .offset-xl-18 {
    margin-left: 75%; }
  .offset-xl-19 {
    margin-left: 79.16667%; }
  .offset-xl-20 {
    margin-left: 83.33333%; }
  .offset-xl-21 {
    margin-left: 87.5%; }
  .offset-xl-22 {
    margin-left: 91.66667%; }
  .offset-xl-23 {
    margin-left: 95.83333%; } }

/* Function */
/*
|---------------------------------------------------------------
| Functions
|---------------------------------------------------------------
| Functions used to add classes to generation
|
|
*/
/*
|
| Add Buttons
|-----------------------
|
*/
/*
|
| Add Blocks
|-----------------------
|
*/
/*
|
| Add Links
|-----------------------
|
*/
/* variables */
/*
|---------------------------------------------------------------
| Variables
|---------------------------------------------------------------
| Import variables
|
|
*/
/*
|---------------------------------------------------------------
| SPACES
|---------------------------------------------------------------
| Margins and paddings
|
|
*/
/*
|--------------
| Space sizes
|--------------
| Variables used to generate magins & paddings classes (including responsive)
| - Normal classes: .{property}-{size} / .mb-md / margin-bottom: $md  
| - Responsive classes: .{screen}-{up/down/only}-{property}-{size} / .md-down-mb-md /  (max-width: 992px) { margin-bottom: $md }
|
*/
/*
|---------------------------------------------------------------
| COLORS
|---------------------------------------------------------------
| Define colors
|
|
*/
/*
|
| Colors declaration
|----------------------
|
*/
/*
|
| Assign colors
|---------------
|
*/
/*
|-------------
| Colors map 
|-------------
| - How to use: add lines to create more "color classes" 
| - Generated classes: .bg-{color} & .color-{color}
|
*/
/*
|---------------------------------------------------------------
| TEXTS
|---------------------------------------------------------------
| Set all text properties
|
|
*/
/*
|
|
| FONT-SIZES
|
|
|----------------------------------------------
| Font sizes settings (for each screen sizes)
|----------------------------------------------
| Variables used to generate font classes (including responsive)
| - Generated classes: .font-{size}
| - Exemple: .font-md
|
*/
/*
|
|
| TITLE-SIZES
|
|
|-----------------------------------------------
| Title sizes settings (for each screen sizes)
|-----------------------------------------------
| Variables used to generate title classes (including responsive)
| - Generated classes: .title-{size}
| - Exemple: .title-md
|
*/
/*
|
| FONT-FAMILIES
|----------------
|
*/
/*
|-------------------------------
| Font family classes creation
|-------------------------------
| How to use: add lines to create more "font family classes" 
| - Generated classes: .font-{family}
| - Exemple: .font-custom
|
*/
/*
|-----------------
| LETTER-SPACING
|-----------------
| Variables used to generate letter-spacing classes
| - Generated classes: .ls-{size}
| - Exemple: .ls-md
|
*/
/*
|
| LINE-HEIGHT
|--------------
|
*/
/*
|
| Default font settings
|------------------------
|
*/
/*
|
| Default titles settings
|--------------------------
|
*/
/*
|---------------------------------------------------------------
| LINKS
|---------------------------------------------------------------
| Generate and assign links colors
|
|
*/
/*
|
| Links default
|----------------
|
*/
/*
|------------------------
| Links colors creation
|------------------------
| - How to use: Add --> $links-colors: map-set-links($map, $index, $color, $color-hover)
| - Generated classes: .link-{color} & .links-{color} (on parent)
|
*/
/*
|---------------------------------------------------------------
| BUTTONS
|---------------------------------------------------------------
| Generate and set buttons
|
|
*/
/*
|
| Buttons base default
|----------------------
|
*/
/*
|
| Buttons size setting
|-----------------------
|
*/
/*
|-------------------
| Buttons creation
|-------------------
| - How to use: $button-colors: map-set-buttons($map, $index, $background-color, $border-color, $text-color)
| - Generated classes: .btn-{size}, .btn-{color}, .btn-icon-{direction}.
|
*/
/*
|---------------------------------------------------------------
| SECTIONS
|---------------------------------------------------------------
| Generate responsive sections
|
|
*/
/*
|-------------------
| Sections setting
|-------------------
| - Generated class: .section
|
*/
/*
|---------------------------------------------------------------
| BUTTONS
|---------------------------------------------------------------
| Generate and set buttons
|
|
*/
/*
|
| Bg img default
|----------------------
|
*/
/*
|-------------------
| Bg img creation
|-------------------
| - Generated classes: .bg-img-{size}
|
*/
/* Components */
/*
|---------------------------------------------------------------
| Helpers
|---------------------------------------------------------------
| Define helper classes
|
|
*/
/*
|
| Text Transform
|----------------------------
|
*/
.tt-u {
  text-transform: uppercase !important; }

.tt-l {
  text-transform: lowercase !important; }

.tt-c {
  text-transform: capitalize !important; }

/*
|
| Underline
|------------
|
*/
.td-u {
  text-decoration: underline !important; }

.td-n {
  text-decoration: none !important; }

/*
|
| Font styles
|--------------
|
*/
.fs-i {
  font-style: italic !important; }

.fs-o {
  font-style: oblique !important; }

.fs-n {
  font-style: normal !important; }

/*
|
| Background cover
|-------------------
|
*/
.bg-cover, .bg-cover-top, .bg-cover-bottom, .banner {
  background-size: cover !important;
  background-position: center center !important;
  background-repeat: no-repeat !important; }

.bg-cover-top {
  background-position: center top !important; }

.bg-cover-bottom {
  background-position: center bottom !important; }

/*
|
| Overlays
|-------------
|
*/
.overlay {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6); }
  .overlay + * {
    position: relative;
    z-index: 2; }

/*
|
| Overflows
|-------------
|
*/
.o-v {
  overflow: visible !important; }

.ox-v {
  overflow-x: visible !important; }

.oy-v {
  overflow-y: visible !important; }

.o-h {
  overflow: hidden !important; }

.ox-h {
  overflow-x: hidden !important; }

.oy-h {
  overflow-y: hidden !important; }

/*
|
| Positions
|-------------
|
*/
.p-r {
  position: relative !important; }

.p-a {
  position: absolute !important; }

.p-f {
  position: fixed !important; }

.p-s {
  position: static !important; }

/*
|
| Flex Alignment
|-----------------
|
*/
.ai-start {
  align-items: flex-start; }

.ai-end {
  align-items: flex-end; }

.ai-center {
  align-items: center; }

.ai-baseline {
  align-items: baseline; }

.ai-stretch {
  align-items: stretch; }

/*
|
| Flex Justify
|---------------
|
*/
.jc-start {
  justify-content: flex-start; }

.jc-end {
  justify-content: flex-end; }

.jc-center {
  justify-content: center; }

.jc-between {
  justify-content: space-between; }

.jc-around {
  justify-content: space-around; }

/*
|
| White space
|--------------
|
*/
.ws-inherit {
  white-space: inherit; }

.ws-normal {
  white-space: normal; }

.ws-nowrap {
  white-space: nowrap; }

.ws-pre {
  white-space: pre; }

.ws-pre-wrap {
  white-space: pre-wrap; }

.ws-pre-line {
  white-space: pre-line; }

/*
|
| Pointer events
|-----------------
|
*/
.pe-inherit {
  pointer-events: inherit; }

.pe-none {
  pointer-events: none; }

.pe-auto {
  pointer-events: auto; }

/*
|
| Optimize animation
|---------------------
|
*/
.optimize-animation {
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  transform-style: preserve-3d;
  perspective: 1000;
  -webkit-perspective: 1000;
  will-change: transform; }

/*
|
| transform-none
|-----------------
|
*/
.transform-none {
  transform: none !important; }

/*
|
| transform-none
|-----------------
|
*/
.transform-none {
  transform: none !important; }

/*
|
| absolute-full
|-----------------
|
*/
.absolute-full {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

/*
|
| Clear floats
|---------------
|
*/
.clear {
  clear: both; }

/*
|
| img full
|-----------
|
*/
.img-full {
  display: inline-block;
  max-width: inherit;
  width: 100%; }

/*
|
| W-100 H-100
|-----------
|
*/
.w-100 {
  width: 100%; }

.h-100 {
  height: 100%; }

/* Core */
/*
|---------------------------------------------------------------
| MAPS
|---------------------------------------------------------------
| Sass maps used in classes generation
|
|
*/
/*
|
| Font Sizes Map
|---------------------
|
*/
/*
|
| Title Sizes Map
|---------------------
|
*/
/*
|
| Buttons Sizes Map
|---------------------
|
*/
/*
|
| Margins & Paddings Maps
|--------------------------
|
*/
/*
|-------------------------------
| Font weight classes creation
|-------------------------------
| How to use: add lines to create more "font weight classes"
| - Generated classes: .font-{weight}
| - Exemple: .font-regular
|
*/
/*
|
| Font-weights
|---------------
|
*/
/*
|
| Text Align Map
|--------------------------
|
*/
/*
|-------------------------------
| Line-height classes creation
|-------------------------------
| How to use: add lines to create more "line-height classes"
| - Generated classes: .lh-{weight}
| - Exemple: .lh-sm
|
*/
/*
|
| Letter Spacing Map
|--------------------------
|
*/
/*
|
| Display Map
|--------------------------
|
*/
/*
|
| Section Map
|--------------------------
|
*/
/*
|---------------------------------------------------------------
| MIXINS
|---------------------------------------------------------------
| Mixins called from generator to generate classes
|
|
*/
/*
|
| Commonly used mixins
|-----------------------
|
*/
/*
|
| Margins & Paddings
|-----------------------
|
*/
/*
|
| Responsive Margins & Paddings
|--------------------------------
|
*/
/*
|
| Font-sizes
|-----------------------
|
*/
/*
|
| Titles
|-----------------------
|
*/
/*
|
| Links
|-----------------------
|
*/
/*
|
| Buttons
|-----------------------
|
*/
/*
|
| Hidden classes
|-----------------------
|
*/
/*
|
| Display
|-----------------------
|
*/
/*
|
| Text Align
|-----------------------
|
*/
/*
|
| Sections
|-----------------------
|
*/
/*
|
| Bg imgs
|-------------
|
*/
/*
|---------------------------------------------------------------
| CLASS GENERATOR
|---------------------------------------------------------------
| Mixins calls to generate classes
|
|
*/
/*------- DISPLAY -------*/
@media (max-width: 1274.98px) {
  .lg-down-d-none {
    display: none; } }

@media (max-width: 991.98px) {
  .md-down-d-none {
    display: none; } }

@media (max-width: 767.98px) {
  .sm-down-d-none {
    display: none; } }

@media (max-width: 575.98px) {
  .xs-down-d-none {
    display: none; } }

.xs-up-d-none {
  display: none; }

@media (min-width: 576px) {
  .sm-up-d-none {
    display: none; } }

@media (min-width: 768px) {
  .md-up-d-none {
    display: none; } }

@media (min-width: 992px) {
  .lg-up-d-none {
    display: none; } }

@media (min-width: 1275px) {
  .xl-up-d-none {
    display: none; } }

@media (max-width: 1274.98px) {
  .lg-down-d-i {
    display: inline; } }

@media (max-width: 991.98px) {
  .md-down-d-i {
    display: inline; } }

@media (max-width: 767.98px) {
  .sm-down-d-i {
    display: inline; } }

@media (max-width: 575.98px) {
  .xs-down-d-i {
    display: inline; } }

.xs-up-d-i {
  display: inline; }

@media (min-width: 576px) {
  .sm-up-d-i {
    display: inline; } }

@media (min-width: 768px) {
  .md-up-d-i {
    display: inline; } }

@media (min-width: 992px) {
  .lg-up-d-i {
    display: inline; } }

@media (min-width: 1275px) {
  .xl-up-d-i {
    display: inline; } }

@media (max-width: 1274.98px) {
  .lg-down-d-b {
    display: block; } }

@media (max-width: 991.98px) {
  .md-down-d-b {
    display: block; } }

@media (max-width: 767.98px) {
  .sm-down-d-b {
    display: block; } }

@media (max-width: 575.98px) {
  .xs-down-d-b {
    display: block; } }

.xs-up-d-b {
  display: block; }

@media (min-width: 576px) {
  .sm-up-d-b {
    display: block; } }

@media (min-width: 768px) {
  .md-up-d-b {
    display: block; } }

@media (min-width: 992px) {
  .lg-up-d-b {
    display: block; } }

@media (min-width: 1275px) {
  .xl-up-d-b {
    display: block; } }

@media (max-width: 1274.98px) {
  .lg-down-d-ib {
    display: inline-block; } }

@media (max-width: 991.98px) {
  .md-down-d-ib {
    display: inline-block; } }

@media (max-width: 767.98px) {
  .sm-down-d-ib {
    display: inline-block; } }

@media (max-width: 575.98px) {
  .xs-down-d-ib {
    display: inline-block; } }

.xs-up-d-ib {
  display: inline-block; }

@media (min-width: 576px) {
  .sm-up-d-ib {
    display: inline-block; } }

@media (min-width: 768px) {
  .md-up-d-ib {
    display: inline-block; } }

@media (min-width: 992px) {
  .lg-up-d-ib {
    display: inline-block; } }

@media (min-width: 1275px) {
  .xl-up-d-ib {
    display: inline-block; } }

@media (max-width: 1274.98px) {
  .lg-down-d-f {
    display: flex; } }

@media (max-width: 991.98px) {
  .md-down-d-f {
    display: flex; } }

@media (max-width: 767.98px) {
  .sm-down-d-f {
    display: flex; } }

@media (max-width: 575.98px) {
  .xs-down-d-f {
    display: flex; } }

.xs-up-d-f {
  display: flex; }

@media (min-width: 576px) {
  .sm-up-d-f {
    display: flex; } }

@media (min-width: 768px) {
  .md-up-d-f {
    display: flex; } }

@media (min-width: 992px) {
  .lg-up-d-f {
    display: flex; } }

@media (min-width: 1275px) {
  .xl-up-d-f {
    display: flex; } }

@media (max-width: 1274.98px) {
  .lg-down-d-if {
    display: inline-flex; } }

@media (max-width: 991.98px) {
  .md-down-d-if {
    display: inline-flex; } }

@media (max-width: 767.98px) {
  .sm-down-d-if {
    display: inline-flex; } }

@media (max-width: 575.98px) {
  .xs-down-d-if {
    display: inline-flex; } }

.xs-up-d-if {
  display: inline-flex; }

@media (min-width: 576px) {
  .sm-up-d-if {
    display: inline-flex; } }

@media (min-width: 768px) {
  .md-up-d-if {
    display: inline-flex; } }

@media (min-width: 992px) {
  .lg-up-d-if {
    display: inline-flex; } }

@media (min-width: 1275px) {
  .xl-up-d-if {
    display: inline-flex; } }

/*------- FONT-SIZES -------*/
.fs-xs {
  font-size: 12px !important; }
  @media (max-width: 1274.98px) {
    .fs-xs {
      font-size: 12px !important; } }
  @media (max-width: 991.98px) {
    .fs-xs {
      font-size: 12px !important; } }
  @media (max-width: 767.98px) {
    .fs-xs {
      font-size: 12px !important; } }
  @media (max-width: 575.98px) {
    .fs-xs {
      font-size: 12px !important; } }

.fs-sm {
  font-size: 16px !important; }
  @media (max-width: 1274.98px) {
    .fs-sm {
      font-size: 16px !important; } }
  @media (max-width: 991.98px) {
    .fs-sm {
      font-size: 16px !important; } }
  @media (max-width: 767.98px) {
    .fs-sm {
      font-size: 16px !important; } }
  @media (max-width: 575.98px) {
    .fs-sm {
      font-size: 16px !important; } }

.fs-md {
  font-size: 18px !important; }
  @media (max-width: 1274.98px) {
    .fs-md {
      font-size: 18px !important; } }
  @media (max-width: 991.98px) {
    .fs-md {
      font-size: 18px !important; } }
  @media (max-width: 767.98px) {
    .fs-md {
      font-size: 18px !important; } }
  @media (max-width: 575.98px) {
    .fs-md {
      font-size: 18px !important; } }

.fs-lg {
  font-size: 28px !important; }
  @media (max-width: 1274.98px) {
    .fs-lg {
      font-size: 28px !important; } }
  @media (max-width: 991.98px) {
    .fs-lg {
      font-size: 28px !important; } }
  @media (max-width: 767.98px) {
    .fs-lg {
      font-size: 28px !important; } }
  @media (max-width: 575.98px) {
    .fs-lg {
      font-size: 28px !important; } }

.fs-xl {
  font-size: 36px !important; }
  @media (max-width: 1274.98px) {
    .fs-xl {
      font-size: 36px !important; } }
  @media (max-width: 991.98px) {
    .fs-xl {
      font-size: 36px !important; } }
  @media (max-width: 767.98px) {
    .fs-xl {
      font-size: 36px !important; } }
  @media (max-width: 575.98px) {
    .fs-xl {
      font-size: 36px !important; } }

.fs-xxl {
  font-size: 36px !important; }
  @media (max-width: 1274.98px) {
    .fs-xxl {
      font-size: 36px !important; } }
  @media (max-width: 991.98px) {
    .fs-xxl {
      font-size: 36px !important; } }
  @media (max-width: 767.98px) {
    .fs-xxl {
      font-size: 36px !important; } }
  @media (max-width: 575.98px) {
    .fs-xxl {
      font-size: 36px !important; } }

/*------- TITLES -------*/
.title-xs, .cms h6 {
  color: inherit;
  font-family: "Nixie One", cursive;
  font-weight: 400;
  line-height: 1.2;
  font-size: 16px; }
  @media (max-width: 1274.98px) {
    .title-xs, .cms h6 {
      font-size: 16px; } }
  @media (max-width: 991.98px) {
    .title-xs, .cms h6 {
      font-size: 16px; } }
  @media (max-width: 767.98px) {
    .title-xs, .cms h6 {
      font-size: 16px; } }
  @media (max-width: 575.98px) {
    .title-xs, .cms h6 {
      font-size: 16px; } }

.title-sm, .cms h5 {
  color: inherit;
  font-family: "Nixie One", cursive;
  font-weight: 400;
  line-height: 1.2;
  font-size: 18px; }
  @media (max-width: 1274.98px) {
    .title-sm, .cms h5 {
      font-size: 18px; } }
  @media (max-width: 991.98px) {
    .title-sm, .cms h5 {
      font-size: 18px; } }
  @media (max-width: 767.98px) {
    .title-sm, .cms h5 {
      font-size: 18px; } }
  @media (max-width: 575.98px) {
    .title-sm, .cms h5 {
      font-size: 18px; } }

.title-md, .cms h4 {
  color: inherit;
  font-family: "Nixie One", cursive;
  font-weight: 400;
  line-height: 1.2;
  font-size: 24px; }
  @media (max-width: 1274.98px) {
    .title-md, .cms h4 {
      font-size: 24px; } }
  @media (max-width: 991.98px) {
    .title-md, .cms h4 {
      font-size: 24px; } }
  @media (max-width: 767.98px) {
    .title-md, .cms h4 {
      font-size: 24px; } }
  @media (max-width: 575.98px) {
    .title-md, .cms h4 {
      font-size: 24px; } }

.title-lg, .cms h3 {
  color: inherit;
  font-family: "Nixie One", cursive;
  font-weight: 400;
  line-height: 1.2;
  font-size: 30px; }
  @media (max-width: 1274.98px) {
    .title-lg, .cms h3 {
      font-size: 30px; } }
  @media (max-width: 991.98px) {
    .title-lg, .cms h3 {
      font-size: 30px; } }
  @media (max-width: 767.98px) {
    .title-lg, .cms h3 {
      font-size: 30px; } }
  @media (max-width: 575.98px) {
    .title-lg, .cms h3 {
      font-size: 30px; } }

.title-xl, .cms h2 {
  color: inherit;
  font-family: "Nixie One", cursive;
  font-weight: 400;
  line-height: 1.2;
  font-size: 50px; }
  @media (max-width: 1274.98px) {
    .title-xl, .cms h2 {
      font-size: 50px; } }
  @media (max-width: 991.98px) {
    .title-xl, .cms h2 {
      font-size: 42px; } }
  @media (max-width: 767.98px) {
    .title-xl, .cms h2 {
      font-size: 42px; } }
  @media (max-width: 575.98px) {
    .title-xl, .cms h2 {
      font-size: 32px; } }

.title-xxl, .cms h1, #page-404 .section-content .item-title {
  color: inherit;
  font-family: "Nixie One", cursive;
  font-weight: 400;
  line-height: 1.2;
  font-size: 62px; }
  @media (max-width: 1274.98px) {
    .title-xxl, .cms h1, #page-404 .section-content .item-title {
      font-size: 50px; } }
  @media (max-width: 991.98px) {
    .title-xxl, .cms h1, #page-404 .section-content .item-title {
      font-size: 42px; } }
  @media (max-width: 767.98px) {
    .title-xxl, .cms h1, #page-404 .section-content .item-title {
      font-size: 36px; } }
  @media (max-width: 575.98px) {
    .title-xxl, .cms h1, #page-404 .section-content .item-title {
      font-size: 36px; } }

/*------- FONT-FAMILY -------*/
.ff-default {
  font-family: "Work Sans", sans-serif ; }

.ff-custom {
  font-family: "Nixie One", cursive ; }

.ff-extra {
  font-family: "Nixie One", cursive ; }

/*------- FONT-WEIGHTS -------*/
.fw-100 {
  font-weight: 100 ; }

.fw-200 {
  font-weight: 200 ; }

.fw-300 {
  font-weight: 300 ; }

.fw-400 {
  font-weight: 400 ; }

.fw-500 {
  font-weight: 500 ; }

.fw-600 {
  font-weight: 600 ; }

.fw-700 {
  font-weight: 700 ; }

.fw-800 {
  font-weight: 800 ; }

.fw-900 {
  font-weight: 900 ; }

/*------- LINE-HEIGHTS -------*/
.lh-xs {
  line-height: 1 ; }

.lh-sm {
  line-height: 1.2 ; }

.lh-md {
  line-height: 1.5 ; }

.lh-lg {
  line-height: 1.6 ; }

.lh-xl {
  line-height: 1.8 ; }

.lh-xxl {
  line-height: 2 ; }

/*------- COLORS -------*/
.c-black {
  color: #000000 ; }

.c-very-dark-grey {
  color: #141414 ; }

.c-dark-grey {
  color: #424242 ; }

.c-grey {
  color: #808080 ; }

.c-light-grey {
  color: #dedede ; }

.c-very-light-grey {
  color: #fafafa ; }

.c-white {
  color: #FFFFFF ; }

/*------- LINKS -------*/
.link-white {
  color: #FFFFFF; }
  .link-white:hover {
    color: #dedede; }

.links-white a {
  color: #FFFFFF; }
  .links-white a:hover {
    color: #dedede; }

.link-black {
  color: #000000; }
  .link-black:hover {
    color: #808080; }

.links-black a {
  color: #000000; }
  .links-black a:hover {
    color: #808080; }

/*------- BUTTONS -------*/
.btn, .btn-black, .btn-white, .btn-xs, .btn-sm, .btn-md, .btn-lg {
  position: relative;
  font-family: "Work Sans", sans-serif;
  display: inline-block;
  padding: 15px 20px;
  font-size: 14px;
  background: #FFFFFF;
  color: #141414;
  border: 1px solid #dedede;
  border-radius: 0;
  transition: all 0.3s;
  box-shadow: none !important;
  white-space: inherit;
  cursor: pointer; }
  .btn:hover, .btn-black:hover, .btn-white:hover, .btn-xs:hover, .btn-sm:hover, .btn-md:hover, .btn-lg:hover {
    color: #141414; }
  .btn .icon, .btn-black .icon, .btn-white .icon, .btn-xs .icon, .btn-sm .icon, .btn-md .icon, .btn-lg .icon {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    height: 100%;
    transition: all 0.4s;
    width: 30px; }
    .btn .icon svg, .btn-black .icon svg, .btn-white .icon svg, .btn-xs .icon svg, .btn-sm .icon svg, .btn-md .icon svg, .btn-lg .icon svg, .btn .icon img, .btn-black .icon img, .btn-white .icon img, .btn-xs .icon img, .btn-sm .icon img, .btn-md .icon img, .btn-lg .icon img {
      width: 100%;
      max-height: 100%; }
  .btn.btn-icon-right, .btn-icon-right.btn-black, .btn-icon-right.btn-white, .btn-icon-right.btn-xs, .btn-icon-right.btn-sm, .btn-icon-right.btn-md, .btn-icon-right.btn-lg {
    text-align: right;
    padding-right: 60px; }
    .btn.btn-icon-right .icon, .btn-icon-right.btn-black .icon, .btn-icon-right.btn-white .icon, .btn-icon-right.btn-xs .icon, .btn-icon-right.btn-sm .icon, .btn-icon-right.btn-md .icon, .btn-icon-right.btn-lg .icon {
      right: 20px; }
  .btn.btn-icon-left, .btn-icon-left.btn-black, .btn-icon-left.btn-white, .btn-icon-left.btn-xs, .btn-icon-left.btn-sm, .btn-icon-left.btn-md, .btn-icon-left.btn-lg {
    text-align: left;
    padding-left: 60px; }
    .btn.btn-icon-left .icon, .btn-icon-left.btn-black .icon, .btn-icon-left.btn-white .icon, .btn-icon-left.btn-xs .icon, .btn-icon-left.btn-sm .icon, .btn-icon-left.btn-md .icon, .btn-icon-left.btn-lg .icon {
      left: 20px; }

.btn-black {
  background-color: #000000;
  border-color: #000000;
  color: #FFFFFF; }
  .btn-black:hover {
    color: #FFFFFF; }
  .btn-black .icon {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    height: 100%;
    transition: all 0.4s;
    width: 30px; }
    .btn-black .icon svg, .btn-black .icon img {
      width: 100%;
      max-height: 100%; }
  .btn-black.btn-icon-right {
    text-align: right;
    padding-right: 60px; }
    .btn-black.btn-icon-right .icon {
      right: 20px; }
  .btn-black.btn-icon-left {
    text-align: left;
    padding-left: 60px; }
    .btn-black.btn-icon-left .icon {
      left: 20px; }

.btn-white {
  background-color: #FFFFFF;
  border-color: #FFFFFF;
  color: #000000; }
  .btn-white:hover {
    color: #000000; }
  .btn-white .icon {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    height: 100%;
    transition: all 0.4s;
    width: 30px; }
    .btn-white .icon svg, .btn-white .icon img {
      width: 100%;
      max-height: 100%; }
  .btn-white.btn-icon-right {
    text-align: right;
    padding-right: 60px; }
    .btn-white.btn-icon-right .icon {
      right: 20px; }
  .btn-white.btn-icon-left {
    text-align: left;
    padding-left: 60px; }
    .btn-white.btn-icon-left .icon {
      left: 20px; }

.btn-xs {
  padding: 2px 5px;
  font-size: 10px; }
  .btn-xs .icon {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    height: 100%;
    transition: all 0.4s;
    width: 10px; }
    .btn-xs .icon svg, .btn-xs .icon img {
      width: 100%;
      max-height: 100%; }
  .btn-xs.btn-icon-right {
    text-align: right;
    padding-right: 25px; }
    .btn-xs.btn-icon-right .icon {
      right: 5px; }
  .btn-xs.btn-icon-left {
    text-align: left;
    padding-left: 25px; }
    .btn-xs.btn-icon-left .icon {
      left: 5px; }

.btn-sm {
  padding: 5px 10px;
  font-size: 14px; }
  .btn-sm .icon {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    height: 100%;
    transition: all 0.4s;
    width: 20px; }
    .btn-sm .icon svg, .btn-sm .icon img {
      width: 100%;
      max-height: 100%; }
  .btn-sm.btn-icon-right {
    text-align: right;
    padding-right: 40px; }
    .btn-sm.btn-icon-right .icon {
      right: 10px; }
  .btn-sm.btn-icon-left {
    text-align: left;
    padding-left: 40px; }
    .btn-sm.btn-icon-left .icon {
      left: 10px; }

.btn-md {
  padding: 10px 20px;
  font-size: 16px; }
  .btn-md .icon {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    height: 100%;
    transition: all 0.4s;
    width: 30px; }
    .btn-md .icon svg, .btn-md .icon img {
      width: 100%;
      max-height: 100%; }
  .btn-md.btn-icon-right {
    text-align: right;
    padding-right: 60px; }
    .btn-md.btn-icon-right .icon {
      right: 20px; }
  .btn-md.btn-icon-left {
    text-align: left;
    padding-left: 60px; }
    .btn-md.btn-icon-left .icon {
      left: 20px; }

.btn-lg {
  padding: 20px 30px;
  font-size: 20px; }
  .btn-lg .icon {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    height: 100%;
    transition: all 0.4s;
    width: 50px; }
    .btn-lg .icon svg, .btn-lg .icon img {
      width: 100%;
      max-height: 100%; }
  .btn-lg.btn-icon-right {
    text-align: right;
    padding-right: 90px; }
    .btn-lg.btn-icon-right .icon {
      right: 30px; }
  .btn-lg.btn-icon-left {
    text-align: left;
    padding-left: 90px; }
    .btn-lg.btn-icon-left .icon {
      left: 30px; }

/*------- BACKGROUND-COLORS -------*/
.bg-black {
  background-color: #000000 ; }

.bg-very-dark-grey {
  background-color: #141414 ; }

.bg-dark-grey {
  background-color: #424242 ; }

.bg-grey {
  background-color: #808080 ; }

.bg-light-grey {
  background-color: #dedede ; }

.bg-very-light-grey {
  background-color: #fafafa ; }

.bg-white {
  background-color: #FFFFFF ; }

/*------- TEXT-ALIGN -------*/
.ta-r {
  text-align: right ; }

.ta-l {
  text-align: left ; }

.ta-c {
  text-align: center ; }

.ta-j {
  text-align: justify ; }

@media (max-width: 1274.98px) {
  .lg-down-ta-r {
    text-align: right; } }

@media (max-width: 991.98px) {
  .md-down-ta-r {
    text-align: right; } }

@media (max-width: 767.98px) {
  .sm-down-ta-r {
    text-align: right; } }

@media (max-width: 575.98px) {
  .xs-down-ta-r {
    text-align: right; } }

.xs-up-ta-r {
  text-align: right; }

@media (min-width: 576px) {
  .sm-up-ta-r {
    text-align: right; } }

@media (min-width: 768px) {
  .md-up-ta-r {
    text-align: right; } }

@media (min-width: 992px) {
  .lg-up-ta-r {
    text-align: right; } }

@media (min-width: 1275px) {
  .xl-up-ta-r {
    text-align: right; } }

@media (max-width: 1274.98px) {
  .lg-down-ta-l {
    text-align: left; } }

@media (max-width: 991.98px) {
  .md-down-ta-l {
    text-align: left; } }

@media (max-width: 767.98px) {
  .sm-down-ta-l {
    text-align: left; } }

@media (max-width: 575.98px) {
  .xs-down-ta-l {
    text-align: left; } }

.xs-up-ta-l {
  text-align: left; }

@media (min-width: 576px) {
  .sm-up-ta-l {
    text-align: left; } }

@media (min-width: 768px) {
  .md-up-ta-l {
    text-align: left; } }

@media (min-width: 992px) {
  .lg-up-ta-l {
    text-align: left; } }

@media (min-width: 1275px) {
  .xl-up-ta-l {
    text-align: left; } }

@media (max-width: 1274.98px) {
  .lg-down-ta-c {
    text-align: center; } }

@media (max-width: 991.98px) {
  .md-down-ta-c {
    text-align: center; } }

@media (max-width: 767.98px) {
  .sm-down-ta-c {
    text-align: center; } }

@media (max-width: 575.98px) {
  .xs-down-ta-c {
    text-align: center; } }

.xs-up-ta-c {
  text-align: center; }

@media (min-width: 576px) {
  .sm-up-ta-c {
    text-align: center; } }

@media (min-width: 768px) {
  .md-up-ta-c {
    text-align: center; } }

@media (min-width: 992px) {
  .lg-up-ta-c {
    text-align: center; } }

@media (min-width: 1275px) {
  .xl-up-ta-c {
    text-align: center; } }

@media (max-width: 1274.98px) {
  .lg-down-ta-j {
    text-align: justify; } }

@media (max-width: 991.98px) {
  .md-down-ta-j {
    text-align: justify; } }

@media (max-width: 767.98px) {
  .sm-down-ta-j {
    text-align: justify; } }

@media (max-width: 575.98px) {
  .xs-down-ta-j {
    text-align: justify; } }

.xs-up-ta-j {
  text-align: justify; }

@media (min-width: 576px) {
  .sm-up-ta-j {
    text-align: justify; } }

@media (min-width: 768px) {
  .md-up-ta-j {
    text-align: justify; } }

@media (min-width: 992px) {
  .lg-up-ta-j {
    text-align: justify; } }

@media (min-width: 1275px) {
  .xl-up-ta-j {
    text-align: justify; } }

/*------- LETTER SPACING -------*/
.ls-xs {
  letter-spacing: 1px ; }

.ls-sm {
  letter-spacing: 2px ; }

.ls-md {
  letter-spacing: 3px ; }

.ls-lg {
  letter-spacing: 4px ; }

.ls-xl {
  letter-spacing: 5px ; }

.ls-xxl {
  letter-spacing: 6px ; }

/*------- DISPLAYS -------*/
.d-none {
  display: none ; }

.d-i {
  display: inline ; }

.d-b {
  display: block ; }

.d-ib {
  display: inline-block ; }

.d-f {
  display: flex ; }

.d-if {
  display: inline-flex ; }

@media (max-width: 1274.98px) {
  .lg-down-d-none {
    display: none; } }

@media (max-width: 991.98px) {
  .md-down-d-none {
    display: none; } }

@media (max-width: 767.98px) {
  .sm-down-d-none {
    display: none; } }

@media (max-width: 575.98px) {
  .xs-down-d-none {
    display: none; } }

.xs-up-d-none {
  display: none; }

@media (min-width: 576px) {
  .sm-up-d-none {
    display: none; } }

@media (min-width: 768px) {
  .md-up-d-none {
    display: none; } }

@media (min-width: 992px) {
  .lg-up-d-none {
    display: none; } }

@media (min-width: 1275px) {
  .xl-up-d-none {
    display: none; } }

@media (max-width: 1274.98px) {
  .lg-down-d-i {
    display: inline; } }

@media (max-width: 991.98px) {
  .md-down-d-i {
    display: inline; } }

@media (max-width: 767.98px) {
  .sm-down-d-i {
    display: inline; } }

@media (max-width: 575.98px) {
  .xs-down-d-i {
    display: inline; } }

.xs-up-d-i {
  display: inline; }

@media (min-width: 576px) {
  .sm-up-d-i {
    display: inline; } }

@media (min-width: 768px) {
  .md-up-d-i {
    display: inline; } }

@media (min-width: 992px) {
  .lg-up-d-i {
    display: inline; } }

@media (min-width: 1275px) {
  .xl-up-d-i {
    display: inline; } }

@media (max-width: 1274.98px) {
  .lg-down-d-b {
    display: block; } }

@media (max-width: 991.98px) {
  .md-down-d-b {
    display: block; } }

@media (max-width: 767.98px) {
  .sm-down-d-b {
    display: block; } }

@media (max-width: 575.98px) {
  .xs-down-d-b {
    display: block; } }

.xs-up-d-b {
  display: block; }

@media (min-width: 576px) {
  .sm-up-d-b {
    display: block; } }

@media (min-width: 768px) {
  .md-up-d-b {
    display: block; } }

@media (min-width: 992px) {
  .lg-up-d-b {
    display: block; } }

@media (min-width: 1275px) {
  .xl-up-d-b {
    display: block; } }

@media (max-width: 1274.98px) {
  .lg-down-d-ib {
    display: inline-block; } }

@media (max-width: 991.98px) {
  .md-down-d-ib {
    display: inline-block; } }

@media (max-width: 767.98px) {
  .sm-down-d-ib {
    display: inline-block; } }

@media (max-width: 575.98px) {
  .xs-down-d-ib {
    display: inline-block; } }

.xs-up-d-ib {
  display: inline-block; }

@media (min-width: 576px) {
  .sm-up-d-ib {
    display: inline-block; } }

@media (min-width: 768px) {
  .md-up-d-ib {
    display: inline-block; } }

@media (min-width: 992px) {
  .lg-up-d-ib {
    display: inline-block; } }

@media (min-width: 1275px) {
  .xl-up-d-ib {
    display: inline-block; } }

@media (max-width: 1274.98px) {
  .lg-down-d-f {
    display: flex; } }

@media (max-width: 991.98px) {
  .md-down-d-f {
    display: flex; } }

@media (max-width: 767.98px) {
  .sm-down-d-f {
    display: flex; } }

@media (max-width: 575.98px) {
  .xs-down-d-f {
    display: flex; } }

.xs-up-d-f {
  display: flex; }

@media (min-width: 576px) {
  .sm-up-d-f {
    display: flex; } }

@media (min-width: 768px) {
  .md-up-d-f {
    display: flex; } }

@media (min-width: 992px) {
  .lg-up-d-f {
    display: flex; } }

@media (min-width: 1275px) {
  .xl-up-d-f {
    display: flex; } }

@media (max-width: 1274.98px) {
  .lg-down-d-if {
    display: inline-flex; } }

@media (max-width: 991.98px) {
  .md-down-d-if {
    display: inline-flex; } }

@media (max-width: 767.98px) {
  .sm-down-d-if {
    display: inline-flex; } }

@media (max-width: 575.98px) {
  .xs-down-d-if {
    display: inline-flex; } }

.xs-up-d-if {
  display: inline-flex; }

@media (min-width: 576px) {
  .sm-up-d-if {
    display: inline-flex; } }

@media (min-width: 768px) {
  .md-up-d-if {
    display: inline-flex; } }

@media (min-width: 992px) {
  .lg-up-d-if {
    display: inline-flex; } }

@media (min-width: 1275px) {
  .xl-up-d-if {
    display: inline-flex; } }

/*------- SECTIONS -------*/
.section {
  position: relative;
  padding-top: 100px !important;
  padding-bottom: 100px !important; }
  @media (max-width: 1274.98px) {
    .section {
      padding-top: 100px !important;
      padding-bottom: 100px !important; } }
  @media (max-width: 991.98px) {
    .section {
      padding-top: 100px !important;
      padding-bottom: 100px !important; } }
  @media (max-width: 767.98px) {
    .section {
      padding-top: 100px !important;
      padding-bottom: 100px !important; } }
  @media (max-width: 575.98px) {
    .section {
      padding-top: 100px !important;
      padding-bottom: 100px !important; } }

/*------- BG IMG -------*/
.bg-img, .bg-img-square, .bg-img-almost-square, .bg-img-landscape, .bg-img-landscape-sm, .bg-img-portrait {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: #000; }
  .bg-img:after, .bg-img-square:after, .bg-img-almost-square:after, .bg-img-landscape:after, .bg-img-landscape-sm:after, .bg-img-portrait:after {
    content: '';
    display: block; }

.bg-img-square:after {
  padding-bottom: 100%; }

.bg-img-almost-square:after {
  padding-bottom: 85%; }

.bg-img-landscape:after {
  padding-bottom: 70%; }

.bg-img-landscape-sm:after {
  padding-bottom: 60%; }

.bg-img-portrait:after {
  padding-bottom: 130%; }

/*------- MARGINS & PADDINGS -------*/
.m-0 {
  margin: 0 !important; }

.mt-0 {
  margin-top: 0 !important; }

.mb-0 {
  margin-bottom: 0 !important; }

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.ml-0 {
  margin-left: 0 !important; }

.mr-0 {
  margin-right: 0 !important; }

.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important; }

.p-0 {
  padding: 0 !important; }

.pt-0 {
  padding-top: 0 !important; }

.pb-0 {
  padding-bottom: 0 !important; }

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.pl-0 {
  padding-left: 0 !important; }

.pr-0 {
  padding-right: 0 !important; }

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.m-xs {
  margin: 5px !important; }

.mt-xs {
  margin-top: 5px !important; }

.mb-xs {
  margin-bottom: 5px !important; }

.my-xs {
  margin-top: 5px !important;
  margin-bottom: 5px !important; }

.ml-xs {
  margin-left: 5px !important; }

.mr-xs {
  margin-right: 5px !important; }

.mx-xs {
  margin-left: 5px !important;
  margin-right: 5px !important; }

.p-xs {
  padding: 5px !important; }

.pt-xs {
  padding-top: 5px !important; }

.pb-xs {
  padding-bottom: 5px !important; }

.py-xs {
  padding-top: 5px !important;
  padding-bottom: 5px !important; }

.pl-xs {
  padding-left: 5px !important; }

.pr-xs {
  padding-right: 5px !important; }

.px-xs {
  padding-left: 5px !important;
  padding-right: 5px !important; }

.m-sm {
  margin: 15px !important; }

.mt-sm {
  margin-top: 15px !important; }

.mb-sm {
  margin-bottom: 15px !important; }

.my-sm {
  margin-top: 15px !important;
  margin-bottom: 15px !important; }

.ml-sm {
  margin-left: 15px !important; }

.mr-sm {
  margin-right: 15px !important; }

.mx-sm {
  margin-left: 15px !important;
  margin-right: 15px !important; }

.p-sm {
  padding: 15px !important; }

.pt-sm {
  padding-top: 15px !important; }

.pb-sm {
  padding-bottom: 15px !important; }

.py-sm {
  padding-top: 15px !important;
  padding-bottom: 15px !important; }

.pl-sm {
  padding-left: 15px !important; }

.pr-sm {
  padding-right: 15px !important; }

.px-sm {
  padding-left: 15px !important;
  padding-right: 15px !important; }

.m-md {
  margin: 24px !important; }

.mt-md {
  margin-top: 24px !important; }

.mb-md {
  margin-bottom: 24px !important; }

.my-md {
  margin-top: 24px !important;
  margin-bottom: 24px !important; }

.ml-md {
  margin-left: 24px !important; }

.mr-md {
  margin-right: 24px !important; }

.mx-md {
  margin-left: 24px !important;
  margin-right: 24px !important; }

.p-md {
  padding: 24px !important; }

.pt-md {
  padding-top: 24px !important; }

.pb-md {
  padding-bottom: 24px !important; }

.py-md {
  padding-top: 24px !important;
  padding-bottom: 24px !important; }

.pl-md {
  padding-left: 24px !important; }

.pr-md {
  padding-right: 24px !important; }

.px-md {
  padding-left: 24px !important;
  padding-right: 24px !important; }

.m-lg {
  margin: 50px !important; }

.mt-lg {
  margin-top: 50px !important; }

.mb-lg {
  margin-bottom: 50px !important; }

.my-lg {
  margin-top: 50px !important;
  margin-bottom: 50px !important; }

.ml-lg {
  margin-left: 50px !important; }

.mr-lg {
  margin-right: 50px !important; }

.mx-lg {
  margin-left: 50px !important;
  margin-right: 50px !important; }

.p-lg {
  padding: 50px !important; }

.pt-lg {
  padding-top: 50px !important; }

.pb-lg {
  padding-bottom: 50px !important; }

.py-lg {
  padding-top: 50px !important;
  padding-bottom: 50px !important; }

.pl-lg {
  padding-left: 50px !important; }

.pr-lg {
  padding-right: 50px !important; }

.px-lg {
  padding-left: 50px !important;
  padding-right: 50px !important; }

.m-xl {
  margin: 70px !important; }

.mt-xl {
  margin-top: 70px !important; }

.mb-xl {
  margin-bottom: 70px !important; }

.my-xl {
  margin-top: 70px !important;
  margin-bottom: 70px !important; }

.ml-xl {
  margin-left: 70px !important; }

.mr-xl {
  margin-right: 70px !important; }

.mx-xl {
  margin-left: 70px !important;
  margin-right: 70px !important; }

.p-xl {
  padding: 70px !important; }

.pt-xl {
  padding-top: 70px !important; }

.pb-xl {
  padding-bottom: 70px !important; }

.py-xl {
  padding-top: 70px !important;
  padding-bottom: 70px !important; }

.pl-xl {
  padding-left: 70px !important; }

.pr-xl {
  padding-right: 70px !important; }

.px-xl {
  padding-left: 70px !important;
  padding-right: 70px !important; }

.m-xxl {
  margin: 140px !important; }

.mt-xxl {
  margin-top: 140px !important; }

.mb-xxl {
  margin-bottom: 140px !important; }

.my-xxl {
  margin-top: 140px !important;
  margin-bottom: 140px !important; }

.ml-xxl {
  margin-left: 140px !important; }

.mr-xxl {
  margin-right: 140px !important; }

.mx-xxl {
  margin-left: 140px !important;
  margin-right: 140px !important; }

.p-xxl {
  padding: 140px !important; }

.pt-xxl {
  padding-top: 140px !important; }

.pb-xxl {
  padding-bottom: 140px !important; }

.py-xxl {
  padding-top: 140px !important;
  padding-bottom: 140px !important; }

.pl-xxl {
  padding-left: 140px !important; }

.pr-xxl {
  padding-right: 140px !important; }

.px-xxl {
  padding-left: 140px !important;
  padding-right: 140px !important; }

.m-0-child > * {
  margin: 0 !important; }

.m-0-child-not-last > *:not(:last-child) {
  margin: 0 !important; }

.mt-0-child > * {
  margin-top: 0 !important; }

.mt-0-child-not-last > *:not(:last-child) {
  margin-top: 0 !important; }

.mb-0-child > * {
  margin-bottom: 0 !important; }

.mb-0-child-not-last > *:not(:last-child) {
  margin-bottom: 0 !important; }

.my-0-child > * {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.my-0-child-not-last > *:not(:last-child) {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.ml-0-child > * {
  margin-left: 0 !important; }

.ml-0-child-not-last > *:not(:last-child) {
  margin-left: 0 !important; }

.mr-0-child > * {
  margin-right: 0 !important; }

.mr-0-child-not-last > *:not(:last-child) {
  margin-right: 0 !important; }

.mx-0-child > * {
  margin-left: 0 !important;
  margin-right: 0 !important; }

.mx-0-child-not-last > *:not(:last-child) {
  margin-left: 0 !important;
  margin-right: 0 !important; }

.p-0-child > * {
  padding: 0 !important; }

.p-0-child-not-last > *:not(:last-child) {
  padding: 0 !important; }

.pt-0-child > * {
  padding-top: 0 !important; }

.pt-0-child-not-last > *:not(:last-child) {
  padding-top: 0 !important; }

.pb-0-child > * {
  padding-bottom: 0 !important; }

.pb-0-child-not-last > *:not(:last-child) {
  padding-bottom: 0 !important; }

.py-0-child > * {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.py-0-child-not-last > *:not(:last-child) {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.pl-0-child > * {
  padding-left: 0 !important; }

.pl-0-child-not-last > *:not(:last-child) {
  padding-left: 0 !important; }

.pr-0-child > * {
  padding-right: 0 !important; }

.pr-0-child-not-last > *:not(:last-child) {
  padding-right: 0 !important; }

.px-0-child > * {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.px-0-child-not-last > *:not(:last-child) {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.m-xs-child > * {
  margin: 5px !important; }

.m-xs-child-not-last > *:not(:last-child) {
  margin: 5px !important; }

.mt-xs-child > * {
  margin-top: 5px !important; }

.mt-xs-child-not-last > *:not(:last-child) {
  margin-top: 5px !important; }

.mb-xs-child > * {
  margin-bottom: 5px !important; }

.mb-xs-child-not-last > *:not(:last-child) {
  margin-bottom: 5px !important; }

.my-xs-child > * {
  margin-top: 5px !important;
  margin-bottom: 5px !important; }

.my-xs-child-not-last > *:not(:last-child) {
  margin-top: 5px !important;
  margin-bottom: 5px !important; }

.ml-xs-child > * {
  margin-left: 5px !important; }

.ml-xs-child-not-last > *:not(:last-child) {
  margin-left: 5px !important; }

.mr-xs-child > * {
  margin-right: 5px !important; }

.mr-xs-child-not-last > *:not(:last-child) {
  margin-right: 5px !important; }

.mx-xs-child > * {
  margin-left: 5px !important;
  margin-right: 5px !important; }

.mx-xs-child-not-last > *:not(:last-child) {
  margin-left: 5px !important;
  margin-right: 5px !important; }

.p-xs-child > * {
  padding: 5px !important; }

.p-xs-child-not-last > *:not(:last-child) {
  padding: 5px !important; }

.pt-xs-child > * {
  padding-top: 5px !important; }

.pt-xs-child-not-last > *:not(:last-child) {
  padding-top: 5px !important; }

.pb-xs-child > * {
  padding-bottom: 5px !important; }

.pb-xs-child-not-last > *:not(:last-child) {
  padding-bottom: 5px !important; }

.py-xs-child > * {
  padding-top: 5px !important;
  padding-bottom: 5px !important; }

.py-xs-child-not-last > *:not(:last-child) {
  padding-top: 5px !important;
  padding-bottom: 5px !important; }

.pl-xs-child > * {
  padding-left: 5px !important; }

.pl-xs-child-not-last > *:not(:last-child) {
  padding-left: 5px !important; }

.pr-xs-child > * {
  padding-right: 5px !important; }

.pr-xs-child-not-last > *:not(:last-child) {
  padding-right: 5px !important; }

.px-xs-child > * {
  padding-left: 5px !important;
  padding-right: 5px !important; }

.px-xs-child-not-last > *:not(:last-child) {
  padding-left: 5px !important;
  padding-right: 5px !important; }

.m-sm-child > * {
  margin: 15px !important; }

.m-sm-child-not-last > *:not(:last-child) {
  margin: 15px !important; }

.mt-sm-child > * {
  margin-top: 15px !important; }

.mt-sm-child-not-last > *:not(:last-child) {
  margin-top: 15px !important; }

.mb-sm-child > * {
  margin-bottom: 15px !important; }

.mb-sm-child-not-last > *:not(:last-child) {
  margin-bottom: 15px !important; }

.my-sm-child > * {
  margin-top: 15px !important;
  margin-bottom: 15px !important; }

.my-sm-child-not-last > *:not(:last-child) {
  margin-top: 15px !important;
  margin-bottom: 15px !important; }

.ml-sm-child > * {
  margin-left: 15px !important; }

.ml-sm-child-not-last > *:not(:last-child) {
  margin-left: 15px !important; }

.mr-sm-child > * {
  margin-right: 15px !important; }

.mr-sm-child-not-last > *:not(:last-child) {
  margin-right: 15px !important; }

.mx-sm-child > * {
  margin-left: 15px !important;
  margin-right: 15px !important; }

.mx-sm-child-not-last > *:not(:last-child) {
  margin-left: 15px !important;
  margin-right: 15px !important; }

.p-sm-child > * {
  padding: 15px !important; }

.p-sm-child-not-last > *:not(:last-child) {
  padding: 15px !important; }

.pt-sm-child > * {
  padding-top: 15px !important; }

.pt-sm-child-not-last > *:not(:last-child) {
  padding-top: 15px !important; }

.pb-sm-child > * {
  padding-bottom: 15px !important; }

.pb-sm-child-not-last > *:not(:last-child) {
  padding-bottom: 15px !important; }

.py-sm-child > * {
  padding-top: 15px !important;
  padding-bottom: 15px !important; }

.py-sm-child-not-last > *:not(:last-child) {
  padding-top: 15px !important;
  padding-bottom: 15px !important; }

.pl-sm-child > * {
  padding-left: 15px !important; }

.pl-sm-child-not-last > *:not(:last-child) {
  padding-left: 15px !important; }

.pr-sm-child > * {
  padding-right: 15px !important; }

.pr-sm-child-not-last > *:not(:last-child) {
  padding-right: 15px !important; }

.px-sm-child > * {
  padding-left: 15px !important;
  padding-right: 15px !important; }

.px-sm-child-not-last > *:not(:last-child) {
  padding-left: 15px !important;
  padding-right: 15px !important; }

.m-md-child > * {
  margin: 24px !important; }

.m-md-child-not-last > *:not(:last-child) {
  margin: 24px !important; }

.mt-md-child > * {
  margin-top: 24px !important; }

.mt-md-child-not-last > *:not(:last-child) {
  margin-top: 24px !important; }

.mb-md-child > * {
  margin-bottom: 24px !important; }

.mb-md-child-not-last > *:not(:last-child) {
  margin-bottom: 24px !important; }

.my-md-child > * {
  margin-top: 24px !important;
  margin-bottom: 24px !important; }

.my-md-child-not-last > *:not(:last-child) {
  margin-top: 24px !important;
  margin-bottom: 24px !important; }

.ml-md-child > * {
  margin-left: 24px !important; }

.ml-md-child-not-last > *:not(:last-child) {
  margin-left: 24px !important; }

.mr-md-child > * {
  margin-right: 24px !important; }

.mr-md-child-not-last > *:not(:last-child) {
  margin-right: 24px !important; }

.mx-md-child > * {
  margin-left: 24px !important;
  margin-right: 24px !important; }

.mx-md-child-not-last > *:not(:last-child) {
  margin-left: 24px !important;
  margin-right: 24px !important; }

.p-md-child > * {
  padding: 24px !important; }

.p-md-child-not-last > *:not(:last-child) {
  padding: 24px !important; }

.pt-md-child > * {
  padding-top: 24px !important; }

.pt-md-child-not-last > *:not(:last-child) {
  padding-top: 24px !important; }

.pb-md-child > * {
  padding-bottom: 24px !important; }

.pb-md-child-not-last > *:not(:last-child) {
  padding-bottom: 24px !important; }

.py-md-child > * {
  padding-top: 24px !important;
  padding-bottom: 24px !important; }

.py-md-child-not-last > *:not(:last-child) {
  padding-top: 24px !important;
  padding-bottom: 24px !important; }

.pl-md-child > * {
  padding-left: 24px !important; }

.pl-md-child-not-last > *:not(:last-child) {
  padding-left: 24px !important; }

.pr-md-child > * {
  padding-right: 24px !important; }

.pr-md-child-not-last > *:not(:last-child) {
  padding-right: 24px !important; }

.px-md-child > * {
  padding-left: 24px !important;
  padding-right: 24px !important; }

.px-md-child-not-last > *:not(:last-child) {
  padding-left: 24px !important;
  padding-right: 24px !important; }

.m-lg-child > * {
  margin: 50px !important; }

.m-lg-child-not-last > *:not(:last-child) {
  margin: 50px !important; }

.mt-lg-child > * {
  margin-top: 50px !important; }

.mt-lg-child-not-last > *:not(:last-child) {
  margin-top: 50px !important; }

.mb-lg-child > * {
  margin-bottom: 50px !important; }

.mb-lg-child-not-last > *:not(:last-child) {
  margin-bottom: 50px !important; }

.my-lg-child > * {
  margin-top: 50px !important;
  margin-bottom: 50px !important; }

.my-lg-child-not-last > *:not(:last-child) {
  margin-top: 50px !important;
  margin-bottom: 50px !important; }

.ml-lg-child > * {
  margin-left: 50px !important; }

.ml-lg-child-not-last > *:not(:last-child) {
  margin-left: 50px !important; }

.mr-lg-child > * {
  margin-right: 50px !important; }

.mr-lg-child-not-last > *:not(:last-child) {
  margin-right: 50px !important; }

.mx-lg-child > * {
  margin-left: 50px !important;
  margin-right: 50px !important; }

.mx-lg-child-not-last > *:not(:last-child) {
  margin-left: 50px !important;
  margin-right: 50px !important; }

.p-lg-child > * {
  padding: 50px !important; }

.p-lg-child-not-last > *:not(:last-child) {
  padding: 50px !important; }

.pt-lg-child > * {
  padding-top: 50px !important; }

.pt-lg-child-not-last > *:not(:last-child) {
  padding-top: 50px !important; }

.pb-lg-child > * {
  padding-bottom: 50px !important; }

.pb-lg-child-not-last > *:not(:last-child) {
  padding-bottom: 50px !important; }

.py-lg-child > * {
  padding-top: 50px !important;
  padding-bottom: 50px !important; }

.py-lg-child-not-last > *:not(:last-child) {
  padding-top: 50px !important;
  padding-bottom: 50px !important; }

.pl-lg-child > * {
  padding-left: 50px !important; }

.pl-lg-child-not-last > *:not(:last-child) {
  padding-left: 50px !important; }

.pr-lg-child > * {
  padding-right: 50px !important; }

.pr-lg-child-not-last > *:not(:last-child) {
  padding-right: 50px !important; }

.px-lg-child > * {
  padding-left: 50px !important;
  padding-right: 50px !important; }

.px-lg-child-not-last > *:not(:last-child) {
  padding-left: 50px !important;
  padding-right: 50px !important; }

.m-xl-child > * {
  margin: 70px !important; }

.m-xl-child-not-last > *:not(:last-child) {
  margin: 70px !important; }

.mt-xl-child > * {
  margin-top: 70px !important; }

.mt-xl-child-not-last > *:not(:last-child) {
  margin-top: 70px !important; }

.mb-xl-child > * {
  margin-bottom: 70px !important; }

.mb-xl-child-not-last > *:not(:last-child) {
  margin-bottom: 70px !important; }

.my-xl-child > * {
  margin-top: 70px !important;
  margin-bottom: 70px !important; }

.my-xl-child-not-last > *:not(:last-child) {
  margin-top: 70px !important;
  margin-bottom: 70px !important; }

.ml-xl-child > * {
  margin-left: 70px !important; }

.ml-xl-child-not-last > *:not(:last-child) {
  margin-left: 70px !important; }

.mr-xl-child > * {
  margin-right: 70px !important; }

.mr-xl-child-not-last > *:not(:last-child) {
  margin-right: 70px !important; }

.mx-xl-child > * {
  margin-left: 70px !important;
  margin-right: 70px !important; }

.mx-xl-child-not-last > *:not(:last-child) {
  margin-left: 70px !important;
  margin-right: 70px !important; }

.p-xl-child > * {
  padding: 70px !important; }

.p-xl-child-not-last > *:not(:last-child) {
  padding: 70px !important; }

.pt-xl-child > * {
  padding-top: 70px !important; }

.pt-xl-child-not-last > *:not(:last-child) {
  padding-top: 70px !important; }

.pb-xl-child > * {
  padding-bottom: 70px !important; }

.pb-xl-child-not-last > *:not(:last-child) {
  padding-bottom: 70px !important; }

.py-xl-child > * {
  padding-top: 70px !important;
  padding-bottom: 70px !important; }

.py-xl-child-not-last > *:not(:last-child) {
  padding-top: 70px !important;
  padding-bottom: 70px !important; }

.pl-xl-child > * {
  padding-left: 70px !important; }

.pl-xl-child-not-last > *:not(:last-child) {
  padding-left: 70px !important; }

.pr-xl-child > * {
  padding-right: 70px !important; }

.pr-xl-child-not-last > *:not(:last-child) {
  padding-right: 70px !important; }

.px-xl-child > * {
  padding-left: 70px !important;
  padding-right: 70px !important; }

.px-xl-child-not-last > *:not(:last-child) {
  padding-left: 70px !important;
  padding-right: 70px !important; }

.m-xxl-child > * {
  margin: 140px !important; }

.m-xxl-child-not-last > *:not(:last-child) {
  margin: 140px !important; }

.mt-xxl-child > * {
  margin-top: 140px !important; }

.mt-xxl-child-not-last > *:not(:last-child) {
  margin-top: 140px !important; }

.mb-xxl-child > * {
  margin-bottom: 140px !important; }

.mb-xxl-child-not-last > *:not(:last-child) {
  margin-bottom: 140px !important; }

.my-xxl-child > * {
  margin-top: 140px !important;
  margin-bottom: 140px !important; }

.my-xxl-child-not-last > *:not(:last-child) {
  margin-top: 140px !important;
  margin-bottom: 140px !important; }

.ml-xxl-child > * {
  margin-left: 140px !important; }

.ml-xxl-child-not-last > *:not(:last-child) {
  margin-left: 140px !important; }

.mr-xxl-child > * {
  margin-right: 140px !important; }

.mr-xxl-child-not-last > *:not(:last-child) {
  margin-right: 140px !important; }

.mx-xxl-child > * {
  margin-left: 140px !important;
  margin-right: 140px !important; }

.mx-xxl-child-not-last > *:not(:last-child) {
  margin-left: 140px !important;
  margin-right: 140px !important; }

.p-xxl-child > * {
  padding: 140px !important; }

.p-xxl-child-not-last > *:not(:last-child) {
  padding: 140px !important; }

.pt-xxl-child > * {
  padding-top: 140px !important; }

.pt-xxl-child-not-last > *:not(:last-child) {
  padding-top: 140px !important; }

.pb-xxl-child > * {
  padding-bottom: 140px !important; }

.pb-xxl-child-not-last > *:not(:last-child) {
  padding-bottom: 140px !important; }

.py-xxl-child > * {
  padding-top: 140px !important;
  padding-bottom: 140px !important; }

.py-xxl-child-not-last > *:not(:last-child) {
  padding-top: 140px !important;
  padding-bottom: 140px !important; }

.pl-xxl-child > * {
  padding-left: 140px !important; }

.pl-xxl-child-not-last > *:not(:last-child) {
  padding-left: 140px !important; }

.pr-xxl-child > * {
  padding-right: 140px !important; }

.pr-xxl-child-not-last > *:not(:last-child) {
  padding-right: 140px !important; }

.px-xxl-child > * {
  padding-left: 140px !important;
  padding-right: 140px !important; }

.px-xxl-child-not-last > *:not(:last-child) {
  padding-left: 140px !important;
  padding-right: 140px !important; }

@media (max-width: 1274.98px) {
  .lg-down-m-0 {
    margin: 0 !important; }
  .lg-down-m-0-child > * {
    margin: 0 !important; }
  .lg-down-m-0-child-not-last > *:not(:last-child) {
    margin: 0 !important; } }

@media (max-width: 1274.98px) {
  .lg-down-mt-0 {
    margin-top: 0 !important; }
  .lg-down-mt-0-child > * {
    margin-top: 0 !important; }
  .lg-down-mt-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important; } }

@media (max-width: 1274.98px) {
  .lg-down-mb-0 {
    margin-bottom: 0 !important; }
  .lg-down-mb-0-child > * {
    margin-bottom: 0 !important; }
  .lg-down-mb-0-child-not-last > *:not(:last-child) {
    margin-bottom: 0 !important; } }

@media (max-width: 1274.98px) {
  .lg-down-my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .lg-down-my-0-child > * {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .lg-down-my-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important;
    margin-bottom: 0 !important; } }

@media (max-width: 1274.98px) {
  .lg-down-ml-0 {
    margin-left: 0 !important; }
  .lg-down-ml-0-child > * {
    margin-left: 0 !important; }
  .lg-down-ml-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important; } }

@media (max-width: 1274.98px) {
  .lg-down-mr-0 {
    margin-right: 0 !important; }
  .lg-down-mr-0-child > * {
    margin-right: 0 !important; }
  .lg-down-mr-0-child-not-last > *:not(:last-child) {
    margin-right: 0 !important; } }

@media (max-width: 1274.98px) {
  .lg-down-mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .lg-down-mx-0-child > * {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .lg-down-mx-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important;
    margin-right: 0 !important; } }

@media (max-width: 1274.98px) {
  .lg-down-p-0 {
    padding: 0 !important; }
  .lg-down-p-0-child > * {
    padding: 0 !important; }
  .lg-down-p-0-child-not-last > *:not(:last-child) {
    padding: 0 !important; } }

@media (max-width: 1274.98px) {
  .lg-down-pt-0 {
    padding-top: 0 !important; }
  .lg-down-pt-0-child > * {
    padding-top: 0 !important; }
  .lg-down-pt-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important; } }

@media (max-width: 1274.98px) {
  .lg-down-pb-0 {
    padding-bottom: 0 !important; }
  .lg-down-pb-0-child > * {
    padding-bottom: 0 !important; }
  .lg-down-pb-0-child-not-last > *:not(:last-child) {
    padding-bottom: 0 !important; } }

@media (max-width: 1274.98px) {
  .lg-down-py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .lg-down-py-0-child > * {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .lg-down-py-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important;
    padding-bottom: 0 !important; } }

@media (max-width: 1274.98px) {
  .lg-down-pl-0 {
    padding-left: 0 !important; }
  .lg-down-pl-0-child > * {
    padding-left: 0 !important; }
  .lg-down-pl-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important; } }

@media (max-width: 1274.98px) {
  .lg-down-pr-0 {
    padding-right: 0 !important; }
  .lg-down-pr-0-child > * {
    padding-right: 0 !important; }
  .lg-down-pr-0-child-not-last > *:not(:last-child) {
    padding-right: 0 !important; } }

@media (max-width: 1274.98px) {
  .lg-down-px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .lg-down-px-0-child > * {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .lg-down-px-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important;
    padding-right: 0 !important; } }

@media (max-width: 1274.98px) {
  .lg-down-m-xs {
    margin: 5px !important; }
  .lg-down-m-xs-child > * {
    margin: 5px !important; }
  .lg-down-m-xs-child-not-last > *:not(:last-child) {
    margin: 5px !important; } }

@media (max-width: 1274.98px) {
  .lg-down-mt-xs {
    margin-top: 5px !important; }
  .lg-down-mt-xs-child > * {
    margin-top: 5px !important; }
  .lg-down-mt-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important; } }

@media (max-width: 1274.98px) {
  .lg-down-mb-xs {
    margin-bottom: 5px !important; }
  .lg-down-mb-xs-child > * {
    margin-bottom: 5px !important; }
  .lg-down-mb-xs-child-not-last > *:not(:last-child) {
    margin-bottom: 5px !important; } }

@media (max-width: 1274.98px) {
  .lg-down-my-xs {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .lg-down-my-xs-child > * {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .lg-down-my-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important;
    margin-bottom: 5px !important; } }

@media (max-width: 1274.98px) {
  .lg-down-ml-xs {
    margin-left: 5px !important; }
  .lg-down-ml-xs-child > * {
    margin-left: 5px !important; }
  .lg-down-ml-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important; } }

@media (max-width: 1274.98px) {
  .lg-down-mr-xs {
    margin-right: 5px !important; }
  .lg-down-mr-xs-child > * {
    margin-right: 5px !important; }
  .lg-down-mr-xs-child-not-last > *:not(:last-child) {
    margin-right: 5px !important; } }

@media (max-width: 1274.98px) {
  .lg-down-mx-xs {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .lg-down-mx-xs-child > * {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .lg-down-mx-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important;
    margin-right: 5px !important; } }

@media (max-width: 1274.98px) {
  .lg-down-p-xs {
    padding: 5px !important; }
  .lg-down-p-xs-child > * {
    padding: 5px !important; }
  .lg-down-p-xs-child-not-last > *:not(:last-child) {
    padding: 5px !important; } }

@media (max-width: 1274.98px) {
  .lg-down-pt-xs {
    padding-top: 5px !important; }
  .lg-down-pt-xs-child > * {
    padding-top: 5px !important; }
  .lg-down-pt-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important; } }

@media (max-width: 1274.98px) {
  .lg-down-pb-xs {
    padding-bottom: 5px !important; }
  .lg-down-pb-xs-child > * {
    padding-bottom: 5px !important; }
  .lg-down-pb-xs-child-not-last > *:not(:last-child) {
    padding-bottom: 5px !important; } }

@media (max-width: 1274.98px) {
  .lg-down-py-xs {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .lg-down-py-xs-child > * {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .lg-down-py-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important;
    padding-bottom: 5px !important; } }

@media (max-width: 1274.98px) {
  .lg-down-pl-xs {
    padding-left: 5px !important; }
  .lg-down-pl-xs-child > * {
    padding-left: 5px !important; }
  .lg-down-pl-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important; } }

@media (max-width: 1274.98px) {
  .lg-down-pr-xs {
    padding-right: 5px !important; }
  .lg-down-pr-xs-child > * {
    padding-right: 5px !important; }
  .lg-down-pr-xs-child-not-last > *:not(:last-child) {
    padding-right: 5px !important; } }

@media (max-width: 1274.98px) {
  .lg-down-px-xs {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .lg-down-px-xs-child > * {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .lg-down-px-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important;
    padding-right: 5px !important; } }

@media (max-width: 1274.98px) {
  .lg-down-m-sm {
    margin: 15px !important; }
  .lg-down-m-sm-child > * {
    margin: 15px !important; }
  .lg-down-m-sm-child-not-last > *:not(:last-child) {
    margin: 15px !important; } }

@media (max-width: 1274.98px) {
  .lg-down-mt-sm {
    margin-top: 15px !important; }
  .lg-down-mt-sm-child > * {
    margin-top: 15px !important; }
  .lg-down-mt-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important; } }

@media (max-width: 1274.98px) {
  .lg-down-mb-sm {
    margin-bottom: 15px !important; }
  .lg-down-mb-sm-child > * {
    margin-bottom: 15px !important; }
  .lg-down-mb-sm-child-not-last > *:not(:last-child) {
    margin-bottom: 15px !important; } }

@media (max-width: 1274.98px) {
  .lg-down-my-sm {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .lg-down-my-sm-child > * {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .lg-down-my-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important;
    margin-bottom: 15px !important; } }

@media (max-width: 1274.98px) {
  .lg-down-ml-sm {
    margin-left: 15px !important; }
  .lg-down-ml-sm-child > * {
    margin-left: 15px !important; }
  .lg-down-ml-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important; } }

@media (max-width: 1274.98px) {
  .lg-down-mr-sm {
    margin-right: 15px !important; }
  .lg-down-mr-sm-child > * {
    margin-right: 15px !important; }
  .lg-down-mr-sm-child-not-last > *:not(:last-child) {
    margin-right: 15px !important; } }

@media (max-width: 1274.98px) {
  .lg-down-mx-sm {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .lg-down-mx-sm-child > * {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .lg-down-mx-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important;
    margin-right: 15px !important; } }

@media (max-width: 1274.98px) {
  .lg-down-p-sm {
    padding: 15px !important; }
  .lg-down-p-sm-child > * {
    padding: 15px !important; }
  .lg-down-p-sm-child-not-last > *:not(:last-child) {
    padding: 15px !important; } }

@media (max-width: 1274.98px) {
  .lg-down-pt-sm {
    padding-top: 15px !important; }
  .lg-down-pt-sm-child > * {
    padding-top: 15px !important; }
  .lg-down-pt-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important; } }

@media (max-width: 1274.98px) {
  .lg-down-pb-sm {
    padding-bottom: 15px !important; }
  .lg-down-pb-sm-child > * {
    padding-bottom: 15px !important; }
  .lg-down-pb-sm-child-not-last > *:not(:last-child) {
    padding-bottom: 15px !important; } }

@media (max-width: 1274.98px) {
  .lg-down-py-sm {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .lg-down-py-sm-child > * {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .lg-down-py-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important;
    padding-bottom: 15px !important; } }

@media (max-width: 1274.98px) {
  .lg-down-pl-sm {
    padding-left: 15px !important; }
  .lg-down-pl-sm-child > * {
    padding-left: 15px !important; }
  .lg-down-pl-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important; } }

@media (max-width: 1274.98px) {
  .lg-down-pr-sm {
    padding-right: 15px !important; }
  .lg-down-pr-sm-child > * {
    padding-right: 15px !important; }
  .lg-down-pr-sm-child-not-last > *:not(:last-child) {
    padding-right: 15px !important; } }

@media (max-width: 1274.98px) {
  .lg-down-px-sm {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .lg-down-px-sm-child > * {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .lg-down-px-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important;
    padding-right: 15px !important; } }

@media (max-width: 1274.98px) {
  .lg-down-m-md {
    margin: 24px !important; }
  .lg-down-m-md-child > * {
    margin: 24px !important; }
  .lg-down-m-md-child-not-last > *:not(:last-child) {
    margin: 24px !important; } }

@media (max-width: 1274.98px) {
  .lg-down-mt-md {
    margin-top: 24px !important; }
  .lg-down-mt-md-child > * {
    margin-top: 24px !important; }
  .lg-down-mt-md-child-not-last > *:not(:last-child) {
    margin-top: 24px !important; } }

@media (max-width: 1274.98px) {
  .lg-down-mb-md {
    margin-bottom: 24px !important; }
  .lg-down-mb-md-child > * {
    margin-bottom: 24px !important; }
  .lg-down-mb-md-child-not-last > *:not(:last-child) {
    margin-bottom: 24px !important; } }

@media (max-width: 1274.98px) {
  .lg-down-my-md {
    margin-top: 24px !important;
    margin-bottom: 24px !important; }
  .lg-down-my-md-child > * {
    margin-top: 24px !important;
    margin-bottom: 24px !important; }
  .lg-down-my-md-child-not-last > *:not(:last-child) {
    margin-top: 24px !important;
    margin-bottom: 24px !important; } }

@media (max-width: 1274.98px) {
  .lg-down-ml-md {
    margin-left: 24px !important; }
  .lg-down-ml-md-child > * {
    margin-left: 24px !important; }
  .lg-down-ml-md-child-not-last > *:not(:last-child) {
    margin-left: 24px !important; } }

@media (max-width: 1274.98px) {
  .lg-down-mr-md {
    margin-right: 24px !important; }
  .lg-down-mr-md-child > * {
    margin-right: 24px !important; }
  .lg-down-mr-md-child-not-last > *:not(:last-child) {
    margin-right: 24px !important; } }

@media (max-width: 1274.98px) {
  .lg-down-mx-md {
    margin-left: 24px !important;
    margin-right: 24px !important; }
  .lg-down-mx-md-child > * {
    margin-left: 24px !important;
    margin-right: 24px !important; }
  .lg-down-mx-md-child-not-last > *:not(:last-child) {
    margin-left: 24px !important;
    margin-right: 24px !important; } }

@media (max-width: 1274.98px) {
  .lg-down-p-md {
    padding: 24px !important; }
  .lg-down-p-md-child > * {
    padding: 24px !important; }
  .lg-down-p-md-child-not-last > *:not(:last-child) {
    padding: 24px !important; } }

@media (max-width: 1274.98px) {
  .lg-down-pt-md {
    padding-top: 24px !important; }
  .lg-down-pt-md-child > * {
    padding-top: 24px !important; }
  .lg-down-pt-md-child-not-last > *:not(:last-child) {
    padding-top: 24px !important; } }

@media (max-width: 1274.98px) {
  .lg-down-pb-md {
    padding-bottom: 24px !important; }
  .lg-down-pb-md-child > * {
    padding-bottom: 24px !important; }
  .lg-down-pb-md-child-not-last > *:not(:last-child) {
    padding-bottom: 24px !important; } }

@media (max-width: 1274.98px) {
  .lg-down-py-md {
    padding-top: 24px !important;
    padding-bottom: 24px !important; }
  .lg-down-py-md-child > * {
    padding-top: 24px !important;
    padding-bottom: 24px !important; }
  .lg-down-py-md-child-not-last > *:not(:last-child) {
    padding-top: 24px !important;
    padding-bottom: 24px !important; } }

@media (max-width: 1274.98px) {
  .lg-down-pl-md {
    padding-left: 24px !important; }
  .lg-down-pl-md-child > * {
    padding-left: 24px !important; }
  .lg-down-pl-md-child-not-last > *:not(:last-child) {
    padding-left: 24px !important; } }

@media (max-width: 1274.98px) {
  .lg-down-pr-md {
    padding-right: 24px !important; }
  .lg-down-pr-md-child > * {
    padding-right: 24px !important; }
  .lg-down-pr-md-child-not-last > *:not(:last-child) {
    padding-right: 24px !important; } }

@media (max-width: 1274.98px) {
  .lg-down-px-md {
    padding-left: 24px !important;
    padding-right: 24px !important; }
  .lg-down-px-md-child > * {
    padding-left: 24px !important;
    padding-right: 24px !important; }
  .lg-down-px-md-child-not-last > *:not(:last-child) {
    padding-left: 24px !important;
    padding-right: 24px !important; } }

@media (max-width: 1274.98px) {
  .lg-down-m-lg {
    margin: 50px !important; }
  .lg-down-m-lg-child > * {
    margin: 50px !important; }
  .lg-down-m-lg-child-not-last > *:not(:last-child) {
    margin: 50px !important; } }

@media (max-width: 1274.98px) {
  .lg-down-mt-lg {
    margin-top: 50px !important; }
  .lg-down-mt-lg-child > * {
    margin-top: 50px !important; }
  .lg-down-mt-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important; } }

@media (max-width: 1274.98px) {
  .lg-down-mb-lg {
    margin-bottom: 50px !important; }
  .lg-down-mb-lg-child > * {
    margin-bottom: 50px !important; }
  .lg-down-mb-lg-child-not-last > *:not(:last-child) {
    margin-bottom: 50px !important; } }

@media (max-width: 1274.98px) {
  .lg-down-my-lg {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .lg-down-my-lg-child > * {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .lg-down-my-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important;
    margin-bottom: 50px !important; } }

@media (max-width: 1274.98px) {
  .lg-down-ml-lg {
    margin-left: 50px !important; }
  .lg-down-ml-lg-child > * {
    margin-left: 50px !important; }
  .lg-down-ml-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important; } }

@media (max-width: 1274.98px) {
  .lg-down-mr-lg {
    margin-right: 50px !important; }
  .lg-down-mr-lg-child > * {
    margin-right: 50px !important; }
  .lg-down-mr-lg-child-not-last > *:not(:last-child) {
    margin-right: 50px !important; } }

@media (max-width: 1274.98px) {
  .lg-down-mx-lg {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .lg-down-mx-lg-child > * {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .lg-down-mx-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important;
    margin-right: 50px !important; } }

@media (max-width: 1274.98px) {
  .lg-down-p-lg {
    padding: 50px !important; }
  .lg-down-p-lg-child > * {
    padding: 50px !important; }
  .lg-down-p-lg-child-not-last > *:not(:last-child) {
    padding: 50px !important; } }

@media (max-width: 1274.98px) {
  .lg-down-pt-lg {
    padding-top: 50px !important; }
  .lg-down-pt-lg-child > * {
    padding-top: 50px !important; }
  .lg-down-pt-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important; } }

@media (max-width: 1274.98px) {
  .lg-down-pb-lg {
    padding-bottom: 50px !important; }
  .lg-down-pb-lg-child > * {
    padding-bottom: 50px !important; }
  .lg-down-pb-lg-child-not-last > *:not(:last-child) {
    padding-bottom: 50px !important; } }

@media (max-width: 1274.98px) {
  .lg-down-py-lg {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .lg-down-py-lg-child > * {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .lg-down-py-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important;
    padding-bottom: 50px !important; } }

@media (max-width: 1274.98px) {
  .lg-down-pl-lg {
    padding-left: 50px !important; }
  .lg-down-pl-lg-child > * {
    padding-left: 50px !important; }
  .lg-down-pl-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important; } }

@media (max-width: 1274.98px) {
  .lg-down-pr-lg {
    padding-right: 50px !important; }
  .lg-down-pr-lg-child > * {
    padding-right: 50px !important; }
  .lg-down-pr-lg-child-not-last > *:not(:last-child) {
    padding-right: 50px !important; } }

@media (max-width: 1274.98px) {
  .lg-down-px-lg {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .lg-down-px-lg-child > * {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .lg-down-px-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important;
    padding-right: 50px !important; } }

@media (max-width: 1274.98px) {
  .lg-down-m-xl {
    margin: 70px !important; }
  .lg-down-m-xl-child > * {
    margin: 70px !important; }
  .lg-down-m-xl-child-not-last > *:not(:last-child) {
    margin: 70px !important; } }

@media (max-width: 1274.98px) {
  .lg-down-mt-xl {
    margin-top: 70px !important; }
  .lg-down-mt-xl-child > * {
    margin-top: 70px !important; }
  .lg-down-mt-xl-child-not-last > *:not(:last-child) {
    margin-top: 70px !important; } }

@media (max-width: 1274.98px) {
  .lg-down-mb-xl {
    margin-bottom: 70px !important; }
  .lg-down-mb-xl-child > * {
    margin-bottom: 70px !important; }
  .lg-down-mb-xl-child-not-last > *:not(:last-child) {
    margin-bottom: 70px !important; } }

@media (max-width: 1274.98px) {
  .lg-down-my-xl {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .lg-down-my-xl-child > * {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .lg-down-my-xl-child-not-last > *:not(:last-child) {
    margin-top: 70px !important;
    margin-bottom: 70px !important; } }

@media (max-width: 1274.98px) {
  .lg-down-ml-xl {
    margin-left: 70px !important; }
  .lg-down-ml-xl-child > * {
    margin-left: 70px !important; }
  .lg-down-ml-xl-child-not-last > *:not(:last-child) {
    margin-left: 70px !important; } }

@media (max-width: 1274.98px) {
  .lg-down-mr-xl {
    margin-right: 70px !important; }
  .lg-down-mr-xl-child > * {
    margin-right: 70px !important; }
  .lg-down-mr-xl-child-not-last > *:not(:last-child) {
    margin-right: 70px !important; } }

@media (max-width: 1274.98px) {
  .lg-down-mx-xl {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .lg-down-mx-xl-child > * {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .lg-down-mx-xl-child-not-last > *:not(:last-child) {
    margin-left: 70px !important;
    margin-right: 70px !important; } }

@media (max-width: 1274.98px) {
  .lg-down-p-xl {
    padding: 70px !important; }
  .lg-down-p-xl-child > * {
    padding: 70px !important; }
  .lg-down-p-xl-child-not-last > *:not(:last-child) {
    padding: 70px !important; } }

@media (max-width: 1274.98px) {
  .lg-down-pt-xl {
    padding-top: 70px !important; }
  .lg-down-pt-xl-child > * {
    padding-top: 70px !important; }
  .lg-down-pt-xl-child-not-last > *:not(:last-child) {
    padding-top: 70px !important; } }

@media (max-width: 1274.98px) {
  .lg-down-pb-xl {
    padding-bottom: 70px !important; }
  .lg-down-pb-xl-child > * {
    padding-bottom: 70px !important; }
  .lg-down-pb-xl-child-not-last > *:not(:last-child) {
    padding-bottom: 70px !important; } }

@media (max-width: 1274.98px) {
  .lg-down-py-xl {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .lg-down-py-xl-child > * {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .lg-down-py-xl-child-not-last > *:not(:last-child) {
    padding-top: 70px !important;
    padding-bottom: 70px !important; } }

@media (max-width: 1274.98px) {
  .lg-down-pl-xl {
    padding-left: 70px !important; }
  .lg-down-pl-xl-child > * {
    padding-left: 70px !important; }
  .lg-down-pl-xl-child-not-last > *:not(:last-child) {
    padding-left: 70px !important; } }

@media (max-width: 1274.98px) {
  .lg-down-pr-xl {
    padding-right: 70px !important; }
  .lg-down-pr-xl-child > * {
    padding-right: 70px !important; }
  .lg-down-pr-xl-child-not-last > *:not(:last-child) {
    padding-right: 70px !important; } }

@media (max-width: 1274.98px) {
  .lg-down-px-xl {
    padding-left: 70px !important;
    padding-right: 70px !important; }
  .lg-down-px-xl-child > * {
    padding-left: 70px !important;
    padding-right: 70px !important; }
  .lg-down-px-xl-child-not-last > *:not(:last-child) {
    padding-left: 70px !important;
    padding-right: 70px !important; } }

@media (max-width: 1274.98px) {
  .lg-down-m-xxl {
    margin: 140px !important; }
  .lg-down-m-xxl-child > * {
    margin: 140px !important; }
  .lg-down-m-xxl-child-not-last > *:not(:last-child) {
    margin: 140px !important; } }

@media (max-width: 1274.98px) {
  .lg-down-mt-xxl {
    margin-top: 140px !important; }
  .lg-down-mt-xxl-child > * {
    margin-top: 140px !important; }
  .lg-down-mt-xxl-child-not-last > *:not(:last-child) {
    margin-top: 140px !important; } }

@media (max-width: 1274.98px) {
  .lg-down-mb-xxl {
    margin-bottom: 140px !important; }
  .lg-down-mb-xxl-child > * {
    margin-bottom: 140px !important; }
  .lg-down-mb-xxl-child-not-last > *:not(:last-child) {
    margin-bottom: 140px !important; } }

@media (max-width: 1274.98px) {
  .lg-down-my-xxl {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .lg-down-my-xxl-child > * {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .lg-down-my-xxl-child-not-last > *:not(:last-child) {
    margin-top: 140px !important;
    margin-bottom: 140px !important; } }

@media (max-width: 1274.98px) {
  .lg-down-ml-xxl {
    margin-left: 140px !important; }
  .lg-down-ml-xxl-child > * {
    margin-left: 140px !important; }
  .lg-down-ml-xxl-child-not-last > *:not(:last-child) {
    margin-left: 140px !important; } }

@media (max-width: 1274.98px) {
  .lg-down-mr-xxl {
    margin-right: 140px !important; }
  .lg-down-mr-xxl-child > * {
    margin-right: 140px !important; }
  .lg-down-mr-xxl-child-not-last > *:not(:last-child) {
    margin-right: 140px !important; } }

@media (max-width: 1274.98px) {
  .lg-down-mx-xxl {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .lg-down-mx-xxl-child > * {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .lg-down-mx-xxl-child-not-last > *:not(:last-child) {
    margin-left: 140px !important;
    margin-right: 140px !important; } }

@media (max-width: 1274.98px) {
  .lg-down-p-xxl {
    padding: 140px !important; }
  .lg-down-p-xxl-child > * {
    padding: 140px !important; }
  .lg-down-p-xxl-child-not-last > *:not(:last-child) {
    padding: 140px !important; } }

@media (max-width: 1274.98px) {
  .lg-down-pt-xxl {
    padding-top: 140px !important; }
  .lg-down-pt-xxl-child > * {
    padding-top: 140px !important; }
  .lg-down-pt-xxl-child-not-last > *:not(:last-child) {
    padding-top: 140px !important; } }

@media (max-width: 1274.98px) {
  .lg-down-pb-xxl {
    padding-bottom: 140px !important; }
  .lg-down-pb-xxl-child > * {
    padding-bottom: 140px !important; }
  .lg-down-pb-xxl-child-not-last > *:not(:last-child) {
    padding-bottom: 140px !important; } }

@media (max-width: 1274.98px) {
  .lg-down-py-xxl {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .lg-down-py-xxl-child > * {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .lg-down-py-xxl-child-not-last > *:not(:last-child) {
    padding-top: 140px !important;
    padding-bottom: 140px !important; } }

@media (max-width: 1274.98px) {
  .lg-down-pl-xxl {
    padding-left: 140px !important; }
  .lg-down-pl-xxl-child > * {
    padding-left: 140px !important; }
  .lg-down-pl-xxl-child-not-last > *:not(:last-child) {
    padding-left: 140px !important; } }

@media (max-width: 1274.98px) {
  .lg-down-pr-xxl {
    padding-right: 140px !important; }
  .lg-down-pr-xxl-child > * {
    padding-right: 140px !important; }
  .lg-down-pr-xxl-child-not-last > *:not(:last-child) {
    padding-right: 140px !important; } }

@media (max-width: 1274.98px) {
  .lg-down-px-xxl {
    padding-left: 140px !important;
    padding-right: 140px !important; }
  .lg-down-px-xxl-child > * {
    padding-left: 140px !important;
    padding-right: 140px !important; }
  .lg-down-px-xxl-child-not-last > *:not(:last-child) {
    padding-left: 140px !important;
    padding-right: 140px !important; } }

@media (max-width: 991.98px) {
  .md-down-m-0 {
    margin: 0 !important; }
  .md-down-m-0-child > * {
    margin: 0 !important; }
  .md-down-m-0-child-not-last > *:not(:last-child) {
    margin: 0 !important; } }

@media (max-width: 991.98px) {
  .md-down-mt-0 {
    margin-top: 0 !important; }
  .md-down-mt-0-child > * {
    margin-top: 0 !important; }
  .md-down-mt-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important; } }

@media (max-width: 991.98px) {
  .md-down-mb-0 {
    margin-bottom: 0 !important; }
  .md-down-mb-0-child > * {
    margin-bottom: 0 !important; }
  .md-down-mb-0-child-not-last > *:not(:last-child) {
    margin-bottom: 0 !important; } }

@media (max-width: 991.98px) {
  .md-down-my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .md-down-my-0-child > * {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .md-down-my-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important;
    margin-bottom: 0 !important; } }

@media (max-width: 991.98px) {
  .md-down-ml-0 {
    margin-left: 0 !important; }
  .md-down-ml-0-child > * {
    margin-left: 0 !important; }
  .md-down-ml-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important; } }

@media (max-width: 991.98px) {
  .md-down-mr-0 {
    margin-right: 0 !important; }
  .md-down-mr-0-child > * {
    margin-right: 0 !important; }
  .md-down-mr-0-child-not-last > *:not(:last-child) {
    margin-right: 0 !important; } }

@media (max-width: 991.98px) {
  .md-down-mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .md-down-mx-0-child > * {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .md-down-mx-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important;
    margin-right: 0 !important; } }

@media (max-width: 991.98px) {
  .md-down-p-0 {
    padding: 0 !important; }
  .md-down-p-0-child > * {
    padding: 0 !important; }
  .md-down-p-0-child-not-last > *:not(:last-child) {
    padding: 0 !important; } }

@media (max-width: 991.98px) {
  .md-down-pt-0 {
    padding-top: 0 !important; }
  .md-down-pt-0-child > * {
    padding-top: 0 !important; }
  .md-down-pt-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important; } }

@media (max-width: 991.98px) {
  .md-down-pb-0 {
    padding-bottom: 0 !important; }
  .md-down-pb-0-child > * {
    padding-bottom: 0 !important; }
  .md-down-pb-0-child-not-last > *:not(:last-child) {
    padding-bottom: 0 !important; } }

@media (max-width: 991.98px) {
  .md-down-py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .md-down-py-0-child > * {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .md-down-py-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important;
    padding-bottom: 0 !important; } }

@media (max-width: 991.98px) {
  .md-down-pl-0 {
    padding-left: 0 !important; }
  .md-down-pl-0-child > * {
    padding-left: 0 !important; }
  .md-down-pl-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important; } }

@media (max-width: 991.98px) {
  .md-down-pr-0 {
    padding-right: 0 !important; }
  .md-down-pr-0-child > * {
    padding-right: 0 !important; }
  .md-down-pr-0-child-not-last > *:not(:last-child) {
    padding-right: 0 !important; } }

@media (max-width: 991.98px) {
  .md-down-px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .md-down-px-0-child > * {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .md-down-px-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important;
    padding-right: 0 !important; } }

@media (max-width: 991.98px) {
  .md-down-m-xs {
    margin: 5px !important; }
  .md-down-m-xs-child > * {
    margin: 5px !important; }
  .md-down-m-xs-child-not-last > *:not(:last-child) {
    margin: 5px !important; } }

@media (max-width: 991.98px) {
  .md-down-mt-xs {
    margin-top: 5px !important; }
  .md-down-mt-xs-child > * {
    margin-top: 5px !important; }
  .md-down-mt-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important; } }

@media (max-width: 991.98px) {
  .md-down-mb-xs {
    margin-bottom: 5px !important; }
  .md-down-mb-xs-child > * {
    margin-bottom: 5px !important; }
  .md-down-mb-xs-child-not-last > *:not(:last-child) {
    margin-bottom: 5px !important; } }

@media (max-width: 991.98px) {
  .md-down-my-xs {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .md-down-my-xs-child > * {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .md-down-my-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important;
    margin-bottom: 5px !important; } }

@media (max-width: 991.98px) {
  .md-down-ml-xs {
    margin-left: 5px !important; }
  .md-down-ml-xs-child > * {
    margin-left: 5px !important; }
  .md-down-ml-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important; } }

@media (max-width: 991.98px) {
  .md-down-mr-xs {
    margin-right: 5px !important; }
  .md-down-mr-xs-child > * {
    margin-right: 5px !important; }
  .md-down-mr-xs-child-not-last > *:not(:last-child) {
    margin-right: 5px !important; } }

@media (max-width: 991.98px) {
  .md-down-mx-xs {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .md-down-mx-xs-child > * {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .md-down-mx-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important;
    margin-right: 5px !important; } }

@media (max-width: 991.98px) {
  .md-down-p-xs {
    padding: 5px !important; }
  .md-down-p-xs-child > * {
    padding: 5px !important; }
  .md-down-p-xs-child-not-last > *:not(:last-child) {
    padding: 5px !important; } }

@media (max-width: 991.98px) {
  .md-down-pt-xs {
    padding-top: 5px !important; }
  .md-down-pt-xs-child > * {
    padding-top: 5px !important; }
  .md-down-pt-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important; } }

@media (max-width: 991.98px) {
  .md-down-pb-xs {
    padding-bottom: 5px !important; }
  .md-down-pb-xs-child > * {
    padding-bottom: 5px !important; }
  .md-down-pb-xs-child-not-last > *:not(:last-child) {
    padding-bottom: 5px !important; } }

@media (max-width: 991.98px) {
  .md-down-py-xs {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .md-down-py-xs-child > * {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .md-down-py-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important;
    padding-bottom: 5px !important; } }

@media (max-width: 991.98px) {
  .md-down-pl-xs {
    padding-left: 5px !important; }
  .md-down-pl-xs-child > * {
    padding-left: 5px !important; }
  .md-down-pl-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important; } }

@media (max-width: 991.98px) {
  .md-down-pr-xs {
    padding-right: 5px !important; }
  .md-down-pr-xs-child > * {
    padding-right: 5px !important; }
  .md-down-pr-xs-child-not-last > *:not(:last-child) {
    padding-right: 5px !important; } }

@media (max-width: 991.98px) {
  .md-down-px-xs {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .md-down-px-xs-child > * {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .md-down-px-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important;
    padding-right: 5px !important; } }

@media (max-width: 991.98px) {
  .md-down-m-sm {
    margin: 15px !important; }
  .md-down-m-sm-child > * {
    margin: 15px !important; }
  .md-down-m-sm-child-not-last > *:not(:last-child) {
    margin: 15px !important; } }

@media (max-width: 991.98px) {
  .md-down-mt-sm {
    margin-top: 15px !important; }
  .md-down-mt-sm-child > * {
    margin-top: 15px !important; }
  .md-down-mt-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important; } }

@media (max-width: 991.98px) {
  .md-down-mb-sm {
    margin-bottom: 15px !important; }
  .md-down-mb-sm-child > * {
    margin-bottom: 15px !important; }
  .md-down-mb-sm-child-not-last > *:not(:last-child) {
    margin-bottom: 15px !important; } }

@media (max-width: 991.98px) {
  .md-down-my-sm {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .md-down-my-sm-child > * {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .md-down-my-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important;
    margin-bottom: 15px !important; } }

@media (max-width: 991.98px) {
  .md-down-ml-sm {
    margin-left: 15px !important; }
  .md-down-ml-sm-child > * {
    margin-left: 15px !important; }
  .md-down-ml-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important; } }

@media (max-width: 991.98px) {
  .md-down-mr-sm {
    margin-right: 15px !important; }
  .md-down-mr-sm-child > * {
    margin-right: 15px !important; }
  .md-down-mr-sm-child-not-last > *:not(:last-child) {
    margin-right: 15px !important; } }

@media (max-width: 991.98px) {
  .md-down-mx-sm {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .md-down-mx-sm-child > * {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .md-down-mx-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important;
    margin-right: 15px !important; } }

@media (max-width: 991.98px) {
  .md-down-p-sm {
    padding: 15px !important; }
  .md-down-p-sm-child > * {
    padding: 15px !important; }
  .md-down-p-sm-child-not-last > *:not(:last-child) {
    padding: 15px !important; } }

@media (max-width: 991.98px) {
  .md-down-pt-sm {
    padding-top: 15px !important; }
  .md-down-pt-sm-child > * {
    padding-top: 15px !important; }
  .md-down-pt-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important; } }

@media (max-width: 991.98px) {
  .md-down-pb-sm {
    padding-bottom: 15px !important; }
  .md-down-pb-sm-child > * {
    padding-bottom: 15px !important; }
  .md-down-pb-sm-child-not-last > *:not(:last-child) {
    padding-bottom: 15px !important; } }

@media (max-width: 991.98px) {
  .md-down-py-sm {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .md-down-py-sm-child > * {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .md-down-py-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important;
    padding-bottom: 15px !important; } }

@media (max-width: 991.98px) {
  .md-down-pl-sm {
    padding-left: 15px !important; }
  .md-down-pl-sm-child > * {
    padding-left: 15px !important; }
  .md-down-pl-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important; } }

@media (max-width: 991.98px) {
  .md-down-pr-sm {
    padding-right: 15px !important; }
  .md-down-pr-sm-child > * {
    padding-right: 15px !important; }
  .md-down-pr-sm-child-not-last > *:not(:last-child) {
    padding-right: 15px !important; } }

@media (max-width: 991.98px) {
  .md-down-px-sm {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .md-down-px-sm-child > * {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .md-down-px-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important;
    padding-right: 15px !important; } }

@media (max-width: 991.98px) {
  .md-down-m-md {
    margin: 24px !important; }
  .md-down-m-md-child > * {
    margin: 24px !important; }
  .md-down-m-md-child-not-last > *:not(:last-child) {
    margin: 24px !important; } }

@media (max-width: 991.98px) {
  .md-down-mt-md {
    margin-top: 24px !important; }
  .md-down-mt-md-child > * {
    margin-top: 24px !important; }
  .md-down-mt-md-child-not-last > *:not(:last-child) {
    margin-top: 24px !important; } }

@media (max-width: 991.98px) {
  .md-down-mb-md {
    margin-bottom: 24px !important; }
  .md-down-mb-md-child > * {
    margin-bottom: 24px !important; }
  .md-down-mb-md-child-not-last > *:not(:last-child) {
    margin-bottom: 24px !important; } }

@media (max-width: 991.98px) {
  .md-down-my-md {
    margin-top: 24px !important;
    margin-bottom: 24px !important; }
  .md-down-my-md-child > * {
    margin-top: 24px !important;
    margin-bottom: 24px !important; }
  .md-down-my-md-child-not-last > *:not(:last-child) {
    margin-top: 24px !important;
    margin-bottom: 24px !important; } }

@media (max-width: 991.98px) {
  .md-down-ml-md {
    margin-left: 24px !important; }
  .md-down-ml-md-child > * {
    margin-left: 24px !important; }
  .md-down-ml-md-child-not-last > *:not(:last-child) {
    margin-left: 24px !important; } }

@media (max-width: 991.98px) {
  .md-down-mr-md {
    margin-right: 24px !important; }
  .md-down-mr-md-child > * {
    margin-right: 24px !important; }
  .md-down-mr-md-child-not-last > *:not(:last-child) {
    margin-right: 24px !important; } }

@media (max-width: 991.98px) {
  .md-down-mx-md {
    margin-left: 24px !important;
    margin-right: 24px !important; }
  .md-down-mx-md-child > * {
    margin-left: 24px !important;
    margin-right: 24px !important; }
  .md-down-mx-md-child-not-last > *:not(:last-child) {
    margin-left: 24px !important;
    margin-right: 24px !important; } }

@media (max-width: 991.98px) {
  .md-down-p-md {
    padding: 24px !important; }
  .md-down-p-md-child > * {
    padding: 24px !important; }
  .md-down-p-md-child-not-last > *:not(:last-child) {
    padding: 24px !important; } }

@media (max-width: 991.98px) {
  .md-down-pt-md {
    padding-top: 24px !important; }
  .md-down-pt-md-child > * {
    padding-top: 24px !important; }
  .md-down-pt-md-child-not-last > *:not(:last-child) {
    padding-top: 24px !important; } }

@media (max-width: 991.98px) {
  .md-down-pb-md {
    padding-bottom: 24px !important; }
  .md-down-pb-md-child > * {
    padding-bottom: 24px !important; }
  .md-down-pb-md-child-not-last > *:not(:last-child) {
    padding-bottom: 24px !important; } }

@media (max-width: 991.98px) {
  .md-down-py-md {
    padding-top: 24px !important;
    padding-bottom: 24px !important; }
  .md-down-py-md-child > * {
    padding-top: 24px !important;
    padding-bottom: 24px !important; }
  .md-down-py-md-child-not-last > *:not(:last-child) {
    padding-top: 24px !important;
    padding-bottom: 24px !important; } }

@media (max-width: 991.98px) {
  .md-down-pl-md {
    padding-left: 24px !important; }
  .md-down-pl-md-child > * {
    padding-left: 24px !important; }
  .md-down-pl-md-child-not-last > *:not(:last-child) {
    padding-left: 24px !important; } }

@media (max-width: 991.98px) {
  .md-down-pr-md {
    padding-right: 24px !important; }
  .md-down-pr-md-child > * {
    padding-right: 24px !important; }
  .md-down-pr-md-child-not-last > *:not(:last-child) {
    padding-right: 24px !important; } }

@media (max-width: 991.98px) {
  .md-down-px-md {
    padding-left: 24px !important;
    padding-right: 24px !important; }
  .md-down-px-md-child > * {
    padding-left: 24px !important;
    padding-right: 24px !important; }
  .md-down-px-md-child-not-last > *:not(:last-child) {
    padding-left: 24px !important;
    padding-right: 24px !important; } }

@media (max-width: 991.98px) {
  .md-down-m-lg {
    margin: 50px !important; }
  .md-down-m-lg-child > * {
    margin: 50px !important; }
  .md-down-m-lg-child-not-last > *:not(:last-child) {
    margin: 50px !important; } }

@media (max-width: 991.98px) {
  .md-down-mt-lg {
    margin-top: 50px !important; }
  .md-down-mt-lg-child > * {
    margin-top: 50px !important; }
  .md-down-mt-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important; } }

@media (max-width: 991.98px) {
  .md-down-mb-lg {
    margin-bottom: 50px !important; }
  .md-down-mb-lg-child > * {
    margin-bottom: 50px !important; }
  .md-down-mb-lg-child-not-last > *:not(:last-child) {
    margin-bottom: 50px !important; } }

@media (max-width: 991.98px) {
  .md-down-my-lg {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .md-down-my-lg-child > * {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .md-down-my-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important;
    margin-bottom: 50px !important; } }

@media (max-width: 991.98px) {
  .md-down-ml-lg {
    margin-left: 50px !important; }
  .md-down-ml-lg-child > * {
    margin-left: 50px !important; }
  .md-down-ml-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important; } }

@media (max-width: 991.98px) {
  .md-down-mr-lg {
    margin-right: 50px !important; }
  .md-down-mr-lg-child > * {
    margin-right: 50px !important; }
  .md-down-mr-lg-child-not-last > *:not(:last-child) {
    margin-right: 50px !important; } }

@media (max-width: 991.98px) {
  .md-down-mx-lg {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .md-down-mx-lg-child > * {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .md-down-mx-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important;
    margin-right: 50px !important; } }

@media (max-width: 991.98px) {
  .md-down-p-lg {
    padding: 50px !important; }
  .md-down-p-lg-child > * {
    padding: 50px !important; }
  .md-down-p-lg-child-not-last > *:not(:last-child) {
    padding: 50px !important; } }

@media (max-width: 991.98px) {
  .md-down-pt-lg {
    padding-top: 50px !important; }
  .md-down-pt-lg-child > * {
    padding-top: 50px !important; }
  .md-down-pt-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important; } }

@media (max-width: 991.98px) {
  .md-down-pb-lg {
    padding-bottom: 50px !important; }
  .md-down-pb-lg-child > * {
    padding-bottom: 50px !important; }
  .md-down-pb-lg-child-not-last > *:not(:last-child) {
    padding-bottom: 50px !important; } }

@media (max-width: 991.98px) {
  .md-down-py-lg {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .md-down-py-lg-child > * {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .md-down-py-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important;
    padding-bottom: 50px !important; } }

@media (max-width: 991.98px) {
  .md-down-pl-lg {
    padding-left: 50px !important; }
  .md-down-pl-lg-child > * {
    padding-left: 50px !important; }
  .md-down-pl-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important; } }

@media (max-width: 991.98px) {
  .md-down-pr-lg {
    padding-right: 50px !important; }
  .md-down-pr-lg-child > * {
    padding-right: 50px !important; }
  .md-down-pr-lg-child-not-last > *:not(:last-child) {
    padding-right: 50px !important; } }

@media (max-width: 991.98px) {
  .md-down-px-lg {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .md-down-px-lg-child > * {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .md-down-px-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important;
    padding-right: 50px !important; } }

@media (max-width: 991.98px) {
  .md-down-m-xl {
    margin: 70px !important; }
  .md-down-m-xl-child > * {
    margin: 70px !important; }
  .md-down-m-xl-child-not-last > *:not(:last-child) {
    margin: 70px !important; } }

@media (max-width: 991.98px) {
  .md-down-mt-xl {
    margin-top: 70px !important; }
  .md-down-mt-xl-child > * {
    margin-top: 70px !important; }
  .md-down-mt-xl-child-not-last > *:not(:last-child) {
    margin-top: 70px !important; } }

@media (max-width: 991.98px) {
  .md-down-mb-xl {
    margin-bottom: 70px !important; }
  .md-down-mb-xl-child > * {
    margin-bottom: 70px !important; }
  .md-down-mb-xl-child-not-last > *:not(:last-child) {
    margin-bottom: 70px !important; } }

@media (max-width: 991.98px) {
  .md-down-my-xl {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .md-down-my-xl-child > * {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .md-down-my-xl-child-not-last > *:not(:last-child) {
    margin-top: 70px !important;
    margin-bottom: 70px !important; } }

@media (max-width: 991.98px) {
  .md-down-ml-xl {
    margin-left: 70px !important; }
  .md-down-ml-xl-child > * {
    margin-left: 70px !important; }
  .md-down-ml-xl-child-not-last > *:not(:last-child) {
    margin-left: 70px !important; } }

@media (max-width: 991.98px) {
  .md-down-mr-xl {
    margin-right: 70px !important; }
  .md-down-mr-xl-child > * {
    margin-right: 70px !important; }
  .md-down-mr-xl-child-not-last > *:not(:last-child) {
    margin-right: 70px !important; } }

@media (max-width: 991.98px) {
  .md-down-mx-xl {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .md-down-mx-xl-child > * {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .md-down-mx-xl-child-not-last > *:not(:last-child) {
    margin-left: 70px !important;
    margin-right: 70px !important; } }

@media (max-width: 991.98px) {
  .md-down-p-xl {
    padding: 70px !important; }
  .md-down-p-xl-child > * {
    padding: 70px !important; }
  .md-down-p-xl-child-not-last > *:not(:last-child) {
    padding: 70px !important; } }

@media (max-width: 991.98px) {
  .md-down-pt-xl {
    padding-top: 70px !important; }
  .md-down-pt-xl-child > * {
    padding-top: 70px !important; }
  .md-down-pt-xl-child-not-last > *:not(:last-child) {
    padding-top: 70px !important; } }

@media (max-width: 991.98px) {
  .md-down-pb-xl {
    padding-bottom: 70px !important; }
  .md-down-pb-xl-child > * {
    padding-bottom: 70px !important; }
  .md-down-pb-xl-child-not-last > *:not(:last-child) {
    padding-bottom: 70px !important; } }

@media (max-width: 991.98px) {
  .md-down-py-xl {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .md-down-py-xl-child > * {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .md-down-py-xl-child-not-last > *:not(:last-child) {
    padding-top: 70px !important;
    padding-bottom: 70px !important; } }

@media (max-width: 991.98px) {
  .md-down-pl-xl {
    padding-left: 70px !important; }
  .md-down-pl-xl-child > * {
    padding-left: 70px !important; }
  .md-down-pl-xl-child-not-last > *:not(:last-child) {
    padding-left: 70px !important; } }

@media (max-width: 991.98px) {
  .md-down-pr-xl {
    padding-right: 70px !important; }
  .md-down-pr-xl-child > * {
    padding-right: 70px !important; }
  .md-down-pr-xl-child-not-last > *:not(:last-child) {
    padding-right: 70px !important; } }

@media (max-width: 991.98px) {
  .md-down-px-xl {
    padding-left: 70px !important;
    padding-right: 70px !important; }
  .md-down-px-xl-child > * {
    padding-left: 70px !important;
    padding-right: 70px !important; }
  .md-down-px-xl-child-not-last > *:not(:last-child) {
    padding-left: 70px !important;
    padding-right: 70px !important; } }

@media (max-width: 991.98px) {
  .md-down-m-xxl {
    margin: 140px !important; }
  .md-down-m-xxl-child > * {
    margin: 140px !important; }
  .md-down-m-xxl-child-not-last > *:not(:last-child) {
    margin: 140px !important; } }

@media (max-width: 991.98px) {
  .md-down-mt-xxl {
    margin-top: 140px !important; }
  .md-down-mt-xxl-child > * {
    margin-top: 140px !important; }
  .md-down-mt-xxl-child-not-last > *:not(:last-child) {
    margin-top: 140px !important; } }

@media (max-width: 991.98px) {
  .md-down-mb-xxl {
    margin-bottom: 140px !important; }
  .md-down-mb-xxl-child > * {
    margin-bottom: 140px !important; }
  .md-down-mb-xxl-child-not-last > *:not(:last-child) {
    margin-bottom: 140px !important; } }

@media (max-width: 991.98px) {
  .md-down-my-xxl {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .md-down-my-xxl-child > * {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .md-down-my-xxl-child-not-last > *:not(:last-child) {
    margin-top: 140px !important;
    margin-bottom: 140px !important; } }

@media (max-width: 991.98px) {
  .md-down-ml-xxl {
    margin-left: 140px !important; }
  .md-down-ml-xxl-child > * {
    margin-left: 140px !important; }
  .md-down-ml-xxl-child-not-last > *:not(:last-child) {
    margin-left: 140px !important; } }

@media (max-width: 991.98px) {
  .md-down-mr-xxl {
    margin-right: 140px !important; }
  .md-down-mr-xxl-child > * {
    margin-right: 140px !important; }
  .md-down-mr-xxl-child-not-last > *:not(:last-child) {
    margin-right: 140px !important; } }

@media (max-width: 991.98px) {
  .md-down-mx-xxl {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .md-down-mx-xxl-child > * {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .md-down-mx-xxl-child-not-last > *:not(:last-child) {
    margin-left: 140px !important;
    margin-right: 140px !important; } }

@media (max-width: 991.98px) {
  .md-down-p-xxl {
    padding: 140px !important; }
  .md-down-p-xxl-child > * {
    padding: 140px !important; }
  .md-down-p-xxl-child-not-last > *:not(:last-child) {
    padding: 140px !important; } }

@media (max-width: 991.98px) {
  .md-down-pt-xxl {
    padding-top: 140px !important; }
  .md-down-pt-xxl-child > * {
    padding-top: 140px !important; }
  .md-down-pt-xxl-child-not-last > *:not(:last-child) {
    padding-top: 140px !important; } }

@media (max-width: 991.98px) {
  .md-down-pb-xxl {
    padding-bottom: 140px !important; }
  .md-down-pb-xxl-child > * {
    padding-bottom: 140px !important; }
  .md-down-pb-xxl-child-not-last > *:not(:last-child) {
    padding-bottom: 140px !important; } }

@media (max-width: 991.98px) {
  .md-down-py-xxl {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .md-down-py-xxl-child > * {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .md-down-py-xxl-child-not-last > *:not(:last-child) {
    padding-top: 140px !important;
    padding-bottom: 140px !important; } }

@media (max-width: 991.98px) {
  .md-down-pl-xxl {
    padding-left: 140px !important; }
  .md-down-pl-xxl-child > * {
    padding-left: 140px !important; }
  .md-down-pl-xxl-child-not-last > *:not(:last-child) {
    padding-left: 140px !important; } }

@media (max-width: 991.98px) {
  .md-down-pr-xxl {
    padding-right: 140px !important; }
  .md-down-pr-xxl-child > * {
    padding-right: 140px !important; }
  .md-down-pr-xxl-child-not-last > *:not(:last-child) {
    padding-right: 140px !important; } }

@media (max-width: 991.98px) {
  .md-down-px-xxl {
    padding-left: 140px !important;
    padding-right: 140px !important; }
  .md-down-px-xxl-child > * {
    padding-left: 140px !important;
    padding-right: 140px !important; }
  .md-down-px-xxl-child-not-last > *:not(:last-child) {
    padding-left: 140px !important;
    padding-right: 140px !important; } }

@media (max-width: 767.98px) {
  .sm-down-m-0 {
    margin: 0 !important; }
  .sm-down-m-0-child > * {
    margin: 0 !important; }
  .sm-down-m-0-child-not-last > *:not(:last-child) {
    margin: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-mt-0 {
    margin-top: 0 !important; }
  .sm-down-mt-0-child > * {
    margin-top: 0 !important; }
  .sm-down-mt-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-mb-0 {
    margin-bottom: 0 !important; }
  .sm-down-mb-0-child > * {
    margin-bottom: 0 !important; }
  .sm-down-mb-0-child-not-last > *:not(:last-child) {
    margin-bottom: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .sm-down-my-0-child > * {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .sm-down-my-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important;
    margin-bottom: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-ml-0 {
    margin-left: 0 !important; }
  .sm-down-ml-0-child > * {
    margin-left: 0 !important; }
  .sm-down-ml-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-mr-0 {
    margin-right: 0 !important; }
  .sm-down-mr-0-child > * {
    margin-right: 0 !important; }
  .sm-down-mr-0-child-not-last > *:not(:last-child) {
    margin-right: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .sm-down-mx-0-child > * {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .sm-down-mx-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important;
    margin-right: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-p-0 {
    padding: 0 !important; }
  .sm-down-p-0-child > * {
    padding: 0 !important; }
  .sm-down-p-0-child-not-last > *:not(:last-child) {
    padding: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-pt-0 {
    padding-top: 0 !important; }
  .sm-down-pt-0-child > * {
    padding-top: 0 !important; }
  .sm-down-pt-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-pb-0 {
    padding-bottom: 0 !important; }
  .sm-down-pb-0-child > * {
    padding-bottom: 0 !important; }
  .sm-down-pb-0-child-not-last > *:not(:last-child) {
    padding-bottom: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .sm-down-py-0-child > * {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .sm-down-py-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important;
    padding-bottom: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-pl-0 {
    padding-left: 0 !important; }
  .sm-down-pl-0-child > * {
    padding-left: 0 !important; }
  .sm-down-pl-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-pr-0 {
    padding-right: 0 !important; }
  .sm-down-pr-0-child > * {
    padding-right: 0 !important; }
  .sm-down-pr-0-child-not-last > *:not(:last-child) {
    padding-right: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .sm-down-px-0-child > * {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .sm-down-px-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important;
    padding-right: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-m-xs {
    margin: 5px !important; }
  .sm-down-m-xs-child > * {
    margin: 5px !important; }
  .sm-down-m-xs-child-not-last > *:not(:last-child) {
    margin: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mt-xs {
    margin-top: 5px !important; }
  .sm-down-mt-xs-child > * {
    margin-top: 5px !important; }
  .sm-down-mt-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mb-xs {
    margin-bottom: 5px !important; }
  .sm-down-mb-xs-child > * {
    margin-bottom: 5px !important; }
  .sm-down-mb-xs-child-not-last > *:not(:last-child) {
    margin-bottom: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-my-xs {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .sm-down-my-xs-child > * {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .sm-down-my-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important;
    margin-bottom: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-ml-xs {
    margin-left: 5px !important; }
  .sm-down-ml-xs-child > * {
    margin-left: 5px !important; }
  .sm-down-ml-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mr-xs {
    margin-right: 5px !important; }
  .sm-down-mr-xs-child > * {
    margin-right: 5px !important; }
  .sm-down-mr-xs-child-not-last > *:not(:last-child) {
    margin-right: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mx-xs {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .sm-down-mx-xs-child > * {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .sm-down-mx-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important;
    margin-right: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-p-xs {
    padding: 5px !important; }
  .sm-down-p-xs-child > * {
    padding: 5px !important; }
  .sm-down-p-xs-child-not-last > *:not(:last-child) {
    padding: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pt-xs {
    padding-top: 5px !important; }
  .sm-down-pt-xs-child > * {
    padding-top: 5px !important; }
  .sm-down-pt-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pb-xs {
    padding-bottom: 5px !important; }
  .sm-down-pb-xs-child > * {
    padding-bottom: 5px !important; }
  .sm-down-pb-xs-child-not-last > *:not(:last-child) {
    padding-bottom: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-py-xs {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .sm-down-py-xs-child > * {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .sm-down-py-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important;
    padding-bottom: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pl-xs {
    padding-left: 5px !important; }
  .sm-down-pl-xs-child > * {
    padding-left: 5px !important; }
  .sm-down-pl-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pr-xs {
    padding-right: 5px !important; }
  .sm-down-pr-xs-child > * {
    padding-right: 5px !important; }
  .sm-down-pr-xs-child-not-last > *:not(:last-child) {
    padding-right: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-px-xs {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .sm-down-px-xs-child > * {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .sm-down-px-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important;
    padding-right: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-m-sm {
    margin: 15px !important; }
  .sm-down-m-sm-child > * {
    margin: 15px !important; }
  .sm-down-m-sm-child-not-last > *:not(:last-child) {
    margin: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mt-sm {
    margin-top: 15px !important; }
  .sm-down-mt-sm-child > * {
    margin-top: 15px !important; }
  .sm-down-mt-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mb-sm {
    margin-bottom: 15px !important; }
  .sm-down-mb-sm-child > * {
    margin-bottom: 15px !important; }
  .sm-down-mb-sm-child-not-last > *:not(:last-child) {
    margin-bottom: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-my-sm {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .sm-down-my-sm-child > * {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .sm-down-my-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important;
    margin-bottom: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-ml-sm {
    margin-left: 15px !important; }
  .sm-down-ml-sm-child > * {
    margin-left: 15px !important; }
  .sm-down-ml-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mr-sm {
    margin-right: 15px !important; }
  .sm-down-mr-sm-child > * {
    margin-right: 15px !important; }
  .sm-down-mr-sm-child-not-last > *:not(:last-child) {
    margin-right: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mx-sm {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .sm-down-mx-sm-child > * {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .sm-down-mx-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important;
    margin-right: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-p-sm {
    padding: 15px !important; }
  .sm-down-p-sm-child > * {
    padding: 15px !important; }
  .sm-down-p-sm-child-not-last > *:not(:last-child) {
    padding: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pt-sm {
    padding-top: 15px !important; }
  .sm-down-pt-sm-child > * {
    padding-top: 15px !important; }
  .sm-down-pt-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pb-sm {
    padding-bottom: 15px !important; }
  .sm-down-pb-sm-child > * {
    padding-bottom: 15px !important; }
  .sm-down-pb-sm-child-not-last > *:not(:last-child) {
    padding-bottom: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-py-sm {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .sm-down-py-sm-child > * {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .sm-down-py-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important;
    padding-bottom: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pl-sm {
    padding-left: 15px !important; }
  .sm-down-pl-sm-child > * {
    padding-left: 15px !important; }
  .sm-down-pl-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pr-sm {
    padding-right: 15px !important; }
  .sm-down-pr-sm-child > * {
    padding-right: 15px !important; }
  .sm-down-pr-sm-child-not-last > *:not(:last-child) {
    padding-right: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-px-sm {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .sm-down-px-sm-child > * {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .sm-down-px-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important;
    padding-right: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-m-md {
    margin: 24px !important; }
  .sm-down-m-md-child > * {
    margin: 24px !important; }
  .sm-down-m-md-child-not-last > *:not(:last-child) {
    margin: 24px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mt-md {
    margin-top: 24px !important; }
  .sm-down-mt-md-child > * {
    margin-top: 24px !important; }
  .sm-down-mt-md-child-not-last > *:not(:last-child) {
    margin-top: 24px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mb-md {
    margin-bottom: 24px !important; }
  .sm-down-mb-md-child > * {
    margin-bottom: 24px !important; }
  .sm-down-mb-md-child-not-last > *:not(:last-child) {
    margin-bottom: 24px !important; } }

@media (max-width: 767.98px) {
  .sm-down-my-md {
    margin-top: 24px !important;
    margin-bottom: 24px !important; }
  .sm-down-my-md-child > * {
    margin-top: 24px !important;
    margin-bottom: 24px !important; }
  .sm-down-my-md-child-not-last > *:not(:last-child) {
    margin-top: 24px !important;
    margin-bottom: 24px !important; } }

@media (max-width: 767.98px) {
  .sm-down-ml-md {
    margin-left: 24px !important; }
  .sm-down-ml-md-child > * {
    margin-left: 24px !important; }
  .sm-down-ml-md-child-not-last > *:not(:last-child) {
    margin-left: 24px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mr-md {
    margin-right: 24px !important; }
  .sm-down-mr-md-child > * {
    margin-right: 24px !important; }
  .sm-down-mr-md-child-not-last > *:not(:last-child) {
    margin-right: 24px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mx-md {
    margin-left: 24px !important;
    margin-right: 24px !important; }
  .sm-down-mx-md-child > * {
    margin-left: 24px !important;
    margin-right: 24px !important; }
  .sm-down-mx-md-child-not-last > *:not(:last-child) {
    margin-left: 24px !important;
    margin-right: 24px !important; } }

@media (max-width: 767.98px) {
  .sm-down-p-md {
    padding: 24px !important; }
  .sm-down-p-md-child > * {
    padding: 24px !important; }
  .sm-down-p-md-child-not-last > *:not(:last-child) {
    padding: 24px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pt-md {
    padding-top: 24px !important; }
  .sm-down-pt-md-child > * {
    padding-top: 24px !important; }
  .sm-down-pt-md-child-not-last > *:not(:last-child) {
    padding-top: 24px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pb-md {
    padding-bottom: 24px !important; }
  .sm-down-pb-md-child > * {
    padding-bottom: 24px !important; }
  .sm-down-pb-md-child-not-last > *:not(:last-child) {
    padding-bottom: 24px !important; } }

@media (max-width: 767.98px) {
  .sm-down-py-md {
    padding-top: 24px !important;
    padding-bottom: 24px !important; }
  .sm-down-py-md-child > * {
    padding-top: 24px !important;
    padding-bottom: 24px !important; }
  .sm-down-py-md-child-not-last > *:not(:last-child) {
    padding-top: 24px !important;
    padding-bottom: 24px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pl-md {
    padding-left: 24px !important; }
  .sm-down-pl-md-child > * {
    padding-left: 24px !important; }
  .sm-down-pl-md-child-not-last > *:not(:last-child) {
    padding-left: 24px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pr-md {
    padding-right: 24px !important; }
  .sm-down-pr-md-child > * {
    padding-right: 24px !important; }
  .sm-down-pr-md-child-not-last > *:not(:last-child) {
    padding-right: 24px !important; } }

@media (max-width: 767.98px) {
  .sm-down-px-md {
    padding-left: 24px !important;
    padding-right: 24px !important; }
  .sm-down-px-md-child > * {
    padding-left: 24px !important;
    padding-right: 24px !important; }
  .sm-down-px-md-child-not-last > *:not(:last-child) {
    padding-left: 24px !important;
    padding-right: 24px !important; } }

@media (max-width: 767.98px) {
  .sm-down-m-lg {
    margin: 50px !important; }
  .sm-down-m-lg-child > * {
    margin: 50px !important; }
  .sm-down-m-lg-child-not-last > *:not(:last-child) {
    margin: 50px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mt-lg {
    margin-top: 50px !important; }
  .sm-down-mt-lg-child > * {
    margin-top: 50px !important; }
  .sm-down-mt-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mb-lg {
    margin-bottom: 50px !important; }
  .sm-down-mb-lg-child > * {
    margin-bottom: 50px !important; }
  .sm-down-mb-lg-child-not-last > *:not(:last-child) {
    margin-bottom: 50px !important; } }

@media (max-width: 767.98px) {
  .sm-down-my-lg {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .sm-down-my-lg-child > * {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .sm-down-my-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important;
    margin-bottom: 50px !important; } }

@media (max-width: 767.98px) {
  .sm-down-ml-lg {
    margin-left: 50px !important; }
  .sm-down-ml-lg-child > * {
    margin-left: 50px !important; }
  .sm-down-ml-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mr-lg {
    margin-right: 50px !important; }
  .sm-down-mr-lg-child > * {
    margin-right: 50px !important; }
  .sm-down-mr-lg-child-not-last > *:not(:last-child) {
    margin-right: 50px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mx-lg {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .sm-down-mx-lg-child > * {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .sm-down-mx-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important;
    margin-right: 50px !important; } }

@media (max-width: 767.98px) {
  .sm-down-p-lg {
    padding: 50px !important; }
  .sm-down-p-lg-child > * {
    padding: 50px !important; }
  .sm-down-p-lg-child-not-last > *:not(:last-child) {
    padding: 50px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pt-lg {
    padding-top: 50px !important; }
  .sm-down-pt-lg-child > * {
    padding-top: 50px !important; }
  .sm-down-pt-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pb-lg {
    padding-bottom: 50px !important; }
  .sm-down-pb-lg-child > * {
    padding-bottom: 50px !important; }
  .sm-down-pb-lg-child-not-last > *:not(:last-child) {
    padding-bottom: 50px !important; } }

@media (max-width: 767.98px) {
  .sm-down-py-lg {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .sm-down-py-lg-child > * {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .sm-down-py-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important;
    padding-bottom: 50px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pl-lg {
    padding-left: 50px !important; }
  .sm-down-pl-lg-child > * {
    padding-left: 50px !important; }
  .sm-down-pl-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pr-lg {
    padding-right: 50px !important; }
  .sm-down-pr-lg-child > * {
    padding-right: 50px !important; }
  .sm-down-pr-lg-child-not-last > *:not(:last-child) {
    padding-right: 50px !important; } }

@media (max-width: 767.98px) {
  .sm-down-px-lg {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .sm-down-px-lg-child > * {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .sm-down-px-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important;
    padding-right: 50px !important; } }

@media (max-width: 767.98px) {
  .sm-down-m-xl {
    margin: 70px !important; }
  .sm-down-m-xl-child > * {
    margin: 70px !important; }
  .sm-down-m-xl-child-not-last > *:not(:last-child) {
    margin: 70px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mt-xl {
    margin-top: 70px !important; }
  .sm-down-mt-xl-child > * {
    margin-top: 70px !important; }
  .sm-down-mt-xl-child-not-last > *:not(:last-child) {
    margin-top: 70px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mb-xl {
    margin-bottom: 70px !important; }
  .sm-down-mb-xl-child > * {
    margin-bottom: 70px !important; }
  .sm-down-mb-xl-child-not-last > *:not(:last-child) {
    margin-bottom: 70px !important; } }

@media (max-width: 767.98px) {
  .sm-down-my-xl {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .sm-down-my-xl-child > * {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .sm-down-my-xl-child-not-last > *:not(:last-child) {
    margin-top: 70px !important;
    margin-bottom: 70px !important; } }

@media (max-width: 767.98px) {
  .sm-down-ml-xl {
    margin-left: 70px !important; }
  .sm-down-ml-xl-child > * {
    margin-left: 70px !important; }
  .sm-down-ml-xl-child-not-last > *:not(:last-child) {
    margin-left: 70px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mr-xl {
    margin-right: 70px !important; }
  .sm-down-mr-xl-child > * {
    margin-right: 70px !important; }
  .sm-down-mr-xl-child-not-last > *:not(:last-child) {
    margin-right: 70px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mx-xl {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .sm-down-mx-xl-child > * {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .sm-down-mx-xl-child-not-last > *:not(:last-child) {
    margin-left: 70px !important;
    margin-right: 70px !important; } }

@media (max-width: 767.98px) {
  .sm-down-p-xl {
    padding: 70px !important; }
  .sm-down-p-xl-child > * {
    padding: 70px !important; }
  .sm-down-p-xl-child-not-last > *:not(:last-child) {
    padding: 70px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pt-xl {
    padding-top: 70px !important; }
  .sm-down-pt-xl-child > * {
    padding-top: 70px !important; }
  .sm-down-pt-xl-child-not-last > *:not(:last-child) {
    padding-top: 70px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pb-xl {
    padding-bottom: 70px !important; }
  .sm-down-pb-xl-child > * {
    padding-bottom: 70px !important; }
  .sm-down-pb-xl-child-not-last > *:not(:last-child) {
    padding-bottom: 70px !important; } }

@media (max-width: 767.98px) {
  .sm-down-py-xl {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .sm-down-py-xl-child > * {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .sm-down-py-xl-child-not-last > *:not(:last-child) {
    padding-top: 70px !important;
    padding-bottom: 70px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pl-xl {
    padding-left: 70px !important; }
  .sm-down-pl-xl-child > * {
    padding-left: 70px !important; }
  .sm-down-pl-xl-child-not-last > *:not(:last-child) {
    padding-left: 70px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pr-xl {
    padding-right: 70px !important; }
  .sm-down-pr-xl-child > * {
    padding-right: 70px !important; }
  .sm-down-pr-xl-child-not-last > *:not(:last-child) {
    padding-right: 70px !important; } }

@media (max-width: 767.98px) {
  .sm-down-px-xl {
    padding-left: 70px !important;
    padding-right: 70px !important; }
  .sm-down-px-xl-child > * {
    padding-left: 70px !important;
    padding-right: 70px !important; }
  .sm-down-px-xl-child-not-last > *:not(:last-child) {
    padding-left: 70px !important;
    padding-right: 70px !important; } }

@media (max-width: 767.98px) {
  .sm-down-m-xxl {
    margin: 140px !important; }
  .sm-down-m-xxl-child > * {
    margin: 140px !important; }
  .sm-down-m-xxl-child-not-last > *:not(:last-child) {
    margin: 140px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mt-xxl {
    margin-top: 140px !important; }
  .sm-down-mt-xxl-child > * {
    margin-top: 140px !important; }
  .sm-down-mt-xxl-child-not-last > *:not(:last-child) {
    margin-top: 140px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mb-xxl {
    margin-bottom: 140px !important; }
  .sm-down-mb-xxl-child > * {
    margin-bottom: 140px !important; }
  .sm-down-mb-xxl-child-not-last > *:not(:last-child) {
    margin-bottom: 140px !important; } }

@media (max-width: 767.98px) {
  .sm-down-my-xxl {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .sm-down-my-xxl-child > * {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .sm-down-my-xxl-child-not-last > *:not(:last-child) {
    margin-top: 140px !important;
    margin-bottom: 140px !important; } }

@media (max-width: 767.98px) {
  .sm-down-ml-xxl {
    margin-left: 140px !important; }
  .sm-down-ml-xxl-child > * {
    margin-left: 140px !important; }
  .sm-down-ml-xxl-child-not-last > *:not(:last-child) {
    margin-left: 140px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mr-xxl {
    margin-right: 140px !important; }
  .sm-down-mr-xxl-child > * {
    margin-right: 140px !important; }
  .sm-down-mr-xxl-child-not-last > *:not(:last-child) {
    margin-right: 140px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mx-xxl {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .sm-down-mx-xxl-child > * {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .sm-down-mx-xxl-child-not-last > *:not(:last-child) {
    margin-left: 140px !important;
    margin-right: 140px !important; } }

@media (max-width: 767.98px) {
  .sm-down-p-xxl {
    padding: 140px !important; }
  .sm-down-p-xxl-child > * {
    padding: 140px !important; }
  .sm-down-p-xxl-child-not-last > *:not(:last-child) {
    padding: 140px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pt-xxl {
    padding-top: 140px !important; }
  .sm-down-pt-xxl-child > * {
    padding-top: 140px !important; }
  .sm-down-pt-xxl-child-not-last > *:not(:last-child) {
    padding-top: 140px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pb-xxl {
    padding-bottom: 140px !important; }
  .sm-down-pb-xxl-child > * {
    padding-bottom: 140px !important; }
  .sm-down-pb-xxl-child-not-last > *:not(:last-child) {
    padding-bottom: 140px !important; } }

@media (max-width: 767.98px) {
  .sm-down-py-xxl {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .sm-down-py-xxl-child > * {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .sm-down-py-xxl-child-not-last > *:not(:last-child) {
    padding-top: 140px !important;
    padding-bottom: 140px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pl-xxl {
    padding-left: 140px !important; }
  .sm-down-pl-xxl-child > * {
    padding-left: 140px !important; }
  .sm-down-pl-xxl-child-not-last > *:not(:last-child) {
    padding-left: 140px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pr-xxl {
    padding-right: 140px !important; }
  .sm-down-pr-xxl-child > * {
    padding-right: 140px !important; }
  .sm-down-pr-xxl-child-not-last > *:not(:last-child) {
    padding-right: 140px !important; } }

@media (max-width: 767.98px) {
  .sm-down-px-xxl {
    padding-left: 140px !important;
    padding-right: 140px !important; }
  .sm-down-px-xxl-child > * {
    padding-left: 140px !important;
    padding-right: 140px !important; }
  .sm-down-px-xxl-child-not-last > *:not(:last-child) {
    padding-left: 140px !important;
    padding-right: 140px !important; } }

@media (max-width: 575.98px) {
  .xs-down-m-0 {
    margin: 0 !important; }
  .xs-down-m-0-child > * {
    margin: 0 !important; }
  .xs-down-m-0-child-not-last > *:not(:last-child) {
    margin: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-mt-0 {
    margin-top: 0 !important; }
  .xs-down-mt-0-child > * {
    margin-top: 0 !important; }
  .xs-down-mt-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-mb-0 {
    margin-bottom: 0 !important; }
  .xs-down-mb-0-child > * {
    margin-bottom: 0 !important; }
  .xs-down-mb-0-child-not-last > *:not(:last-child) {
    margin-bottom: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .xs-down-my-0-child > * {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .xs-down-my-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important;
    margin-bottom: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-ml-0 {
    margin-left: 0 !important; }
  .xs-down-ml-0-child > * {
    margin-left: 0 !important; }
  .xs-down-ml-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-mr-0 {
    margin-right: 0 !important; }
  .xs-down-mr-0-child > * {
    margin-right: 0 !important; }
  .xs-down-mr-0-child-not-last > *:not(:last-child) {
    margin-right: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .xs-down-mx-0-child > * {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .xs-down-mx-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important;
    margin-right: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-p-0 {
    padding: 0 !important; }
  .xs-down-p-0-child > * {
    padding: 0 !important; }
  .xs-down-p-0-child-not-last > *:not(:last-child) {
    padding: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-pt-0 {
    padding-top: 0 !important; }
  .xs-down-pt-0-child > * {
    padding-top: 0 !important; }
  .xs-down-pt-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-pb-0 {
    padding-bottom: 0 !important; }
  .xs-down-pb-0-child > * {
    padding-bottom: 0 !important; }
  .xs-down-pb-0-child-not-last > *:not(:last-child) {
    padding-bottom: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .xs-down-py-0-child > * {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .xs-down-py-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important;
    padding-bottom: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-pl-0 {
    padding-left: 0 !important; }
  .xs-down-pl-0-child > * {
    padding-left: 0 !important; }
  .xs-down-pl-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-pr-0 {
    padding-right: 0 !important; }
  .xs-down-pr-0-child > * {
    padding-right: 0 !important; }
  .xs-down-pr-0-child-not-last > *:not(:last-child) {
    padding-right: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .xs-down-px-0-child > * {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .xs-down-px-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important;
    padding-right: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-m-xs {
    margin: 5px !important; }
  .xs-down-m-xs-child > * {
    margin: 5px !important; }
  .xs-down-m-xs-child-not-last > *:not(:last-child) {
    margin: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mt-xs {
    margin-top: 5px !important; }
  .xs-down-mt-xs-child > * {
    margin-top: 5px !important; }
  .xs-down-mt-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mb-xs {
    margin-bottom: 5px !important; }
  .xs-down-mb-xs-child > * {
    margin-bottom: 5px !important; }
  .xs-down-mb-xs-child-not-last > *:not(:last-child) {
    margin-bottom: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-my-xs {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .xs-down-my-xs-child > * {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .xs-down-my-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important;
    margin-bottom: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-ml-xs {
    margin-left: 5px !important; }
  .xs-down-ml-xs-child > * {
    margin-left: 5px !important; }
  .xs-down-ml-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mr-xs {
    margin-right: 5px !important; }
  .xs-down-mr-xs-child > * {
    margin-right: 5px !important; }
  .xs-down-mr-xs-child-not-last > *:not(:last-child) {
    margin-right: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mx-xs {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .xs-down-mx-xs-child > * {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .xs-down-mx-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important;
    margin-right: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-p-xs {
    padding: 5px !important; }
  .xs-down-p-xs-child > * {
    padding: 5px !important; }
  .xs-down-p-xs-child-not-last > *:not(:last-child) {
    padding: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pt-xs {
    padding-top: 5px !important; }
  .xs-down-pt-xs-child > * {
    padding-top: 5px !important; }
  .xs-down-pt-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pb-xs {
    padding-bottom: 5px !important; }
  .xs-down-pb-xs-child > * {
    padding-bottom: 5px !important; }
  .xs-down-pb-xs-child-not-last > *:not(:last-child) {
    padding-bottom: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-py-xs {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .xs-down-py-xs-child > * {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .xs-down-py-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important;
    padding-bottom: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pl-xs {
    padding-left: 5px !important; }
  .xs-down-pl-xs-child > * {
    padding-left: 5px !important; }
  .xs-down-pl-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pr-xs {
    padding-right: 5px !important; }
  .xs-down-pr-xs-child > * {
    padding-right: 5px !important; }
  .xs-down-pr-xs-child-not-last > *:not(:last-child) {
    padding-right: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-px-xs {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .xs-down-px-xs-child > * {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .xs-down-px-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important;
    padding-right: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-m-sm {
    margin: 15px !important; }
  .xs-down-m-sm-child > * {
    margin: 15px !important; }
  .xs-down-m-sm-child-not-last > *:not(:last-child) {
    margin: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mt-sm {
    margin-top: 15px !important; }
  .xs-down-mt-sm-child > * {
    margin-top: 15px !important; }
  .xs-down-mt-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mb-sm {
    margin-bottom: 15px !important; }
  .xs-down-mb-sm-child > * {
    margin-bottom: 15px !important; }
  .xs-down-mb-sm-child-not-last > *:not(:last-child) {
    margin-bottom: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-my-sm {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .xs-down-my-sm-child > * {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .xs-down-my-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important;
    margin-bottom: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-ml-sm {
    margin-left: 15px !important; }
  .xs-down-ml-sm-child > * {
    margin-left: 15px !important; }
  .xs-down-ml-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mr-sm {
    margin-right: 15px !important; }
  .xs-down-mr-sm-child > * {
    margin-right: 15px !important; }
  .xs-down-mr-sm-child-not-last > *:not(:last-child) {
    margin-right: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mx-sm {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .xs-down-mx-sm-child > * {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .xs-down-mx-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important;
    margin-right: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-p-sm {
    padding: 15px !important; }
  .xs-down-p-sm-child > * {
    padding: 15px !important; }
  .xs-down-p-sm-child-not-last > *:not(:last-child) {
    padding: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pt-sm {
    padding-top: 15px !important; }
  .xs-down-pt-sm-child > * {
    padding-top: 15px !important; }
  .xs-down-pt-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pb-sm {
    padding-bottom: 15px !important; }
  .xs-down-pb-sm-child > * {
    padding-bottom: 15px !important; }
  .xs-down-pb-sm-child-not-last > *:not(:last-child) {
    padding-bottom: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-py-sm {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .xs-down-py-sm-child > * {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .xs-down-py-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important;
    padding-bottom: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pl-sm {
    padding-left: 15px !important; }
  .xs-down-pl-sm-child > * {
    padding-left: 15px !important; }
  .xs-down-pl-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pr-sm {
    padding-right: 15px !important; }
  .xs-down-pr-sm-child > * {
    padding-right: 15px !important; }
  .xs-down-pr-sm-child-not-last > *:not(:last-child) {
    padding-right: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-px-sm {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .xs-down-px-sm-child > * {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .xs-down-px-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important;
    padding-right: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-m-md {
    margin: 24px !important; }
  .xs-down-m-md-child > * {
    margin: 24px !important; }
  .xs-down-m-md-child-not-last > *:not(:last-child) {
    margin: 24px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mt-md {
    margin-top: 24px !important; }
  .xs-down-mt-md-child > * {
    margin-top: 24px !important; }
  .xs-down-mt-md-child-not-last > *:not(:last-child) {
    margin-top: 24px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mb-md {
    margin-bottom: 24px !important; }
  .xs-down-mb-md-child > * {
    margin-bottom: 24px !important; }
  .xs-down-mb-md-child-not-last > *:not(:last-child) {
    margin-bottom: 24px !important; } }

@media (max-width: 575.98px) {
  .xs-down-my-md {
    margin-top: 24px !important;
    margin-bottom: 24px !important; }
  .xs-down-my-md-child > * {
    margin-top: 24px !important;
    margin-bottom: 24px !important; }
  .xs-down-my-md-child-not-last > *:not(:last-child) {
    margin-top: 24px !important;
    margin-bottom: 24px !important; } }

@media (max-width: 575.98px) {
  .xs-down-ml-md {
    margin-left: 24px !important; }
  .xs-down-ml-md-child > * {
    margin-left: 24px !important; }
  .xs-down-ml-md-child-not-last > *:not(:last-child) {
    margin-left: 24px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mr-md {
    margin-right: 24px !important; }
  .xs-down-mr-md-child > * {
    margin-right: 24px !important; }
  .xs-down-mr-md-child-not-last > *:not(:last-child) {
    margin-right: 24px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mx-md {
    margin-left: 24px !important;
    margin-right: 24px !important; }
  .xs-down-mx-md-child > * {
    margin-left: 24px !important;
    margin-right: 24px !important; }
  .xs-down-mx-md-child-not-last > *:not(:last-child) {
    margin-left: 24px !important;
    margin-right: 24px !important; } }

@media (max-width: 575.98px) {
  .xs-down-p-md {
    padding: 24px !important; }
  .xs-down-p-md-child > * {
    padding: 24px !important; }
  .xs-down-p-md-child-not-last > *:not(:last-child) {
    padding: 24px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pt-md {
    padding-top: 24px !important; }
  .xs-down-pt-md-child > * {
    padding-top: 24px !important; }
  .xs-down-pt-md-child-not-last > *:not(:last-child) {
    padding-top: 24px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pb-md {
    padding-bottom: 24px !important; }
  .xs-down-pb-md-child > * {
    padding-bottom: 24px !important; }
  .xs-down-pb-md-child-not-last > *:not(:last-child) {
    padding-bottom: 24px !important; } }

@media (max-width: 575.98px) {
  .xs-down-py-md {
    padding-top: 24px !important;
    padding-bottom: 24px !important; }
  .xs-down-py-md-child > * {
    padding-top: 24px !important;
    padding-bottom: 24px !important; }
  .xs-down-py-md-child-not-last > *:not(:last-child) {
    padding-top: 24px !important;
    padding-bottom: 24px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pl-md {
    padding-left: 24px !important; }
  .xs-down-pl-md-child > * {
    padding-left: 24px !important; }
  .xs-down-pl-md-child-not-last > *:not(:last-child) {
    padding-left: 24px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pr-md {
    padding-right: 24px !important; }
  .xs-down-pr-md-child > * {
    padding-right: 24px !important; }
  .xs-down-pr-md-child-not-last > *:not(:last-child) {
    padding-right: 24px !important; } }

@media (max-width: 575.98px) {
  .xs-down-px-md {
    padding-left: 24px !important;
    padding-right: 24px !important; }
  .xs-down-px-md-child > * {
    padding-left: 24px !important;
    padding-right: 24px !important; }
  .xs-down-px-md-child-not-last > *:not(:last-child) {
    padding-left: 24px !important;
    padding-right: 24px !important; } }

@media (max-width: 575.98px) {
  .xs-down-m-lg {
    margin: 50px !important; }
  .xs-down-m-lg-child > * {
    margin: 50px !important; }
  .xs-down-m-lg-child-not-last > *:not(:last-child) {
    margin: 50px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mt-lg {
    margin-top: 50px !important; }
  .xs-down-mt-lg-child > * {
    margin-top: 50px !important; }
  .xs-down-mt-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mb-lg {
    margin-bottom: 50px !important; }
  .xs-down-mb-lg-child > * {
    margin-bottom: 50px !important; }
  .xs-down-mb-lg-child-not-last > *:not(:last-child) {
    margin-bottom: 50px !important; } }

@media (max-width: 575.98px) {
  .xs-down-my-lg {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .xs-down-my-lg-child > * {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .xs-down-my-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important;
    margin-bottom: 50px !important; } }

@media (max-width: 575.98px) {
  .xs-down-ml-lg {
    margin-left: 50px !important; }
  .xs-down-ml-lg-child > * {
    margin-left: 50px !important; }
  .xs-down-ml-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mr-lg {
    margin-right: 50px !important; }
  .xs-down-mr-lg-child > * {
    margin-right: 50px !important; }
  .xs-down-mr-lg-child-not-last > *:not(:last-child) {
    margin-right: 50px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mx-lg {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .xs-down-mx-lg-child > * {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .xs-down-mx-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important;
    margin-right: 50px !important; } }

@media (max-width: 575.98px) {
  .xs-down-p-lg {
    padding: 50px !important; }
  .xs-down-p-lg-child > * {
    padding: 50px !important; }
  .xs-down-p-lg-child-not-last > *:not(:last-child) {
    padding: 50px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pt-lg {
    padding-top: 50px !important; }
  .xs-down-pt-lg-child > * {
    padding-top: 50px !important; }
  .xs-down-pt-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pb-lg {
    padding-bottom: 50px !important; }
  .xs-down-pb-lg-child > * {
    padding-bottom: 50px !important; }
  .xs-down-pb-lg-child-not-last > *:not(:last-child) {
    padding-bottom: 50px !important; } }

@media (max-width: 575.98px) {
  .xs-down-py-lg {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .xs-down-py-lg-child > * {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .xs-down-py-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important;
    padding-bottom: 50px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pl-lg {
    padding-left: 50px !important; }
  .xs-down-pl-lg-child > * {
    padding-left: 50px !important; }
  .xs-down-pl-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pr-lg {
    padding-right: 50px !important; }
  .xs-down-pr-lg-child > * {
    padding-right: 50px !important; }
  .xs-down-pr-lg-child-not-last > *:not(:last-child) {
    padding-right: 50px !important; } }

@media (max-width: 575.98px) {
  .xs-down-px-lg {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .xs-down-px-lg-child > * {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .xs-down-px-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important;
    padding-right: 50px !important; } }

@media (max-width: 575.98px) {
  .xs-down-m-xl {
    margin: 70px !important; }
  .xs-down-m-xl-child > * {
    margin: 70px !important; }
  .xs-down-m-xl-child-not-last > *:not(:last-child) {
    margin: 70px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mt-xl {
    margin-top: 70px !important; }
  .xs-down-mt-xl-child > * {
    margin-top: 70px !important; }
  .xs-down-mt-xl-child-not-last > *:not(:last-child) {
    margin-top: 70px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mb-xl {
    margin-bottom: 70px !important; }
  .xs-down-mb-xl-child > * {
    margin-bottom: 70px !important; }
  .xs-down-mb-xl-child-not-last > *:not(:last-child) {
    margin-bottom: 70px !important; } }

@media (max-width: 575.98px) {
  .xs-down-my-xl {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .xs-down-my-xl-child > * {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .xs-down-my-xl-child-not-last > *:not(:last-child) {
    margin-top: 70px !important;
    margin-bottom: 70px !important; } }

@media (max-width: 575.98px) {
  .xs-down-ml-xl {
    margin-left: 70px !important; }
  .xs-down-ml-xl-child > * {
    margin-left: 70px !important; }
  .xs-down-ml-xl-child-not-last > *:not(:last-child) {
    margin-left: 70px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mr-xl {
    margin-right: 70px !important; }
  .xs-down-mr-xl-child > * {
    margin-right: 70px !important; }
  .xs-down-mr-xl-child-not-last > *:not(:last-child) {
    margin-right: 70px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mx-xl {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .xs-down-mx-xl-child > * {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .xs-down-mx-xl-child-not-last > *:not(:last-child) {
    margin-left: 70px !important;
    margin-right: 70px !important; } }

@media (max-width: 575.98px) {
  .xs-down-p-xl {
    padding: 70px !important; }
  .xs-down-p-xl-child > * {
    padding: 70px !important; }
  .xs-down-p-xl-child-not-last > *:not(:last-child) {
    padding: 70px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pt-xl {
    padding-top: 70px !important; }
  .xs-down-pt-xl-child > * {
    padding-top: 70px !important; }
  .xs-down-pt-xl-child-not-last > *:not(:last-child) {
    padding-top: 70px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pb-xl {
    padding-bottom: 70px !important; }
  .xs-down-pb-xl-child > * {
    padding-bottom: 70px !important; }
  .xs-down-pb-xl-child-not-last > *:not(:last-child) {
    padding-bottom: 70px !important; } }

@media (max-width: 575.98px) {
  .xs-down-py-xl {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .xs-down-py-xl-child > * {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .xs-down-py-xl-child-not-last > *:not(:last-child) {
    padding-top: 70px !important;
    padding-bottom: 70px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pl-xl {
    padding-left: 70px !important; }
  .xs-down-pl-xl-child > * {
    padding-left: 70px !important; }
  .xs-down-pl-xl-child-not-last > *:not(:last-child) {
    padding-left: 70px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pr-xl {
    padding-right: 70px !important; }
  .xs-down-pr-xl-child > * {
    padding-right: 70px !important; }
  .xs-down-pr-xl-child-not-last > *:not(:last-child) {
    padding-right: 70px !important; } }

@media (max-width: 575.98px) {
  .xs-down-px-xl {
    padding-left: 70px !important;
    padding-right: 70px !important; }
  .xs-down-px-xl-child > * {
    padding-left: 70px !important;
    padding-right: 70px !important; }
  .xs-down-px-xl-child-not-last > *:not(:last-child) {
    padding-left: 70px !important;
    padding-right: 70px !important; } }

@media (max-width: 575.98px) {
  .xs-down-m-xxl {
    margin: 140px !important; }
  .xs-down-m-xxl-child > * {
    margin: 140px !important; }
  .xs-down-m-xxl-child-not-last > *:not(:last-child) {
    margin: 140px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mt-xxl {
    margin-top: 140px !important; }
  .xs-down-mt-xxl-child > * {
    margin-top: 140px !important; }
  .xs-down-mt-xxl-child-not-last > *:not(:last-child) {
    margin-top: 140px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mb-xxl {
    margin-bottom: 140px !important; }
  .xs-down-mb-xxl-child > * {
    margin-bottom: 140px !important; }
  .xs-down-mb-xxl-child-not-last > *:not(:last-child) {
    margin-bottom: 140px !important; } }

@media (max-width: 575.98px) {
  .xs-down-my-xxl {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .xs-down-my-xxl-child > * {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .xs-down-my-xxl-child-not-last > *:not(:last-child) {
    margin-top: 140px !important;
    margin-bottom: 140px !important; } }

@media (max-width: 575.98px) {
  .xs-down-ml-xxl {
    margin-left: 140px !important; }
  .xs-down-ml-xxl-child > * {
    margin-left: 140px !important; }
  .xs-down-ml-xxl-child-not-last > *:not(:last-child) {
    margin-left: 140px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mr-xxl {
    margin-right: 140px !important; }
  .xs-down-mr-xxl-child > * {
    margin-right: 140px !important; }
  .xs-down-mr-xxl-child-not-last > *:not(:last-child) {
    margin-right: 140px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mx-xxl {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .xs-down-mx-xxl-child > * {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .xs-down-mx-xxl-child-not-last > *:not(:last-child) {
    margin-left: 140px !important;
    margin-right: 140px !important; } }

@media (max-width: 575.98px) {
  .xs-down-p-xxl {
    padding: 140px !important; }
  .xs-down-p-xxl-child > * {
    padding: 140px !important; }
  .xs-down-p-xxl-child-not-last > *:not(:last-child) {
    padding: 140px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pt-xxl {
    padding-top: 140px !important; }
  .xs-down-pt-xxl-child > * {
    padding-top: 140px !important; }
  .xs-down-pt-xxl-child-not-last > *:not(:last-child) {
    padding-top: 140px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pb-xxl {
    padding-bottom: 140px !important; }
  .xs-down-pb-xxl-child > * {
    padding-bottom: 140px !important; }
  .xs-down-pb-xxl-child-not-last > *:not(:last-child) {
    padding-bottom: 140px !important; } }

@media (max-width: 575.98px) {
  .xs-down-py-xxl {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .xs-down-py-xxl-child > * {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .xs-down-py-xxl-child-not-last > *:not(:last-child) {
    padding-top: 140px !important;
    padding-bottom: 140px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pl-xxl {
    padding-left: 140px !important; }
  .xs-down-pl-xxl-child > * {
    padding-left: 140px !important; }
  .xs-down-pl-xxl-child-not-last > *:not(:last-child) {
    padding-left: 140px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pr-xxl {
    padding-right: 140px !important; }
  .xs-down-pr-xxl-child > * {
    padding-right: 140px !important; }
  .xs-down-pr-xxl-child-not-last > *:not(:last-child) {
    padding-right: 140px !important; } }

@media (max-width: 575.98px) {
  .xs-down-px-xxl {
    padding-left: 140px !important;
    padding-right: 140px !important; }
  .xs-down-px-xxl-child > * {
    padding-left: 140px !important;
    padding-right: 140px !important; }
  .xs-down-px-xxl-child-not-last > *:not(:last-child) {
    padding-left: 140px !important;
    padding-right: 140px !important; } }

.xs-up-m-0 {
  margin: 0 !important; }

.xs-up-m-0-child > * {
  margin: 0 !important; }

.xs-up-m-0-child-not-last > *:not(:last-child) {
  margin: 0 !important; }

.xs-up-mt-0 {
  margin-top: 0 !important; }

.xs-up-mt-0-child > * {
  margin-top: 0 !important; }

.xs-up-mt-0-child-not-last > *:not(:last-child) {
  margin-top: 0 !important; }

.xs-up-mb-0 {
  margin-bottom: 0 !important; }

.xs-up-mb-0-child > * {
  margin-bottom: 0 !important; }

.xs-up-mb-0-child-not-last > *:not(:last-child) {
  margin-bottom: 0 !important; }

.xs-up-my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.xs-up-my-0-child > * {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.xs-up-my-0-child-not-last > *:not(:last-child) {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.xs-up-ml-0 {
  margin-left: 0 !important; }

.xs-up-ml-0-child > * {
  margin-left: 0 !important; }

.xs-up-ml-0-child-not-last > *:not(:last-child) {
  margin-left: 0 !important; }

.xs-up-mr-0 {
  margin-right: 0 !important; }

.xs-up-mr-0-child > * {
  margin-right: 0 !important; }

.xs-up-mr-0-child-not-last > *:not(:last-child) {
  margin-right: 0 !important; }

.xs-up-mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important; }

.xs-up-mx-0-child > * {
  margin-left: 0 !important;
  margin-right: 0 !important; }

.xs-up-mx-0-child-not-last > *:not(:last-child) {
  margin-left: 0 !important;
  margin-right: 0 !important; }

.xs-up-p-0 {
  padding: 0 !important; }

.xs-up-p-0-child > * {
  padding: 0 !important; }

.xs-up-p-0-child-not-last > *:not(:last-child) {
  padding: 0 !important; }

.xs-up-pt-0 {
  padding-top: 0 !important; }

.xs-up-pt-0-child > * {
  padding-top: 0 !important; }

.xs-up-pt-0-child-not-last > *:not(:last-child) {
  padding-top: 0 !important; }

.xs-up-pb-0 {
  padding-bottom: 0 !important; }

.xs-up-pb-0-child > * {
  padding-bottom: 0 !important; }

.xs-up-pb-0-child-not-last > *:not(:last-child) {
  padding-bottom: 0 !important; }

.xs-up-py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.xs-up-py-0-child > * {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.xs-up-py-0-child-not-last > *:not(:last-child) {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.xs-up-pl-0 {
  padding-left: 0 !important; }

.xs-up-pl-0-child > * {
  padding-left: 0 !important; }

.xs-up-pl-0-child-not-last > *:not(:last-child) {
  padding-left: 0 !important; }

.xs-up-pr-0 {
  padding-right: 0 !important; }

.xs-up-pr-0-child > * {
  padding-right: 0 !important; }

.xs-up-pr-0-child-not-last > *:not(:last-child) {
  padding-right: 0 !important; }

.xs-up-px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.xs-up-px-0-child > * {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.xs-up-px-0-child-not-last > *:not(:last-child) {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.xs-up-m-xs {
  margin: 5px !important; }

.xs-up-m-xs-child > * {
  margin: 5px !important; }

.xs-up-m-xs-child-not-last > *:not(:last-child) {
  margin: 5px !important; }

.xs-up-mt-xs {
  margin-top: 5px !important; }

.xs-up-mt-xs-child > * {
  margin-top: 5px !important; }

.xs-up-mt-xs-child-not-last > *:not(:last-child) {
  margin-top: 5px !important; }

.xs-up-mb-xs {
  margin-bottom: 5px !important; }

.xs-up-mb-xs-child > * {
  margin-bottom: 5px !important; }

.xs-up-mb-xs-child-not-last > *:not(:last-child) {
  margin-bottom: 5px !important; }

.xs-up-my-xs {
  margin-top: 5px !important;
  margin-bottom: 5px !important; }

.xs-up-my-xs-child > * {
  margin-top: 5px !important;
  margin-bottom: 5px !important; }

.xs-up-my-xs-child-not-last > *:not(:last-child) {
  margin-top: 5px !important;
  margin-bottom: 5px !important; }

.xs-up-ml-xs {
  margin-left: 5px !important; }

.xs-up-ml-xs-child > * {
  margin-left: 5px !important; }

.xs-up-ml-xs-child-not-last > *:not(:last-child) {
  margin-left: 5px !important; }

.xs-up-mr-xs {
  margin-right: 5px !important; }

.xs-up-mr-xs-child > * {
  margin-right: 5px !important; }

.xs-up-mr-xs-child-not-last > *:not(:last-child) {
  margin-right: 5px !important; }

.xs-up-mx-xs {
  margin-left: 5px !important;
  margin-right: 5px !important; }

.xs-up-mx-xs-child > * {
  margin-left: 5px !important;
  margin-right: 5px !important; }

.xs-up-mx-xs-child-not-last > *:not(:last-child) {
  margin-left: 5px !important;
  margin-right: 5px !important; }

.xs-up-p-xs {
  padding: 5px !important; }

.xs-up-p-xs-child > * {
  padding: 5px !important; }

.xs-up-p-xs-child-not-last > *:not(:last-child) {
  padding: 5px !important; }

.xs-up-pt-xs {
  padding-top: 5px !important; }

.xs-up-pt-xs-child > * {
  padding-top: 5px !important; }

.xs-up-pt-xs-child-not-last > *:not(:last-child) {
  padding-top: 5px !important; }

.xs-up-pb-xs {
  padding-bottom: 5px !important; }

.xs-up-pb-xs-child > * {
  padding-bottom: 5px !important; }

.xs-up-pb-xs-child-not-last > *:not(:last-child) {
  padding-bottom: 5px !important; }

.xs-up-py-xs {
  padding-top: 5px !important;
  padding-bottom: 5px !important; }

.xs-up-py-xs-child > * {
  padding-top: 5px !important;
  padding-bottom: 5px !important; }

.xs-up-py-xs-child-not-last > *:not(:last-child) {
  padding-top: 5px !important;
  padding-bottom: 5px !important; }

.xs-up-pl-xs {
  padding-left: 5px !important; }

.xs-up-pl-xs-child > * {
  padding-left: 5px !important; }

.xs-up-pl-xs-child-not-last > *:not(:last-child) {
  padding-left: 5px !important; }

.xs-up-pr-xs {
  padding-right: 5px !important; }

.xs-up-pr-xs-child > * {
  padding-right: 5px !important; }

.xs-up-pr-xs-child-not-last > *:not(:last-child) {
  padding-right: 5px !important; }

.xs-up-px-xs {
  padding-left: 5px !important;
  padding-right: 5px !important; }

.xs-up-px-xs-child > * {
  padding-left: 5px !important;
  padding-right: 5px !important; }

.xs-up-px-xs-child-not-last > *:not(:last-child) {
  padding-left: 5px !important;
  padding-right: 5px !important; }

.xs-up-m-sm {
  margin: 15px !important; }

.xs-up-m-sm-child > * {
  margin: 15px !important; }

.xs-up-m-sm-child-not-last > *:not(:last-child) {
  margin: 15px !important; }

.xs-up-mt-sm {
  margin-top: 15px !important; }

.xs-up-mt-sm-child > * {
  margin-top: 15px !important; }

.xs-up-mt-sm-child-not-last > *:not(:last-child) {
  margin-top: 15px !important; }

.xs-up-mb-sm {
  margin-bottom: 15px !important; }

.xs-up-mb-sm-child > * {
  margin-bottom: 15px !important; }

.xs-up-mb-sm-child-not-last > *:not(:last-child) {
  margin-bottom: 15px !important; }

.xs-up-my-sm {
  margin-top: 15px !important;
  margin-bottom: 15px !important; }

.xs-up-my-sm-child > * {
  margin-top: 15px !important;
  margin-bottom: 15px !important; }

.xs-up-my-sm-child-not-last > *:not(:last-child) {
  margin-top: 15px !important;
  margin-bottom: 15px !important; }

.xs-up-ml-sm {
  margin-left: 15px !important; }

.xs-up-ml-sm-child > * {
  margin-left: 15px !important; }

.xs-up-ml-sm-child-not-last > *:not(:last-child) {
  margin-left: 15px !important; }

.xs-up-mr-sm {
  margin-right: 15px !important; }

.xs-up-mr-sm-child > * {
  margin-right: 15px !important; }

.xs-up-mr-sm-child-not-last > *:not(:last-child) {
  margin-right: 15px !important; }

.xs-up-mx-sm {
  margin-left: 15px !important;
  margin-right: 15px !important; }

.xs-up-mx-sm-child > * {
  margin-left: 15px !important;
  margin-right: 15px !important; }

.xs-up-mx-sm-child-not-last > *:not(:last-child) {
  margin-left: 15px !important;
  margin-right: 15px !important; }

.xs-up-p-sm {
  padding: 15px !important; }

.xs-up-p-sm-child > * {
  padding: 15px !important; }

.xs-up-p-sm-child-not-last > *:not(:last-child) {
  padding: 15px !important; }

.xs-up-pt-sm {
  padding-top: 15px !important; }

.xs-up-pt-sm-child > * {
  padding-top: 15px !important; }

.xs-up-pt-sm-child-not-last > *:not(:last-child) {
  padding-top: 15px !important; }

.xs-up-pb-sm {
  padding-bottom: 15px !important; }

.xs-up-pb-sm-child > * {
  padding-bottom: 15px !important; }

.xs-up-pb-sm-child-not-last > *:not(:last-child) {
  padding-bottom: 15px !important; }

.xs-up-py-sm {
  padding-top: 15px !important;
  padding-bottom: 15px !important; }

.xs-up-py-sm-child > * {
  padding-top: 15px !important;
  padding-bottom: 15px !important; }

.xs-up-py-sm-child-not-last > *:not(:last-child) {
  padding-top: 15px !important;
  padding-bottom: 15px !important; }

.xs-up-pl-sm {
  padding-left: 15px !important; }

.xs-up-pl-sm-child > * {
  padding-left: 15px !important; }

.xs-up-pl-sm-child-not-last > *:not(:last-child) {
  padding-left: 15px !important; }

.xs-up-pr-sm {
  padding-right: 15px !important; }

.xs-up-pr-sm-child > * {
  padding-right: 15px !important; }

.xs-up-pr-sm-child-not-last > *:not(:last-child) {
  padding-right: 15px !important; }

.xs-up-px-sm {
  padding-left: 15px !important;
  padding-right: 15px !important; }

.xs-up-px-sm-child > * {
  padding-left: 15px !important;
  padding-right: 15px !important; }

.xs-up-px-sm-child-not-last > *:not(:last-child) {
  padding-left: 15px !important;
  padding-right: 15px !important; }

.xs-up-m-md {
  margin: 24px !important; }

.xs-up-m-md-child > * {
  margin: 24px !important; }

.xs-up-m-md-child-not-last > *:not(:last-child) {
  margin: 24px !important; }

.xs-up-mt-md {
  margin-top: 24px !important; }

.xs-up-mt-md-child > * {
  margin-top: 24px !important; }

.xs-up-mt-md-child-not-last > *:not(:last-child) {
  margin-top: 24px !important; }

.xs-up-mb-md {
  margin-bottom: 24px !important; }

.xs-up-mb-md-child > * {
  margin-bottom: 24px !important; }

.xs-up-mb-md-child-not-last > *:not(:last-child) {
  margin-bottom: 24px !important; }

.xs-up-my-md {
  margin-top: 24px !important;
  margin-bottom: 24px !important; }

.xs-up-my-md-child > * {
  margin-top: 24px !important;
  margin-bottom: 24px !important; }

.xs-up-my-md-child-not-last > *:not(:last-child) {
  margin-top: 24px !important;
  margin-bottom: 24px !important; }

.xs-up-ml-md {
  margin-left: 24px !important; }

.xs-up-ml-md-child > * {
  margin-left: 24px !important; }

.xs-up-ml-md-child-not-last > *:not(:last-child) {
  margin-left: 24px !important; }

.xs-up-mr-md {
  margin-right: 24px !important; }

.xs-up-mr-md-child > * {
  margin-right: 24px !important; }

.xs-up-mr-md-child-not-last > *:not(:last-child) {
  margin-right: 24px !important; }

.xs-up-mx-md {
  margin-left: 24px !important;
  margin-right: 24px !important; }

.xs-up-mx-md-child > * {
  margin-left: 24px !important;
  margin-right: 24px !important; }

.xs-up-mx-md-child-not-last > *:not(:last-child) {
  margin-left: 24px !important;
  margin-right: 24px !important; }

.xs-up-p-md {
  padding: 24px !important; }

.xs-up-p-md-child > * {
  padding: 24px !important; }

.xs-up-p-md-child-not-last > *:not(:last-child) {
  padding: 24px !important; }

.xs-up-pt-md {
  padding-top: 24px !important; }

.xs-up-pt-md-child > * {
  padding-top: 24px !important; }

.xs-up-pt-md-child-not-last > *:not(:last-child) {
  padding-top: 24px !important; }

.xs-up-pb-md {
  padding-bottom: 24px !important; }

.xs-up-pb-md-child > * {
  padding-bottom: 24px !important; }

.xs-up-pb-md-child-not-last > *:not(:last-child) {
  padding-bottom: 24px !important; }

.xs-up-py-md {
  padding-top: 24px !important;
  padding-bottom: 24px !important; }

.xs-up-py-md-child > * {
  padding-top: 24px !important;
  padding-bottom: 24px !important; }

.xs-up-py-md-child-not-last > *:not(:last-child) {
  padding-top: 24px !important;
  padding-bottom: 24px !important; }

.xs-up-pl-md {
  padding-left: 24px !important; }

.xs-up-pl-md-child > * {
  padding-left: 24px !important; }

.xs-up-pl-md-child-not-last > *:not(:last-child) {
  padding-left: 24px !important; }

.xs-up-pr-md {
  padding-right: 24px !important; }

.xs-up-pr-md-child > * {
  padding-right: 24px !important; }

.xs-up-pr-md-child-not-last > *:not(:last-child) {
  padding-right: 24px !important; }

.xs-up-px-md {
  padding-left: 24px !important;
  padding-right: 24px !important; }

.xs-up-px-md-child > * {
  padding-left: 24px !important;
  padding-right: 24px !important; }

.xs-up-px-md-child-not-last > *:not(:last-child) {
  padding-left: 24px !important;
  padding-right: 24px !important; }

.xs-up-m-lg {
  margin: 50px !important; }

.xs-up-m-lg-child > * {
  margin: 50px !important; }

.xs-up-m-lg-child-not-last > *:not(:last-child) {
  margin: 50px !important; }

.xs-up-mt-lg {
  margin-top: 50px !important; }

.xs-up-mt-lg-child > * {
  margin-top: 50px !important; }

.xs-up-mt-lg-child-not-last > *:not(:last-child) {
  margin-top: 50px !important; }

.xs-up-mb-lg {
  margin-bottom: 50px !important; }

.xs-up-mb-lg-child > * {
  margin-bottom: 50px !important; }

.xs-up-mb-lg-child-not-last > *:not(:last-child) {
  margin-bottom: 50px !important; }

.xs-up-my-lg {
  margin-top: 50px !important;
  margin-bottom: 50px !important; }

.xs-up-my-lg-child > * {
  margin-top: 50px !important;
  margin-bottom: 50px !important; }

.xs-up-my-lg-child-not-last > *:not(:last-child) {
  margin-top: 50px !important;
  margin-bottom: 50px !important; }

.xs-up-ml-lg {
  margin-left: 50px !important; }

.xs-up-ml-lg-child > * {
  margin-left: 50px !important; }

.xs-up-ml-lg-child-not-last > *:not(:last-child) {
  margin-left: 50px !important; }

.xs-up-mr-lg {
  margin-right: 50px !important; }

.xs-up-mr-lg-child > * {
  margin-right: 50px !important; }

.xs-up-mr-lg-child-not-last > *:not(:last-child) {
  margin-right: 50px !important; }

.xs-up-mx-lg {
  margin-left: 50px !important;
  margin-right: 50px !important; }

.xs-up-mx-lg-child > * {
  margin-left: 50px !important;
  margin-right: 50px !important; }

.xs-up-mx-lg-child-not-last > *:not(:last-child) {
  margin-left: 50px !important;
  margin-right: 50px !important; }

.xs-up-p-lg {
  padding: 50px !important; }

.xs-up-p-lg-child > * {
  padding: 50px !important; }

.xs-up-p-lg-child-not-last > *:not(:last-child) {
  padding: 50px !important; }

.xs-up-pt-lg {
  padding-top: 50px !important; }

.xs-up-pt-lg-child > * {
  padding-top: 50px !important; }

.xs-up-pt-lg-child-not-last > *:not(:last-child) {
  padding-top: 50px !important; }

.xs-up-pb-lg {
  padding-bottom: 50px !important; }

.xs-up-pb-lg-child > * {
  padding-bottom: 50px !important; }

.xs-up-pb-lg-child-not-last > *:not(:last-child) {
  padding-bottom: 50px !important; }

.xs-up-py-lg {
  padding-top: 50px !important;
  padding-bottom: 50px !important; }

.xs-up-py-lg-child > * {
  padding-top: 50px !important;
  padding-bottom: 50px !important; }

.xs-up-py-lg-child-not-last > *:not(:last-child) {
  padding-top: 50px !important;
  padding-bottom: 50px !important; }

.xs-up-pl-lg {
  padding-left: 50px !important; }

.xs-up-pl-lg-child > * {
  padding-left: 50px !important; }

.xs-up-pl-lg-child-not-last > *:not(:last-child) {
  padding-left: 50px !important; }

.xs-up-pr-lg {
  padding-right: 50px !important; }

.xs-up-pr-lg-child > * {
  padding-right: 50px !important; }

.xs-up-pr-lg-child-not-last > *:not(:last-child) {
  padding-right: 50px !important; }

.xs-up-px-lg {
  padding-left: 50px !important;
  padding-right: 50px !important; }

.xs-up-px-lg-child > * {
  padding-left: 50px !important;
  padding-right: 50px !important; }

.xs-up-px-lg-child-not-last > *:not(:last-child) {
  padding-left: 50px !important;
  padding-right: 50px !important; }

.xs-up-m-xl {
  margin: 70px !important; }

.xs-up-m-xl-child > * {
  margin: 70px !important; }

.xs-up-m-xl-child-not-last > *:not(:last-child) {
  margin: 70px !important; }

.xs-up-mt-xl {
  margin-top: 70px !important; }

.xs-up-mt-xl-child > * {
  margin-top: 70px !important; }

.xs-up-mt-xl-child-not-last > *:not(:last-child) {
  margin-top: 70px !important; }

.xs-up-mb-xl {
  margin-bottom: 70px !important; }

.xs-up-mb-xl-child > * {
  margin-bottom: 70px !important; }

.xs-up-mb-xl-child-not-last > *:not(:last-child) {
  margin-bottom: 70px !important; }

.xs-up-my-xl {
  margin-top: 70px !important;
  margin-bottom: 70px !important; }

.xs-up-my-xl-child > * {
  margin-top: 70px !important;
  margin-bottom: 70px !important; }

.xs-up-my-xl-child-not-last > *:not(:last-child) {
  margin-top: 70px !important;
  margin-bottom: 70px !important; }

.xs-up-ml-xl {
  margin-left: 70px !important; }

.xs-up-ml-xl-child > * {
  margin-left: 70px !important; }

.xs-up-ml-xl-child-not-last > *:not(:last-child) {
  margin-left: 70px !important; }

.xs-up-mr-xl {
  margin-right: 70px !important; }

.xs-up-mr-xl-child > * {
  margin-right: 70px !important; }

.xs-up-mr-xl-child-not-last > *:not(:last-child) {
  margin-right: 70px !important; }

.xs-up-mx-xl {
  margin-left: 70px !important;
  margin-right: 70px !important; }

.xs-up-mx-xl-child > * {
  margin-left: 70px !important;
  margin-right: 70px !important; }

.xs-up-mx-xl-child-not-last > *:not(:last-child) {
  margin-left: 70px !important;
  margin-right: 70px !important; }

.xs-up-p-xl {
  padding: 70px !important; }

.xs-up-p-xl-child > * {
  padding: 70px !important; }

.xs-up-p-xl-child-not-last > *:not(:last-child) {
  padding: 70px !important; }

.xs-up-pt-xl {
  padding-top: 70px !important; }

.xs-up-pt-xl-child > * {
  padding-top: 70px !important; }

.xs-up-pt-xl-child-not-last > *:not(:last-child) {
  padding-top: 70px !important; }

.xs-up-pb-xl {
  padding-bottom: 70px !important; }

.xs-up-pb-xl-child > * {
  padding-bottom: 70px !important; }

.xs-up-pb-xl-child-not-last > *:not(:last-child) {
  padding-bottom: 70px !important; }

.xs-up-py-xl {
  padding-top: 70px !important;
  padding-bottom: 70px !important; }

.xs-up-py-xl-child > * {
  padding-top: 70px !important;
  padding-bottom: 70px !important; }

.xs-up-py-xl-child-not-last > *:not(:last-child) {
  padding-top: 70px !important;
  padding-bottom: 70px !important; }

.xs-up-pl-xl {
  padding-left: 70px !important; }

.xs-up-pl-xl-child > * {
  padding-left: 70px !important; }

.xs-up-pl-xl-child-not-last > *:not(:last-child) {
  padding-left: 70px !important; }

.xs-up-pr-xl {
  padding-right: 70px !important; }

.xs-up-pr-xl-child > * {
  padding-right: 70px !important; }

.xs-up-pr-xl-child-not-last > *:not(:last-child) {
  padding-right: 70px !important; }

.xs-up-px-xl {
  padding-left: 70px !important;
  padding-right: 70px !important; }

.xs-up-px-xl-child > * {
  padding-left: 70px !important;
  padding-right: 70px !important; }

.xs-up-px-xl-child-not-last > *:not(:last-child) {
  padding-left: 70px !important;
  padding-right: 70px !important; }

.xs-up-m-xxl {
  margin: 140px !important; }

.xs-up-m-xxl-child > * {
  margin: 140px !important; }

.xs-up-m-xxl-child-not-last > *:not(:last-child) {
  margin: 140px !important; }

.xs-up-mt-xxl {
  margin-top: 140px !important; }

.xs-up-mt-xxl-child > * {
  margin-top: 140px !important; }

.xs-up-mt-xxl-child-not-last > *:not(:last-child) {
  margin-top: 140px !important; }

.xs-up-mb-xxl {
  margin-bottom: 140px !important; }

.xs-up-mb-xxl-child > * {
  margin-bottom: 140px !important; }

.xs-up-mb-xxl-child-not-last > *:not(:last-child) {
  margin-bottom: 140px !important; }

.xs-up-my-xxl {
  margin-top: 140px !important;
  margin-bottom: 140px !important; }

.xs-up-my-xxl-child > * {
  margin-top: 140px !important;
  margin-bottom: 140px !important; }

.xs-up-my-xxl-child-not-last > *:not(:last-child) {
  margin-top: 140px !important;
  margin-bottom: 140px !important; }

.xs-up-ml-xxl {
  margin-left: 140px !important; }

.xs-up-ml-xxl-child > * {
  margin-left: 140px !important; }

.xs-up-ml-xxl-child-not-last > *:not(:last-child) {
  margin-left: 140px !important; }

.xs-up-mr-xxl {
  margin-right: 140px !important; }

.xs-up-mr-xxl-child > * {
  margin-right: 140px !important; }

.xs-up-mr-xxl-child-not-last > *:not(:last-child) {
  margin-right: 140px !important; }

.xs-up-mx-xxl {
  margin-left: 140px !important;
  margin-right: 140px !important; }

.xs-up-mx-xxl-child > * {
  margin-left: 140px !important;
  margin-right: 140px !important; }

.xs-up-mx-xxl-child-not-last > *:not(:last-child) {
  margin-left: 140px !important;
  margin-right: 140px !important; }

.xs-up-p-xxl {
  padding: 140px !important; }

.xs-up-p-xxl-child > * {
  padding: 140px !important; }

.xs-up-p-xxl-child-not-last > *:not(:last-child) {
  padding: 140px !important; }

.xs-up-pt-xxl {
  padding-top: 140px !important; }

.xs-up-pt-xxl-child > * {
  padding-top: 140px !important; }

.xs-up-pt-xxl-child-not-last > *:not(:last-child) {
  padding-top: 140px !important; }

.xs-up-pb-xxl {
  padding-bottom: 140px !important; }

.xs-up-pb-xxl-child > * {
  padding-bottom: 140px !important; }

.xs-up-pb-xxl-child-not-last > *:not(:last-child) {
  padding-bottom: 140px !important; }

.xs-up-py-xxl {
  padding-top: 140px !important;
  padding-bottom: 140px !important; }

.xs-up-py-xxl-child > * {
  padding-top: 140px !important;
  padding-bottom: 140px !important; }

.xs-up-py-xxl-child-not-last > *:not(:last-child) {
  padding-top: 140px !important;
  padding-bottom: 140px !important; }

.xs-up-pl-xxl {
  padding-left: 140px !important; }

.xs-up-pl-xxl-child > * {
  padding-left: 140px !important; }

.xs-up-pl-xxl-child-not-last > *:not(:last-child) {
  padding-left: 140px !important; }

.xs-up-pr-xxl {
  padding-right: 140px !important; }

.xs-up-pr-xxl-child > * {
  padding-right: 140px !important; }

.xs-up-pr-xxl-child-not-last > *:not(:last-child) {
  padding-right: 140px !important; }

.xs-up-px-xxl {
  padding-left: 140px !important;
  padding-right: 140px !important; }

.xs-up-px-xxl-child > * {
  padding-left: 140px !important;
  padding-right: 140px !important; }

.xs-up-px-xxl-child-not-last > *:not(:last-child) {
  padding-left: 140px !important;
  padding-right: 140px !important; }

@media (min-width: 576px) {
  .sm-up-m-0 {
    margin: 0 !important; }
  .sm-up-m-0-child > * {
    margin: 0 !important; }
  .sm-up-m-0-child-not-last > *:not(:last-child) {
    margin: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-mt-0 {
    margin-top: 0 !important; }
  .sm-up-mt-0-child > * {
    margin-top: 0 !important; }
  .sm-up-mt-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-mb-0 {
    margin-bottom: 0 !important; }
  .sm-up-mb-0-child > * {
    margin-bottom: 0 !important; }
  .sm-up-mb-0-child-not-last > *:not(:last-child) {
    margin-bottom: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .sm-up-my-0-child > * {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .sm-up-my-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important;
    margin-bottom: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-ml-0 {
    margin-left: 0 !important; }
  .sm-up-ml-0-child > * {
    margin-left: 0 !important; }
  .sm-up-ml-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-mr-0 {
    margin-right: 0 !important; }
  .sm-up-mr-0-child > * {
    margin-right: 0 !important; }
  .sm-up-mr-0-child-not-last > *:not(:last-child) {
    margin-right: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .sm-up-mx-0-child > * {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .sm-up-mx-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important;
    margin-right: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-p-0 {
    padding: 0 !important; }
  .sm-up-p-0-child > * {
    padding: 0 !important; }
  .sm-up-p-0-child-not-last > *:not(:last-child) {
    padding: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-pt-0 {
    padding-top: 0 !important; }
  .sm-up-pt-0-child > * {
    padding-top: 0 !important; }
  .sm-up-pt-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-pb-0 {
    padding-bottom: 0 !important; }
  .sm-up-pb-0-child > * {
    padding-bottom: 0 !important; }
  .sm-up-pb-0-child-not-last > *:not(:last-child) {
    padding-bottom: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .sm-up-py-0-child > * {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .sm-up-py-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important;
    padding-bottom: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-pl-0 {
    padding-left: 0 !important; }
  .sm-up-pl-0-child > * {
    padding-left: 0 !important; }
  .sm-up-pl-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-pr-0 {
    padding-right: 0 !important; }
  .sm-up-pr-0-child > * {
    padding-right: 0 !important; }
  .sm-up-pr-0-child-not-last > *:not(:last-child) {
    padding-right: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .sm-up-px-0-child > * {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .sm-up-px-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important;
    padding-right: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-m-xs {
    margin: 5px !important; }
  .sm-up-m-xs-child > * {
    margin: 5px !important; }
  .sm-up-m-xs-child-not-last > *:not(:last-child) {
    margin: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-mt-xs {
    margin-top: 5px !important; }
  .sm-up-mt-xs-child > * {
    margin-top: 5px !important; }
  .sm-up-mt-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-mb-xs {
    margin-bottom: 5px !important; }
  .sm-up-mb-xs-child > * {
    margin-bottom: 5px !important; }
  .sm-up-mb-xs-child-not-last > *:not(:last-child) {
    margin-bottom: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-my-xs {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .sm-up-my-xs-child > * {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .sm-up-my-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important;
    margin-bottom: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-ml-xs {
    margin-left: 5px !important; }
  .sm-up-ml-xs-child > * {
    margin-left: 5px !important; }
  .sm-up-ml-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-mr-xs {
    margin-right: 5px !important; }
  .sm-up-mr-xs-child > * {
    margin-right: 5px !important; }
  .sm-up-mr-xs-child-not-last > *:not(:last-child) {
    margin-right: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-mx-xs {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .sm-up-mx-xs-child > * {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .sm-up-mx-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important;
    margin-right: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-p-xs {
    padding: 5px !important; }
  .sm-up-p-xs-child > * {
    padding: 5px !important; }
  .sm-up-p-xs-child-not-last > *:not(:last-child) {
    padding: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-pt-xs {
    padding-top: 5px !important; }
  .sm-up-pt-xs-child > * {
    padding-top: 5px !important; }
  .sm-up-pt-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-pb-xs {
    padding-bottom: 5px !important; }
  .sm-up-pb-xs-child > * {
    padding-bottom: 5px !important; }
  .sm-up-pb-xs-child-not-last > *:not(:last-child) {
    padding-bottom: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-py-xs {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .sm-up-py-xs-child > * {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .sm-up-py-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important;
    padding-bottom: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-pl-xs {
    padding-left: 5px !important; }
  .sm-up-pl-xs-child > * {
    padding-left: 5px !important; }
  .sm-up-pl-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-pr-xs {
    padding-right: 5px !important; }
  .sm-up-pr-xs-child > * {
    padding-right: 5px !important; }
  .sm-up-pr-xs-child-not-last > *:not(:last-child) {
    padding-right: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-px-xs {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .sm-up-px-xs-child > * {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .sm-up-px-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important;
    padding-right: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-m-sm {
    margin: 15px !important; }
  .sm-up-m-sm-child > * {
    margin: 15px !important; }
  .sm-up-m-sm-child-not-last > *:not(:last-child) {
    margin: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-mt-sm {
    margin-top: 15px !important; }
  .sm-up-mt-sm-child > * {
    margin-top: 15px !important; }
  .sm-up-mt-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-mb-sm {
    margin-bottom: 15px !important; }
  .sm-up-mb-sm-child > * {
    margin-bottom: 15px !important; }
  .sm-up-mb-sm-child-not-last > *:not(:last-child) {
    margin-bottom: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-my-sm {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .sm-up-my-sm-child > * {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .sm-up-my-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important;
    margin-bottom: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-ml-sm {
    margin-left: 15px !important; }
  .sm-up-ml-sm-child > * {
    margin-left: 15px !important; }
  .sm-up-ml-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-mr-sm {
    margin-right: 15px !important; }
  .sm-up-mr-sm-child > * {
    margin-right: 15px !important; }
  .sm-up-mr-sm-child-not-last > *:not(:last-child) {
    margin-right: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-mx-sm {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .sm-up-mx-sm-child > * {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .sm-up-mx-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important;
    margin-right: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-p-sm {
    padding: 15px !important; }
  .sm-up-p-sm-child > * {
    padding: 15px !important; }
  .sm-up-p-sm-child-not-last > *:not(:last-child) {
    padding: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-pt-sm {
    padding-top: 15px !important; }
  .sm-up-pt-sm-child > * {
    padding-top: 15px !important; }
  .sm-up-pt-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-pb-sm {
    padding-bottom: 15px !important; }
  .sm-up-pb-sm-child > * {
    padding-bottom: 15px !important; }
  .sm-up-pb-sm-child-not-last > *:not(:last-child) {
    padding-bottom: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-py-sm {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .sm-up-py-sm-child > * {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .sm-up-py-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important;
    padding-bottom: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-pl-sm {
    padding-left: 15px !important; }
  .sm-up-pl-sm-child > * {
    padding-left: 15px !important; }
  .sm-up-pl-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-pr-sm {
    padding-right: 15px !important; }
  .sm-up-pr-sm-child > * {
    padding-right: 15px !important; }
  .sm-up-pr-sm-child-not-last > *:not(:last-child) {
    padding-right: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-px-sm {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .sm-up-px-sm-child > * {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .sm-up-px-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important;
    padding-right: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-m-md {
    margin: 24px !important; }
  .sm-up-m-md-child > * {
    margin: 24px !important; }
  .sm-up-m-md-child-not-last > *:not(:last-child) {
    margin: 24px !important; } }

@media (min-width: 576px) {
  .sm-up-mt-md {
    margin-top: 24px !important; }
  .sm-up-mt-md-child > * {
    margin-top: 24px !important; }
  .sm-up-mt-md-child-not-last > *:not(:last-child) {
    margin-top: 24px !important; } }

@media (min-width: 576px) {
  .sm-up-mb-md {
    margin-bottom: 24px !important; }
  .sm-up-mb-md-child > * {
    margin-bottom: 24px !important; }
  .sm-up-mb-md-child-not-last > *:not(:last-child) {
    margin-bottom: 24px !important; } }

@media (min-width: 576px) {
  .sm-up-my-md {
    margin-top: 24px !important;
    margin-bottom: 24px !important; }
  .sm-up-my-md-child > * {
    margin-top: 24px !important;
    margin-bottom: 24px !important; }
  .sm-up-my-md-child-not-last > *:not(:last-child) {
    margin-top: 24px !important;
    margin-bottom: 24px !important; } }

@media (min-width: 576px) {
  .sm-up-ml-md {
    margin-left: 24px !important; }
  .sm-up-ml-md-child > * {
    margin-left: 24px !important; }
  .sm-up-ml-md-child-not-last > *:not(:last-child) {
    margin-left: 24px !important; } }

@media (min-width: 576px) {
  .sm-up-mr-md {
    margin-right: 24px !important; }
  .sm-up-mr-md-child > * {
    margin-right: 24px !important; }
  .sm-up-mr-md-child-not-last > *:not(:last-child) {
    margin-right: 24px !important; } }

@media (min-width: 576px) {
  .sm-up-mx-md {
    margin-left: 24px !important;
    margin-right: 24px !important; }
  .sm-up-mx-md-child > * {
    margin-left: 24px !important;
    margin-right: 24px !important; }
  .sm-up-mx-md-child-not-last > *:not(:last-child) {
    margin-left: 24px !important;
    margin-right: 24px !important; } }

@media (min-width: 576px) {
  .sm-up-p-md {
    padding: 24px !important; }
  .sm-up-p-md-child > * {
    padding: 24px !important; }
  .sm-up-p-md-child-not-last > *:not(:last-child) {
    padding: 24px !important; } }

@media (min-width: 576px) {
  .sm-up-pt-md {
    padding-top: 24px !important; }
  .sm-up-pt-md-child > * {
    padding-top: 24px !important; }
  .sm-up-pt-md-child-not-last > *:not(:last-child) {
    padding-top: 24px !important; } }

@media (min-width: 576px) {
  .sm-up-pb-md {
    padding-bottom: 24px !important; }
  .sm-up-pb-md-child > * {
    padding-bottom: 24px !important; }
  .sm-up-pb-md-child-not-last > *:not(:last-child) {
    padding-bottom: 24px !important; } }

@media (min-width: 576px) {
  .sm-up-py-md {
    padding-top: 24px !important;
    padding-bottom: 24px !important; }
  .sm-up-py-md-child > * {
    padding-top: 24px !important;
    padding-bottom: 24px !important; }
  .sm-up-py-md-child-not-last > *:not(:last-child) {
    padding-top: 24px !important;
    padding-bottom: 24px !important; } }

@media (min-width: 576px) {
  .sm-up-pl-md {
    padding-left: 24px !important; }
  .sm-up-pl-md-child > * {
    padding-left: 24px !important; }
  .sm-up-pl-md-child-not-last > *:not(:last-child) {
    padding-left: 24px !important; } }

@media (min-width: 576px) {
  .sm-up-pr-md {
    padding-right: 24px !important; }
  .sm-up-pr-md-child > * {
    padding-right: 24px !important; }
  .sm-up-pr-md-child-not-last > *:not(:last-child) {
    padding-right: 24px !important; } }

@media (min-width: 576px) {
  .sm-up-px-md {
    padding-left: 24px !important;
    padding-right: 24px !important; }
  .sm-up-px-md-child > * {
    padding-left: 24px !important;
    padding-right: 24px !important; }
  .sm-up-px-md-child-not-last > *:not(:last-child) {
    padding-left: 24px !important;
    padding-right: 24px !important; } }

@media (min-width: 576px) {
  .sm-up-m-lg {
    margin: 50px !important; }
  .sm-up-m-lg-child > * {
    margin: 50px !important; }
  .sm-up-m-lg-child-not-last > *:not(:last-child) {
    margin: 50px !important; } }

@media (min-width: 576px) {
  .sm-up-mt-lg {
    margin-top: 50px !important; }
  .sm-up-mt-lg-child > * {
    margin-top: 50px !important; }
  .sm-up-mt-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important; } }

@media (min-width: 576px) {
  .sm-up-mb-lg {
    margin-bottom: 50px !important; }
  .sm-up-mb-lg-child > * {
    margin-bottom: 50px !important; }
  .sm-up-mb-lg-child-not-last > *:not(:last-child) {
    margin-bottom: 50px !important; } }

@media (min-width: 576px) {
  .sm-up-my-lg {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .sm-up-my-lg-child > * {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .sm-up-my-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important;
    margin-bottom: 50px !important; } }

@media (min-width: 576px) {
  .sm-up-ml-lg {
    margin-left: 50px !important; }
  .sm-up-ml-lg-child > * {
    margin-left: 50px !important; }
  .sm-up-ml-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important; } }

@media (min-width: 576px) {
  .sm-up-mr-lg {
    margin-right: 50px !important; }
  .sm-up-mr-lg-child > * {
    margin-right: 50px !important; }
  .sm-up-mr-lg-child-not-last > *:not(:last-child) {
    margin-right: 50px !important; } }

@media (min-width: 576px) {
  .sm-up-mx-lg {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .sm-up-mx-lg-child > * {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .sm-up-mx-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important;
    margin-right: 50px !important; } }

@media (min-width: 576px) {
  .sm-up-p-lg {
    padding: 50px !important; }
  .sm-up-p-lg-child > * {
    padding: 50px !important; }
  .sm-up-p-lg-child-not-last > *:not(:last-child) {
    padding: 50px !important; } }

@media (min-width: 576px) {
  .sm-up-pt-lg {
    padding-top: 50px !important; }
  .sm-up-pt-lg-child > * {
    padding-top: 50px !important; }
  .sm-up-pt-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important; } }

@media (min-width: 576px) {
  .sm-up-pb-lg {
    padding-bottom: 50px !important; }
  .sm-up-pb-lg-child > * {
    padding-bottom: 50px !important; }
  .sm-up-pb-lg-child-not-last > *:not(:last-child) {
    padding-bottom: 50px !important; } }

@media (min-width: 576px) {
  .sm-up-py-lg {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .sm-up-py-lg-child > * {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .sm-up-py-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important;
    padding-bottom: 50px !important; } }

@media (min-width: 576px) {
  .sm-up-pl-lg {
    padding-left: 50px !important; }
  .sm-up-pl-lg-child > * {
    padding-left: 50px !important; }
  .sm-up-pl-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important; } }

@media (min-width: 576px) {
  .sm-up-pr-lg {
    padding-right: 50px !important; }
  .sm-up-pr-lg-child > * {
    padding-right: 50px !important; }
  .sm-up-pr-lg-child-not-last > *:not(:last-child) {
    padding-right: 50px !important; } }

@media (min-width: 576px) {
  .sm-up-px-lg {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .sm-up-px-lg-child > * {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .sm-up-px-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important;
    padding-right: 50px !important; } }

@media (min-width: 576px) {
  .sm-up-m-xl {
    margin: 70px !important; }
  .sm-up-m-xl-child > * {
    margin: 70px !important; }
  .sm-up-m-xl-child-not-last > *:not(:last-child) {
    margin: 70px !important; } }

@media (min-width: 576px) {
  .sm-up-mt-xl {
    margin-top: 70px !important; }
  .sm-up-mt-xl-child > * {
    margin-top: 70px !important; }
  .sm-up-mt-xl-child-not-last > *:not(:last-child) {
    margin-top: 70px !important; } }

@media (min-width: 576px) {
  .sm-up-mb-xl {
    margin-bottom: 70px !important; }
  .sm-up-mb-xl-child > * {
    margin-bottom: 70px !important; }
  .sm-up-mb-xl-child-not-last > *:not(:last-child) {
    margin-bottom: 70px !important; } }

@media (min-width: 576px) {
  .sm-up-my-xl {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .sm-up-my-xl-child > * {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .sm-up-my-xl-child-not-last > *:not(:last-child) {
    margin-top: 70px !important;
    margin-bottom: 70px !important; } }

@media (min-width: 576px) {
  .sm-up-ml-xl {
    margin-left: 70px !important; }
  .sm-up-ml-xl-child > * {
    margin-left: 70px !important; }
  .sm-up-ml-xl-child-not-last > *:not(:last-child) {
    margin-left: 70px !important; } }

@media (min-width: 576px) {
  .sm-up-mr-xl {
    margin-right: 70px !important; }
  .sm-up-mr-xl-child > * {
    margin-right: 70px !important; }
  .sm-up-mr-xl-child-not-last > *:not(:last-child) {
    margin-right: 70px !important; } }

@media (min-width: 576px) {
  .sm-up-mx-xl {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .sm-up-mx-xl-child > * {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .sm-up-mx-xl-child-not-last > *:not(:last-child) {
    margin-left: 70px !important;
    margin-right: 70px !important; } }

@media (min-width: 576px) {
  .sm-up-p-xl {
    padding: 70px !important; }
  .sm-up-p-xl-child > * {
    padding: 70px !important; }
  .sm-up-p-xl-child-not-last > *:not(:last-child) {
    padding: 70px !important; } }

@media (min-width: 576px) {
  .sm-up-pt-xl {
    padding-top: 70px !important; }
  .sm-up-pt-xl-child > * {
    padding-top: 70px !important; }
  .sm-up-pt-xl-child-not-last > *:not(:last-child) {
    padding-top: 70px !important; } }

@media (min-width: 576px) {
  .sm-up-pb-xl {
    padding-bottom: 70px !important; }
  .sm-up-pb-xl-child > * {
    padding-bottom: 70px !important; }
  .sm-up-pb-xl-child-not-last > *:not(:last-child) {
    padding-bottom: 70px !important; } }

@media (min-width: 576px) {
  .sm-up-py-xl {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .sm-up-py-xl-child > * {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .sm-up-py-xl-child-not-last > *:not(:last-child) {
    padding-top: 70px !important;
    padding-bottom: 70px !important; } }

@media (min-width: 576px) {
  .sm-up-pl-xl {
    padding-left: 70px !important; }
  .sm-up-pl-xl-child > * {
    padding-left: 70px !important; }
  .sm-up-pl-xl-child-not-last > *:not(:last-child) {
    padding-left: 70px !important; } }

@media (min-width: 576px) {
  .sm-up-pr-xl {
    padding-right: 70px !important; }
  .sm-up-pr-xl-child > * {
    padding-right: 70px !important; }
  .sm-up-pr-xl-child-not-last > *:not(:last-child) {
    padding-right: 70px !important; } }

@media (min-width: 576px) {
  .sm-up-px-xl {
    padding-left: 70px !important;
    padding-right: 70px !important; }
  .sm-up-px-xl-child > * {
    padding-left: 70px !important;
    padding-right: 70px !important; }
  .sm-up-px-xl-child-not-last > *:not(:last-child) {
    padding-left: 70px !important;
    padding-right: 70px !important; } }

@media (min-width: 576px) {
  .sm-up-m-xxl {
    margin: 140px !important; }
  .sm-up-m-xxl-child > * {
    margin: 140px !important; }
  .sm-up-m-xxl-child-not-last > *:not(:last-child) {
    margin: 140px !important; } }

@media (min-width: 576px) {
  .sm-up-mt-xxl {
    margin-top: 140px !important; }
  .sm-up-mt-xxl-child > * {
    margin-top: 140px !important; }
  .sm-up-mt-xxl-child-not-last > *:not(:last-child) {
    margin-top: 140px !important; } }

@media (min-width: 576px) {
  .sm-up-mb-xxl {
    margin-bottom: 140px !important; }
  .sm-up-mb-xxl-child > * {
    margin-bottom: 140px !important; }
  .sm-up-mb-xxl-child-not-last > *:not(:last-child) {
    margin-bottom: 140px !important; } }

@media (min-width: 576px) {
  .sm-up-my-xxl {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .sm-up-my-xxl-child > * {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .sm-up-my-xxl-child-not-last > *:not(:last-child) {
    margin-top: 140px !important;
    margin-bottom: 140px !important; } }

@media (min-width: 576px) {
  .sm-up-ml-xxl {
    margin-left: 140px !important; }
  .sm-up-ml-xxl-child > * {
    margin-left: 140px !important; }
  .sm-up-ml-xxl-child-not-last > *:not(:last-child) {
    margin-left: 140px !important; } }

@media (min-width: 576px) {
  .sm-up-mr-xxl {
    margin-right: 140px !important; }
  .sm-up-mr-xxl-child > * {
    margin-right: 140px !important; }
  .sm-up-mr-xxl-child-not-last > *:not(:last-child) {
    margin-right: 140px !important; } }

@media (min-width: 576px) {
  .sm-up-mx-xxl {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .sm-up-mx-xxl-child > * {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .sm-up-mx-xxl-child-not-last > *:not(:last-child) {
    margin-left: 140px !important;
    margin-right: 140px !important; } }

@media (min-width: 576px) {
  .sm-up-p-xxl {
    padding: 140px !important; }
  .sm-up-p-xxl-child > * {
    padding: 140px !important; }
  .sm-up-p-xxl-child-not-last > *:not(:last-child) {
    padding: 140px !important; } }

@media (min-width: 576px) {
  .sm-up-pt-xxl {
    padding-top: 140px !important; }
  .sm-up-pt-xxl-child > * {
    padding-top: 140px !important; }
  .sm-up-pt-xxl-child-not-last > *:not(:last-child) {
    padding-top: 140px !important; } }

@media (min-width: 576px) {
  .sm-up-pb-xxl {
    padding-bottom: 140px !important; }
  .sm-up-pb-xxl-child > * {
    padding-bottom: 140px !important; }
  .sm-up-pb-xxl-child-not-last > *:not(:last-child) {
    padding-bottom: 140px !important; } }

@media (min-width: 576px) {
  .sm-up-py-xxl {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .sm-up-py-xxl-child > * {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .sm-up-py-xxl-child-not-last > *:not(:last-child) {
    padding-top: 140px !important;
    padding-bottom: 140px !important; } }

@media (min-width: 576px) {
  .sm-up-pl-xxl {
    padding-left: 140px !important; }
  .sm-up-pl-xxl-child > * {
    padding-left: 140px !important; }
  .sm-up-pl-xxl-child-not-last > *:not(:last-child) {
    padding-left: 140px !important; } }

@media (min-width: 576px) {
  .sm-up-pr-xxl {
    padding-right: 140px !important; }
  .sm-up-pr-xxl-child > * {
    padding-right: 140px !important; }
  .sm-up-pr-xxl-child-not-last > *:not(:last-child) {
    padding-right: 140px !important; } }

@media (min-width: 576px) {
  .sm-up-px-xxl {
    padding-left: 140px !important;
    padding-right: 140px !important; }
  .sm-up-px-xxl-child > * {
    padding-left: 140px !important;
    padding-right: 140px !important; }
  .sm-up-px-xxl-child-not-last > *:not(:last-child) {
    padding-left: 140px !important;
    padding-right: 140px !important; } }

@media (min-width: 768px) {
  .md-up-m-0 {
    margin: 0 !important; }
  .md-up-m-0-child > * {
    margin: 0 !important; }
  .md-up-m-0-child-not-last > *:not(:last-child) {
    margin: 0 !important; } }

@media (min-width: 768px) {
  .md-up-mt-0 {
    margin-top: 0 !important; }
  .md-up-mt-0-child > * {
    margin-top: 0 !important; }
  .md-up-mt-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important; } }

@media (min-width: 768px) {
  .md-up-mb-0 {
    margin-bottom: 0 !important; }
  .md-up-mb-0-child > * {
    margin-bottom: 0 !important; }
  .md-up-mb-0-child-not-last > *:not(:last-child) {
    margin-bottom: 0 !important; } }

@media (min-width: 768px) {
  .md-up-my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .md-up-my-0-child > * {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .md-up-my-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important;
    margin-bottom: 0 !important; } }

@media (min-width: 768px) {
  .md-up-ml-0 {
    margin-left: 0 !important; }
  .md-up-ml-0-child > * {
    margin-left: 0 !important; }
  .md-up-ml-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important; } }

@media (min-width: 768px) {
  .md-up-mr-0 {
    margin-right: 0 !important; }
  .md-up-mr-0-child > * {
    margin-right: 0 !important; }
  .md-up-mr-0-child-not-last > *:not(:last-child) {
    margin-right: 0 !important; } }

@media (min-width: 768px) {
  .md-up-mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .md-up-mx-0-child > * {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .md-up-mx-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important;
    margin-right: 0 !important; } }

@media (min-width: 768px) {
  .md-up-p-0 {
    padding: 0 !important; }
  .md-up-p-0-child > * {
    padding: 0 !important; }
  .md-up-p-0-child-not-last > *:not(:last-child) {
    padding: 0 !important; } }

@media (min-width: 768px) {
  .md-up-pt-0 {
    padding-top: 0 !important; }
  .md-up-pt-0-child > * {
    padding-top: 0 !important; }
  .md-up-pt-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important; } }

@media (min-width: 768px) {
  .md-up-pb-0 {
    padding-bottom: 0 !important; }
  .md-up-pb-0-child > * {
    padding-bottom: 0 !important; }
  .md-up-pb-0-child-not-last > *:not(:last-child) {
    padding-bottom: 0 !important; } }

@media (min-width: 768px) {
  .md-up-py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .md-up-py-0-child > * {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .md-up-py-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important;
    padding-bottom: 0 !important; } }

@media (min-width: 768px) {
  .md-up-pl-0 {
    padding-left: 0 !important; }
  .md-up-pl-0-child > * {
    padding-left: 0 !important; }
  .md-up-pl-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important; } }

@media (min-width: 768px) {
  .md-up-pr-0 {
    padding-right: 0 !important; }
  .md-up-pr-0-child > * {
    padding-right: 0 !important; }
  .md-up-pr-0-child-not-last > *:not(:last-child) {
    padding-right: 0 !important; } }

@media (min-width: 768px) {
  .md-up-px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .md-up-px-0-child > * {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .md-up-px-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important;
    padding-right: 0 !important; } }

@media (min-width: 768px) {
  .md-up-m-xs {
    margin: 5px !important; }
  .md-up-m-xs-child > * {
    margin: 5px !important; }
  .md-up-m-xs-child-not-last > *:not(:last-child) {
    margin: 5px !important; } }

@media (min-width: 768px) {
  .md-up-mt-xs {
    margin-top: 5px !important; }
  .md-up-mt-xs-child > * {
    margin-top: 5px !important; }
  .md-up-mt-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important; } }

@media (min-width: 768px) {
  .md-up-mb-xs {
    margin-bottom: 5px !important; }
  .md-up-mb-xs-child > * {
    margin-bottom: 5px !important; }
  .md-up-mb-xs-child-not-last > *:not(:last-child) {
    margin-bottom: 5px !important; } }

@media (min-width: 768px) {
  .md-up-my-xs {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .md-up-my-xs-child > * {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .md-up-my-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important;
    margin-bottom: 5px !important; } }

@media (min-width: 768px) {
  .md-up-ml-xs {
    margin-left: 5px !important; }
  .md-up-ml-xs-child > * {
    margin-left: 5px !important; }
  .md-up-ml-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important; } }

@media (min-width: 768px) {
  .md-up-mr-xs {
    margin-right: 5px !important; }
  .md-up-mr-xs-child > * {
    margin-right: 5px !important; }
  .md-up-mr-xs-child-not-last > *:not(:last-child) {
    margin-right: 5px !important; } }

@media (min-width: 768px) {
  .md-up-mx-xs {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .md-up-mx-xs-child > * {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .md-up-mx-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important;
    margin-right: 5px !important; } }

@media (min-width: 768px) {
  .md-up-p-xs {
    padding: 5px !important; }
  .md-up-p-xs-child > * {
    padding: 5px !important; }
  .md-up-p-xs-child-not-last > *:not(:last-child) {
    padding: 5px !important; } }

@media (min-width: 768px) {
  .md-up-pt-xs {
    padding-top: 5px !important; }
  .md-up-pt-xs-child > * {
    padding-top: 5px !important; }
  .md-up-pt-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important; } }

@media (min-width: 768px) {
  .md-up-pb-xs {
    padding-bottom: 5px !important; }
  .md-up-pb-xs-child > * {
    padding-bottom: 5px !important; }
  .md-up-pb-xs-child-not-last > *:not(:last-child) {
    padding-bottom: 5px !important; } }

@media (min-width: 768px) {
  .md-up-py-xs {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .md-up-py-xs-child > * {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .md-up-py-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important;
    padding-bottom: 5px !important; } }

@media (min-width: 768px) {
  .md-up-pl-xs {
    padding-left: 5px !important; }
  .md-up-pl-xs-child > * {
    padding-left: 5px !important; }
  .md-up-pl-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important; } }

@media (min-width: 768px) {
  .md-up-pr-xs {
    padding-right: 5px !important; }
  .md-up-pr-xs-child > * {
    padding-right: 5px !important; }
  .md-up-pr-xs-child-not-last > *:not(:last-child) {
    padding-right: 5px !important; } }

@media (min-width: 768px) {
  .md-up-px-xs {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .md-up-px-xs-child > * {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .md-up-px-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important;
    padding-right: 5px !important; } }

@media (min-width: 768px) {
  .md-up-m-sm {
    margin: 15px !important; }
  .md-up-m-sm-child > * {
    margin: 15px !important; }
  .md-up-m-sm-child-not-last > *:not(:last-child) {
    margin: 15px !important; } }

@media (min-width: 768px) {
  .md-up-mt-sm {
    margin-top: 15px !important; }
  .md-up-mt-sm-child > * {
    margin-top: 15px !important; }
  .md-up-mt-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important; } }

@media (min-width: 768px) {
  .md-up-mb-sm {
    margin-bottom: 15px !important; }
  .md-up-mb-sm-child > * {
    margin-bottom: 15px !important; }
  .md-up-mb-sm-child-not-last > *:not(:last-child) {
    margin-bottom: 15px !important; } }

@media (min-width: 768px) {
  .md-up-my-sm {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .md-up-my-sm-child > * {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .md-up-my-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important;
    margin-bottom: 15px !important; } }

@media (min-width: 768px) {
  .md-up-ml-sm {
    margin-left: 15px !important; }
  .md-up-ml-sm-child > * {
    margin-left: 15px !important; }
  .md-up-ml-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important; } }

@media (min-width: 768px) {
  .md-up-mr-sm {
    margin-right: 15px !important; }
  .md-up-mr-sm-child > * {
    margin-right: 15px !important; }
  .md-up-mr-sm-child-not-last > *:not(:last-child) {
    margin-right: 15px !important; } }

@media (min-width: 768px) {
  .md-up-mx-sm {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .md-up-mx-sm-child > * {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .md-up-mx-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important;
    margin-right: 15px !important; } }

@media (min-width: 768px) {
  .md-up-p-sm {
    padding: 15px !important; }
  .md-up-p-sm-child > * {
    padding: 15px !important; }
  .md-up-p-sm-child-not-last > *:not(:last-child) {
    padding: 15px !important; } }

@media (min-width: 768px) {
  .md-up-pt-sm {
    padding-top: 15px !important; }
  .md-up-pt-sm-child > * {
    padding-top: 15px !important; }
  .md-up-pt-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important; } }

@media (min-width: 768px) {
  .md-up-pb-sm {
    padding-bottom: 15px !important; }
  .md-up-pb-sm-child > * {
    padding-bottom: 15px !important; }
  .md-up-pb-sm-child-not-last > *:not(:last-child) {
    padding-bottom: 15px !important; } }

@media (min-width: 768px) {
  .md-up-py-sm {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .md-up-py-sm-child > * {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .md-up-py-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important;
    padding-bottom: 15px !important; } }

@media (min-width: 768px) {
  .md-up-pl-sm {
    padding-left: 15px !important; }
  .md-up-pl-sm-child > * {
    padding-left: 15px !important; }
  .md-up-pl-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important; } }

@media (min-width: 768px) {
  .md-up-pr-sm {
    padding-right: 15px !important; }
  .md-up-pr-sm-child > * {
    padding-right: 15px !important; }
  .md-up-pr-sm-child-not-last > *:not(:last-child) {
    padding-right: 15px !important; } }

@media (min-width: 768px) {
  .md-up-px-sm {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .md-up-px-sm-child > * {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .md-up-px-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important;
    padding-right: 15px !important; } }

@media (min-width: 768px) {
  .md-up-m-md {
    margin: 24px !important; }
  .md-up-m-md-child > * {
    margin: 24px !important; }
  .md-up-m-md-child-not-last > *:not(:last-child) {
    margin: 24px !important; } }

@media (min-width: 768px) {
  .md-up-mt-md {
    margin-top: 24px !important; }
  .md-up-mt-md-child > * {
    margin-top: 24px !important; }
  .md-up-mt-md-child-not-last > *:not(:last-child) {
    margin-top: 24px !important; } }

@media (min-width: 768px) {
  .md-up-mb-md {
    margin-bottom: 24px !important; }
  .md-up-mb-md-child > * {
    margin-bottom: 24px !important; }
  .md-up-mb-md-child-not-last > *:not(:last-child) {
    margin-bottom: 24px !important; } }

@media (min-width: 768px) {
  .md-up-my-md {
    margin-top: 24px !important;
    margin-bottom: 24px !important; }
  .md-up-my-md-child > * {
    margin-top: 24px !important;
    margin-bottom: 24px !important; }
  .md-up-my-md-child-not-last > *:not(:last-child) {
    margin-top: 24px !important;
    margin-bottom: 24px !important; } }

@media (min-width: 768px) {
  .md-up-ml-md {
    margin-left: 24px !important; }
  .md-up-ml-md-child > * {
    margin-left: 24px !important; }
  .md-up-ml-md-child-not-last > *:not(:last-child) {
    margin-left: 24px !important; } }

@media (min-width: 768px) {
  .md-up-mr-md {
    margin-right: 24px !important; }
  .md-up-mr-md-child > * {
    margin-right: 24px !important; }
  .md-up-mr-md-child-not-last > *:not(:last-child) {
    margin-right: 24px !important; } }

@media (min-width: 768px) {
  .md-up-mx-md {
    margin-left: 24px !important;
    margin-right: 24px !important; }
  .md-up-mx-md-child > * {
    margin-left: 24px !important;
    margin-right: 24px !important; }
  .md-up-mx-md-child-not-last > *:not(:last-child) {
    margin-left: 24px !important;
    margin-right: 24px !important; } }

@media (min-width: 768px) {
  .md-up-p-md {
    padding: 24px !important; }
  .md-up-p-md-child > * {
    padding: 24px !important; }
  .md-up-p-md-child-not-last > *:not(:last-child) {
    padding: 24px !important; } }

@media (min-width: 768px) {
  .md-up-pt-md {
    padding-top: 24px !important; }
  .md-up-pt-md-child > * {
    padding-top: 24px !important; }
  .md-up-pt-md-child-not-last > *:not(:last-child) {
    padding-top: 24px !important; } }

@media (min-width: 768px) {
  .md-up-pb-md {
    padding-bottom: 24px !important; }
  .md-up-pb-md-child > * {
    padding-bottom: 24px !important; }
  .md-up-pb-md-child-not-last > *:not(:last-child) {
    padding-bottom: 24px !important; } }

@media (min-width: 768px) {
  .md-up-py-md {
    padding-top: 24px !important;
    padding-bottom: 24px !important; }
  .md-up-py-md-child > * {
    padding-top: 24px !important;
    padding-bottom: 24px !important; }
  .md-up-py-md-child-not-last > *:not(:last-child) {
    padding-top: 24px !important;
    padding-bottom: 24px !important; } }

@media (min-width: 768px) {
  .md-up-pl-md {
    padding-left: 24px !important; }
  .md-up-pl-md-child > * {
    padding-left: 24px !important; }
  .md-up-pl-md-child-not-last > *:not(:last-child) {
    padding-left: 24px !important; } }

@media (min-width: 768px) {
  .md-up-pr-md {
    padding-right: 24px !important; }
  .md-up-pr-md-child > * {
    padding-right: 24px !important; }
  .md-up-pr-md-child-not-last > *:not(:last-child) {
    padding-right: 24px !important; } }

@media (min-width: 768px) {
  .md-up-px-md {
    padding-left: 24px !important;
    padding-right: 24px !important; }
  .md-up-px-md-child > * {
    padding-left: 24px !important;
    padding-right: 24px !important; }
  .md-up-px-md-child-not-last > *:not(:last-child) {
    padding-left: 24px !important;
    padding-right: 24px !important; } }

@media (min-width: 768px) {
  .md-up-m-lg {
    margin: 50px !important; }
  .md-up-m-lg-child > * {
    margin: 50px !important; }
  .md-up-m-lg-child-not-last > *:not(:last-child) {
    margin: 50px !important; } }

@media (min-width: 768px) {
  .md-up-mt-lg {
    margin-top: 50px !important; }
  .md-up-mt-lg-child > * {
    margin-top: 50px !important; }
  .md-up-mt-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important; } }

@media (min-width: 768px) {
  .md-up-mb-lg {
    margin-bottom: 50px !important; }
  .md-up-mb-lg-child > * {
    margin-bottom: 50px !important; }
  .md-up-mb-lg-child-not-last > *:not(:last-child) {
    margin-bottom: 50px !important; } }

@media (min-width: 768px) {
  .md-up-my-lg {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .md-up-my-lg-child > * {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .md-up-my-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important;
    margin-bottom: 50px !important; } }

@media (min-width: 768px) {
  .md-up-ml-lg {
    margin-left: 50px !important; }
  .md-up-ml-lg-child > * {
    margin-left: 50px !important; }
  .md-up-ml-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important; } }

@media (min-width: 768px) {
  .md-up-mr-lg {
    margin-right: 50px !important; }
  .md-up-mr-lg-child > * {
    margin-right: 50px !important; }
  .md-up-mr-lg-child-not-last > *:not(:last-child) {
    margin-right: 50px !important; } }

@media (min-width: 768px) {
  .md-up-mx-lg {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .md-up-mx-lg-child > * {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .md-up-mx-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important;
    margin-right: 50px !important; } }

@media (min-width: 768px) {
  .md-up-p-lg {
    padding: 50px !important; }
  .md-up-p-lg-child > * {
    padding: 50px !important; }
  .md-up-p-lg-child-not-last > *:not(:last-child) {
    padding: 50px !important; } }

@media (min-width: 768px) {
  .md-up-pt-lg {
    padding-top: 50px !important; }
  .md-up-pt-lg-child > * {
    padding-top: 50px !important; }
  .md-up-pt-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important; } }

@media (min-width: 768px) {
  .md-up-pb-lg {
    padding-bottom: 50px !important; }
  .md-up-pb-lg-child > * {
    padding-bottom: 50px !important; }
  .md-up-pb-lg-child-not-last > *:not(:last-child) {
    padding-bottom: 50px !important; } }

@media (min-width: 768px) {
  .md-up-py-lg {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .md-up-py-lg-child > * {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .md-up-py-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important;
    padding-bottom: 50px !important; } }

@media (min-width: 768px) {
  .md-up-pl-lg {
    padding-left: 50px !important; }
  .md-up-pl-lg-child > * {
    padding-left: 50px !important; }
  .md-up-pl-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important; } }

@media (min-width: 768px) {
  .md-up-pr-lg {
    padding-right: 50px !important; }
  .md-up-pr-lg-child > * {
    padding-right: 50px !important; }
  .md-up-pr-lg-child-not-last > *:not(:last-child) {
    padding-right: 50px !important; } }

@media (min-width: 768px) {
  .md-up-px-lg {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .md-up-px-lg-child > * {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .md-up-px-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important;
    padding-right: 50px !important; } }

@media (min-width: 768px) {
  .md-up-m-xl {
    margin: 70px !important; }
  .md-up-m-xl-child > * {
    margin: 70px !important; }
  .md-up-m-xl-child-not-last > *:not(:last-child) {
    margin: 70px !important; } }

@media (min-width: 768px) {
  .md-up-mt-xl {
    margin-top: 70px !important; }
  .md-up-mt-xl-child > * {
    margin-top: 70px !important; }
  .md-up-mt-xl-child-not-last > *:not(:last-child) {
    margin-top: 70px !important; } }

@media (min-width: 768px) {
  .md-up-mb-xl {
    margin-bottom: 70px !important; }
  .md-up-mb-xl-child > * {
    margin-bottom: 70px !important; }
  .md-up-mb-xl-child-not-last > *:not(:last-child) {
    margin-bottom: 70px !important; } }

@media (min-width: 768px) {
  .md-up-my-xl {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .md-up-my-xl-child > * {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .md-up-my-xl-child-not-last > *:not(:last-child) {
    margin-top: 70px !important;
    margin-bottom: 70px !important; } }

@media (min-width: 768px) {
  .md-up-ml-xl {
    margin-left: 70px !important; }
  .md-up-ml-xl-child > * {
    margin-left: 70px !important; }
  .md-up-ml-xl-child-not-last > *:not(:last-child) {
    margin-left: 70px !important; } }

@media (min-width: 768px) {
  .md-up-mr-xl {
    margin-right: 70px !important; }
  .md-up-mr-xl-child > * {
    margin-right: 70px !important; }
  .md-up-mr-xl-child-not-last > *:not(:last-child) {
    margin-right: 70px !important; } }

@media (min-width: 768px) {
  .md-up-mx-xl {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .md-up-mx-xl-child > * {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .md-up-mx-xl-child-not-last > *:not(:last-child) {
    margin-left: 70px !important;
    margin-right: 70px !important; } }

@media (min-width: 768px) {
  .md-up-p-xl {
    padding: 70px !important; }
  .md-up-p-xl-child > * {
    padding: 70px !important; }
  .md-up-p-xl-child-not-last > *:not(:last-child) {
    padding: 70px !important; } }

@media (min-width: 768px) {
  .md-up-pt-xl {
    padding-top: 70px !important; }
  .md-up-pt-xl-child > * {
    padding-top: 70px !important; }
  .md-up-pt-xl-child-not-last > *:not(:last-child) {
    padding-top: 70px !important; } }

@media (min-width: 768px) {
  .md-up-pb-xl {
    padding-bottom: 70px !important; }
  .md-up-pb-xl-child > * {
    padding-bottom: 70px !important; }
  .md-up-pb-xl-child-not-last > *:not(:last-child) {
    padding-bottom: 70px !important; } }

@media (min-width: 768px) {
  .md-up-py-xl {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .md-up-py-xl-child > * {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .md-up-py-xl-child-not-last > *:not(:last-child) {
    padding-top: 70px !important;
    padding-bottom: 70px !important; } }

@media (min-width: 768px) {
  .md-up-pl-xl {
    padding-left: 70px !important; }
  .md-up-pl-xl-child > * {
    padding-left: 70px !important; }
  .md-up-pl-xl-child-not-last > *:not(:last-child) {
    padding-left: 70px !important; } }

@media (min-width: 768px) {
  .md-up-pr-xl {
    padding-right: 70px !important; }
  .md-up-pr-xl-child > * {
    padding-right: 70px !important; }
  .md-up-pr-xl-child-not-last > *:not(:last-child) {
    padding-right: 70px !important; } }

@media (min-width: 768px) {
  .md-up-px-xl {
    padding-left: 70px !important;
    padding-right: 70px !important; }
  .md-up-px-xl-child > * {
    padding-left: 70px !important;
    padding-right: 70px !important; }
  .md-up-px-xl-child-not-last > *:not(:last-child) {
    padding-left: 70px !important;
    padding-right: 70px !important; } }

@media (min-width: 768px) {
  .md-up-m-xxl {
    margin: 140px !important; }
  .md-up-m-xxl-child > * {
    margin: 140px !important; }
  .md-up-m-xxl-child-not-last > *:not(:last-child) {
    margin: 140px !important; } }

@media (min-width: 768px) {
  .md-up-mt-xxl {
    margin-top: 140px !important; }
  .md-up-mt-xxl-child > * {
    margin-top: 140px !important; }
  .md-up-mt-xxl-child-not-last > *:not(:last-child) {
    margin-top: 140px !important; } }

@media (min-width: 768px) {
  .md-up-mb-xxl {
    margin-bottom: 140px !important; }
  .md-up-mb-xxl-child > * {
    margin-bottom: 140px !important; }
  .md-up-mb-xxl-child-not-last > *:not(:last-child) {
    margin-bottom: 140px !important; } }

@media (min-width: 768px) {
  .md-up-my-xxl {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .md-up-my-xxl-child > * {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .md-up-my-xxl-child-not-last > *:not(:last-child) {
    margin-top: 140px !important;
    margin-bottom: 140px !important; } }

@media (min-width: 768px) {
  .md-up-ml-xxl {
    margin-left: 140px !important; }
  .md-up-ml-xxl-child > * {
    margin-left: 140px !important; }
  .md-up-ml-xxl-child-not-last > *:not(:last-child) {
    margin-left: 140px !important; } }

@media (min-width: 768px) {
  .md-up-mr-xxl {
    margin-right: 140px !important; }
  .md-up-mr-xxl-child > * {
    margin-right: 140px !important; }
  .md-up-mr-xxl-child-not-last > *:not(:last-child) {
    margin-right: 140px !important; } }

@media (min-width: 768px) {
  .md-up-mx-xxl {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .md-up-mx-xxl-child > * {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .md-up-mx-xxl-child-not-last > *:not(:last-child) {
    margin-left: 140px !important;
    margin-right: 140px !important; } }

@media (min-width: 768px) {
  .md-up-p-xxl {
    padding: 140px !important; }
  .md-up-p-xxl-child > * {
    padding: 140px !important; }
  .md-up-p-xxl-child-not-last > *:not(:last-child) {
    padding: 140px !important; } }

@media (min-width: 768px) {
  .md-up-pt-xxl {
    padding-top: 140px !important; }
  .md-up-pt-xxl-child > * {
    padding-top: 140px !important; }
  .md-up-pt-xxl-child-not-last > *:not(:last-child) {
    padding-top: 140px !important; } }

@media (min-width: 768px) {
  .md-up-pb-xxl {
    padding-bottom: 140px !important; }
  .md-up-pb-xxl-child > * {
    padding-bottom: 140px !important; }
  .md-up-pb-xxl-child-not-last > *:not(:last-child) {
    padding-bottom: 140px !important; } }

@media (min-width: 768px) {
  .md-up-py-xxl {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .md-up-py-xxl-child > * {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .md-up-py-xxl-child-not-last > *:not(:last-child) {
    padding-top: 140px !important;
    padding-bottom: 140px !important; } }

@media (min-width: 768px) {
  .md-up-pl-xxl {
    padding-left: 140px !important; }
  .md-up-pl-xxl-child > * {
    padding-left: 140px !important; }
  .md-up-pl-xxl-child-not-last > *:not(:last-child) {
    padding-left: 140px !important; } }

@media (min-width: 768px) {
  .md-up-pr-xxl {
    padding-right: 140px !important; }
  .md-up-pr-xxl-child > * {
    padding-right: 140px !important; }
  .md-up-pr-xxl-child-not-last > *:not(:last-child) {
    padding-right: 140px !important; } }

@media (min-width: 768px) {
  .md-up-px-xxl {
    padding-left: 140px !important;
    padding-right: 140px !important; }
  .md-up-px-xxl-child > * {
    padding-left: 140px !important;
    padding-right: 140px !important; }
  .md-up-px-xxl-child-not-last > *:not(:last-child) {
    padding-left: 140px !important;
    padding-right: 140px !important; } }

@media (min-width: 992px) {
  .lg-up-m-0 {
    margin: 0 !important; }
  .lg-up-m-0-child > * {
    margin: 0 !important; }
  .lg-up-m-0-child-not-last > *:not(:last-child) {
    margin: 0 !important; } }

@media (min-width: 992px) {
  .lg-up-mt-0 {
    margin-top: 0 !important; }
  .lg-up-mt-0-child > * {
    margin-top: 0 !important; }
  .lg-up-mt-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important; } }

@media (min-width: 992px) {
  .lg-up-mb-0 {
    margin-bottom: 0 !important; }
  .lg-up-mb-0-child > * {
    margin-bottom: 0 !important; }
  .lg-up-mb-0-child-not-last > *:not(:last-child) {
    margin-bottom: 0 !important; } }

@media (min-width: 992px) {
  .lg-up-my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .lg-up-my-0-child > * {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .lg-up-my-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important;
    margin-bottom: 0 !important; } }

@media (min-width: 992px) {
  .lg-up-ml-0 {
    margin-left: 0 !important; }
  .lg-up-ml-0-child > * {
    margin-left: 0 !important; }
  .lg-up-ml-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important; } }

@media (min-width: 992px) {
  .lg-up-mr-0 {
    margin-right: 0 !important; }
  .lg-up-mr-0-child > * {
    margin-right: 0 !important; }
  .lg-up-mr-0-child-not-last > *:not(:last-child) {
    margin-right: 0 !important; } }

@media (min-width: 992px) {
  .lg-up-mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .lg-up-mx-0-child > * {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .lg-up-mx-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important;
    margin-right: 0 !important; } }

@media (min-width: 992px) {
  .lg-up-p-0 {
    padding: 0 !important; }
  .lg-up-p-0-child > * {
    padding: 0 !important; }
  .lg-up-p-0-child-not-last > *:not(:last-child) {
    padding: 0 !important; } }

@media (min-width: 992px) {
  .lg-up-pt-0 {
    padding-top: 0 !important; }
  .lg-up-pt-0-child > * {
    padding-top: 0 !important; }
  .lg-up-pt-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important; } }

@media (min-width: 992px) {
  .lg-up-pb-0 {
    padding-bottom: 0 !important; }
  .lg-up-pb-0-child > * {
    padding-bottom: 0 !important; }
  .lg-up-pb-0-child-not-last > *:not(:last-child) {
    padding-bottom: 0 !important; } }

@media (min-width: 992px) {
  .lg-up-py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .lg-up-py-0-child > * {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .lg-up-py-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important;
    padding-bottom: 0 !important; } }

@media (min-width: 992px) {
  .lg-up-pl-0 {
    padding-left: 0 !important; }
  .lg-up-pl-0-child > * {
    padding-left: 0 !important; }
  .lg-up-pl-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important; } }

@media (min-width: 992px) {
  .lg-up-pr-0 {
    padding-right: 0 !important; }
  .lg-up-pr-0-child > * {
    padding-right: 0 !important; }
  .lg-up-pr-0-child-not-last > *:not(:last-child) {
    padding-right: 0 !important; } }

@media (min-width: 992px) {
  .lg-up-px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .lg-up-px-0-child > * {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .lg-up-px-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important;
    padding-right: 0 !important; } }

@media (min-width: 992px) {
  .lg-up-m-xs {
    margin: 5px !important; }
  .lg-up-m-xs-child > * {
    margin: 5px !important; }
  .lg-up-m-xs-child-not-last > *:not(:last-child) {
    margin: 5px !important; } }

@media (min-width: 992px) {
  .lg-up-mt-xs {
    margin-top: 5px !important; }
  .lg-up-mt-xs-child > * {
    margin-top: 5px !important; }
  .lg-up-mt-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important; } }

@media (min-width: 992px) {
  .lg-up-mb-xs {
    margin-bottom: 5px !important; }
  .lg-up-mb-xs-child > * {
    margin-bottom: 5px !important; }
  .lg-up-mb-xs-child-not-last > *:not(:last-child) {
    margin-bottom: 5px !important; } }

@media (min-width: 992px) {
  .lg-up-my-xs {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .lg-up-my-xs-child > * {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .lg-up-my-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important;
    margin-bottom: 5px !important; } }

@media (min-width: 992px) {
  .lg-up-ml-xs {
    margin-left: 5px !important; }
  .lg-up-ml-xs-child > * {
    margin-left: 5px !important; }
  .lg-up-ml-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important; } }

@media (min-width: 992px) {
  .lg-up-mr-xs {
    margin-right: 5px !important; }
  .lg-up-mr-xs-child > * {
    margin-right: 5px !important; }
  .lg-up-mr-xs-child-not-last > *:not(:last-child) {
    margin-right: 5px !important; } }

@media (min-width: 992px) {
  .lg-up-mx-xs {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .lg-up-mx-xs-child > * {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .lg-up-mx-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important;
    margin-right: 5px !important; } }

@media (min-width: 992px) {
  .lg-up-p-xs {
    padding: 5px !important; }
  .lg-up-p-xs-child > * {
    padding: 5px !important; }
  .lg-up-p-xs-child-not-last > *:not(:last-child) {
    padding: 5px !important; } }

@media (min-width: 992px) {
  .lg-up-pt-xs {
    padding-top: 5px !important; }
  .lg-up-pt-xs-child > * {
    padding-top: 5px !important; }
  .lg-up-pt-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important; } }

@media (min-width: 992px) {
  .lg-up-pb-xs {
    padding-bottom: 5px !important; }
  .lg-up-pb-xs-child > * {
    padding-bottom: 5px !important; }
  .lg-up-pb-xs-child-not-last > *:not(:last-child) {
    padding-bottom: 5px !important; } }

@media (min-width: 992px) {
  .lg-up-py-xs {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .lg-up-py-xs-child > * {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .lg-up-py-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important;
    padding-bottom: 5px !important; } }

@media (min-width: 992px) {
  .lg-up-pl-xs {
    padding-left: 5px !important; }
  .lg-up-pl-xs-child > * {
    padding-left: 5px !important; }
  .lg-up-pl-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important; } }

@media (min-width: 992px) {
  .lg-up-pr-xs {
    padding-right: 5px !important; }
  .lg-up-pr-xs-child > * {
    padding-right: 5px !important; }
  .lg-up-pr-xs-child-not-last > *:not(:last-child) {
    padding-right: 5px !important; } }

@media (min-width: 992px) {
  .lg-up-px-xs {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .lg-up-px-xs-child > * {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .lg-up-px-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important;
    padding-right: 5px !important; } }

@media (min-width: 992px) {
  .lg-up-m-sm {
    margin: 15px !important; }
  .lg-up-m-sm-child > * {
    margin: 15px !important; }
  .lg-up-m-sm-child-not-last > *:not(:last-child) {
    margin: 15px !important; } }

@media (min-width: 992px) {
  .lg-up-mt-sm {
    margin-top: 15px !important; }
  .lg-up-mt-sm-child > * {
    margin-top: 15px !important; }
  .lg-up-mt-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important; } }

@media (min-width: 992px) {
  .lg-up-mb-sm {
    margin-bottom: 15px !important; }
  .lg-up-mb-sm-child > * {
    margin-bottom: 15px !important; }
  .lg-up-mb-sm-child-not-last > *:not(:last-child) {
    margin-bottom: 15px !important; } }

@media (min-width: 992px) {
  .lg-up-my-sm {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .lg-up-my-sm-child > * {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .lg-up-my-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important;
    margin-bottom: 15px !important; } }

@media (min-width: 992px) {
  .lg-up-ml-sm {
    margin-left: 15px !important; }
  .lg-up-ml-sm-child > * {
    margin-left: 15px !important; }
  .lg-up-ml-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important; } }

@media (min-width: 992px) {
  .lg-up-mr-sm {
    margin-right: 15px !important; }
  .lg-up-mr-sm-child > * {
    margin-right: 15px !important; }
  .lg-up-mr-sm-child-not-last > *:not(:last-child) {
    margin-right: 15px !important; } }

@media (min-width: 992px) {
  .lg-up-mx-sm {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .lg-up-mx-sm-child > * {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .lg-up-mx-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important;
    margin-right: 15px !important; } }

@media (min-width: 992px) {
  .lg-up-p-sm {
    padding: 15px !important; }
  .lg-up-p-sm-child > * {
    padding: 15px !important; }
  .lg-up-p-sm-child-not-last > *:not(:last-child) {
    padding: 15px !important; } }

@media (min-width: 992px) {
  .lg-up-pt-sm {
    padding-top: 15px !important; }
  .lg-up-pt-sm-child > * {
    padding-top: 15px !important; }
  .lg-up-pt-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important; } }

@media (min-width: 992px) {
  .lg-up-pb-sm {
    padding-bottom: 15px !important; }
  .lg-up-pb-sm-child > * {
    padding-bottom: 15px !important; }
  .lg-up-pb-sm-child-not-last > *:not(:last-child) {
    padding-bottom: 15px !important; } }

@media (min-width: 992px) {
  .lg-up-py-sm {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .lg-up-py-sm-child > * {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .lg-up-py-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important;
    padding-bottom: 15px !important; } }

@media (min-width: 992px) {
  .lg-up-pl-sm {
    padding-left: 15px !important; }
  .lg-up-pl-sm-child > * {
    padding-left: 15px !important; }
  .lg-up-pl-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important; } }

@media (min-width: 992px) {
  .lg-up-pr-sm {
    padding-right: 15px !important; }
  .lg-up-pr-sm-child > * {
    padding-right: 15px !important; }
  .lg-up-pr-sm-child-not-last > *:not(:last-child) {
    padding-right: 15px !important; } }

@media (min-width: 992px) {
  .lg-up-px-sm {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .lg-up-px-sm-child > * {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .lg-up-px-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important;
    padding-right: 15px !important; } }

@media (min-width: 992px) {
  .lg-up-m-md {
    margin: 24px !important; }
  .lg-up-m-md-child > * {
    margin: 24px !important; }
  .lg-up-m-md-child-not-last > *:not(:last-child) {
    margin: 24px !important; } }

@media (min-width: 992px) {
  .lg-up-mt-md {
    margin-top: 24px !important; }
  .lg-up-mt-md-child > * {
    margin-top: 24px !important; }
  .lg-up-mt-md-child-not-last > *:not(:last-child) {
    margin-top: 24px !important; } }

@media (min-width: 992px) {
  .lg-up-mb-md {
    margin-bottom: 24px !important; }
  .lg-up-mb-md-child > * {
    margin-bottom: 24px !important; }
  .lg-up-mb-md-child-not-last > *:not(:last-child) {
    margin-bottom: 24px !important; } }

@media (min-width: 992px) {
  .lg-up-my-md {
    margin-top: 24px !important;
    margin-bottom: 24px !important; }
  .lg-up-my-md-child > * {
    margin-top: 24px !important;
    margin-bottom: 24px !important; }
  .lg-up-my-md-child-not-last > *:not(:last-child) {
    margin-top: 24px !important;
    margin-bottom: 24px !important; } }

@media (min-width: 992px) {
  .lg-up-ml-md {
    margin-left: 24px !important; }
  .lg-up-ml-md-child > * {
    margin-left: 24px !important; }
  .lg-up-ml-md-child-not-last > *:not(:last-child) {
    margin-left: 24px !important; } }

@media (min-width: 992px) {
  .lg-up-mr-md {
    margin-right: 24px !important; }
  .lg-up-mr-md-child > * {
    margin-right: 24px !important; }
  .lg-up-mr-md-child-not-last > *:not(:last-child) {
    margin-right: 24px !important; } }

@media (min-width: 992px) {
  .lg-up-mx-md {
    margin-left: 24px !important;
    margin-right: 24px !important; }
  .lg-up-mx-md-child > * {
    margin-left: 24px !important;
    margin-right: 24px !important; }
  .lg-up-mx-md-child-not-last > *:not(:last-child) {
    margin-left: 24px !important;
    margin-right: 24px !important; } }

@media (min-width: 992px) {
  .lg-up-p-md {
    padding: 24px !important; }
  .lg-up-p-md-child > * {
    padding: 24px !important; }
  .lg-up-p-md-child-not-last > *:not(:last-child) {
    padding: 24px !important; } }

@media (min-width: 992px) {
  .lg-up-pt-md {
    padding-top: 24px !important; }
  .lg-up-pt-md-child > * {
    padding-top: 24px !important; }
  .lg-up-pt-md-child-not-last > *:not(:last-child) {
    padding-top: 24px !important; } }

@media (min-width: 992px) {
  .lg-up-pb-md {
    padding-bottom: 24px !important; }
  .lg-up-pb-md-child > * {
    padding-bottom: 24px !important; }
  .lg-up-pb-md-child-not-last > *:not(:last-child) {
    padding-bottom: 24px !important; } }

@media (min-width: 992px) {
  .lg-up-py-md {
    padding-top: 24px !important;
    padding-bottom: 24px !important; }
  .lg-up-py-md-child > * {
    padding-top: 24px !important;
    padding-bottom: 24px !important; }
  .lg-up-py-md-child-not-last > *:not(:last-child) {
    padding-top: 24px !important;
    padding-bottom: 24px !important; } }

@media (min-width: 992px) {
  .lg-up-pl-md {
    padding-left: 24px !important; }
  .lg-up-pl-md-child > * {
    padding-left: 24px !important; }
  .lg-up-pl-md-child-not-last > *:not(:last-child) {
    padding-left: 24px !important; } }

@media (min-width: 992px) {
  .lg-up-pr-md {
    padding-right: 24px !important; }
  .lg-up-pr-md-child > * {
    padding-right: 24px !important; }
  .lg-up-pr-md-child-not-last > *:not(:last-child) {
    padding-right: 24px !important; } }

@media (min-width: 992px) {
  .lg-up-px-md {
    padding-left: 24px !important;
    padding-right: 24px !important; }
  .lg-up-px-md-child > * {
    padding-left: 24px !important;
    padding-right: 24px !important; }
  .lg-up-px-md-child-not-last > *:not(:last-child) {
    padding-left: 24px !important;
    padding-right: 24px !important; } }

@media (min-width: 992px) {
  .lg-up-m-lg {
    margin: 50px !important; }
  .lg-up-m-lg-child > * {
    margin: 50px !important; }
  .lg-up-m-lg-child-not-last > *:not(:last-child) {
    margin: 50px !important; } }

@media (min-width: 992px) {
  .lg-up-mt-lg {
    margin-top: 50px !important; }
  .lg-up-mt-lg-child > * {
    margin-top: 50px !important; }
  .lg-up-mt-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important; } }

@media (min-width: 992px) {
  .lg-up-mb-lg {
    margin-bottom: 50px !important; }
  .lg-up-mb-lg-child > * {
    margin-bottom: 50px !important; }
  .lg-up-mb-lg-child-not-last > *:not(:last-child) {
    margin-bottom: 50px !important; } }

@media (min-width: 992px) {
  .lg-up-my-lg {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .lg-up-my-lg-child > * {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .lg-up-my-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important;
    margin-bottom: 50px !important; } }

@media (min-width: 992px) {
  .lg-up-ml-lg {
    margin-left: 50px !important; }
  .lg-up-ml-lg-child > * {
    margin-left: 50px !important; }
  .lg-up-ml-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important; } }

@media (min-width: 992px) {
  .lg-up-mr-lg {
    margin-right: 50px !important; }
  .lg-up-mr-lg-child > * {
    margin-right: 50px !important; }
  .lg-up-mr-lg-child-not-last > *:not(:last-child) {
    margin-right: 50px !important; } }

@media (min-width: 992px) {
  .lg-up-mx-lg {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .lg-up-mx-lg-child > * {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .lg-up-mx-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important;
    margin-right: 50px !important; } }

@media (min-width: 992px) {
  .lg-up-p-lg {
    padding: 50px !important; }
  .lg-up-p-lg-child > * {
    padding: 50px !important; }
  .lg-up-p-lg-child-not-last > *:not(:last-child) {
    padding: 50px !important; } }

@media (min-width: 992px) {
  .lg-up-pt-lg {
    padding-top: 50px !important; }
  .lg-up-pt-lg-child > * {
    padding-top: 50px !important; }
  .lg-up-pt-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important; } }

@media (min-width: 992px) {
  .lg-up-pb-lg {
    padding-bottom: 50px !important; }
  .lg-up-pb-lg-child > * {
    padding-bottom: 50px !important; }
  .lg-up-pb-lg-child-not-last > *:not(:last-child) {
    padding-bottom: 50px !important; } }

@media (min-width: 992px) {
  .lg-up-py-lg {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .lg-up-py-lg-child > * {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .lg-up-py-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important;
    padding-bottom: 50px !important; } }

@media (min-width: 992px) {
  .lg-up-pl-lg {
    padding-left: 50px !important; }
  .lg-up-pl-lg-child > * {
    padding-left: 50px !important; }
  .lg-up-pl-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important; } }

@media (min-width: 992px) {
  .lg-up-pr-lg {
    padding-right: 50px !important; }
  .lg-up-pr-lg-child > * {
    padding-right: 50px !important; }
  .lg-up-pr-lg-child-not-last > *:not(:last-child) {
    padding-right: 50px !important; } }

@media (min-width: 992px) {
  .lg-up-px-lg {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .lg-up-px-lg-child > * {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .lg-up-px-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important;
    padding-right: 50px !important; } }

@media (min-width: 992px) {
  .lg-up-m-xl {
    margin: 70px !important; }
  .lg-up-m-xl-child > * {
    margin: 70px !important; }
  .lg-up-m-xl-child-not-last > *:not(:last-child) {
    margin: 70px !important; } }

@media (min-width: 992px) {
  .lg-up-mt-xl {
    margin-top: 70px !important; }
  .lg-up-mt-xl-child > * {
    margin-top: 70px !important; }
  .lg-up-mt-xl-child-not-last > *:not(:last-child) {
    margin-top: 70px !important; } }

@media (min-width: 992px) {
  .lg-up-mb-xl {
    margin-bottom: 70px !important; }
  .lg-up-mb-xl-child > * {
    margin-bottom: 70px !important; }
  .lg-up-mb-xl-child-not-last > *:not(:last-child) {
    margin-bottom: 70px !important; } }

@media (min-width: 992px) {
  .lg-up-my-xl {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .lg-up-my-xl-child > * {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .lg-up-my-xl-child-not-last > *:not(:last-child) {
    margin-top: 70px !important;
    margin-bottom: 70px !important; } }

@media (min-width: 992px) {
  .lg-up-ml-xl {
    margin-left: 70px !important; }
  .lg-up-ml-xl-child > * {
    margin-left: 70px !important; }
  .lg-up-ml-xl-child-not-last > *:not(:last-child) {
    margin-left: 70px !important; } }

@media (min-width: 992px) {
  .lg-up-mr-xl {
    margin-right: 70px !important; }
  .lg-up-mr-xl-child > * {
    margin-right: 70px !important; }
  .lg-up-mr-xl-child-not-last > *:not(:last-child) {
    margin-right: 70px !important; } }

@media (min-width: 992px) {
  .lg-up-mx-xl {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .lg-up-mx-xl-child > * {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .lg-up-mx-xl-child-not-last > *:not(:last-child) {
    margin-left: 70px !important;
    margin-right: 70px !important; } }

@media (min-width: 992px) {
  .lg-up-p-xl {
    padding: 70px !important; }
  .lg-up-p-xl-child > * {
    padding: 70px !important; }
  .lg-up-p-xl-child-not-last > *:not(:last-child) {
    padding: 70px !important; } }

@media (min-width: 992px) {
  .lg-up-pt-xl {
    padding-top: 70px !important; }
  .lg-up-pt-xl-child > * {
    padding-top: 70px !important; }
  .lg-up-pt-xl-child-not-last > *:not(:last-child) {
    padding-top: 70px !important; } }

@media (min-width: 992px) {
  .lg-up-pb-xl {
    padding-bottom: 70px !important; }
  .lg-up-pb-xl-child > * {
    padding-bottom: 70px !important; }
  .lg-up-pb-xl-child-not-last > *:not(:last-child) {
    padding-bottom: 70px !important; } }

@media (min-width: 992px) {
  .lg-up-py-xl {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .lg-up-py-xl-child > * {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .lg-up-py-xl-child-not-last > *:not(:last-child) {
    padding-top: 70px !important;
    padding-bottom: 70px !important; } }

@media (min-width: 992px) {
  .lg-up-pl-xl {
    padding-left: 70px !important; }
  .lg-up-pl-xl-child > * {
    padding-left: 70px !important; }
  .lg-up-pl-xl-child-not-last > *:not(:last-child) {
    padding-left: 70px !important; } }

@media (min-width: 992px) {
  .lg-up-pr-xl {
    padding-right: 70px !important; }
  .lg-up-pr-xl-child > * {
    padding-right: 70px !important; }
  .lg-up-pr-xl-child-not-last > *:not(:last-child) {
    padding-right: 70px !important; } }

@media (min-width: 992px) {
  .lg-up-px-xl {
    padding-left: 70px !important;
    padding-right: 70px !important; }
  .lg-up-px-xl-child > * {
    padding-left: 70px !important;
    padding-right: 70px !important; }
  .lg-up-px-xl-child-not-last > *:not(:last-child) {
    padding-left: 70px !important;
    padding-right: 70px !important; } }

@media (min-width: 992px) {
  .lg-up-m-xxl {
    margin: 140px !important; }
  .lg-up-m-xxl-child > * {
    margin: 140px !important; }
  .lg-up-m-xxl-child-not-last > *:not(:last-child) {
    margin: 140px !important; } }

@media (min-width: 992px) {
  .lg-up-mt-xxl {
    margin-top: 140px !important; }
  .lg-up-mt-xxl-child > * {
    margin-top: 140px !important; }
  .lg-up-mt-xxl-child-not-last > *:not(:last-child) {
    margin-top: 140px !important; } }

@media (min-width: 992px) {
  .lg-up-mb-xxl {
    margin-bottom: 140px !important; }
  .lg-up-mb-xxl-child > * {
    margin-bottom: 140px !important; }
  .lg-up-mb-xxl-child-not-last > *:not(:last-child) {
    margin-bottom: 140px !important; } }

@media (min-width: 992px) {
  .lg-up-my-xxl {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .lg-up-my-xxl-child > * {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .lg-up-my-xxl-child-not-last > *:not(:last-child) {
    margin-top: 140px !important;
    margin-bottom: 140px !important; } }

@media (min-width: 992px) {
  .lg-up-ml-xxl {
    margin-left: 140px !important; }
  .lg-up-ml-xxl-child > * {
    margin-left: 140px !important; }
  .lg-up-ml-xxl-child-not-last > *:not(:last-child) {
    margin-left: 140px !important; } }

@media (min-width: 992px) {
  .lg-up-mr-xxl {
    margin-right: 140px !important; }
  .lg-up-mr-xxl-child > * {
    margin-right: 140px !important; }
  .lg-up-mr-xxl-child-not-last > *:not(:last-child) {
    margin-right: 140px !important; } }

@media (min-width: 992px) {
  .lg-up-mx-xxl {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .lg-up-mx-xxl-child > * {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .lg-up-mx-xxl-child-not-last > *:not(:last-child) {
    margin-left: 140px !important;
    margin-right: 140px !important; } }

@media (min-width: 992px) {
  .lg-up-p-xxl {
    padding: 140px !important; }
  .lg-up-p-xxl-child > * {
    padding: 140px !important; }
  .lg-up-p-xxl-child-not-last > *:not(:last-child) {
    padding: 140px !important; } }

@media (min-width: 992px) {
  .lg-up-pt-xxl {
    padding-top: 140px !important; }
  .lg-up-pt-xxl-child > * {
    padding-top: 140px !important; }
  .lg-up-pt-xxl-child-not-last > *:not(:last-child) {
    padding-top: 140px !important; } }

@media (min-width: 992px) {
  .lg-up-pb-xxl {
    padding-bottom: 140px !important; }
  .lg-up-pb-xxl-child > * {
    padding-bottom: 140px !important; }
  .lg-up-pb-xxl-child-not-last > *:not(:last-child) {
    padding-bottom: 140px !important; } }

@media (min-width: 992px) {
  .lg-up-py-xxl {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .lg-up-py-xxl-child > * {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .lg-up-py-xxl-child-not-last > *:not(:last-child) {
    padding-top: 140px !important;
    padding-bottom: 140px !important; } }

@media (min-width: 992px) {
  .lg-up-pl-xxl {
    padding-left: 140px !important; }
  .lg-up-pl-xxl-child > * {
    padding-left: 140px !important; }
  .lg-up-pl-xxl-child-not-last > *:not(:last-child) {
    padding-left: 140px !important; } }

@media (min-width: 992px) {
  .lg-up-pr-xxl {
    padding-right: 140px !important; }
  .lg-up-pr-xxl-child > * {
    padding-right: 140px !important; }
  .lg-up-pr-xxl-child-not-last > *:not(:last-child) {
    padding-right: 140px !important; } }

@media (min-width: 992px) {
  .lg-up-px-xxl {
    padding-left: 140px !important;
    padding-right: 140px !important; }
  .lg-up-px-xxl-child > * {
    padding-left: 140px !important;
    padding-right: 140px !important; }
  .lg-up-px-xxl-child-not-last > *:not(:last-child) {
    padding-left: 140px !important;
    padding-right: 140px !important; } }

@media (min-width: 1275px) {
  .xl-up-m-0 {
    margin: 0 !important; }
  .xl-up-m-0-child > * {
    margin: 0 !important; }
  .xl-up-m-0-child-not-last > *:not(:last-child) {
    margin: 0 !important; } }

@media (min-width: 1275px) {
  .xl-up-mt-0 {
    margin-top: 0 !important; }
  .xl-up-mt-0-child > * {
    margin-top: 0 !important; }
  .xl-up-mt-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important; } }

@media (min-width: 1275px) {
  .xl-up-mb-0 {
    margin-bottom: 0 !important; }
  .xl-up-mb-0-child > * {
    margin-bottom: 0 !important; }
  .xl-up-mb-0-child-not-last > *:not(:last-child) {
    margin-bottom: 0 !important; } }

@media (min-width: 1275px) {
  .xl-up-my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .xl-up-my-0-child > * {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .xl-up-my-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important;
    margin-bottom: 0 !important; } }

@media (min-width: 1275px) {
  .xl-up-ml-0 {
    margin-left: 0 !important; }
  .xl-up-ml-0-child > * {
    margin-left: 0 !important; }
  .xl-up-ml-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important; } }

@media (min-width: 1275px) {
  .xl-up-mr-0 {
    margin-right: 0 !important; }
  .xl-up-mr-0-child > * {
    margin-right: 0 !important; }
  .xl-up-mr-0-child-not-last > *:not(:last-child) {
    margin-right: 0 !important; } }

@media (min-width: 1275px) {
  .xl-up-mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .xl-up-mx-0-child > * {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .xl-up-mx-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important;
    margin-right: 0 !important; } }

@media (min-width: 1275px) {
  .xl-up-p-0 {
    padding: 0 !important; }
  .xl-up-p-0-child > * {
    padding: 0 !important; }
  .xl-up-p-0-child-not-last > *:not(:last-child) {
    padding: 0 !important; } }

@media (min-width: 1275px) {
  .xl-up-pt-0 {
    padding-top: 0 !important; }
  .xl-up-pt-0-child > * {
    padding-top: 0 !important; }
  .xl-up-pt-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important; } }

@media (min-width: 1275px) {
  .xl-up-pb-0 {
    padding-bottom: 0 !important; }
  .xl-up-pb-0-child > * {
    padding-bottom: 0 !important; }
  .xl-up-pb-0-child-not-last > *:not(:last-child) {
    padding-bottom: 0 !important; } }

@media (min-width: 1275px) {
  .xl-up-py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .xl-up-py-0-child > * {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .xl-up-py-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important;
    padding-bottom: 0 !important; } }

@media (min-width: 1275px) {
  .xl-up-pl-0 {
    padding-left: 0 !important; }
  .xl-up-pl-0-child > * {
    padding-left: 0 !important; }
  .xl-up-pl-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important; } }

@media (min-width: 1275px) {
  .xl-up-pr-0 {
    padding-right: 0 !important; }
  .xl-up-pr-0-child > * {
    padding-right: 0 !important; }
  .xl-up-pr-0-child-not-last > *:not(:last-child) {
    padding-right: 0 !important; } }

@media (min-width: 1275px) {
  .xl-up-px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .xl-up-px-0-child > * {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .xl-up-px-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important;
    padding-right: 0 !important; } }

@media (min-width: 1275px) {
  .xl-up-m-xs {
    margin: 5px !important; }
  .xl-up-m-xs-child > * {
    margin: 5px !important; }
  .xl-up-m-xs-child-not-last > *:not(:last-child) {
    margin: 5px !important; } }

@media (min-width: 1275px) {
  .xl-up-mt-xs {
    margin-top: 5px !important; }
  .xl-up-mt-xs-child > * {
    margin-top: 5px !important; }
  .xl-up-mt-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important; } }

@media (min-width: 1275px) {
  .xl-up-mb-xs {
    margin-bottom: 5px !important; }
  .xl-up-mb-xs-child > * {
    margin-bottom: 5px !important; }
  .xl-up-mb-xs-child-not-last > *:not(:last-child) {
    margin-bottom: 5px !important; } }

@media (min-width: 1275px) {
  .xl-up-my-xs {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .xl-up-my-xs-child > * {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .xl-up-my-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important;
    margin-bottom: 5px !important; } }

@media (min-width: 1275px) {
  .xl-up-ml-xs {
    margin-left: 5px !important; }
  .xl-up-ml-xs-child > * {
    margin-left: 5px !important; }
  .xl-up-ml-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important; } }

@media (min-width: 1275px) {
  .xl-up-mr-xs {
    margin-right: 5px !important; }
  .xl-up-mr-xs-child > * {
    margin-right: 5px !important; }
  .xl-up-mr-xs-child-not-last > *:not(:last-child) {
    margin-right: 5px !important; } }

@media (min-width: 1275px) {
  .xl-up-mx-xs {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .xl-up-mx-xs-child > * {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .xl-up-mx-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important;
    margin-right: 5px !important; } }

@media (min-width: 1275px) {
  .xl-up-p-xs {
    padding: 5px !important; }
  .xl-up-p-xs-child > * {
    padding: 5px !important; }
  .xl-up-p-xs-child-not-last > *:not(:last-child) {
    padding: 5px !important; } }

@media (min-width: 1275px) {
  .xl-up-pt-xs {
    padding-top: 5px !important; }
  .xl-up-pt-xs-child > * {
    padding-top: 5px !important; }
  .xl-up-pt-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important; } }

@media (min-width: 1275px) {
  .xl-up-pb-xs {
    padding-bottom: 5px !important; }
  .xl-up-pb-xs-child > * {
    padding-bottom: 5px !important; }
  .xl-up-pb-xs-child-not-last > *:not(:last-child) {
    padding-bottom: 5px !important; } }

@media (min-width: 1275px) {
  .xl-up-py-xs {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .xl-up-py-xs-child > * {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .xl-up-py-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important;
    padding-bottom: 5px !important; } }

@media (min-width: 1275px) {
  .xl-up-pl-xs {
    padding-left: 5px !important; }
  .xl-up-pl-xs-child > * {
    padding-left: 5px !important; }
  .xl-up-pl-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important; } }

@media (min-width: 1275px) {
  .xl-up-pr-xs {
    padding-right: 5px !important; }
  .xl-up-pr-xs-child > * {
    padding-right: 5px !important; }
  .xl-up-pr-xs-child-not-last > *:not(:last-child) {
    padding-right: 5px !important; } }

@media (min-width: 1275px) {
  .xl-up-px-xs {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .xl-up-px-xs-child > * {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .xl-up-px-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important;
    padding-right: 5px !important; } }

@media (min-width: 1275px) {
  .xl-up-m-sm {
    margin: 15px !important; }
  .xl-up-m-sm-child > * {
    margin: 15px !important; }
  .xl-up-m-sm-child-not-last > *:not(:last-child) {
    margin: 15px !important; } }

@media (min-width: 1275px) {
  .xl-up-mt-sm {
    margin-top: 15px !important; }
  .xl-up-mt-sm-child > * {
    margin-top: 15px !important; }
  .xl-up-mt-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important; } }

@media (min-width: 1275px) {
  .xl-up-mb-sm {
    margin-bottom: 15px !important; }
  .xl-up-mb-sm-child > * {
    margin-bottom: 15px !important; }
  .xl-up-mb-sm-child-not-last > *:not(:last-child) {
    margin-bottom: 15px !important; } }

@media (min-width: 1275px) {
  .xl-up-my-sm {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .xl-up-my-sm-child > * {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .xl-up-my-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important;
    margin-bottom: 15px !important; } }

@media (min-width: 1275px) {
  .xl-up-ml-sm {
    margin-left: 15px !important; }
  .xl-up-ml-sm-child > * {
    margin-left: 15px !important; }
  .xl-up-ml-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important; } }

@media (min-width: 1275px) {
  .xl-up-mr-sm {
    margin-right: 15px !important; }
  .xl-up-mr-sm-child > * {
    margin-right: 15px !important; }
  .xl-up-mr-sm-child-not-last > *:not(:last-child) {
    margin-right: 15px !important; } }

@media (min-width: 1275px) {
  .xl-up-mx-sm {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .xl-up-mx-sm-child > * {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .xl-up-mx-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important;
    margin-right: 15px !important; } }

@media (min-width: 1275px) {
  .xl-up-p-sm {
    padding: 15px !important; }
  .xl-up-p-sm-child > * {
    padding: 15px !important; }
  .xl-up-p-sm-child-not-last > *:not(:last-child) {
    padding: 15px !important; } }

@media (min-width: 1275px) {
  .xl-up-pt-sm {
    padding-top: 15px !important; }
  .xl-up-pt-sm-child > * {
    padding-top: 15px !important; }
  .xl-up-pt-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important; } }

@media (min-width: 1275px) {
  .xl-up-pb-sm {
    padding-bottom: 15px !important; }
  .xl-up-pb-sm-child > * {
    padding-bottom: 15px !important; }
  .xl-up-pb-sm-child-not-last > *:not(:last-child) {
    padding-bottom: 15px !important; } }

@media (min-width: 1275px) {
  .xl-up-py-sm {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .xl-up-py-sm-child > * {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .xl-up-py-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important;
    padding-bottom: 15px !important; } }

@media (min-width: 1275px) {
  .xl-up-pl-sm {
    padding-left: 15px !important; }
  .xl-up-pl-sm-child > * {
    padding-left: 15px !important; }
  .xl-up-pl-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important; } }

@media (min-width: 1275px) {
  .xl-up-pr-sm {
    padding-right: 15px !important; }
  .xl-up-pr-sm-child > * {
    padding-right: 15px !important; }
  .xl-up-pr-sm-child-not-last > *:not(:last-child) {
    padding-right: 15px !important; } }

@media (min-width: 1275px) {
  .xl-up-px-sm {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .xl-up-px-sm-child > * {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .xl-up-px-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important;
    padding-right: 15px !important; } }

@media (min-width: 1275px) {
  .xl-up-m-md {
    margin: 24px !important; }
  .xl-up-m-md-child > * {
    margin: 24px !important; }
  .xl-up-m-md-child-not-last > *:not(:last-child) {
    margin: 24px !important; } }

@media (min-width: 1275px) {
  .xl-up-mt-md {
    margin-top: 24px !important; }
  .xl-up-mt-md-child > * {
    margin-top: 24px !important; }
  .xl-up-mt-md-child-not-last > *:not(:last-child) {
    margin-top: 24px !important; } }

@media (min-width: 1275px) {
  .xl-up-mb-md {
    margin-bottom: 24px !important; }
  .xl-up-mb-md-child > * {
    margin-bottom: 24px !important; }
  .xl-up-mb-md-child-not-last > *:not(:last-child) {
    margin-bottom: 24px !important; } }

@media (min-width: 1275px) {
  .xl-up-my-md {
    margin-top: 24px !important;
    margin-bottom: 24px !important; }
  .xl-up-my-md-child > * {
    margin-top: 24px !important;
    margin-bottom: 24px !important; }
  .xl-up-my-md-child-not-last > *:not(:last-child) {
    margin-top: 24px !important;
    margin-bottom: 24px !important; } }

@media (min-width: 1275px) {
  .xl-up-ml-md {
    margin-left: 24px !important; }
  .xl-up-ml-md-child > * {
    margin-left: 24px !important; }
  .xl-up-ml-md-child-not-last > *:not(:last-child) {
    margin-left: 24px !important; } }

@media (min-width: 1275px) {
  .xl-up-mr-md {
    margin-right: 24px !important; }
  .xl-up-mr-md-child > * {
    margin-right: 24px !important; }
  .xl-up-mr-md-child-not-last > *:not(:last-child) {
    margin-right: 24px !important; } }

@media (min-width: 1275px) {
  .xl-up-mx-md {
    margin-left: 24px !important;
    margin-right: 24px !important; }
  .xl-up-mx-md-child > * {
    margin-left: 24px !important;
    margin-right: 24px !important; }
  .xl-up-mx-md-child-not-last > *:not(:last-child) {
    margin-left: 24px !important;
    margin-right: 24px !important; } }

@media (min-width: 1275px) {
  .xl-up-p-md {
    padding: 24px !important; }
  .xl-up-p-md-child > * {
    padding: 24px !important; }
  .xl-up-p-md-child-not-last > *:not(:last-child) {
    padding: 24px !important; } }

@media (min-width: 1275px) {
  .xl-up-pt-md {
    padding-top: 24px !important; }
  .xl-up-pt-md-child > * {
    padding-top: 24px !important; }
  .xl-up-pt-md-child-not-last > *:not(:last-child) {
    padding-top: 24px !important; } }

@media (min-width: 1275px) {
  .xl-up-pb-md {
    padding-bottom: 24px !important; }
  .xl-up-pb-md-child > * {
    padding-bottom: 24px !important; }
  .xl-up-pb-md-child-not-last > *:not(:last-child) {
    padding-bottom: 24px !important; } }

@media (min-width: 1275px) {
  .xl-up-py-md {
    padding-top: 24px !important;
    padding-bottom: 24px !important; }
  .xl-up-py-md-child > * {
    padding-top: 24px !important;
    padding-bottom: 24px !important; }
  .xl-up-py-md-child-not-last > *:not(:last-child) {
    padding-top: 24px !important;
    padding-bottom: 24px !important; } }

@media (min-width: 1275px) {
  .xl-up-pl-md {
    padding-left: 24px !important; }
  .xl-up-pl-md-child > * {
    padding-left: 24px !important; }
  .xl-up-pl-md-child-not-last > *:not(:last-child) {
    padding-left: 24px !important; } }

@media (min-width: 1275px) {
  .xl-up-pr-md {
    padding-right: 24px !important; }
  .xl-up-pr-md-child > * {
    padding-right: 24px !important; }
  .xl-up-pr-md-child-not-last > *:not(:last-child) {
    padding-right: 24px !important; } }

@media (min-width: 1275px) {
  .xl-up-px-md {
    padding-left: 24px !important;
    padding-right: 24px !important; }
  .xl-up-px-md-child > * {
    padding-left: 24px !important;
    padding-right: 24px !important; }
  .xl-up-px-md-child-not-last > *:not(:last-child) {
    padding-left: 24px !important;
    padding-right: 24px !important; } }

@media (min-width: 1275px) {
  .xl-up-m-lg {
    margin: 50px !important; }
  .xl-up-m-lg-child > * {
    margin: 50px !important; }
  .xl-up-m-lg-child-not-last > *:not(:last-child) {
    margin: 50px !important; } }

@media (min-width: 1275px) {
  .xl-up-mt-lg {
    margin-top: 50px !important; }
  .xl-up-mt-lg-child > * {
    margin-top: 50px !important; }
  .xl-up-mt-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important; } }

@media (min-width: 1275px) {
  .xl-up-mb-lg {
    margin-bottom: 50px !important; }
  .xl-up-mb-lg-child > * {
    margin-bottom: 50px !important; }
  .xl-up-mb-lg-child-not-last > *:not(:last-child) {
    margin-bottom: 50px !important; } }

@media (min-width: 1275px) {
  .xl-up-my-lg {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .xl-up-my-lg-child > * {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .xl-up-my-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important;
    margin-bottom: 50px !important; } }

@media (min-width: 1275px) {
  .xl-up-ml-lg {
    margin-left: 50px !important; }
  .xl-up-ml-lg-child > * {
    margin-left: 50px !important; }
  .xl-up-ml-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important; } }

@media (min-width: 1275px) {
  .xl-up-mr-lg {
    margin-right: 50px !important; }
  .xl-up-mr-lg-child > * {
    margin-right: 50px !important; }
  .xl-up-mr-lg-child-not-last > *:not(:last-child) {
    margin-right: 50px !important; } }

@media (min-width: 1275px) {
  .xl-up-mx-lg {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .xl-up-mx-lg-child > * {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .xl-up-mx-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important;
    margin-right: 50px !important; } }

@media (min-width: 1275px) {
  .xl-up-p-lg {
    padding: 50px !important; }
  .xl-up-p-lg-child > * {
    padding: 50px !important; }
  .xl-up-p-lg-child-not-last > *:not(:last-child) {
    padding: 50px !important; } }

@media (min-width: 1275px) {
  .xl-up-pt-lg {
    padding-top: 50px !important; }
  .xl-up-pt-lg-child > * {
    padding-top: 50px !important; }
  .xl-up-pt-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important; } }

@media (min-width: 1275px) {
  .xl-up-pb-lg {
    padding-bottom: 50px !important; }
  .xl-up-pb-lg-child > * {
    padding-bottom: 50px !important; }
  .xl-up-pb-lg-child-not-last > *:not(:last-child) {
    padding-bottom: 50px !important; } }

@media (min-width: 1275px) {
  .xl-up-py-lg {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .xl-up-py-lg-child > * {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .xl-up-py-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important;
    padding-bottom: 50px !important; } }

@media (min-width: 1275px) {
  .xl-up-pl-lg {
    padding-left: 50px !important; }
  .xl-up-pl-lg-child > * {
    padding-left: 50px !important; }
  .xl-up-pl-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important; } }

@media (min-width: 1275px) {
  .xl-up-pr-lg {
    padding-right: 50px !important; }
  .xl-up-pr-lg-child > * {
    padding-right: 50px !important; }
  .xl-up-pr-lg-child-not-last > *:not(:last-child) {
    padding-right: 50px !important; } }

@media (min-width: 1275px) {
  .xl-up-px-lg {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .xl-up-px-lg-child > * {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .xl-up-px-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important;
    padding-right: 50px !important; } }

@media (min-width: 1275px) {
  .xl-up-m-xl {
    margin: 70px !important; }
  .xl-up-m-xl-child > * {
    margin: 70px !important; }
  .xl-up-m-xl-child-not-last > *:not(:last-child) {
    margin: 70px !important; } }

@media (min-width: 1275px) {
  .xl-up-mt-xl {
    margin-top: 70px !important; }
  .xl-up-mt-xl-child > * {
    margin-top: 70px !important; }
  .xl-up-mt-xl-child-not-last > *:not(:last-child) {
    margin-top: 70px !important; } }

@media (min-width: 1275px) {
  .xl-up-mb-xl {
    margin-bottom: 70px !important; }
  .xl-up-mb-xl-child > * {
    margin-bottom: 70px !important; }
  .xl-up-mb-xl-child-not-last > *:not(:last-child) {
    margin-bottom: 70px !important; } }

@media (min-width: 1275px) {
  .xl-up-my-xl {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .xl-up-my-xl-child > * {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .xl-up-my-xl-child-not-last > *:not(:last-child) {
    margin-top: 70px !important;
    margin-bottom: 70px !important; } }

@media (min-width: 1275px) {
  .xl-up-ml-xl {
    margin-left: 70px !important; }
  .xl-up-ml-xl-child > * {
    margin-left: 70px !important; }
  .xl-up-ml-xl-child-not-last > *:not(:last-child) {
    margin-left: 70px !important; } }

@media (min-width: 1275px) {
  .xl-up-mr-xl {
    margin-right: 70px !important; }
  .xl-up-mr-xl-child > * {
    margin-right: 70px !important; }
  .xl-up-mr-xl-child-not-last > *:not(:last-child) {
    margin-right: 70px !important; } }

@media (min-width: 1275px) {
  .xl-up-mx-xl {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .xl-up-mx-xl-child > * {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .xl-up-mx-xl-child-not-last > *:not(:last-child) {
    margin-left: 70px !important;
    margin-right: 70px !important; } }

@media (min-width: 1275px) {
  .xl-up-p-xl {
    padding: 70px !important; }
  .xl-up-p-xl-child > * {
    padding: 70px !important; }
  .xl-up-p-xl-child-not-last > *:not(:last-child) {
    padding: 70px !important; } }

@media (min-width: 1275px) {
  .xl-up-pt-xl {
    padding-top: 70px !important; }
  .xl-up-pt-xl-child > * {
    padding-top: 70px !important; }
  .xl-up-pt-xl-child-not-last > *:not(:last-child) {
    padding-top: 70px !important; } }

@media (min-width: 1275px) {
  .xl-up-pb-xl {
    padding-bottom: 70px !important; }
  .xl-up-pb-xl-child > * {
    padding-bottom: 70px !important; }
  .xl-up-pb-xl-child-not-last > *:not(:last-child) {
    padding-bottom: 70px !important; } }

@media (min-width: 1275px) {
  .xl-up-py-xl {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .xl-up-py-xl-child > * {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .xl-up-py-xl-child-not-last > *:not(:last-child) {
    padding-top: 70px !important;
    padding-bottom: 70px !important; } }

@media (min-width: 1275px) {
  .xl-up-pl-xl {
    padding-left: 70px !important; }
  .xl-up-pl-xl-child > * {
    padding-left: 70px !important; }
  .xl-up-pl-xl-child-not-last > *:not(:last-child) {
    padding-left: 70px !important; } }

@media (min-width: 1275px) {
  .xl-up-pr-xl {
    padding-right: 70px !important; }
  .xl-up-pr-xl-child > * {
    padding-right: 70px !important; }
  .xl-up-pr-xl-child-not-last > *:not(:last-child) {
    padding-right: 70px !important; } }

@media (min-width: 1275px) {
  .xl-up-px-xl {
    padding-left: 70px !important;
    padding-right: 70px !important; }
  .xl-up-px-xl-child > * {
    padding-left: 70px !important;
    padding-right: 70px !important; }
  .xl-up-px-xl-child-not-last > *:not(:last-child) {
    padding-left: 70px !important;
    padding-right: 70px !important; } }

@media (min-width: 1275px) {
  .xl-up-m-xxl {
    margin: 140px !important; }
  .xl-up-m-xxl-child > * {
    margin: 140px !important; }
  .xl-up-m-xxl-child-not-last > *:not(:last-child) {
    margin: 140px !important; } }

@media (min-width: 1275px) {
  .xl-up-mt-xxl {
    margin-top: 140px !important; }
  .xl-up-mt-xxl-child > * {
    margin-top: 140px !important; }
  .xl-up-mt-xxl-child-not-last > *:not(:last-child) {
    margin-top: 140px !important; } }

@media (min-width: 1275px) {
  .xl-up-mb-xxl {
    margin-bottom: 140px !important; }
  .xl-up-mb-xxl-child > * {
    margin-bottom: 140px !important; }
  .xl-up-mb-xxl-child-not-last > *:not(:last-child) {
    margin-bottom: 140px !important; } }

@media (min-width: 1275px) {
  .xl-up-my-xxl {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .xl-up-my-xxl-child > * {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .xl-up-my-xxl-child-not-last > *:not(:last-child) {
    margin-top: 140px !important;
    margin-bottom: 140px !important; } }

@media (min-width: 1275px) {
  .xl-up-ml-xxl {
    margin-left: 140px !important; }
  .xl-up-ml-xxl-child > * {
    margin-left: 140px !important; }
  .xl-up-ml-xxl-child-not-last > *:not(:last-child) {
    margin-left: 140px !important; } }

@media (min-width: 1275px) {
  .xl-up-mr-xxl {
    margin-right: 140px !important; }
  .xl-up-mr-xxl-child > * {
    margin-right: 140px !important; }
  .xl-up-mr-xxl-child-not-last > *:not(:last-child) {
    margin-right: 140px !important; } }

@media (min-width: 1275px) {
  .xl-up-mx-xxl {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .xl-up-mx-xxl-child > * {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .xl-up-mx-xxl-child-not-last > *:not(:last-child) {
    margin-left: 140px !important;
    margin-right: 140px !important; } }

@media (min-width: 1275px) {
  .xl-up-p-xxl {
    padding: 140px !important; }
  .xl-up-p-xxl-child > * {
    padding: 140px !important; }
  .xl-up-p-xxl-child-not-last > *:not(:last-child) {
    padding: 140px !important; } }

@media (min-width: 1275px) {
  .xl-up-pt-xxl {
    padding-top: 140px !important; }
  .xl-up-pt-xxl-child > * {
    padding-top: 140px !important; }
  .xl-up-pt-xxl-child-not-last > *:not(:last-child) {
    padding-top: 140px !important; } }

@media (min-width: 1275px) {
  .xl-up-pb-xxl {
    padding-bottom: 140px !important; }
  .xl-up-pb-xxl-child > * {
    padding-bottom: 140px !important; }
  .xl-up-pb-xxl-child-not-last > *:not(:last-child) {
    padding-bottom: 140px !important; } }

@media (min-width: 1275px) {
  .xl-up-py-xxl {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .xl-up-py-xxl-child > * {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .xl-up-py-xxl-child-not-last > *:not(:last-child) {
    padding-top: 140px !important;
    padding-bottom: 140px !important; } }

@media (min-width: 1275px) {
  .xl-up-pl-xxl {
    padding-left: 140px !important; }
  .xl-up-pl-xxl-child > * {
    padding-left: 140px !important; }
  .xl-up-pl-xxl-child-not-last > *:not(:last-child) {
    padding-left: 140px !important; } }

@media (min-width: 1275px) {
  .xl-up-pr-xxl {
    padding-right: 140px !important; }
  .xl-up-pr-xxl-child > * {
    padding-right: 140px !important; }
  .xl-up-pr-xxl-child-not-last > *:not(:last-child) {
    padding-right: 140px !important; } }

@media (min-width: 1275px) {
  .xl-up-px-xxl {
    padding-left: 140px !important;
    padding-right: 140px !important; }
  .xl-up-px-xxl-child > * {
    padding-left: 140px !important;
    padding-right: 140px !important; }
  .xl-up-px-xxl-child-not-last > *:not(:last-child) {
    padding-left: 140px !important;
    padding-right: 140px !important; } }

/*
|--------------------
|    FONTS IMPORTS
|--------------------
|
| 100 - Extra Light or Ultra Light
| 200 - Light or Thin
| 300 - Book or Demi
| 400 - Normal or Regular
| 500 - Medium
| 600 - Semibold, Demibold
| 700 - Bold
| 800 - Black, Extra Bold or Heavy
| 900 - Extra Black, Fat, Poster or Ultra Black
|
*/
@font-face {
  font-family: 'graphik';
  src: url("../fonts/graphik/Graphik-Light.eot");
  src: url("../fonts/graphik/Graphik-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/graphik/Graphik-Light.woff2") format("woff2"), url("../fonts/graphik/Graphik-Light.woff") format("woff"), url("../fonts/graphik/Graphik-Light.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
  font-stretch: normal; }

@font-face {
  font-family: 'graphik';
  src: url("../fonts/graphik/Graphik-Regular.eot");
  src: url("../fonts/graphik/Graphik-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/graphik/Graphik-Regular.woff2") format("woff2"), url("../fonts/graphik/Graphik-Regular.woff") format("woff"), url("../fonts/graphik/Graphik-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal; }

@font-face {
  font-family: 'graphik';
  src: url("../fonts/graphik/Graphik-Medium.eot");
  src: url("../fonts/graphik/Graphik-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/graphik/Graphik-Medium.woff2") format("woff2"), url("../fonts/graphik/Graphik-Medium.woff") format("woff"), url("../fonts/graphik/Graphik-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal; }

@font-face {
  font-family: 'graphik';
  src: url("../fonts/graphik/Graphik-Bold.eot");
  src: url("../fonts/graphik/Graphik-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/graphik/Graphik-Bold.woff2") format("woff2"), url("../fonts/graphik/Graphik-Bold.woff") format("woff"), url("../fonts/graphik/Graphik-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal; }

@font-face {
  font-family: 'canela';
  src: url("../fonts/canela/Canela-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/canela/Canela-Regular.otf") format("opentype"), url("../fonts/canela/Canela-Regular.woff") format("woff"), url("../fonts/canela/Canela-Regular.ttf") format("truetype"), url("../fonts/canela/Canela-Regular.svg#Canela-Regular") format("svg");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'canela';
  src: url("../fonts/canela/Canela-Thin.eot?#iefix") format("embedded-opentype"), url("../fonts/canela/Canela-Thin.otf") format("opentype"), url("../fonts/canela/Canela-Thin.woff") format("woff"), url("../fonts/canela/Canela-Thin.ttf") format("truetype"), url("../fonts/canela/Canela-Thin.svg#Canela-Thin") format("svg");
  font-weight: 200;
  font-style: normal; }

@font-face {
  font-family: 'canela';
  src: url("../fonts/canela/Canela-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/canela/Canela-Light.otf") format("opentype"), url("../fonts/canela/Canela-Light.woff") format("woff"), url("../fonts/canela/Canela-Light.ttf") format("truetype"), url("../fonts/canela/Canela-Light.svg#Canela-Light") format("svg");
  font-weight: 300;
  font-style: normal; }

/*
|--------------------------
|      DEFAULT & RESET
|--------------------------
*/
html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar; }

body {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px; }

* {
  margin: 0;
  padding: 0; }

*,
*::before,
*::after {
  box-sizing: inherit; }

body {
  font-family: "Work Sans", sans-serif;
  color: #141414;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  overflow-x: hidden; }

h1, h2, h3, h4, h5, h6 {
  color: inherit;
  font-family: "Nixie One", cursive;
  font-weight: 400;
  line-height: 1.2; }

h1,
h2 {
  font-size: 70px;
  line-height: 80px; }
  @media screen and (max-width: 1500px) {
    h1,
    h2 {
      font-size: 60px;
      line-height: 65px; } }
  @media (max-width: 991.98px) {
    h1,
    h2 {
      font-size: 48px;
      line-height: 60px; } }
  @media (max-width: 767.98px) {
    h1,
    h2 {
      font-size: 36px;
      line-height: 42px; } }

button:focus, input:focus, textarea:focus {
  outline: none !important;
  box-shadow: none !important; }

button {
  -webkit-appearance: none;
  padding: 0;
  background-color: transparent;
  border: none; }

img {
  max-width: inherit; }

a, img, span, button {
  display: inline-block; }

a {
  color: #114D90; }
  a:hover {
    color: #114D90; }

ul {
  margin: 0;
  padding: 0; }
  ul li {
    list-style-type: none; }

ul,
ol {
  margin-left: 30px; }
  @media (max-width: 767.98px) {
    ul,
    ol {
      margin-left: 15px; } }

ol {
  list-style: none;
  counter-reset: li; }

ul > li {
  position: relative;
  padding-left: 1em; }
  ul > li::before {
    content: "◦";
    color: #114D90;
    position: absolute;
    top: 0;
    left: 0;
    width: 1em;
    margin-right: 1em; }

ol > li {
  counter-increment: li; }
  ol > li::before {
    content: "." counter(li);
    color: #114D90;
    display: inline-block;
    width: 1em;
    margin-left: -1.5em;
    margin-right: 0.5em;
    text-align: right;
    direction: rtl; }

li {
  margin-bottom: 15px; }

/*
|--------------------
|      HEADER
|--------------------
*/
/*
|
| Header
|---------
*/
.link-menu, #header .header-container .item-nav .item-menu li .item-link, #header .lang-container {
  color: #141414;
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;
  transition: color .2s ease-out; }
  .link-menu:hover, #header .header-container .item-nav .item-menu li .item-link:hover, #header .lang-container:hover {
    color: #A51C34; }

#header {
  width: 100%;
  max-width: 100vw;
  position: fixed;
  z-index: 10000;
  transform: translateY(0);
  transition: background-color .2s ease-out, transform .2s ease-out; }
  #header.active {
    background-color: #FFFFFF; }
  #header.translated {
    transform: translateY(-100%); }
  #header .header-band {
    position: relative;
    width: 100vw;
    background-color: #114D90;
    color: #FFFFFF;
    text-align: center;
    padding: 5px 0;
    opacity: 1;
    visibility: visible;
    transition: visibility .2s ease-out, opacity .2s ease-out; }
    #header .header-band.hide {
      opacity: 0;
      visibility: hidden;
      display: none; }
    #header .header-band .header-band__close {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer; }
      #header .header-band .header-band__close svg {
        fill: #FFFFFF; }
    #header .header-band .header-band__text {
      padding: 0 30px; }
  #header .header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 25px;
    padding-bottom: 25px; }
    #header .header-container .item-logo img {
      width: 100px; }
    #header .header-container .item-logo svg path, #header .header-container .item-logo svg polygon {
      fill: #000000; }
    #header .header-container .nav-drop {
      display: none;
      opacity: 0;
      visibility: visible;
      position: absolute;
      bottom: -20px;
      left: 0;
      transform: translate(-22%, 100%);
      background-color: #37BFB5;
      width: 250px;
      padding: 40px 55px;
      font-size: 14px;
      box-shadow: 0px 6px 20px 10px rgba(55, 191, 181, 0.2);
      transition: visibility .2s ease-out, opacity .2s ease-out; }
      #header .header-container .nav-drop li {
        margin-right: 0 !important;
        display: block !important; }
        #header .header-container .nav-drop li:not(:last-child) {
          margin-bottom: 24px; }
        #header .header-container .nav-drop li a {
          color: #FFFFFF;
          text-decoration: none; }
          #header .header-container .nav-drop li a:hover {
            text-shadow: 0 0 0.01px #FFFFFF; }
      #header .header-container .nav-drop.active {
        display: block;
        opacity: 1;
        visibility: visible; }
    #header .header-container .item-nav {
      display: none; }
      #header .header-container .item-nav .item-menu {
        display: flex; }
        #header .header-container .item-nav .item-menu li {
          margin-right: 67px;
          padding-left: 0;
          margin-bottom: 0 !important;
          list-style-type: none; }
          #header .header-container .item-nav .item-menu li::before {
            display: none; }
          @media (max-width: 1274.98px) {
            #header .header-container .item-nav .item-menu li {
              margin-right: 40px; } }
      @media (min-width: 992px) {
        #header .header-container .item-nav {
          display: flex;
          align-items: center; }
          #header .header-container .item-nav .item-menu {
            margin-right: 0; } }
  #header .lang-container {
    position: relative;
    display: inline-flex;
    text-transform: uppercase;
    top: 1px;
    cursor: pointer;
    margin-right: 67px;
    margin-bottom: 0 !important; }
    #header .lang-container ul {
      position: absolute;
      z-index: 1;
      top: 100%;
      left: 0;
      opacity: 0;
      visibility: hidden;
      transform: translateX(10px);
      transition: opacity 1.2s cubic-bezier(0.19, 1, 0.22, 1), transform 2s cubic-bezier(0.19, 1, 0.22, 1);
      margin-left: 0; }
      #header .lang-container ul li {
        padding-left: 0; }
        #header .lang-container ul li::before {
          display: none; }
        #header .lang-container ul li a {
          text-decoration: none; }
    #header .lang-container:hover ul {
      opacity: 1 !important;
      visibility: visible !important;
      transform: translateX(0) !important; }
  #header .btn-menu {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
    padding: 0;
    border: none;
    background-color: transparent;
    cursor: pointer; }
    #header .btn-menu > .item-burger {
      display: block;
      width: 25px; }
      #header .btn-menu > .item-burger > span {
        display: block;
        width: 100%;
        height: 2px;
        background-color: #141414;
        border-radius: 2px; }
        #header .btn-menu > .item-burger > span:nth-child(2) {
          margin: 6px 0; }
    @media (min-width: 992px) {
      #header .btn-menu {
        display: none; } }

/*
|
| Menu
|-------
*/
#mobile-menu {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: #FFFFFF;
  overflow: auto;
  opacity: 0;
  visibility: hidden; }
  #mobile-menu .item-container .nav-drop {
    max-height: 0;
    overflow: hidden;
    transition: max-height .2s ease-out; }
    #mobile-menu .item-container .nav-drop.active {
      max-height: 1000000px; }
    #mobile-menu .item-container .nav-drop li {
      padding-left: 20px; }
      #mobile-menu .item-container .nav-drop li a {
        font-size: 16px;
        text-decoration: none; }
    #mobile-menu .item-container .nav-drop .nav-drop-li {
      margin-bottom: 5px !important; }
  #mobile-menu .item-container ul > li:not(:last-child) {
    margin-bottom: 20px; }
  #mobile-menu .item-container ul > li:before {
    content: ""; }
  #mobile-menu .item-container ul > li a {
    text-transform: uppercase;
    text-decoration: none;
    color: #141414;
    font-size: 20px;
    font-weight: 500;
    -webkit-font-smoothing: antialiased; }
    @media (max-width: 575.98px) {
      #mobile-menu .item-container ul > li a {
        font-size: 26px; } }
  #mobile-menu .item-socials-container {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%; }
    #mobile-menu .item-socials-container .item-socials {
      display: flex;
      align-items: center;
      margin-bottom: 25px; }
      #mobile-menu .item-socials-container .item-socials a {
        font-size: 25px;
        text-decoration: none; }
        #mobile-menu .item-socials-container .item-socials a:not(:last-child) {
          margin-right: 25px; }
      @media (max-width: 575.98px) {
        #mobile-menu .item-socials-container .item-socials {
          margin-bottom: 15px; }
          #mobile-menu .item-socials-container .item-socials a {
            text-decoration: none;
            font-size: 20px; } }

/*
|--------------------
|      FOOTER
|--------------------
*/
#footer {
  background-color: #FFFFFF;
  padding-top: 55px;
  margin-bottom: 55px; }
  #footer .footer__logo {
    border-right: 1px solid #000000;
    height: 100%; }
    #footer .footer__logo img {
      width: 100px; }
    @media (max-width: 1274.98px) {
      #footer .footer__logo {
        margin-bottom: 20px;
        border-right: 0; } }
  #footer .footer__menus {
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px; }
    @media (max-width: 991.98px) {
      #footer .footer__menus {
        flex-direction: column; } }
    @media (max-width: 991.98px) {
      #footer .footer__menus .footer__menus__col {
        margin-bottom: 20px; } }
    #footer .footer__menus .footer__menus__col .footer__menus__col__title {
      color: #A51C34;
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 16px;
      font-family: "Work Sans", sans-serif;
      font-weight: bold; }
    #footer .footer__menus .footer__menus__col .footer__menus__col__links {
      display: flex;
      flex-direction: column; }
      #footer .footer__menus .footer__menus__col .footer__menus__col__links a {
        font-size: 14px;
        margin-bottom: 6px;
        line-height: 24px;
        font-weight: 300;
        text-decoration: none;
        transition: color .2s ease-out; }
        #footer .footer__menus .footer__menus__col .footer__menus__col__links a:hover {
          color: #A51C34; }
    #footer .footer__menus .footer__menus__col--social {
      display: flex;
      justify-content: space-between; }
      #footer .footer__menus .footer__menus__col--social a {
        border-radius: 100%;
        border: 2px solid #000000;
        display: flex;
        height: 44px;
        width: 43px;
        background-color: #000000;
        text-decoration: none; }
        #footer .footer__menus .footer__menus__col--social a img {
          transition: filter .2s ease-out; }
        #footer .footer__menus .footer__menus__col--social a:not(:last-child) {
          margin-right: 16px; }
        #footer .footer__menus .footer__menus__col--social a:hover img {
          filter: invert(1); }

.footer__newsletter {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 10;
  width: 350px;
  padding: 20px;
  background-color: white;
  transition: transform .8s; }
  .footer__newsletter.--down {
    transform: translateY(calc(100% - 55px)); }
    .footer__newsletter.--down .footer__newsletter__toggle:before {
      width: 10px;
      top: 10px;
      left: -6px; }
    .footer__newsletter.--down .footer__newsletter__toggle:after {
      width: 10px;
      top: 10px;
      left: 6px; }
  .footer__newsletter__toggle {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 30px;
    height: 30px;
    cursor: pointer; }
    .footer__newsletter__toggle:before {
      content: '';
      position: absolute;
      inset: 0;
      margin: auto;
      width: 20px;
      height: 2px;
      background-color: #A51C34;
      transform: rotate(-45deg);
      transition: top .8s, left .8s, width .8s; }
    .footer__newsletter__toggle:after {
      content: '';
      position: absolute;
      inset: 0;
      margin: auto;
      width: 20px;
      height: 2px;
      background-color: #A51C34;
      transform: rotate(45deg);
      transition: top .8s, left .8s, width .8s; }
  .footer__newsletter .footer__newsletter__title {
    color: #A51C34;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 24px;
    font-family: "Work Sans", sans-serif;
    font-weight: bold;
    cursor: pointer; }
  .footer__newsletter .footer__newsletter__form {
    position: relative; }
    .footer__newsletter .footer__newsletter__form .footer__newsletter__form__btn {
      margin-top: 10px;
      float: right; }
    .footer__newsletter .footer__newsletter__form .validation_error .gform_submission_error {
      font-family: "Work Sans", sans-serif;
      font-size: 16px;
      line-height: 20px; }
    .footer__newsletter .footer__newsletter__form form ul {
      margin-left: 0; }
    .footer__newsletter .footer__newsletter__form form li.gfield {
      padding-left: 0; }
      .footer__newsletter .footer__newsletter__form form li.gfield::before {
        display: none; }
    .footer__newsletter .footer__newsletter__form form .gfield_required {
      display: none; }
    .footer__newsletter .footer__newsletter__form form .gform_fields {
      display: flex;
      flex-direction: column; }
    .footer__newsletter .footer__newsletter__form form .gfield_label {
      font-weight: 300;
      text-transform: uppercase;
      font-size: 14px;
      letter-spacing: .2px;
      margin-bottom: 5px;
      display: none; }
    .footer__newsletter .footer__newsletter__form form input[type="text"] {
      width: 100%;
      border: none;
      border-bottom: 2px solid #37BFB5;
      margin-right: 24px;
      height: 30px;
      padding-bottom: 10px;
      font-size: 14px;
      color: #114D90; }
      @media (max-width: 991.98px) {
        .footer__newsletter .footer__newsletter__form form input[type="text"] {
          width: 100%; } }
      .footer__newsletter .footer__newsletter__form form input[type="text"]::placeholder {
        color: #114D90; }
    .footer__newsletter .footer__newsletter__form form select {
      width: 100%;
      border: none;
      border-bottom: 2px solid #37BFB5;
      height: 33px;
      font-size: 14px;
      color: #114D90;
      padding-bottom: 10px;
      background-image: url("../img/icon/bottom-green.svg");
      background-repeat: no-repeat;
      background-size: 15px;
      background-position: calc(100%) center; }
      .footer__newsletter .footer__newsletter__form form select::placeholder {
        color: #114D90; }
      @media (max-width: 991.98px) {
        .footer__newsletter .footer__newsletter__form form select {
          width: 100%; } }
    .footer__newsletter .footer__newsletter__form form input[type="submit"] {
      display: none; }
    .footer__newsletter .footer__newsletter__form form .validation_message.gfield_validation_message {
      color: #A51C34; }

/*
|--------------------
|      CONTENT
|--------------------
*/
.site-container {
  overflow-x: hidden; }

/*
|
| Banner
|---------
*/
.banner {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  background-color: #000000; }
  .banner .item-content {
    width: 100%;
    padding: 100px 0; }

/*
|
| Custom card
|
| Use in news archive page
|--------------
*/
.custom-card {
  /*
    * Card img
    */
  /*
    * Card content
    */
  /*
    * Hover state
    */ }
  .custom-card .custom-card-link {
    display: block; }
  .custom-card .card-img-container {
    position: relative;
    overflow: hidden; }
    .custom-card .card-img-container:after {
      content: '';
      display: block;
      padding-bottom: 100%; }
    .custom-card .card-img-container .card-img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transition: transform 2s cubic-bezier(0.19, 1, 0.22, 1);
      will-change: transform; }
  .custom-card .card-content .date {
    color: rgba(20, 20, 20, 0.6); }
  .custom-card:hover .card-img-container .card-img {
    transform: scale(1.05); }

/*
|
| Cookie banner
|----------------
*/
.cookie-banner {
  display: none;
  position: fixed;
  z-index: 1;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 30px 0; }
  .cookie-banner .item-close {
    font-size: 24px;
    cursor: pointer; }
  @media (max-width: 767.98px) {
    .cookie-banner {
      padding: 20px 0;
      font-size: 12px; } }
  @media (max-width: 575.98px) {
    .cookie-banner {
      padding: 10px 0 20px 0; }
      .cookie-banner .item-close {
        font-size: 18px; } }

/*
|
| Pagination
|---------------
*/
.pagination-container li {
  display: inline-block; }
  .pagination-container li a {
    transition: all 0.3s ease-out;
    padding: 0 8px; }
    .pagination-container li a:hover {
      color: #808080; }
  .pagination-container li .current {
    padding: 0 8px;
    font-weight: 800;
    color: #808080; }

/*
|
| CMS
|------
*/
.cms span {
  display: inline; }

.cms h1, .cms h2, .cms h3, .cms h4, .cms h5, .cms h6 {
  margin-bottom: 30px; }

.cms img {
  display: block;
  width: 100%;
  height: auto; }

.cms strong {
  font-weight: 800; }

.cms p {
  margin-bottom: 20px; }

.cms.cms-no-break p {
  margin-bottom: 0; }

.cms blockquote {
  position: relative;
  width: 80%;
  margin-right: 0;
  margin-left: auto;
  padding: 130px 0;
  font-size: 30px;
  line-height: 1.5em;
  font-weight: 300; }
  @media (max-width: 991.98px) {
    .cms blockquote {
      margin: auto; } }
  .cms blockquote:before {
    content: "";
    position: absolute;
    background-image: url("../img/icon/quote-left.svg");
    width: 50px;
    height: 50px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    transform: translateX(-60px) translateY(-30px); }
  .cms blockquote:after {
    content: "";
    position: absolute;
    right: 0;
    background-image: url("../img/icon/quote-right.svg");
    width: 50px;
    height: 50px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    transform: translateX(0) translateY(-50px); }
    @media (max-width: 991.98px) {
      .cms blockquote:after {
        transform: translateX(60px) translateY(-50px); } }

/*
|
| Page offset
|--------------
*/
.page-offset {
  padding-top: 112px; }
  @media (max-width: 1274.98px) {
    .page-offset {
      padding-top: 112px; } }

.no-scroll {
  overflow: hidden; }

/*
|
| Custom Loader
|---------------
*/
.custom-loader {
  width: 100px;
  display: inline-block; }
  .custom-loader.cl-center {
    margin-left: auto;
    margin-right: auto; }
  .custom-loader svg {
    display: inline-block;
    width: 100%;
    height: auto;
    fill: #000000; }
    .custom-loader svg circle {
      stroke: #000000; }
  .custom-loader.ajax-loader {
    display: none; }

#wcb_p {
  display: flex; }

/*
|
| Custom Select
|---------------
*/
.custom-select-wrapper {
  position: relative;
  user-select: none;
  margin-right: 50px; }

.custom-select {
  position: relative;
  display: flex;
  flex-direction: column; }

.custom-select__trigger {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  font-size: 24px;
  font-weight: 300;
  color: #FFFFFF;
  width: 340px;
  height: 30px;
  line-height: 60px;
  background: #37BFB5;
  cursor: pointer;
  padding-bottom: 10px;
  font-weight: 400; }
  .custom-select__trigger--small {
    width: 200px;
    height: 24px;
    font-size: 14px; }

.custom-options {
  position: absolute;
  display: block;
  top: 100%;
  left: 0;
  right: 0;
  background: #FFFFFF;
  transition: all 0.5s;
  opacity: 0;
  width: 340px;
  visibility: hidden;
  pointer-events: none;
  z-index: 2;
  font-size: 16px; }
  .custom-options--small {
    width: 200px;
    height: 24px; }

.custom-select.open .custom-options {
  opacity: 1;
  visibility: visible;
  pointer-events: all; }

.custom-option {
  position: relative;
  display: block;
  padding: 0;
  padding-left: 10px;
  font-size: 16px;
  font-weight: 300;
  color: #424242;
  line-height: 30px;
  cursor: pointer;
  transition: all 0.5s;
  background-color: #FFFFFF; }
  .custom-option--small {
    font-size: 14px; }

.custom-option:hover {
  cursor: pointer;
  background-color: #dedede; }

.custom-option.selected {
  color: #FFFFFF;
  background-color: #114D90; }

.arrow {
  position: relative;
  height: 15px;
  width: 15px; }
  .arrow--small {
    height: 10px; }

.arrow::before, .arrow::after {
  content: "";
  position: absolute;
  bottom: 0px;
  width: 0.15rem;
  height: 100%;
  transition: all 0.5s; }

.arrow::before {
  left: -5px;
  transform: rotate(-45deg);
  background-color: #FFFFFF; }

.arrow::after {
  left: 5px;
  transform: rotate(45deg);
  background-color: #FFFFFF; }

.open .arrow::before {
  left: -5px;
  transform: rotate(45deg); }

.open .arrow::after {
  left: 5px;
  transform: rotate(-45deg); }

.arrow--small::before {
  left: -3px;
  transform: rotate(-45deg);
  background-color: #FFFFFF; }

.arrow--small::after {
  left: 3px;
  transform: rotate(45deg);
  background-color: #FFFFFF; }

.open .arrow--small::before {
  left: -3px;
  transform: rotate(45deg); }

.open .arrow--small::after {
  left: 3px;
  transform: rotate(-45deg); }

/*
|--------------------
|     PAGE HOME
|--------------------
*/
#page-portfolio {
  background-color: #D7F2F0; }

/*
|--------------------
|      NEWS
|--------------------
*/
.load-more-container {
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center; }
  .load-more-container .loader-container {
    display: none; }

/*
|--------------------
|      SINGLE
|--------------------
*/
.post-date {
  font-size: 12px;
  color: rgba(128, 128, 128, 0.6);
  text-transform: uppercase;
  font-weight: 400; }

.post-excerpt {
  font-size: 22px;
  color: rgba(20, 20, 20, 0.6);
  font-weight: 400; }

/*
|--------------------
|       Contact
|--------------------
*/
#page-contact {
  background-color: #D7F2F0; }

/*
|--------------------
|       404
|--------------------
*/
#page-404 {
  /*
    |
    | Section contact
    |------------------
    */ }
  #page-404 .section-content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    background-color: #141414;
    color: #FFFFFF;
    text-align: center; }
    #page-404 .section-content .item-title {
      font-size: 8vw;
      font-weight: 300;
      line-height: 1; }

#page-scaling {
  background-color: #D7F2F0; }

#page-team {
  background-color: #D7F2F0; }

#page-content-list {
  background-color: #D7F2F0; }

#page-jobs {
  background-color: #D7F2F0; }

#page-faq {
  background-color: #D7F2F0; }

#page-manifesto {
  background-color: #D7F2F0; }

#page-pitch {
  background-color: #D7F2F0; }

#page-cms {
  background-color: #D7F2F0;
  padding-bottom: 40px; }
  #page-cms h1 {
    color: #114D90; }

body {
  --e-global-color-primary: #a41d34;
  --e-global-color-secondary: #114d90;
  --e-global-color-text: #141414;
  --e-global-color-accent: #37bfb5;
  --e-global-color-1a88f45: #37bfb5;
  --e-global-color-74e3cec: #d7f2f0;
  --e-global-color-6db9d6f: #ffffff;
  --e-global-color-22f671a: #edd1d6;
  --e-global-color-757b17c: #c97685;
  --e-global-color-82019cb: #cfdbe9;
  --e-global-color-2ef249d: #7094bc;
  --e-global-typography-6626967-font-family: "Nixie One";
  --e-global-typography-6626967-font-size: 70px;
  --e-global-typography-6626967-font-weight: 400;
  --e-global-typography-6626967-line-height: 95px;
  --e-global-typography-primary-font-family: "Nixie One";
  --e-global-typography-primary-font-weight: 400;
  --e-global-typography-secondary-font-family: "Work Sans";
  --e-global-typography-secondary-font-weight: 800;
  --e-global-typography-secondary-text-transform: uppercase;
  --e-global-typography-text-font-family: "Work Sans";
  --e-global-typography-text-font-size: 16px;
  --e-global-typography-text-font-weight: 400;
  --e-global-typography-text-line-height: 24px;
  --e-global-typography-accent-font-family: "Roboto";
  --e-global-typography-accent-font-weight: 500;
  --e-global-typography-26088f0-font-family: "Nixie One";
  --e-global-typography-26088f0-font-size: 40px;
  --e-global-typography-26088f0-font-weight: 700;
  --e-global-typography-26088f0-line-height: 48px;
  --e-global-typography-0e917a9-font-family: "Work Sans";
  --e-global-typography-0e917a9-font-size: 24px;
  --e-global-typography-0e917a9-font-weight: 600;
  --e-global-typography-0e917a9-line-height: 28px;
  --e-global-typography-935d655-font-family: "Work Sans";
  --e-global-typography-935d655-font-size: 24px;
  --e-global-typography-935d655-font-weight: 400;
  --e-global-typography-935d655-line-height: 28px;
  --e-global-typography-2942aeb-font-family: "Work Sans";
  --e-global-typography-2942aeb-font-size: 12px;
  --e-global-typography-2942aeb-font-weight: 700;
  --e-global-typography-2942aeb-text-transform: uppercase;
  --e-global-typography-2942aeb-line-height: 14px;
  --e-global-typography-3890ace-font-family: "Nixie One";
  --e-global-typography-3890ace-font-size: 24px;
  --e-global-typography-3890ace-font-weight: 600;
  --e-global-typography-3890ace-line-height: 28px;
  --e-global-typography-f20730e-font-family: "Work Sans";
  --e-global-typography-f20730e-font-size: 20px;
  --e-global-typography-f20730e-font-weight: 400;
  --e-global-typography-f20730e-line-height: 24px;
  --e-global-typography-0f47f69-font-family: "Nixie One";
  --e-global-typography-0f47f69-font-size: 20px;
  --e-global-typography-0f47f69-font-weight: 600;
  --e-global-typography-0f47f69-line-height: 24px;
  --e-global-typography-149b58e-font-family: "Work Sans";
  --e-global-typography-149b58e-font-size: 16px;
  --e-global-typography-149b58e-font-weight: 700;
  --e-global-typography-149b58e-line-height: 14px; }

.page-template-elementor {
  overflow-x: hidden !important;
  /* Text red */
  /* h1 */
  /* h2 */ }
  .page-template-elementor #page-elementor {
    background-color: #D7F2F0 !important;
    background-color: #d7f2f0 !important; }
    .page-template-elementor #page-elementor > header {
      padding-top: 80px !important; }
  .page-template-elementor .title-xxl, .page-template-elementor .cms h1, .cms .page-template-elementor h1, .page-template-elementor #page-404 .section-content .item-title, #page-404 .section-content .page-template-elementor .item-title {
    color: #114D90 !important; }
  .page-template-elementor .elementor-heading-title.elementor-size-xl {
    color: #A51C34 !important; }
  .page-template-elementor .elementor-heading-title.elementor-size-default {
    color: #114D90 !important; }
  .page-template-elementor .elementor-heading-title.elementor-size-medium {
    color: #37BFB5 !important;
    font-weight: 600 !important;
    text-transform: uppercase !important; }
  .page-template-elementor .elementor-element.elementor-element-bdca838 {
    background-color: #37BFB5 !important; }
    .page-template-elementor .elementor-element.elementor-element-bdca838 .elementor-heading-title {
      color: #A51C34 !important; }
    .page-template-elementor .elementor-element.elementor-element-bdca838:before, .page-template-elementor .elementor-element.elementor-element-bdca838:after {
      content: "" !important;
      position: absolute !important;
      top: 0 !important;
      right: 100% !important;
      bottom: 0 !important;
      width: 100% !important;
      background-color: inherit !important; }
    .page-template-elementor .elementor-element.elementor-element-bdca838:after {
      left: 100% !important;
      right: auto !important; }
  .page-template-elementor .elementor-section.elementor-element-6bd532e {
    background-color: #A51C34 !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
    .page-template-elementor .elementor-section.elementor-element-6bd532e .elementor-heading-title.elementor-size-default {
      color: #FFFFFF !important; }
    .page-template-elementor .elementor-section.elementor-element-6bd532e:before, .page-template-elementor .elementor-section.elementor-element-6bd532e:after {
      content: "" !important;
      position: absolute !important;
      top: 0 !important;
      right: 100% !important;
      bottom: 0 !important;
      width: 100% !important;
      background-color: inherit !important; }
    .page-template-elementor .elementor-section.elementor-element-6bd532e:after {
      left: 100% !important;
      right: auto !important; }
    .page-template-elementor .elementor-section.elementor-element-6bd532e .elementor-button-link.elementor-button .elementor-button-content-wrapper {
      width: 11px !important;
      height: 10px !important;
      border-top: 2px solid #A51C34 !important;
      border-left: 2px solid #A51C34 !important;
      transform: rotate(135deg) !important; }
      .page-template-elementor .elementor-section.elementor-element-6bd532e .elementor-button-link.elementor-button .elementor-button-content-wrapper:after {
        content: "" !important;
        display: block !important;
        width: 2px !important;
        height: 12px !important;
        background-color: #A51C34 !important;
        transform: rotate(-45deg) translate(3.5px, 1px) !important;
        left: 0 !important;
        top: 0 !important; }
  .page-template-elementor .elementor-element.elementor-element-012da88 {
    color: #A51C34 !important; }
  .page-template-elementor .elementor-element.elementor-element-75b7b61 > .elementor-widget-container {
    margin: 40px 0px 0px 0px;
    font-size: 60px;
    font-weight: 400;
    line-height: 80px;
    font-family: "Nixie One", Sans-serif; }
  .page-template-elementor .elementor-element.elementor-element-75b7b61 .elementor-heading-title {
    color: #114d90;
    font-family: "Nixie One", Sans-serif;
    font-size: 60px;
    font-weight: 400;
    line-height: 80px; }
  @media (min-width: 768px) {
    .page-template-elementor .elementor-column.elementor-col-50,
    .page-template-elementor .elementor-column[data-col="50"] {
      width: 50%; } }
  .page-template-elementor .elementor-element.elementor-element-f627e9e .elementor-heading-title,
  .page-template-elementor .elementor-element.elementor-element-121d01f .elementor-heading-title {
    font-size: 40px !important;
    font-weight: 700 !important;
    line-height: 48px !important;
    margin: 20px 0px 0px 0px !important; }
  .page-template-elementor .elementor-element.elementor-element-e19de74 {
    margin-top: 10px;
    margin-bottom: 0px; }
  .page-template-elementor .elementor-element.elementor-element-eebae0d .elementor-heading-title {
    font-family: "Nixie One", Sans-serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 95px; }
  .page-template-elementor .elementor-element.elementor-element-75b7b61 > .elementor-widget-container {
    margin: 40px 0px 0px 0px !important; }
  .page-template-elementor .elementor-element.elementor-element-f627e9e > .elementor-widget-container,
  .page-template-elementor .elementor-element.elementor-element-75b7b61 > .elementor-widget-container {
    margin: 20px 0px 0px 0px; }
  .page-template-elementor .elementor-element.elementor-element-c0543a9 > .elementor-container,
  .page-template-elementor .elementor-element.elementor-element-cf37dd5 > .elementor-container,
  .page-template-elementor .elementor-element.elementor-element-c96cbbb > .elementor-container,
  .page-template-elementor .elementor-element.elementor-element-ace3078 > .elementor-container,
  .page-template-elementor .elementor-element.elementor-element-e19de74 > .elementor-container,
  .page-template-elementor .elementor-element.elementor-element-e55b53f > .elementor-container,
  .page-template-elementor .elementor-element.elementor-element-2d2ab33 > .elementor-container,
  .page-template-elementor .elementor-element.elementor-element-1e15c67 > .elementor-container,
  .page-template-elementor .elementor-element.elementor-element-e8aaf69 > .elementor-container,
  .page-template-elementor .elementor-element.elementor-element-63f2193 > .elementor-container {
    max-width: 900px; }
  .page-template-elementor .elementor-element.elementor-element-6428d36 > .elementor-widget-wrap,
  .page-template-elementor .elementor-element.elementor-element-3565c07 > .elementor-widget-wrap,
  .page-template-elementor .elementor-element.elementor-element-e6bbd6a > .elementor-widget-wrap {
    background-color: #37bfb5; }
  .page-template-elementor .elementor-element.elementor-element-d3c4845:not(.elementor-motion-effects-element-type-background) > .elementor-widget-wrap,
  .page-template-elementor .elementor-element.elementor-element-d3c4845 > .elementor-widget-wrap > .elementor-motion-effects-container > .elementor-motion-effects-layer {
    background-color: #fff; }
  .page-template-elementor .elementor-element.elementor-element-8e607cd .elementor-divider {
    padding-top: 15px;
    padding-bottom: 15px; }
  .page-template-elementor .elementor-element.elementor-element-8e607cd .elementor-divider .elementor-divider-separator,
  .page-template-elementor .elementor-element.elementor-element-1676e54 .elementor-divider .elementor-divider-separator,
  .page-template-elementor .elementor-element.elementor-element-d491432 .elementor-divider .elementor-divider-separator,
  .page-template-elementor .elementor-element.elementor-element-8654859 .elementor-divider .elementor-divider-separator {
    border-top: var(--divider-border-width) var(--divider-border-style) #a41d34; }
  .page-template-elementor .elementor-element.elementor-element-1129b92,
  .page-template-elementor .elementor-element.elementor-element-2c84fe7,
  .page-template-elementor .elementor-element.elementor-element-d2f021a,
  .page-template-elementor .elementor-element.elementor-element-0668401,
  .page-template-elementor .elementor-element.elementor-element-ff26c6b,
  .page-template-elementor .elementor-element.elementor-element-31d564e,
  .page-template-elementor .elementor-element.elementor-element-45ca68e,
  .page-template-elementor .elementor-element.elementor-element-e4889d3,
  .page-template-elementor .elementor-element.elementor-element-5afce5c,
  .page-template-elementor .elementor-element.elementor-element-84d6708,
  .page-template-elementor .elementor-element.elementor-element-2a516be,
  .page-template-elementor .elementor-element.elementor-element-cfb9d59,
  .page-template-elementor .elementor-element.elementor-element-d2b7933,
  .page-template-elementor .elementor-element.elementor-element-1c45409,
  .page-template-elementor .elementor-element.elementor-element-21fbf36,
  .page-template-elementor .elementor-element.elementor-element-7998d49 {
    color: #a41d34; }
  .page-template-elementor .elementor-element.elementor-element-3e79179 .elementor-heading-title,
  .page-template-elementor .elementor-element.elementor-element-62be1b2 .elementor-heading-title,
  .page-template-elementor .elementor-element.elementor-element-246aebc .elementor-heading-title {
    color: var(--e-global-color-6db9d6f);
    font-family: "Work Sans", Sans-serif;
    font-size: 24px;
    font-weight: 400;
    line-height: 28px; }
  .page-template-elementor .elementor-element.elementor-element-e4f00a7 .elementor-heading-title,
  .page-template-elementor .elementor-element.elementor-element-55b82a0 .elementor-heading-title,
  .page-template-elementor .elementor-element.elementor-element-2e7097b .elementor-heading-title {
    color: var(--e-global-color-6db9d6f);
    font-family: var(--e-global-typography-0e917a9-font-family), Sans-serif;
    font-size: var(--e-global-typography-0e917a9-font-size);
    font-weight: var(--e-global-typography-0e917a9-font-weight);
    line-height: var(--e-global-typography-0e917a9-line-height);
    letter-spacing: var(--e-global-typography-0e917a9-letter-spacing);
    word-spacing: var(--e-global-typography-0e917a9-word-spacing); }
  .page-template-elementor .elementor-element.elementor-element-21783cf .elementor-heading-title,
  .page-template-elementor .elementor-element.elementor-element-aa605d4 .elementor-heading-title,
  .page-template-elementor .elementor-element.elementor-element-2511652 .elementor-heading-title {
    color: var(--e-global-color-6db9d6f);
    font-family: var(--e-global-typography-3890ace-font-family), Sans-serif;
    font-size: var(--e-global-typography-3890ace-font-size);
    font-weight: var(--e-global-typography-3890ace-font-weight);
    line-height: var(--e-global-typography-3890ace-line-height);
    letter-spacing: var(--e-global-typography-3890ace-letter-spacing);
    word-spacing: var(--e-global-typography-3890ace-word-spacing); }
  .page-template-elementor .elementor-element.elementor-element-f687bc3 .elementor-heading-title,
  .page-template-elementor .elementor-element.elementor-element-3097aaf .elementor-heading-title,
  .page-template-elementor .elementor-element.elementor-element-e934cba .elementor-heading-title {
    color: var(--e-global-color-6db9d6f);
    font-family: var(--e-global-typography-f20730e-font-family), Sans-serif;
    font-size: var(--e-global-typography-f20730e-font-size);
    font-weight: var(--e-global-typography-f20730e-font-weight);
    line-height: var(--e-global-typography-f20730e-line-height);
    letter-spacing: var(--e-global-typography-f20730e-letter-spacing);
    word-spacing: var(--e-global-typography-f20730e-word-spacing); }
  .page-template-elementor .elementor-element.elementor-element-6fc4389 .elementor-heading-title,
  .page-template-elementor .elementor-element.elementor-element-d613f42 .elementor-heading-title,
  .page-template-elementor .elementor-element.elementor-element-26dc4a2 .elementor-heading-title {
    color: var(--e-global-color-6db9d6f);
    font-family: var(--e-global-typography-0f47f69-font-family), Sans-serif;
    font-size: var(--e-global-typography-0f47f69-font-size);
    font-weight: var(--e-global-typography-0f47f69-font-weight);
    line-height: var(--e-global-typography-0f47f69-line-height);
    letter-spacing: var(--e-global-typography-0f47f69-letter-spacing);
    word-spacing: var(--e-global-typography-0f47f69-word-spacing); }
  .page-template-elementor .elementor-element.elementor-element-95cfb18 .elementor-heading-title,
  .page-template-elementor .elementor-element.elementor-element-4c219ce .elementor-heading-title,
  .page-template-elementor .elementor-element.elementor-element-c027490 .elementor-heading-title {
    color: var(--e-global-color-6db9d6f);
    font-family: var(--e-global-typography-149b58e-font-family), Sans-serif;
    font-size: var(--e-global-typography-149b58e-font-size);
    font-weight: var(--e-global-typography-149b58e-font-weight);
    line-height: var(--e-global-typography-149b58e-line-height);
    letter-spacing: var(--e-global-typography-149b58e-letter-spacing);
    word-spacing: var(--e-global-typography-149b58e-word-spacing); }
  .page-template-elementor .elementor-element.elementor-element-6b575bc .elementor-heading-title,
  .page-template-elementor .elementor-element.elementor-element-e061261 .elementor-heading-title,
  .page-template-elementor .elementor-element.elementor-element-42bdf65 .elementor-heading-title {
    color: var(--e-global-color-6db9d6f);
    font-family: var(--e-global-typography-2942aeb-font-family), Sans-serif;
    font-size: var(--e-global-typography-2942aeb-font-size);
    font-weight: var(--e-global-typography-2942aeb-font-weight);
    text-transform: var(--e-global-typography-2942aeb-text-transform);
    line-height: var(--e-global-typography-2942aeb-line-height);
    letter-spacing: var(--e-global-typography-2942aeb-letter-spacing);
    word-spacing: var(--e-global-typography-2942aeb-word-spacing); }
  .page-template-elementor .elementor-element.elementor-element-eeabc16 .elementor-button {
    font-family: "Work Sans", Sans-serif;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 14px;
    fill: var(--e-global-color-6db9d6f);
    color: var(--e-global-color-6db9d6f);
    background-color: #37bfb500;
    border-style: solid;
    border-width: 2px 2px 2px 2px;
    border-color: var(--e-global-color-6db9d6f);
    border-radius: 31px 31px 31px 31px; }
  .page-template-elementor .elementor-element.elementor-element-eeabc16 .elementor-button:hover,
  .page-template-elementor .elementor-element.elementor-element-eeabc16 .elementor-button:focus {
    color: var(--e-global-color-accent);
    background-color: var(--e-global-color-6db9d6f); }
  .page-template-elementor .elementor-element.elementor-element-28e48c5 .elementor-button {
    font-family: "Work Sans", Sans-serif;
    font-size: 12px;
    font-weight: 700;
    line-height: 14px;
    fill: var(--e-global-color-1a88f45);
    color: var(--e-global-color-1a88f45);
    background-color: var(--e-global-color-6db9d6f);
    border-style: solid;
    border-width: 2px 2px 2px 2px;
    border-color: var(--e-global-color-6db9d6f);
    border-radius: 31px 31px 31px 31px; }
  .page-template-elementor .elementor-element.elementor-element-28e48c5 .elementor-button:hover,
  .page-template-elementor .elementor-element.elementor-element-28e48c5 .elementor-button:focus {
    color: var(--e-global-color-6db9d6f);
    background-color: #37bfb500; }
  .page-template-elementor .elementor-element.elementor-element-d574ea8 {
    background-color: var(--e-global-color-6db9d6f); }
  .page-template-elementor .elementor-element.elementor-element-68dd00a .elementor-button {
    font-family: "Work Sans", Sans-serif;
    font-size: 12px;
    font-weight: 700;
    line-height: 14px;
    fill: var(--e-global-color-primary);
    color: var(--e-global-color-primary);
    background-color: #ffffff00;
    border-style: solid;
    border-width: 2px 2px 2px 2px;
    border-color: var(--e-global-color-primary);
    border-radius: 31px 31px 31px 31px; }
  .page-template-elementor .elementor-element.elementor-element-68dd00a .elementor-button:hover,
  .page-template-elementor .elementor-element.elementor-element-68dd00a .elementor-button:focus {
    color: var(--e-global-color-6db9d6f);
    background-color: var(--e-global-color-primary); }
  .page-template-elementor .elementor-element.elementor-element-d6ba808 .elementor-button {
    font-family: "Work Sans", Sans-serif;
    font-size: 12px;
    font-weight: 700;
    line-height: 14px;
    fill: var(--e-global-color-6db9d6f);
    color: var(--e-global-color-6db9d6f);
    background-color: var(--e-global-color-primary);
    border-style: solid;
    border-width: 2px 2px 2px 2px;
    border-color: var(--e-global-color-primary);
    border-radius: 31px 31px 31px 31px; }
  .page-template-elementor .elementor-element.elementor-element-d6ba808 .elementor-button:hover,
  .page-template-elementor .elementor-element.elementor-element-d6ba808 .elementor-button:focus {
    color: var(--e-global-color-primary);
    background-color: #ffffff00; }
  .page-template-elementor .elementor-element.elementor-element-d73bcdf .elementor-button {
    font-family: "Work Sans", Sans-serif;
    font-size: 12px;
    font-weight: 700;
    line-height: 14px;
    fill: var(--e-global-color-secondary);
    color: var(--e-global-color-secondary);
    background-color: #ffffff00;
    border-style: solid;
    border-width: 2px 2px 2px 2px;
    border-color: var(--e-global-color-secondary);
    border-radius: 31px 31px 31px 31px; }
  .page-template-elementor .elementor-element.elementor-element-d73bcdf .elementor-button:hover,
  .page-template-elementor .elementor-element.elementor-element-d73bcdf .elementor-button:focus {
    color: var(--e-global-color-6db9d6f);
    background-color: var(--e-global-color-secondary); }
  .page-template-elementor .elementor-element.elementor-element-39ce5c6 .elementor-button {
    font-family: "Work Sans", Sans-serif;
    font-size: 12px;
    font-weight: 700;
    line-height: 14px;
    fill: var(--e-global-color-6db9d6f);
    color: var(--e-global-color-6db9d6f);
    background-color: var(--e-global-color-secondary);
    border-style: solid;
    border-width: 2px 2px 2px 2px;
    border-color: var(--e-global-color-secondary);
    border-radius: 31px 31px 31px 31px; }
  .page-template-elementor .elementor-element.elementor-element-39ce5c6 .elementor-button:hover,
  .page-template-elementor .elementor-element.elementor-element-39ce5c6 .elementor-button:focus {
    color: var(--e-global-color-secondary);
    background-color: #ffffff00; }
  .page-template-elementor .elementor-element.elementor-element-a93be93 .elementor-button {
    font-family: "Work Sans", Sans-serif;
    font-size: 12px;
    font-weight: 700;
    line-height: 14px;
    fill: var(--e-global-color-primary);
    color: var(--e-global-color-primary);
    background-color: #d7f2f000;
    border-style: solid;
    border-width: 2px 2px 2px 2px;
    border-color: var(--e-global-color-primary);
    border-radius: 31px 31px 31px 31px; }
  .page-template-elementor .elementor-element.elementor-element-a93be93 .elementor-button:hover,
  .page-template-elementor .elementor-element.elementor-element-a93be93 .elementor-button:focus {
    color: var(--e-global-color-primary);
    background-color: var(--e-global-color-6db9d6f); }
  .page-template-elementor .elementor-element.elementor-element-7fb8882 .elementor-button {
    font-family: "Work Sans", Sans-serif;
    font-size: 12px;
    font-weight: 700;
    line-height: 14px;
    fill: var(--e-global-color-secondary);
    color: var(--e-global-color-secondary);
    background-color: #d7f2f000;
    border-style: solid;
    border-width: 2px 2px 2px 2px;
    border-color: var(--e-global-color-secondary);
    border-radius: 31px 31px 31px 31px; }
  .page-template-elementor .elementor-element.elementor-element-7fb8882 .elementor-button:hover,
  .page-template-elementor .elementor-element.elementor-element-7fb8882 .elementor-button:focus {
    color: var(--e-global-color-secondary);
    background-color: var(--e-global-color-6db9d6f); }
  .page-template-elementor .elementor-element.elementor-element-f2534fb .elementor-button {
    font-family: "Work Sans", Sans-serif;
    font-size: 12px;
    font-weight: 700;
    line-height: 14px;
    fill: var(--e-global-color-accent);
    color: var(--e-global-color-accent);
    background-color: #d7f2f000;
    border-style: solid;
    border-width: 2px 2px 2px 2px;
    border-color: var(--e-global-color-accent);
    border-radius: 31px 31px 31px 31px; }
  .page-template-elementor .elementor-element.elementor-element-f2534fb .elementor-button:hover,
  .page-template-elementor .elementor-element.elementor-element-f2534fb .elementor-button:focus {
    color: var(--e-global-color-accent);
    background-color: var(--e-global-color-6db9d6f); }
  .page-template-elementor .elementor-element.elementor-element-63f2193 > .elementor-background-overlay {
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s; }
  .page-template-elementor .elementor-element.elementor-element-63f2193:not(.elementor-motion-effects-element-type-background),
  .page-template-elementor .elementor-element.elementor-element-63f2193 > .elementor-motion-effects-container > .elementor-motion-effects-layer {
    background-color: var(--e-global-color-6db9d6f); }
  .page-template-elementor .elementor-element.elementor-element-706472f > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 20px 20px 20px 20px;
    background-color: #d7f2f0; }
  .page-template-elementor .elementor-element.elementor-element-a2c50ee .elementor-alert-title {
    color: var(--e-global-color-primary);
    font-family: "Work Sans", Sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px; }
  .page-template-elementor .elementor-element.elementor-element-f096b59 .elementor-alert {
    background-color: var(--e-global-color-82019cb);
    border-color: var(--e-global-color-2ef249d);
    border-left-width: 5px; }
  .page-template-elementor .elementor-element.elementor-element-1724f1f .elementor-alert {
    background-color: var(--e-global-color-74e3cec);
    border-color: var(--e-global-color-accent);
    border-left-width: 5px; }
  .page-template-elementor .elementor-element.elementor-element-a2c50ee .elementor-alert button.elementor-alert-dismiss {
    position: absolute;
    right: var(--dismiss-icon-horizontal-position, 10px);
    top: var(--dismiss-icon-vertical-position, 10px);
    padding: 3px;
    font-size: var(--dismiss-icon-size, 20px);
    line-height: 1;
    background: transparent;
    color: var(--dismiss-icon-normal-color, inherit);
    border: none;
    cursor: pointer;
    transition-duration: var(--dismiss-icon-hover-transition-duration, 0.3s); }
  .page-template-elementor .elementor-element.elementor-element-1724f1f .elementor-alert button.elementor-alert-dismiss {
    position: absolute;
    right: var(--dismiss-icon-horizontal-position, 10px);
    top: var(--dismiss-icon-vertical-position, 10px);
    padding: 3px;
    font-size: var(--dismiss-icon-size, 20px);
    line-height: 1;
    background: transparent;
    color: var(--dismiss-icon-normal-color, inherit);
    border: none;
    cursor: pointer;
    transition-duration: var(--dismiss-icon-hover-transition-duration, 0.3s); }
  .page-template-elementor .elementor-element.elementor-element-1724f1f .elementor-alert button.elementor-alert-dismiss {
    position: absolute;
    right: var(--dismiss-icon-horizontal-position, 10px);
    top: var(--dismiss-icon-vertical-position, 10px);
    padding: 3px;
    font-size: var(--dismiss-icon-size, 20px);
    line-height: 1;
    background: transparent;
    color: var(--dismiss-icon-normal-color, inherit);
    border: none;
    cursor: pointer;
    transition-duration: var(--dismiss-icon-hover-transition-duration, 0.3s); }
  .page-template-elementor .elementor-element.elementor-element-7587645 > .elementor-widget-container {
    margin: 0;
    padding: 20px 20px 20px 20px;
    background-color: var(--e-global-color-6db9d6f); }
  .page-template-elementor .elementor-element.elementor-element-8e607cd .elementor-divider .elementor-divider-separator {
    border-top: var(--divider-border-width) var(--divider-border-style) var(--divider-color); }
  .page-template-elementor .elementor-element.elementor-element-8654859,
  .page-template-elementor .elementor-element.elementor-element-012661e,
  .page-template-elementor .elementor-element.elementor-element-8baa63f,
  .page-template-elementor .elementor-element.elementor-element-61d1fcb,
  .page-template-elementor .elementor-element.elementor-element-685d753,
  .page-template-elementor .elementor-element.elementor-element-0f9c1a8,
  .page-template-elementor .elementor-element.elementor-element-bb3dbdd,
  .page-template-elementor .elementor-element.elementor-element-04e920c,
  .page-template-elementor .elementor-element.elementor-element-b1165bb {
    font-family: var(--e-global-typography-0f47f69-font-family), Sans-serif;
    font-size: var(--e-global-typography-0f47f69-font-size);
    font-weight: var(--e-global-typography-0f47f69-font-weight);
    line-height: var(--e-global-typography-0f47f69-line-height);
    letter-spacing: var(--e-global-typography-0f47f69-letter-spacing);
    word-spacing: var(--e-global-typography-0f47f69-word-spacing); }
  .page-template-elementor .elementor-element.elementor-element-8970fe3:not(.elementor-motion-effects-element-type-background) > .elementor-widget-wrap,
  .page-template-elementor .elementor-element.elementor-element-8970fe3 > .elementor-widget-wrap > .elementor-motion-effects-container > .elementor-motion-effects-layer,
  .page-template-elementor .elementor-element.elementor-element-7fb16df:not(.elementor-motion-effects-element-type-background) > .elementor-widget-wrap,
  .page-template-elementor .elementor-element.elementor-element-7fb16df > .elementor-widget-wrap > .elementor-motion-effects-container > .elementor-motion-effects-layer,
  .page-template-elementor .elementor-element.elementor-element-32b0fb1:not(.elementor-motion-effects-element-type-background) > .elementor-widget-wrap,
  .page-template-elementor .elementor-element.elementor-element-32b0fb1 > .elementor-widget-wrap > .elementor-motion-effects-container > .elementor-motion-effects-layer,
  .page-template-elementor .elementor-element.elementor-element-0dd8135:not(.elementor-motion-effects-element-type-background) > .elementor-widget-wrap,
  .page-template-elementor .elementor-element.elementor-element-0dd8135 > .elementor-widget-wrap > .elementor-motion-effects-container > .elementor-motion-effects-layer,
  .page-template-elementor .elementor-element.elementor-element-fee20b2 > .elementor-element-populated,
  .page-template-elementor .elementor-element.elementor-element-089fd60 > .elementor-element-populated,
  .page-template-elementor .elementor-element.elementor-element-0804a8c > .elementor-element-populated,
  .page-template-elementor .elementor-element.elementor-element-2d96ecf .elementor-column-gap-custom .elementor-column > .elementor-element-populated,
  .page-template-elementor .elementor-element.elementor-element-81a43fc > .elementor-element-populated,
  .page-template-elementor .elementor-element.elementor-element-e2898e1 > .elementor-element-populated {
    background-color: #fff; }
  .page-template-elementor .elementor-element.elementor-element-65fd1f5:not(.elementor-motion-effects-element-type-background) > .elementor-widget-wrap,
  .page-template-elementor .elementor-element.elementor-element-65fd1f5 > .elementor-widget-wrap > .elementor-motion-effects-container > .elementor-motion-effects-layer,
  .page-template-elementor .elementor-element.elementor-element-d0c1afa:not(.elementor-motion-effects-element-type-background) > .elementor-widget-wrap,
  .page-template-elementor .elementor-element.elementor-element-d0c1afa > .elementor-widget-wrap > .elementor-motion-effects-container > .elementor-motion-effects-layer {
    background-color: var(--e-global-color-1a88f45); }
  .page-template-elementor .elementor-element.elementor-element-78bb0ab,
  .page-template-elementor .elementor-element.elementor-element-a3faf3f,
  .page-template-elementor .elementor-element.elementor-element-33ab284,
  .page-template-elementor .elementor-element.elementor-element-04e920c,
  .page-template-elementor .elementor-element.elementor-element-8654859,
  .page-template-elementor .elementor-element.elementor-element-667d23b,
  .page-template-elementor .elementor-element.elementor-element-d0b726c,
  .page-template-elementor .elementor-element.elementor-element-9f127f8,
  .page-template-elementor .elementor-element.elementor-element-6418f6a {
    color: var(--e-global-color-6db9d6f); }
  .page-template-elementor .elementor-element.elementor-element-7061acc > .elementor-widget-container {
    color: var(--e-global-color-6db9d6f); }
  .page-template-elementor .elementor-element.elementor-element-39a3d76 .elementor-icon-list-items.elementor-inline-items .elementor-icon-list-item:before,
  .page-template-elementor .elementor-element.elementor-element-fb9d01a .elementor-icon-list-items.elementor-inline-items .elementor-icon-list-item:before,
  .page-template-elementor .elementor-element.elementor-element-0f0bb56 .elementor-icon-list-items.elementor-inline-items .elementor-icon-list-item:before,
  .page-template-elementor .elementor-element.elementor-element-47e3882 .elementor-icon-list-items.elementor-inline-items .elementor-icon-list-item:before,
  .page-template-elementor .elementor-element.elementor-element-47e3882 .elementor-icon-list-items.elementor-inline-items .elementor-icon-list-item:before,
  .page-template-elementor .elementor-element.elementor-element-26102a3 .elementor-icon-list-items.elementor-inline-items .elementor-icon-list-item:before,
  .page-template-elementor .elementor-element.elementor-element-6ca352b .elementor-icon-list-items.elementor-inline-items .elementor-icon-list-item:before,
  .page-template-elementor .elementor-element.elementor-element-412dd2c .elementor-icon-list-items.elementor-inline-items .elementor-icon-list-item:before,
  .page-template-elementor .elementor-element.elementor-element-d465084 .elementor-icon-list-items.elementor-inline-items .elementor-icon-list-item:before,
  .page-template-elementor .elementor-element.elementor-element-de33c17 .elementor-icon-list-items.elementor-inline-items .elementor-icon-list-item:before {
    color: transparent; }
  .page-template-elementor .elementor-element.elementor-element-fb9d01a .elementor-icon-list-text,
  .page-template-elementor .elementor-element.elementor-element-6ca352b .elementor-icon-list-text,
  .page-template-elementor .elementor-element.elementor-element-412dd2c .elementor-icon-list-text,
  .page-template-elementor .elementor-element.elementor-element-0f0bb56 .elementor-icon-list-text {
    font-family: var(--e-global-typography-2942aeb-font-family), Sans-serif;
    font-size: var(--e-global-typography-2942aeb-font-size);
    font-weight: var(--e-global-typography-2942aeb-font-weight);
    text-transform: var(--e-global-typography-2942aeb-text-transform);
    line-height: var(--e-global-typography-2942aeb-line-height);
    letter-spacing: var(--e-global-typography-2942aeb-letter-spacing);
    word-spacing: var(--e-global-typography-2942aeb-word-spacing); }
  .page-template-elementor .elementor-element.elementor-element-39a3d76 .elementor-icon-list-item:not(:last-child):after,
  .page-template-elementor .elementor-element.elementor-element-47e3882 .elementor-icon-list-item:not(:last-child):after,
  .page-template-elementor .elementor-element.elementor-element-fb9d01a .elementor-icon-list-item:not(:last-child):after,
  .page-template-elementor .elementor-element.elementor-element-0f0bb56 .elementor-icon-list-item:not(:last-child):after,
  .page-template-elementor .elementor-element.elementor-element-26102a3 .elementor-icon-list-item:not(:last-child):after,
  .page-template-elementor .elementor-element.elementor-element-d465084 .elementor-icon-list-item:not(:last-child):after,
  .page-template-elementor .elementor-element.elementor-element-de33c17 .elementor-icon-list-item:not(:last-child):after {
    border-color: var(--e-global-color-74e3cec); }
  .page-template-elementor .elementor-element.elementor-element-39a3d76 .elementor-icon-list-icon i,
  .page-template-elementor .elementor-element.elementor-element-39a3d76 .elementor-icon-list-text,
  .page-template-elementor .elementor-element.elementor-element-fb9d01a .elementor-icon-list-icon i,
  .page-template-elementor .elementor-element.elementor-element-fb9d01a .elementor-icon-list-text,
  .page-template-elementor .elementor-element.elementor-element-0f0bb56 .elementor-icon-list-icon i,
  .page-template-elementor .elementor-element.elementor-element-0f0bb56 .elementor-icon-list-text,
  .page-template-elementor .elementor-element.elementor-element-47e3882 .elementor-icon-list-icon i,
  .page-template-elementor .elementor-element.elementor-element-47e3882 .elementor-icon-list-text,
  .page-template-elementor .elementor-element.elementor-element-5f30868 .elementor-icon-list-icon i,
  .page-template-elementor .elementor-element.elementor-element-5f30868 .elementor-icon-list-text,
  .page-template-elementor .elementor-element.elementor-element-6ca352b .elementor-icon-list-icon i,
  .page-template-elementor .elementor-element.elementor-element-6ca352b .elementor-icon-list-text,
  .page-template-elementor .elementor-element.elementor-element-26102a3 .elementor-icon-list-icon i,
  .page-template-elementor .elementor-element.elementor-element-26102a3 .elementor-icon-list-text,
  .page-template-elementor .elementor-element.elementor-element-d465084 .elementor-icon-list-icon i,
  .page-template-elementor .elementor-element.elementor-element-d465084 .elementor-icon-list-text,
  .page-template-elementor .elementor-element.elementor-element-de33c17 .elementor-icon-list-icon i,
  .page-template-elementor .elementor-element.elementor-element-de33c17 .elementor-icon-list-text {
    color: white; }
  .page-template-elementor .elementor-element.elementor-element-f25de74 .elementor-button,
  .page-template-elementor .elementor-element.elementor-element-2f798fd .elementor-button,
  .page-template-elementor .elementor-element.elementor-element-181611a .elementor-button,
  .page-template-elementor .elementor-element.elementor-element-659b72e .elementor-button,
  .page-template-elementor .elementor-element.elementor-element-8e21e75 .elementor-button,
  .page-template-elementor .elementor-element.elementor-element-1fa5685 .elementor-button,
  .page-template-elementor .elementor-element.elementor-element-ca1f697 .elementor-button {
    font-family: "Work Sans", Sans-serif;
    font-size: 12px;
    font-weight: 700;
    line-height: 14px;
    fill: var(--e-global-color-1a88f45);
    color: var(--e-global-color-1a88f45);
    background-color: var(--e-global-color-6db9d6f);
    border-style: solid;
    border-width: 2px 2px 2px 2px;
    border-color: var(--e-global-color-6db9d6f);
    border-radius: 31px 31px 31px 31px; }
    .page-template-elementor .elementor-element.elementor-element-f25de74 .elementor-button:hover, .page-template-elementor .elementor-element.elementor-element-f25de74 .elementor-button:focus,
    .page-template-elementor .elementor-element.elementor-element-2f798fd .elementor-button:hover,
    .page-template-elementor .elementor-element.elementor-element-2f798fd .elementor-button:focus,
    .page-template-elementor .elementor-element.elementor-element-181611a .elementor-button:hover,
    .page-template-elementor .elementor-element.elementor-element-181611a .elementor-button:focus,
    .page-template-elementor .elementor-element.elementor-element-659b72e .elementor-button:hover,
    .page-template-elementor .elementor-element.elementor-element-659b72e .elementor-button:focus,
    .page-template-elementor .elementor-element.elementor-element-8e21e75 .elementor-button:hover,
    .page-template-elementor .elementor-element.elementor-element-8e21e75 .elementor-button:focus,
    .page-template-elementor .elementor-element.elementor-element-1fa5685 .elementor-button:hover,
    .page-template-elementor .elementor-element.elementor-element-1fa5685 .elementor-button:focus,
    .page-template-elementor .elementor-element.elementor-element-ca1f697 .elementor-button:hover,
    .page-template-elementor .elementor-element.elementor-element-ca1f697 .elementor-button:focus {
      color: var(--e-global-color-6db9d6f); }
  .page-template-elementor .elementor-element.elementor-element-78bb0ab .elementor-title,
  .page-template-elementor .elementor-element.elementor-element-fe48926 .elementor-title,
  .page-template-elementor .elementor-element.elementor-element-ef06e5d .elementor-title,
  .page-template-elementor .elementor-element.elementor-element-4b270f1 .elementor-title,
  .page-template-elementor .elementor-element.elementor-element-9ddc152 .elementor-title,
  .page-template-elementor .elementor-element.elementor-element-b7da8e8 .elementor-title,
  .page-template-elementor .elementor-element.elementor-element-9a290d1 .elementor-title {
    font-family: var(--e-global-typography-f20730e-font-family), Sans-serif;
    font-size: var(--e-global-typography-f20730e-font-size);
    font-weight: var(--e-global-typography-f20730e-font-weight);
    line-height: var(--e-global-typography-f20730e-line-height);
    letter-spacing: var(--e-global-typography-f20730e-letter-spacing);
    word-spacing: var(--e-global-typography-f20730e-word-spacing); }
  .page-template-elementor .elementor-element.elementor-element-667d23b,
  .page-template-elementor .elementor-element.elementor-element-71fdef2,
  .page-template-elementor .elementor-element.elementor-element-4bc1bef,
  .page-template-elementor .elementor-element.elementor-element-b85d807,
  .page-template-elementor .elementor-element.elementor-element-1b2ecfa,
  .page-template-elementor .elementor-element.elementor-element-fc0e7fe,
  .page-template-elementor .elementor-element.elementor-element-90b31ca {
    font-weight: var(--e-global-typography-149b58e-font-weight); }
  .page-template-elementor .elementor-element.elementor-element-2b1b4f2 {
    --divider-color: var(--e-global-color-74e3cec); }
  .page-template-elementor .elementor-social-icon {
    --e-social-icon-icon-color: #fff;
    display: inline-flex;
    background-color: #69727d;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer; }
  .page-template-elementor .elementor-element.elementor-element-a942de0 .elementor-heading-title {
    font-family: var(--e-global-typography-26088f0-font-family), Sans-serif;
    font-size: var(--e-global-typography-26088f0-font-size);
    font-weight: var(--e-global-typography-26088f0-font-weight);
    line-height: var(--e-global-typography-26088f0-line-height);
    letter-spacing: var(--e-global-typography-26088f0-letter-spacing);
    word-spacing: var(--e-global-typography-26088f0-word-spacing); }
  .page-template-elementor .elementor-element.elementor-element-ec869f1 .elementor-heading-title,
  .page-template-elementor .elementor-element.elementor-element-f742e06 .elementor-heading-title,
  .page-template-elementor .elementor-element.elementor-element-9e4d242 .elementor-heading-title,
  .page-template-elementor .elementor-element.elementor-element-60b80b8 .elementor-heading-title {
    font-family: var(--e-global-typography-935d655-font-family), Sans-serif;
    font-size: var(--e-global-typography-935d655-font-size);
    font-weight: var(--e-global-typography-935d655-font-weight);
    line-height: var(--e-global-typography-935d655-line-height);
    letter-spacing: var(--e-global-typography-935d655-letter-spacing);
    word-spacing: var(--e-global-typography-935d655-word-spacing); }
  .page-template-elementor .elementor-element.elementor-element-7b056ce,
  .page-template-elementor .elementor-element.elementor-element-124110a,
  .page-template-elementor .elementor-element.elementor-element-5df08e8,
  .page-template-elementor .elementor-element.elementor-element-b40d9cc,
  .page-template-elementor .elementor-element.elementor-element-cb8af0d,
  .page-template-elementor .elementor-element.elementor-element-d19bfba,
  .page-template-elementor .elementor-element.elementor-element-a3ee66d,
  .page-template-elementor .elementor-element.elementor-element-6f61a39,
  .page-template-elementor .elementor-element.elementor-element-48541df,
  .page-template-elementor .elementor-element.elementor-element-0125a99,
  .page-template-elementor .elementor-element.elementor-element-ea33a97,
  .page-template-elementor .elementor-element.elementor-element-08b9efa {
    text-align: center;
    font-family: var(--e-global-typography-0e917a9-font-family), Sans-serif;
    font-size: var(--e-global-typography-0e917a9-font-size);
    font-weight: var(--e-global-typography-0e917a9-font-weight);
    line-height: var(--e-global-typography-0e917a9-line-height);
    letter-spacing: var(--e-global-typography-0e917a9-letter-spacing);
    word-spacing: var(--e-global-typography-0e917a9-word-spacing); }
  .page-template-elementor .elementor-element.elementor-element-46a5163:not(.elementor-motion-effects-element-type-background),
  .page-template-elementor .elementor-element.elementor-element-46a5163 > .elementor-motion-effects-container > .elementor-motion-effects-layer,
  .page-template-elementor .elementor-element.elementor-element-4a3b1dd:not(.elementor-motion-effects-element-type-background),
  .page-template-elementor .elementor-element.elementor-element-4a3b1dd > .elementor-motion-effects-container > .elementor-motion-effects-layer,
  .page-template-elementor .elementor-element.elementor-element-920d85d:not(.elementor-motion-effects-element-type-background),
  .page-template-elementor .elementor-element.elementor-element-920d85d > .elementor-motion-effects-container > .elementor-motion-effects-layer,
  .page-template-elementor .elementor-element.elementor-element-0e3c8e9:not(.elementor-motion-effects-element-type-background),
  .page-template-elementor .elementor-element.elementor-element-0e3c8e9 > .elementor-motion-effects-container > .elementor-motion-effects-layer {
    background-color: var(--e-global-color-1a88f45); }
  .page-template-elementor .elementor-element.elementor-element-147aeb6 .elementor-toggle-title {
    font-family: var(--e-global-typography-935d655-font-family), Sans-serif;
    font-size: var(--e-global-typography-935d655-font-size);
    font-weight: var(--e-global-typography-935d655-font-weight);
    line-height: var(--e-global-typography-935d655-line-height);
    letter-spacing: var(--e-global-typography-935d655-letter-spacing);
    word-spacing: var(--e-global-typography-935d655-word-spacing); }
  .page-template-elementor .elementor-element.elementor-element-5b87357 .elementor-heading-title {
    color: var(--e-global-color-6db9d6f);
    font-family: var(--e-global-typography-f20730e-font-family), Sans-serif;
    font-size: var(--e-global-typography-f20730e-font-size);
    font-weight: var(--e-global-typography-f20730e-font-weight);
    line-height: var(--e-global-typography-f20730e-line-height);
    letter-spacing: var(--e-global-typography-f20730e-letter-spacing);
    word-spacing: var(--e-global-typography-f20730e-word-spacing); }
  .page-template-elementor .elementor-element.elementor-element-8eb7aa7,
  .page-template-elementor .elementor-element.elementor-element-91be057 > .elementor-widget-container {
    color: #fff; }
  .page-template-elementor .elementor-element.elementor-element-b2cf3d4 .elementor-alert {
    background-color: var(--e-global-color-6db9d6f);
    border-color: var(--e-global-color-1a88f45);
    border-left-width: 5px; }
  .page-template-elementor .elementor-element.elementor-element-a2c50ee .elementor-alert button.elementor-alert-dismiss,
  .page-template-elementor .elementor-element.elementor-element-16236ab .elementor-alert button.elementor-alert-dismiss {
    color: var(--e-global-color-primary); }
  .page-template-elementor .elementor-element.elementor-element-f096b59 .elementor-alert button.elementor-alert-dismiss {
    color: var(--e-global-color-secondary); }
  .page-template-elementor .elementor-element.elementor-element-1724f1f .elementor-alert button.elementor-alert-dismiss,
  .page-template-elementor .elementor-element.elementor-element-b2cf3d4 .elementor-alert button.elementor-alert-dismiss {
    color: var(--e-global-color-accent); }

.elementor-star-rating .elementor-star-full, .elementor-star-rating .elementor-star-empty {
  width: 16px;
  font-size: 0; }
  .elementor-star-rating .elementor-star-full:before, .elementor-star-rating .elementor-star-empty:before {
    content: '★';
    font-size: 16px; }

.elementor-star-rating .elementor-star-empty:before {
  content: "☆" !important; }

.elementor-accordion-item.--open .elementor-tab-title .elementor-accordion-icon-closed {
  display: none !important; }

.elementor-accordion-item.--open .elementor-tab-title .elementor-accordion-icon-opened {
  display: block !important; }

.elementor-accordion-item.--open .elementor-tab-content {
  display: block; }

.elementor-toggle-item.--open .elementor-toggle-title, .elementor-toggle-item.--open .elementor-toggle-icon {
  color: white !important; }

.elementor-toggle-item.--open .elementor-tab-content {
  display: block; }

#single-startups {
  background-color: #D7F2F0; }

#single-programs {
  background-color: #D7F2F0; }

#page-news-single {
  background-color: #D7F2F0; }

.c-header-home {
  position: relative;
  z-index: 10; }
  .c-header-home .c-header-home__title {
    margin-top: 84px;
    color: #114D90;
    margin-bottom: 16px; }
  .c-header-home .c-header-home__text {
    margin-bottom: 32px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500; }
  .c-header-home .c-header-home__image {
    width: 100%;
    display: none; }
    @media (max-width: 991.98px) {
      .c-header-home .c-header-home__image {
        display: block; } }
  .c-header-home .c-header-home__container {
    margin-bottom: 240px; }
    @media (max-width: 991.98px) {
      .c-header-home .c-header-home__container {
        margin-bottom: 150px; } }
  .c-header-home .c-header-home__badge {
    margin-bottom: 100px;
    display: flex;
    align-items: center; }
    .c-header-home .c-header-home__badge .c-header-home__badge__text {
      margin-left: 10px;
      font-weight: 500; }
  .c-header-home .c-header-home__decoration {
    position: absolute;
    top: 0;
    right: 0;
    width: 582px;
    height: 100%;
    transform: translateY(-75px); }
    @media (max-width: 991.98px) {
      .c-header-home .c-header-home__decoration {
        display: none; } }
    .c-header-home .c-header-home__decoration .c-header-home__decoration__element {
      background-color: #A51C34;
      background-size: cover;
      position: absolute;
      height: 604px; }
      .c-header-home .c-header-home__decoration .c-header-home__decoration__element[data-index="1"] {
        width: 97px;
        right: 822px;
        top: 17px;
        background-position-x: 5px; }
      .c-header-home .c-header-home__decoration .c-header-home__decoration__element[data-index="2"] {
        width: 139px;
        right: 671px;
        background-position-x: -93px; }
      .c-header-home .c-header-home__decoration .c-header-home__decoration__element[data-index="3"] {
        width: 185px;
        top: 17px;
        right: 475px;
        background-position-x: -231px; }
      .c-header-home .c-header-home__decoration .c-header-home__decoration__element[data-index="4"] {
        width: 50px;
        right: 415px;
        background-position-x: -461px; }
      .c-header-home .c-header-home__decoration .c-header-home__decoration__element[data-index="5"] {
        width: 405px;
        top: 17px;
        right: 0;
        background-position-x: -521px; }

.c-header-portfolio {
  padding-top: 70px;
  padding-bottom: 70px; }
  @media (max-width: 991.98px) {
    .c-header-portfolio {
      padding-top: 20px;
      padding-bottom: 20px; } }
  .c-header-portfolio .c-header-portfolio__title {
    color: #114D90;
    margin-bottom: 20px; }

.c-header-startups .c-header-startups__back {
  position: relative;
  display: inline-flex;
  align-items: center;
  margin-bottom: 40px; }
  .c-header-startups .c-header-startups__back .c-header-startups__back__link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .c-header-startups .c-header-startups__back .c-header-startups__back__arrow {
    height: 40px;
    width: 40px;
    border: 1px solid #A51C34;
    margin-right: 20px;
    border-radius: 100%;
    transform: rotate(180deg);
    transition: border-color .2s ease-out; }
  .c-header-startups .c-header-startups__back .c-header-startups__back__text {
    text-transform: uppercase;
    color: #A51C34;
    transition: color .2s ease-out; }
  .c-header-startups .c-header-startups__back:hover .c-header-startups__back__text {
    color: #114D90; }
  .c-header-startups .c-header-startups__back:hover .c-header-startups__back__arrow {
    border-color: #114D90; }
  .c-header-startups .c-header-startups__back:hover .long-arrow-right {
    border-color: #114D90; }
    .c-header-startups .c-header-startups__back:hover .long-arrow-right:after {
      background-color: #114D90; }

.c-header-startups .c-header-startups__title {
  color: #114D90;
  padding-bottom: 150px; }
  @media (max-width: 1274.98px) {
    .c-header-startups .c-header-startups__title {
      padding-bottom: 40px; } }

.c-header-startups .c-header-startups__info {
  position: relative;
  padding-bottom: 20px; }
  .c-header-startups .c-header-startups__info .c-header-startups__info__logo {
    height: 400px;
    background-color: #000000;
    position: relative;
    z-index: 10;
    transform: translateY(-96px);
    background-size: cover;
    background-position: center; }
    @media (max-width: 1274.98px) {
      .c-header-startups .c-header-startups__info .c-header-startups__info__logo {
        transform: translateY(0); } }
    @media (max-width: 767.98px) {
      .c-header-startups .c-header-startups__info .c-header-startups__info__logo {
        height: 200px; } }
  .c-header-startups .c-header-startups__info .c-header-startups__info__data {
    height: 420px;
    background-color: #37BFB5;
    position: absolute;
    top: 0; }
    @media (max-width: 1274.98px) {
      .c-header-startups .c-header-startups__info .c-header-startups__info__data {
        position: relative; } }
    @media (max-width: 767.98px) {
      .c-header-startups .c-header-startups__info .c-header-startups__info__data {
        height: auto; } }
    .c-header-startups .c-header-startups__info .c-header-startups__info__data .c-header-startups__info__data__website {
      position: absolute;
      bottom: 32px;
      left: 72px; }
      @media (max-width: 1274.98px) {
        .c-header-startups .c-header-startups__info .c-header-startups__info__data .c-header-startups__info__data__website {
          top: 35px;
          bottom: auto;
          right: 12px;
          left: auto;
          max-width: 200px; } }
      @media (max-width: 767.98px) {
        .c-header-startups .c-header-startups__info .c-header-startups__info__data .c-header-startups__info__data__website {
          padding-left: 14px;
          max-width: 180px; }
          .c-header-startups .c-header-startups__info .c-header-startups__info__data .c-header-startups__info__data__website .btn__text {
            margin-right: 14px; } }
    .c-header-startups .c-header-startups__info .c-header-startups__info__data .c-header-startups__info__data__taxonomies {
      position: relative;
      padding-top: 40px;
      left: 330px;
      width: calc(100% - 330px);
      height: 100%;
      color: #FFFFFF; }
      .c-header-startups .c-header-startups__info .c-header-startups__info__data .c-header-startups__info__data__taxonomies::after, .c-header-startups .c-header-startups__info .c-header-startups__info__data .c-header-startups__info__data__taxonomies::before {
        content: "";
        position: absolute;
        width: 1px;
        height: 100px;
        left: -24px;
        top: 0; }
      .c-header-startups .c-header-startups__info .c-header-startups__info__data .c-header-startups__info__data__taxonomies::before {
        background-color: #37BFB5;
        transform: translateY(-100%); }
      .c-header-startups .c-header-startups__info .c-header-startups__info__data .c-header-startups__info__data__taxonomies::after {
        background-color: #FFFFFF;
        transform: translateY(0%); }
      @media (max-width: 1274.98px) {
        .c-header-startups .c-header-startups__info .c-header-startups__info__data .c-header-startups__info__data__taxonomies {
          width: auto;
          left: 0;
          padding-bottom: 40px; } }
      @media (max-width: 767.98px) {
        .c-header-startups .c-header-startups__info .c-header-startups__info__data .c-header-startups__info__data__taxonomies {
          padding-bottom: 150px; } }
      .c-header-startups .c-header-startups__info .c-header-startups__info__data .c-header-startups__info__data__taxonomies .c-header-startups__info__data__taxonomies__top {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 28px; }
        @media (max-width: 1274.98px) {
          .c-header-startups .c-header-startups__info .c-header-startups__info__data .c-header-startups__info__data__taxonomies .c-header-startups__info__data__taxonomies__top {
            width: calc(100% - 200px); } }
        .c-header-startups .c-header-startups__info .c-header-startups__info__data .c-header-startups__info__data__taxonomies .c-header-startups__info__data__taxonomies__top .c-header-startups__info__data__taxonomies__top__element {
          color: #FFFFFF;
          border: 1px solid #FFFFFF;
          border-radius: 20px;
          text-transform: uppercase;
          font-size: 12px;
          line-height: 24px;
          padding: 8px 24px;
          margin-right: 10px;
          margin-bottom: 5px; }
      .c-header-startups .c-header-startups__info .c-header-startups__info__data .c-header-startups__info__data__taxonomies .c-header-startups__info__data__taxonomies__body {
        display: flex;
        flex-wrap: wrap; }
        .c-header-startups .c-header-startups__info .c-header-startups__info__data .c-header-startups__info__data__taxonomies .c-header-startups__info__data__taxonomies__body .c-header-startups__info__data__taxonomies__body__element {
          margin-bottom: 30px;
          width: 50%; }
          .c-header-startups .c-header-startups__info .c-header-startups__info__data .c-header-startups__info__data__taxonomies .c-header-startups__info__data__taxonomies__body .c-header-startups__info__data__taxonomies__body__element .c-header-startups__info__data__taxonomies__body__element__title {
            font-size: 12px;
            line-height: 24px;
            letter-spacing: 2px;
            margin-bottom: 5px;
            font-family: "Work Sans", sans-serif;
            text-transform: uppercase; }
      .c-header-startups .c-header-startups__info .c-header-startups__info__data .c-header-startups__info__data__taxonomies .c-header-startups__info__data__taxonomies__bottom {
        position: absolute;
        bottom: 0;
        transform: translateY(40px); }
        .c-header-startups .c-header-startups__info .c-header-startups__info__data .c-header-startups__info__data__taxonomies .c-header-startups__info__data__taxonomies__bottom .c-header-startups__info__data__taxonomies__bottom__title {
          font-size: 24px;
          line-height: 28px;
          margin-bottom: 24px;
          font-family: "Work Sans", sans-serif; }
        .c-header-startups .c-header-startups__info .c-header-startups__info__data .c-header-startups__info__data__taxonomies .c-header-startups__info__data__taxonomies__bottom .c-header-startups__info__data__taxonomies__bottom__image-container {
          display: flex; }
        .c-header-startups .c-header-startups__info .c-header-startups__info__data .c-header-startups__info__data__taxonomies .c-header-startups__info__data__taxonomies__bottom .c-header-startups__info__data__taxonomies__bottom__image {
          width: 86px;
          height: 112px;
          background-color: #000000;
          background-size: cover;
          background-position: center; }
          .c-header-startups .c-header-startups__info .c-header-startups__info__data .c-header-startups__info__data__taxonomies .c-header-startups__info__data__taxonomies__bottom .c-header-startups__info__data__taxonomies__bottom__image:not(:last-child) {
            margin-right: 24px; }
    .c-header-startups .c-header-startups__info .c-header-startups__info__data .c-header-startups__info__data__deco {
      background-color: #000000;
      height: 420px;
      width: 420px;
      position: absolute;
      top: 0;
      right: 0;
      transform: translateX(100%);
      background-size: cover;
      background-position: center; }
      @media (max-width: 1274.98px) {
        .c-header-startups .c-header-startups__info .c-header-startups__info__data .c-header-startups__info__data__deco {
          display: none; } }

.c-header-scaling {
  padding-bottom: 120px;
  padding-top: 80px; }
  @media (max-width: 1274.98px) {
    .c-header-scaling {
      padding-top: 0; } }
  .c-header-scaling .c-header-scaling__title {
    margin-bottom: 32px;
    color: #114D90; }
  .c-header-scaling .c-header-scaling__decoration {
    position: absolute;
    top: 80px;
    right: 0;
    height: 424px;
    width: 435px;
    background-position: center;
    background-size: cover; }
    @media (max-width: 1274.98px) {
      .c-header-scaling .c-header-scaling__decoration {
        display: none; } }

.c-header-team {
  padding-top: 70px;
  padding-bottom: 64px; }
  .c-header-team .c-header-team__title {
    color: #114D90;
    margin-bottom: 32px; }

.c-header-news {
  margin-bottom: 80px; }
  .c-header-news .c-header-news__back {
    position: relative;
    display: inline-flex;
    align-items: center;
    margin-bottom: 104px; }
    .c-header-news .c-header-news__back .c-header-news__back__link {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }
    .c-header-news .c-header-news__back .c-header-news__back__arrow {
      height: 40px;
      width: 40px;
      border: 1px solid #A51C34;
      margin-right: 20px;
      border-radius: 100%;
      transform: rotate(180deg);
      transition: border-color .2s ease-out; }
    .c-header-news .c-header-news__back .c-header-news__back__text {
      text-transform: uppercase;
      color: #A51C34;
      transition: color .2s ease-out; }
    .c-header-news .c-header-news__back:hover .c-header-news__back__text {
      color: #114D90; }
    .c-header-news .c-header-news__back:hover .c-header-news__back__arrow {
      border-color: #114D90; }
    .c-header-news .c-header-news__back:hover .long-arrow-right {
      border-color: #114D90; }
      .c-header-news .c-header-news__back:hover .long-arrow-right:after {
        background-color: #114D90; }
  .c-header-news .c-header-news__content-cover {
    height: 100%;
    width: 2000vw;
    position: absolute;
    top: 0;
    right: 0;
    background-color: #37BFB5; }
  .c-header-news .c-header-news__content-image {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
    background-color: #000000;
    height: 680px;
    width: 470px;
    background-size: cover;
    background-position: center; }
    @media (max-width: 1274.98px) {
      .c-header-news .c-header-news__content-image {
        display: none; } }
  .c-header-news .c-header-news__content {
    position: relative;
    background-color: #37BFB5;
    padding-bottom: 142px;
    color: #FFFFFF; }
    .c-header-news .c-header-news__content .c-header-news__content__top {
      display: flex;
      margin-bottom: 40px; }
      .c-header-news .c-header-news__content .c-header-news__content__top .c-header-news__content__top__terms {
        padding-top: 32px; }
        .c-header-news .c-header-news__content .c-header-news__content__top .c-header-news__content__top__terms .c-header-news__content__top__terms__element {
          color: #FFFFFF;
          border: 1px solid #FFFFFF;
          border-radius: 20px;
          text-transform: uppercase;
          font-size: 12px;
          line-height: 24px;
          padding: 8px 24px;
          margin-right: 50px;
          margin-bottom: 10px; }
      .c-header-news .c-header-news__content .c-header-news__content__top .c-header-news__content__top__separator {
        height: 100px;
        background-color: #FFFFFF;
        width: 1px; }
        .c-header-news .c-header-news__content .c-header-news__content__top .c-header-news__content__top__separator::before {
          content: "";
          position: absolute;
          height: 100px;
          transform: translateY(-100%);
          width: 1px;
          background-color: #37BFB5; }
      .c-header-news .c-header-news__content .c-header-news__content__top .c-header-news__content__top__data {
        padding-top: 32px;
        margin-left: 50px; }
        .c-header-news .c-header-news__content .c-header-news__content__top .c-header-news__content__top__data .c-header-news__content__top__data__author {
          font-size: 24px;
          line-height: 28px;
          font-style: italic;
          margin-bottom: 6px;
          max-width: 400px; }
    .c-header-news .c-header-news__content .c-header-news__content__title {
      max-width: 800px;
      margin-bottom: 24px;
      font-size: 60px;
      line-height: 68px; }
    .c-header-news .c-header-news__content .c-header-news__content__text {
      max-width: 750px; }

.c-header-content-list {
  padding-top: 70px;
  padding-bottom: 80px; }
  .c-header-content-list .c-header-content-list__title {
    margin-bottom: 20px;
    color: #114D90; }
  .c-header-content-list .c-header-content-list__text {
    max-width: 750px; }

.c-header-jobs {
  padding-top: 88px;
  margin-top: 32px;
  padding-bottom: 80px; }
  .c-header-jobs .c-header-jobs__image-container {
    position: absolute;
    top: -88px;
    left: 0;
    width: 352px;
    height: 361px;
    background-color: #114D90;
    background-size: cover;
    background-position: center; }
  .c-header-jobs .c-header-jobs__content {
    position: relative;
    padding-top: 80px;
    padding-bottom: 120px;
    padding-left: 165px;
    color: #FFFFFF;
    background-color: #37BFB5; }
    @media (max-width: 991.98px) {
      .c-header-jobs .c-header-jobs__content {
        padding-left: 10px;
        padding-right: 10px; } }
    .c-header-jobs .c-header-jobs__content .c-header-jobs__content__title {
      margin-bottom: 20px; }
    .c-header-jobs .c-header-jobs__content .c-header-jobs__content__text {
      max-width: 640px; }
    .c-header-jobs .c-header-jobs__content::after, .c-header-jobs .c-header-jobs__content::before {
      content: "";
      position: absolute;
      width: 1px;
      height: 80px;
      left: 160px;
      bottom: 0; }
    .c-header-jobs .c-header-jobs__content::before {
      background-color: #FFFFFF; }
    .c-header-jobs .c-header-jobs__content::after {
      background-color: #37BFB5;
      transform: translateY(100%); }

.c-header-faq {
  position: relative;
  padding-top: 70px; }
  .c-header-faq .c-header-faq__image-container {
    position: absolute;
    right: 0;
    top: 0;
    width: 323px;
    height: 806px;
    background-color: #114D90; }
    @media (max-width: 991.98px) {
      .c-header-faq .c-header-faq__image-container {
        display: none; } }
  .c-header-faq .c-header-faq__content .c-header-faq__content__title {
    color: #114D90;
    margin-bottom: 20px;
    max-width: 660px; }
  .c-header-faq .c-header-faq__content .c-header-faq__content__text {
    max-width: 750px; }

.c-header-manifesto {
  position: relative;
  z-index: 2;
  padding-top: 70px; }
  .c-header-manifesto .c-header-manifesto__title {
    margin-bottom: 32px;
    color: #114D90; }
  .c-header-manifesto .c-header-manifesto__text {
    margin-bottom: 56px;
    max-width: 750px; }
  .c-header-manifesto .c-header-manifesto__image {
    max-width: 100%; }

.c-header-pitch {
  margin-top: 60px; }
  .c-header-pitch .c-header-pitch__title {
    color: #114D90;
    margin-bottom: 16px; }
  .c-header-pitch .c-header-pitch__text {
    max-width: 640px;
    padding-bottom: 80px; }

.c-header-contact {
  padding-top: 70px;
  padding-bottom: 80px; }
  .c-header-contact .c-header-contact__title {
    color: #114D90;
    margin-bottom: 16px; }
  .c-header-contact .c-header-contact__text {
    max-width: 640px; }

.c-card-passion {
  position: relative; }
  @media (max-width: 991.98px) {
    .c-card-passion {
      padding-bottom: 40px; } }
  .c-card-passion .c-card-passion__link {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .c-card-passion .c-card-passion__top {
    position: relative;
    margin-bottom: 16px;
    height: 195px;
    width: 100%;
    overflow: hidden; }
    @media (max-width: 991.98px) {
      .c-card-passion .c-card-passion__top {
        height: 260px; } }
    .c-card-passion .c-card-passion__top .c-card-passion__top__cover {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: #000000;
      opacity: .2;
      transition: opacity .2s ease-out; }
    .c-card-passion .c-card-passion__top .c-card-passion__top__image {
      height: 100%;
      width: 100%;
      background-size: cover;
      background-position: center; }
  .c-card-passion .c-card-passion__bottom {
    color: #FFFFFF;
    font-weight: 500;
    height: 234px;
    position: relative; }
    @media (max-width: 991.98px) {
      .c-card-passion .c-card-passion__bottom {
        height: 210px; } }
    .c-card-passion .c-card-passion__bottom .c-card-passion__bottom__title {
      font-size: 30px;
      line-height: 35px;
      font-family: "Work Sans", sans-serif;
      margin-bottom: 16px; }
    .c-card-passion .c-card-passion__bottom .c-card-passion__bottom__text {
      font-size: 16px;
      line-height: 24px; }
    .c-card-passion .c-card-passion__bottom .c-card-passion__bottom__arrow {
      background-color: transparent;
      border: 1px solid #FFFFFF;
      border-radius: 100%;
      width: 40px;
      height: 40px;
      position: absolute;
      bottom: 10px;
      transition: background-color .2s ease-out; }
    .c-card-passion .c-card-passion__bottom .c-card-passion__bottom__line {
      position: absolute;
      height: 2px;
      width: 100%;
      bottom: -2px;
      background-color: #FFFFFF;
      transform: scaleX(0);
      transform-origin: left;
      transition: transform .2s ease-out; }
  .c-card-passion:hover {
    border-color: #FFFFFF; }
    .c-card-passion:hover .c-card-passion__top__cover {
      opacity: .1; }
    .c-card-passion:hover .c-card-passion__bottom__arrow {
      background-color: #FFFFFF; }
      .c-card-passion:hover .c-card-passion__bottom__arrow .long-arrow-right {
        border-color: #37BFB5; }
        .c-card-passion:hover .c-card-passion__bottom__arrow .long-arrow-right:after {
          background-color: #37BFB5; }
    .c-card-passion:hover .c-card-passion__bottom__line {
      transform: scaleX(1); }

.c-card-news {
  position: relative;
  height: 288px;
  margin-bottom: 24px;
  overflow: hidden;
  background-size: cover;
  background-position: center; }
  .c-card-news--big {
    height: 600px;
    margin-bottom: 0; }
    @media (max-width: 1274.98px) {
      .c-card-news--big {
        margin-bottom: 24px; } }
    @media (max-width: 991.98px) {
      .c-card-news--big {
        height: 288px; } }
  .c-card-news .c-card-news__image,
  .c-card-news .c-card-news__cover,
  .c-card-news .c-card-news__link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .c-card-news .c-card-news__image {
    background-size: cover;
    background-position: center;
    transition: transform .5s ease-out; }
  .c-card-news .c-card-news__cover {
    background-color: #000000;
    opacity: .5;
    transition: opacity .2s linear; }
  .c-card-news .c-card-news__content {
    position: absolute;
    color: #FFFFFF;
    width: calc(100% - 60px);
    bottom: 30px;
    left: 30px; }
    .c-card-news .c-card-news__content .c-card-news__content__date {
      font-weight: 500;
      font-size: 16px;
      margin-bottom: 8px; }
    .c-card-news .c-card-news__content .c-card-news__content__title {
      font-size: 24px;
      line-height: 28px;
      font-family: "Work Sans", sans-serif;
      margin-bottom: 8px; }
    .c-card-news .c-card-news__content .c-card-news__content__intro {
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
      margin-bottom: 16px; }
    .c-card-news .c-card-news__content .c-card-news__content__arrow {
      height: 40px;
      width: 40px;
      background-color: #FFFFFF;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center; }
  .c-card-news:hover .c-card-news__cover {
    opacity: .2; }
  .c-card-news:hover .c-card-news__image {
    transform: scale(1.01); }
  .c-card-news:hover .c-card-news__content__arrow {
    background-color: #A51C34; }
    .c-card-news:hover .c-card-news__content__arrow .long-arrow-right--red {
      border-color: #FFFFFF; }
      .c-card-news:hover .c-card-news__content__arrow .long-arrow-right--red:after {
        background-color: #FFFFFF; }

.c-card-team .c-card-team__line {
  position: absolute;
  bottom: 65px;
  left: 30px;
  width: 1px;
  height: 140px;
  background-color: #37BFB5; }

.c-card-team .c-card-team__line-green,
.c-card-team .c-card-team__line-white {
  display: none; }

.c-card-team .c-card-team__image {
  width: 100%;
  height: 400px;
  background-size: cover;
  background-position: center;
  margin-bottom: 24px; }
  .c-card-team .c-card-team__image .c-card-team__image__hover {
    height: 100%;
    width: 100%;
    background-color: #FFFFFF;
    position: absolute;
    top: 0;
    left: 0;
    color: #37BFB5;
    padding: 32px 32px;
    opacity: 0;
    transition: opacity .5s ease-out; }

.c-card-team .c-card-team__info {
  padding-left: 56px; }
  .c-card-team .c-card-team__info .c-card-team__info__name {
    color: #37BFB5;
    font-size: 30px;
    font-weight: 500;
    line-height: 35px;
    margin-bottom: 4px; }
  .c-card-team .c-card-team__info .c-card-team__info__job {
    margin-bottom: 12px; }
  .c-card-team .c-card-team__info .c-card-team__info__socials {
    display: flex; }
    .c-card-team .c-card-team__info .c-card-team__info__socials a {
      border-radius: 100%;
      border: 2px solid #000000;
      display: flex;
      height: 36px;
      width: 35px;
      background-color: #000000; }
      .c-card-team .c-card-team__info .c-card-team__info__socials a img {
        width: 100%;
        transition: filter .5s ease-out; }
      .c-card-team .c-card-team__info .c-card-team__info__socials a:not(:last-child) {
        margin-right: 16px; }
      .c-card-team .c-card-team__info .c-card-team__info__socials a:hover img {
        filter: invert(1); }

.c-card-team.active .c-card-team__image .c-card-team__image__hover, .c-card-team:hover .c-card-team__image .c-card-team__image__hover {
  opacity: .9; }

.c-card-team.active .c-card-team__line-green, .c-card-team:hover .c-card-team__line-green {
  height: 20px; }

.c-card-team .c-card-team__line-green {
  position: absolute;
  bottom: 0;
  left: 30px;
  width: 1px;
  height: 70px;
  background-color: #37BFB5;
  display: block;
  transition: height .5s ease-out; }

.c-card-team .c-card-team__line-white {
  position: absolute;
  top: -24px;
  left: 30px;
  width: 1px;
  height: 70px;
  background-color: #FFFFFF;
  display: block; }

.c-card-team .c-card-team__line {
  display: none; }

.c-card-team--alternate .c-card-team__info .c-card-team__info__job,
.c-card-team--alternate .c-card-team__info .c-card-team__info__name {
  color: #FFFFFF; }

.c-card-program {
  height: 100%;
  position: relative;
  padding-top: 200px; }
  .c-card-program .c-card-program__link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1; }
  .c-card-program .c-card-program__image {
    position: absolute;
    left: 0;
    top: 0;
    width: calc(100% - 54px);
    height: 262px;
    background-position: center;
    background-size: cover; }
    @media (max-width: 991.98px) {
      .c-card-program .c-card-program__image {
        width: 100%; } }
  .c-card-program .c-card-program__info {
    position: relative;
    margin-left: 55px;
    width: calc(416px - 110px);
    height: calc(326px - 80px);
    background-color: #37BFB5;
    padding: 40px 55px;
    color: #FFFFFF;
    transition: color .2s ease-out, background-color .2s ease-out; }
    @media (max-width: 991.98px) {
      .c-card-program .c-card-program__info {
        margin-left: 0;
        width: 230px;
        height: calc(390px - 80px);
        padding: 10px; } }
    .c-card-program .c-card-program__info .c-card-program__info__terms {
      margin-bottom: 22px;
      display: flex;
      flex-wrap: wrap; }
      .c-card-program .c-card-program__info .c-card-program__info__terms .c-card-program__info__terms__element {
        color: #FFFFFF;
        border: 1px solid #FFFFFF;
        border-radius: 20px;
        text-transform: uppercase;
        font-size: 12px;
        line-height: 24px;
        padding: 8px 24px;
        margin-right: 10px;
        margin-bottom: 10px; }
    .c-card-program .c-card-program__info .c-card-program__info__title {
      font-size: 24px;
      line-height: 28px;
      margin-bottom: 16px; }
    .c-card-program .c-card-program__info .c-card-program__info__text {
      margin-bottom: 52px; }
    .c-card-program .c-card-program__info .c-card-program__info__arrow {
      position: absolute;
      bottom: 40px;
      left: 55px;
      height: 40px;
      width: 40px;
      background-color: #FFFFFF;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      border: 2px solid #FFFFFF;
      transition: background-color #FFFFFF 0.2s ease-out; }
      @media (max-width: 991.98px) {
        .c-card-program .c-card-program__info .c-card-program__info__arrow {
          left: 10px; } }
  .c-card-program:hover .c-card-program__info {
    background-color: #FFFFFF;
    color: #37BFB5; }
    .c-card-program:hover .c-card-program__info .c-card-program__info__terms__element {
      color: #37BFB5;
      border-color: #37BFB5; }
  .c-card-program:hover .c-card-program__info__arrow {
    background-color: #FFFFFF;
    border-color: #37BFB5; }
    .c-card-program:hover .c-card-program__info__arrow .long-arrow-right {
      border-color: #37BFB5; }
      .c-card-program:hover .c-card-program__info__arrow .long-arrow-right:after {
        background-color: #37BFB5; }
  .c-card-program--no-slide .c-card-program__info {
    height: auto; }
  .c-card-program--no-slide:hover .c-card-program__info {
    background-color: #D7F2F0;
    color: #37BFB5; }

.c-section-passion {
  position: relative; }
  @media (max-width: 1274.98px) {
    .c-section-passion {
      background-color: #37BFB5; } }
  .c-section-passion .c-section-passion__line::after, .c-section-passion .c-section-passion__line::before {
    content: '';
    height: 80px;
    width: 1px;
    position: absolute; }
  .c-section-passion .c-section-passion__line--bottom::after, .c-section-passion .c-section-passion__line--bottom::before {
    bottom: 0;
    left: 50%; }
  .c-section-passion .c-section-passion__line--bottom::after {
    background-color: #FFFFFF; }
  .c-section-passion .c-section-passion__line--bottom::before {
    background-color: #37BFB5;
    transform: translateY(100%); }
  .c-section-passion .c-section-passion__line--top span {
    position: absolute;
    top: -60px;
    left: 65px;
    color: #37BFB5;
    text-transform: uppercase;
    font-size: 10px; }
  .c-section-passion .c-section-passion__line--top__green, .c-section-passion .c-section-passion__line--top__white {
    height: 80px;
    width: 1px;
    position: absolute;
    top: 0;
    left: 55px;
    transform: scaleY(0); }
  .c-section-passion .c-section-passion__line--top__green {
    background-color: #37BFB5;
    top: -80px;
    transform-origin: top;
    animation: grow_green 4s linear infinite; }
  .c-section-passion .c-section-passion__line--top__white {
    background-color: #FFFFFF;
    z-index: 2;
    transform-origin: top;
    animation: grow_white 4s linear infinite; }

@keyframes grow_green {
  0% {
    transform: scaleY(0); }
  50% {
    transform: scaleY(1); }
  100% {
    transform: scaleY(1); } }

@keyframes grow_white {
  0% {
    transform: scaleY(0); }
  50% {
    transform: scaleY(0); }
  100% {
    transform: scaleY(1); } }
  .c-section-passion .c-section-passion__content {
    position: relative;
    padding-top: 120px;
    padding-bottom: 120px; }
    @media (max-width: 991.98px) {
      .c-section-passion .c-section-passion__content {
        padding-top: 100px;
        padding-bottom: 50px; } }
    .c-section-passion .c-section-passion__content .c-section-passion__content__background {
      position: absolute;
      height: 100%;
      width: 100vw;
      left: 0;
      top: 0;
      background-color: #37BFB5; }
    .c-section-passion .c-section-passion__content .c-section-passion__content__title {
      color: #FFFFFF;
      margin-bottom: 64px; }

.c-section-family {
  padding: 120px 0; }
  @media (max-width: 991.98px) {
    .c-section-family {
      padding: 100px 0; } }
  .c-section-family .c-section-family__title {
    color: #114D90;
    margin-bottom: 24px; }
  .c-section-family .c-section-family__slider {
    position: relative;
    margin-top: 86px; }
    .c-section-family .c-section-family__slider .swiper-container {
      position: relative;
      overflow: unset !important; }
      @media (max-width: 1274.98px) {
        .c-section-family .c-section-family__slider .swiper-container {
          overflow: hidden !important; } }
      .c-section-family .c-section-family__slider .swiper-container:after {
        content: '';
        height: 100%;
        width: 100vw;
        background-color: #FFFFFF;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 2;
        transform: translateX(-100%); }
    .c-section-family .c-section-family__slider .c-section-family__slider__slide {
      height: 330px;
      background-size: cover;
      background-position: center;
      position: relative; }
      .c-section-family .c-section-family__slider .c-section-family__slider__slide .c-section-family__slider__slide__cover {
        position: absolute;
        top: 0;
        left: 0;
        background-color: #114D90;
        opacity: .5;
        width: 100%;
        height: 100%; }
      .c-section-family .c-section-family__slider .c-section-family__slider__slide .c-section-family__slider__slide__image {
        position: absolute;
        left: 50%;
        bottom: 44px;
        transform: translate(-50%); }
      .c-section-family .c-section-family__slider .c-section-family__slider__slide .c-section-family__slider__slide__hover {
        opacity: 0;
        visibility: hidden;
        position: absolute;
        top: 0;
        left: 0;
        background-color: #37BFB5;
        width: calc(100% - 84px);
        height: calc(100% - 80px);
        transition: visibility .5s ease-out, opacity .5s ease-out;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        padding: 40px 42px; }
        .c-section-family .c-section-family__slider .c-section-family__slider__slide .c-section-family__slider__slide__hover .c-section-family__slider__slide__hover__image {
          margin-bottom: 32px;
          display: none; }
        .c-section-family .c-section-family__slider .c-section-family__slider__slide .c-section-family__slider__slide__hover .c-section-family__slider__slide__hover__text {
          color: #FFFFFF;
          text-align: left;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px; }
        .c-section-family .c-section-family__slider .c-section-family__slider__slide .c-section-family__slider__slide__hover.active {
          opacity: 1;
          visibility: visible; }
    .c-section-family .c-section-family__slider .c-section-family__slider__nav {
      position: absolute;
      bottom: 0;
      right: 0;
      display: flex;
      z-index: 2;
      transform: translateY(50%); }
      .c-section-family .c-section-family__slider .c-section-family__slider__nav .c-section-family__slider__nav__prev,
      .c-section-family .c-section-family__slider .c-section-family__slider__nav .c-section-family__slider__nav__next {
        height: 64px;
        width: 64px;
        background-color: #A51C34;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border: 2px solid #A51C34;
        transition: background-color #FFFFFF 0.2s ease-out; }
        .c-section-family .c-section-family__slider .c-section-family__slider__nav .c-section-family__slider__nav__prev:hover,
        .c-section-family .c-section-family__slider .c-section-family__slider__nav .c-section-family__slider__nav__next:hover {
          background-color: #FFFFFF; }
          .c-section-family .c-section-family__slider .c-section-family__slider__nav .c-section-family__slider__nav__prev:hover .long-arrow-right,
          .c-section-family .c-section-family__slider .c-section-family__slider__nav .c-section-family__slider__nav__next:hover .long-arrow-right {
            border-color: #A51C34; }
            .c-section-family .c-section-family__slider .c-section-family__slider__nav .c-section-family__slider__nav__prev:hover .long-arrow-right:after,
            .c-section-family .c-section-family__slider .c-section-family__slider__nav .c-section-family__slider__nav__next:hover .long-arrow-right:after {
              background-color: #A51C34; }
      .c-section-family .c-section-family__slider .c-section-family__slider__nav .c-section-family__slider__nav__prev {
        margin-right: 5px;
        transform: rotate(-180deg); }

.c-section-faq {
  position: relative;
  background-color: #37BFB5;
  padding-top: 110px;
  padding-bottom: 110px; }
  .c-section-faq--nobkg {
    background-color: unset; }
  .c-section-faq .c-section-faq__line::after, .c-section-faq .c-section-faq__line::before {
    content: '';
    height: 80px;
    width: 1px;
    position: absolute;
    left: 50%; }
  .c-section-faq .c-section-faq__line--bottom::after, .c-section-faq .c-section-faq__line--bottom::before {
    bottom: 0; }
  .c-section-faq .c-section-faq__line--bottom::after {
    background-color: #FFFFFF;
    transform: translate(320px); }
  .c-section-faq .c-section-faq__line--bottom::before {
    background-color: #37BFB5;
    transform: translate(320px, 100%); }
  .c-section-faq .c-section-faq__line--top::after, .c-section-faq .c-section-faq__line--top::before {
    top: 0; }
  .c-section-faq .c-section-faq__line--top::after {
    background-color: #FFFFFF;
    transform: translate(-320px); }
  .c-section-faq .c-section-faq__line--top::before {
    background-color: #37BFB5;
    transform: translate(-320px, -100%); }
  @media (max-width: 1274.98px) {
    .c-section-faq .c-section-faq__line {
      display: none; } }
  .c-section-faq .c-section-faq__decoration {
    position: absolute;
    top: 80px;
    right: -60px;
    height: 806px;
    width: 323px;
    background-position: center;
    background-size: cover; }
    @media (max-width: 1274.98px) {
      .c-section-faq .c-section-faq__decoration {
        display: none; } }
  .c-section-faq .c-section-faq__decoration__container {
    background-color: #37BFB5;
    padding: 60px 110px 80px 110px;
    position: relative; }
    @media (max-width: 991.98px) {
      .c-section-faq .c-section-faq__decoration__container {
        padding: 60px 20px 80px 20px; } }
    .c-section-faq .c-section-faq__decoration__container::after, .c-section-faq .c-section-faq__decoration__container::before {
      content: "";
      position: absolute;
      width: 1px;
      height: 100px;
      left: 110px;
      top: 0; }
      @media (max-width: 991.98px) {
        .c-section-faq .c-section-faq__decoration__container::after, .c-section-faq .c-section-faq__decoration__container::before {
          display: none; } }
    .c-section-faq .c-section-faq__decoration__container::before {
      background-color: #FFFFFF; }
    .c-section-faq .c-section-faq__decoration__container::after {
      background-color: #37BFB5;
      transform: translateY(-100%); }
  .c-section-faq .c-section-faq__title {
    color: #FFFFFF;
    margin-bottom: 85px; }
  .c-section-faq .c-section-faq__terms {
    display: flex;
    margin-bottom: 50px;
    margin-left: 40px; }
    @media (max-width: 991.98px) {
      .c-section-faq .c-section-faq__terms {
        margin-left: 0; } }
    @media (max-width: 991.98px) {
      .c-section-faq .c-section-faq__terms {
        flex-direction: column;
        align-items: center; } }
    .c-section-faq .c-section-faq__terms .c-section-faq__terms__element {
      color: #FFFFFF;
      border: 1px solid #FFFFFF;
      border-radius: 20px;
      text-transform: uppercase;
      font-size: 12px;
      line-height: 24px;
      padding: 6px 30px;
      margin-right: 10px;
      margin-bottom: 10px;
      cursor: pointer;
      transition: background-color .2s ease-out, color .2s ease-out; }
      .c-section-faq .c-section-faq__terms .c-section-faq__terms__element.active:hover, .c-section-faq .c-section-faq__terms .c-section-faq__terms__element.active {
        background-color: #FFFFFF;
        color: #37BFB5; }
      .c-section-faq .c-section-faq__terms .c-section-faq__terms__element:hover {
        background-color: rgba(255, 255, 255, 0.8);
        color: #37BFB5; }
      @media (max-width: 991.98px) {
        .c-section-faq .c-section-faq__terms .c-section-faq__terms__element {
          text-align: center;
          margin-right: 0;
          max-width: 300px; } }
  .c-section-faq .c-section-faq__content .c-section-faq__content__element {
    transition: opacity .2s ease-out; }
    .c-section-faq .c-section-faq__content .c-section-faq__content__element.hidden {
      height: 0 !important;
      opacity: 0 !important;
      visibility: hidden !important; }
    .c-section-faq .c-section-faq__content .c-section-faq__content__element .c-section-faq__content__element__titleContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      padding-bottom: 25px;
      border-bottom: 1px solid #FFFFFF; }
      .c-section-faq .c-section-faq__content .c-section-faq__content__element .c-section-faq__content__element__titleContainer .c-section-faq__content__element__titleContainer__title {
        font-family: "Work Sans", sans-serif;
        color: #FFFFFF;
        font-size: 24px;
        line-height: 28px;
        text-transform: none;
        font-weight: 500;
        padding-right: 20px;
        transition: color 0.2s #114D90; }
      .c-section-faq .c-section-faq__content .c-section-faq__content__element .c-section-faq__content__element__titleContainer .c-section-faq__content__element__titleContainer__arrow {
        min-height: 40px;
        min-width: 40px;
        background-color: #FFFFFF;
        border-radius: 100%;
        transform: rotate(90deg);
        display: flex;
        justify-content: center;
        align-items: center;
        transition: transform .2s ease-out, background-color .2s ease-out; }
      .c-section-faq .c-section-faq__content .c-section-faq__content__element .c-section-faq__content__element__titleContainer.active .c-section-faq__content__element__titleContainer__arrow {
        transform: rotate(-90deg); }
    .c-section-faq .c-section-faq__content .c-section-faq__content__element .c-section-faq__content__element__textContainer {
      overflow: hidden;
      max-height: 0;
      transition: max-height .3s ease-out; }
      .c-section-faq .c-section-faq__content .c-section-faq__content__element .c-section-faq__content__element__textContainer .c-section-faq__content__element__textContainer__text {
        font-size: 20px;
        color: #FFFFFF;
        line-height: 28px;
        transform: translateY(-100%);
        transition: transform .3s ease-out; }
      .c-section-faq .c-section-faq__content .c-section-faq__content__element .c-section-faq__content__element__textContainer.active {
        max-height: 2000px; }
        .c-section-faq .c-section-faq__content .c-section-faq__content__element .c-section-faq__content__element__textContainer.active .c-section-faq__content__element__textContainer__text {
          transform: translateY(0); }
    .c-section-faq .c-section-faq__content .c-section-faq__content__element:hover {
      opacity: 1 !important; }

.c-section-twocols {
  padding: 160px 0; }
  .c-section-twocols--small {
    padding: 80px 0; }
  .c-section-twocols--darkgrey {
    color: #424242; }
  .c-section-twocols .c-section-twocols__left {
    position: relative;
    height: 830px; }
    @media screen and (max-width: 992px) {
      .c-section-twocols .c-section-twocols__left {
        height: auto; } }
    .c-section-twocols .c-section-twocols__left .c-section-twocols__left__image {
      height: 470px;
      width: 470px;
      background-color: #000000;
      background-position: center;
      background-size: cover;
      position: absolute; }
      .c-section-twocols .c-section-twocols__left .c-section-twocols__left__image[data-index="1"] {
        top: 0;
        left: 110px; }
        @media screen and (max-width: 992px) {
          .c-section-twocols .c-section-twocols__left .c-section-twocols__left__image[data-index="1"] {
            left: calc(110px + 50%); } }
        @media (max-width: 991.98px) {
          .c-section-twocols .c-section-twocols__left .c-section-twocols__left__image[data-index="1"] {
            left: 50%; } }
      .c-section-twocols .c-section-twocols__left .c-section-twocols__left__image[data-index="2"] {
        top: 360px;
        left: 0; }
        @media screen and (max-width: 992px) {
          .c-section-twocols .c-section-twocols__left .c-section-twocols__left__image[data-index="2"] {
            left: 50%; } }
      @media screen and (max-width: 1250px) {
        .c-section-twocols .c-section-twocols__left .c-section-twocols__left__image {
          position: static;
          margin: 10px auto;
          max-width: 100%;
          height: 50%;
          width: auto;
          transform: translateX(0);
          left: 0; } }
      @media screen and (max-width: 992px) {
        .c-section-twocols .c-section-twocols__left .c-section-twocols__left__image {
          width: 50%;
          height: 470px; } }
      @media (max-width: 991.98px) {
        .c-section-twocols .c-section-twocols__left .c-section-twocols__left__image {
          width: 90%; } }
  .c-section-twocols .c-section-twocols__right {
    position: absolute;
    top: 50%;
    transform: translateY(-50%); }
    @media screen and (max-width: 992px) {
      .c-section-twocols .c-section-twocols__right {
        position: static;
        top: 0;
        transform: translateY(0); } }
    @media (max-width: 991.98px) {
      .c-section-twocols .c-section-twocols__right {
        width: 90%;
        margin: 0 auto; } }
    .c-section-twocols .c-section-twocols__right .c-section-twocols__right__title {
      color: #114D90;
      margin-bottom: 24px; }
    .c-section-twocols .c-section-twocols__right .c-section-twocols__right__text {
      margin-bottom: 56px; }
      .c-section-twocols .c-section-twocols__right .c-section-twocols__right__text ol li, .c-section-twocols .c-section-twocols__right .c-section-twocols__right__text ul li {
        font-size: 16px; }

.c-section-news-latest {
  background-color: #FFFFFF; }
  .c-section-news-latest--related {
    padding: 80px 0; }
  .c-section-news-latest .c-section-news-latest__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 70px; }
    @media (max-width: 767.98px) {
      .c-section-news-latest .c-section-news-latest__header {
        flex-direction: column;
        justify-content: center;
        align-items: flex-start; } }
    .c-section-news-latest .c-section-news-latest__header .c-section-news-latest__header__title {
      color: #114D90; }
      @media (max-width: 767.98px) {
        .c-section-news-latest .c-section-news-latest__header .c-section-news-latest__header__title {
          margin-bottom: 20px; } }

.c-section-portfolio-bottom {
  padding-bottom: 80px; }
  .c-section-portfolio-bottom .c-section-portfolio-bottom__text {
    font-weight: 500;
    color: #808080; }

.c-section-portfolio-filter .c-section-portfolio-filter__header {
  display: flex;
  align-items: center;
  padding-bottom: 96px; }
  @media (max-width: 991.98px) {
    .c-section-portfolio-filter .c-section-portfolio-filter__header {
      padding-bottom: 0; } }
  @media (max-width: 991.98px) {
    .c-section-portfolio-filter .c-section-portfolio-filter__header {
      flex-direction: column; } }
  .c-section-portfolio-filter .c-section-portfolio-filter__header .c-section-portfolio-filter__header__title {
    color: #37BFB5;
    font-size: 24px;
    line-height: 28px;
    font-weight: 400;
    margin-right: 38px; }
    @media (max-width: 991.98px) {
      .c-section-portfolio-filter .c-section-portfolio-filter__header .c-section-portfolio-filter__header__title {
        margin-right: 0; } }
  .c-section-portfolio-filter .c-section-portfolio-filter__header .c-section-portfolio-filter__header__element {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-right: 48px;
    transition: color .2s ease-out; }
    .c-section-portfolio-filter .c-section-portfolio-filter__header .c-section-portfolio-filter__header__element .c-section-portfolio-filter__header__element__arrow {
      margin: 0 10px; }
    .c-section-portfolio-filter .c-section-portfolio-filter__header .c-section-portfolio-filter__header__element.active, .c-section-portfolio-filter .c-section-portfolio-filter__header .c-section-portfolio-filter__header__element:hover {
      color: #37BFB5; }
    @media (max-width: 991.98px) {
      .c-section-portfolio-filter .c-section-portfolio-filter__header .c-section-portfolio-filter__header__element {
        margin-right: 10px;
        margin-left: 10px; } }
  .c-section-portfolio-filter .c-section-portfolio-filter__header .c-section-portfolio-filter__header__element-container {
    display: flex; }

.c-section-portfolio-filter .c-section-portfolio-filter__body {
  position: relative;
  padding-bottom: 110px; }
  .c-section-portfolio-filter .c-section-portfolio-filter__body .c-section-portfolio-filter__body__left {
    background-color: #37BFB5;
    padding-bottom: 56px;
    position: relative; }
    @media (max-width: 1274.98px) {
      .c-section-portfolio-filter .c-section-portfolio-filter__body .c-section-portfolio-filter__body__left {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 2;
        transform: translateY(calc(100% - 65px));
        max-height: 100vh;
        overflow-y: scroll;
        transition: transform .2s ease-out;
        width: 100%;
        padding-left: 24px; }
        .c-section-portfolio-filter .c-section-portfolio-filter__body .c-section-portfolio-filter__body__left.active {
          transform: translateY(calc(0% + 110px)); } }
    @media (max-width: 991.98px) {
      .c-section-portfolio-filter .c-section-portfolio-filter__body .c-section-portfolio-filter__body__left {
        padding-right: 24px; } }
    .c-section-portfolio-filter .c-section-portfolio-filter__body .c-section-portfolio-filter__body__left:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      transform: translateX(100%);
      background-color: #37BFB5;
      height: 100%;
      width: 40px; }
      @media (max-width: 991.98px) {
        .c-section-portfolio-filter .c-section-portfolio-filter__body .c-section-portfolio-filter__body__left:after {
          display: none; } }
    .c-section-portfolio-filter .c-section-portfolio-filter__body .c-section-portfolio-filter__body__left .c-section-portfolio-filter__body__left__cover {
      height: 100%;
      width: 72px;
      background-color: #37BFB5;
      transform: translateX(-100%);
      position: absolute;
      top: 0;
      left: 0; }
      @media (max-width: 1274.98px) {
        .c-section-portfolio-filter .c-section-portfolio-filter__body .c-section-portfolio-filter__body__left .c-section-portfolio-filter__body__left__cover {
          display: none; } }
    .c-section-portfolio-filter .c-section-portfolio-filter__body .c-section-portfolio-filter__body__left .c-section-portfolio-filter__body__left__content {
      padding-top: 24px; }
      .c-section-portfolio-filter .c-section-portfolio-filter__body .c-section-portfolio-filter__body__left .c-section-portfolio-filter__body__left__content:after, .c-section-portfolio-filter .c-section-portfolio-filter__body .c-section-portfolio-filter__body__left .c-section-portfolio-filter__body__left__content:before {
        content: "";
        position: absolute;
        width: 1px;
        height: 60px;
        top: 0;
        left: 0; }
        @media (max-width: 1274.98px) {
          .c-section-portfolio-filter .c-section-portfolio-filter__body .c-section-portfolio-filter__body__left .c-section-portfolio-filter__body__left__content:after, .c-section-portfolio-filter .c-section-portfolio-filter__body .c-section-portfolio-filter__body__left .c-section-portfolio-filter__body__left__content:before {
            display: none; } }
      .c-section-portfolio-filter .c-section-portfolio-filter__body .c-section-portfolio-filter__body__left .c-section-portfolio-filter__body__left__content:after {
        background-color: #FFFFFF; }
      .c-section-portfolio-filter .c-section-portfolio-filter__body .c-section-portfolio-filter__body__left .c-section-portfolio-filter__body__left__content:before {
        background-color: #37BFB5;
        transform: translateY(-100%); }
      @media (max-width: 991.98px) {
        .c-section-portfolio-filter .c-section-portfolio-filter__body .c-section-portfolio-filter__body__left .c-section-portfolio-filter__body__left__content {
          padding-left: 20px;
          padding-top: 50px; } }
      .c-section-portfolio-filter .c-section-portfolio-filter__body .c-section-portfolio-filter__body__left .c-section-portfolio-filter__body__left__content .c-section-portfolio-filter__body__left__content__header {
        color: #FFFFFF;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        cursor: pointer;
        transition: color .2s ease-out; }
        .c-section-portfolio-filter .c-section-portfolio-filter__body .c-section-portfolio-filter__body__left .c-section-portfolio-filter__body__left__content .c-section-portfolio-filter__body__left__content__header--filter {
          display: none; }
          @media (max-width: 1274.98px) {
            .c-section-portfolio-filter .c-section-portfolio-filter__body .c-section-portfolio-filter__body__left .c-section-portfolio-filter__body__left__content .c-section-portfolio-filter__body__left__content__header--filter {
              display: block; } }
        .c-section-portfolio-filter .c-section-portfolio-filter__body .c-section-portfolio-filter__body__left .c-section-portfolio-filter__body__left__content .c-section-portfolio-filter__body__left__content__header .c-section-portfolio-filter__body__left__content__header__text--filter {
          display: block;
          margin: 0 auto; }
          @media (max-width: 991.98px) {
            .c-section-portfolio-filter .c-section-portfolio-filter__body .c-section-portfolio-filter__body__left .c-section-portfolio-filter__body__left__content .c-section-portfolio-filter__body__left__content__header .c-section-portfolio-filter__body__left__content__header__text--filter {
              text-align: center;
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 40px;
              padding-top: 10px; } }
        .c-section-portfolio-filter .c-section-portfolio-filter__body .c-section-portfolio-filter__body__left .c-section-portfolio-filter__body__left__content .c-section-portfolio-filter__body__left__content__header .c-section-portfolio-filter__body__left__content__header__image {
          margin-right: 16px;
          margin-left: 54px; }
          @media (max-width: 1274.98px) {
            .c-section-portfolio-filter .c-section-portfolio-filter__body .c-section-portfolio-filter__body__left .c-section-portfolio-filter__body__left__content .c-section-portfolio-filter__body__left__content__header .c-section-portfolio-filter__body__left__content__header__image {
              margin-left: 0; } }
        .c-section-portfolio-filter .c-section-portfolio-filter__body .c-section-portfolio-filter__body__left .c-section-portfolio-filter__body__left__content .c-section-portfolio-filter__body__left__content__header:hover {
          color: #114D90; }
      .c-section-portfolio-filter .c-section-portfolio-filter__body .c-section-portfolio-filter__body__left .c-section-portfolio-filter__body__left__content .c-section-portfolio-filter__body__left__content__body .c-section-portfolio-filter__body__left__content__body__section .c-section-portfolio-filter__body__left__content__body__section__title {
        color: #FFFFFF;
        font-size: 24px;
        line-height: 28px;
        font-weight: 400;
        margin-top: 40px;
        margin-bottom: 30px; }
      .c-section-portfolio-filter .c-section-portfolio-filter__body .c-section-portfolio-filter__body__left .c-section-portfolio-filter__body__left__content .c-section-portfolio-filter__body__left__content__body .c-section-portfolio-filter__body__left__content__body__section .c-section-portfolio-filter__body__left__content__body__section__list {
        display: flex;
        flex-wrap: wrap; }
        .c-section-portfolio-filter .c-section-portfolio-filter__body .c-section-portfolio-filter__body__left .c-section-portfolio-filter__body__left__content .c-section-portfolio-filter__body__left__content__body .c-section-portfolio-filter__body__left__content__body__section .c-section-portfolio-filter__body__left__content__body__section__list select {
          background-color: transparent;
          color: #FFFFFF;
          border: none;
          font-size: 12px;
          height: 24px;
          width: 175px;
          background-size: 15px; }
        .c-section-portfolio-filter .c-section-portfolio-filter__body .c-section-portfolio-filter__body__left .c-section-portfolio-filter__body__left__content .c-section-portfolio-filter__body__left__content__body .c-section-portfolio-filter__body__left__content__body__section .c-section-portfolio-filter__body__left__content__body__section__list .c-section-portfolio-filter__body__left__content__body__section__list__element {
          color: #FFFFFF;
          border: 1px solid #FFFFFF;
          border-radius: 20px;
          text-transform: uppercase;
          font-size: 12px;
          line-height: 24px;
          padding: 8px 24px;
          margin-right: 10px;
          margin-bottom: 10px;
          cursor: pointer;
          transition: background-color .2s ease-out, color .2s ease-out; }
          .c-section-portfolio-filter .c-section-portfolio-filter__body .c-section-portfolio-filter__body__left .c-section-portfolio-filter__body__left__content .c-section-portfolio-filter__body__left__content__body .c-section-portfolio-filter__body__left__content__body__section .c-section-portfolio-filter__body__left__content__body__section__list .c-section-portfolio-filter__body__left__content__body__section__list__element.active:hover, .c-section-portfolio-filter .c-section-portfolio-filter__body .c-section-portfolio-filter__body__left .c-section-portfolio-filter__body__left__content .c-section-portfolio-filter__body__left__content__body .c-section-portfolio-filter__body__left__content__body__section .c-section-portfolio-filter__body__left__content__body__section__list .c-section-portfolio-filter__body__left__content__body__section__list__element.active {
            background-color: #FFFFFF;
            color: #37BFB5; }
          .c-section-portfolio-filter .c-section-portfolio-filter__body .c-section-portfolio-filter__body__left .c-section-portfolio-filter__body__left__content .c-section-portfolio-filter__body__left__content__body .c-section-portfolio-filter__body__left__content__body__section .c-section-portfolio-filter__body__left__content__body__section__list .c-section-portfolio-filter__body__left__content__body__section__list__element:hover {
            background-color: rgba(255, 255, 255, 0.8);
            color: #37BFB5; }
  .c-section-portfolio-filter .c-section-portfolio-filter__body .c-section-portfolio-filter__body__right {
    padding-top: 20px;
    padding-bottom: 60px; }
    .c-section-portfolio-filter .c-section-portfolio-filter__body .c-section-portfolio-filter__body__right .c-section-portfolio-filter__body__right__column {
      width: 196px; }
      @media (max-width: 1274.98px) {
        .c-section-portfolio-filter .c-section-portfolio-filter__body .c-section-portfolio-filter__body__right .c-section-portfolio-filter__body__right__column {
          width: 150px; } }
      @media (max-width: 767.98px) {
        .c-section-portfolio-filter .c-section-portfolio-filter__body .c-section-portfolio-filter__body__right .c-section-portfolio-filter__body__right__column {
          width: 196px; } }
      .c-section-portfolio-filter .c-section-portfolio-filter__body .c-section-portfolio-filter__body__right .c-section-portfolio-filter__body__right__column:not(:last-child) {
        margin-right: 10px; }
        @media (max-width: 991.98px) {
          .c-section-portfolio-filter .c-section-portfolio-filter__body .c-section-portfolio-filter__body__right .c-section-portfolio-filter__body__right__column:not(:last-child) {
            margin-right: auto; } }
      @media (max-width: 991.98px) {
        .c-section-portfolio-filter .c-section-portfolio-filter__body .c-section-portfolio-filter__body__right .c-section-portfolio-filter__body__right__column {
          margin: 0px auto; } }
      @media screen and (max-width: 400px) {
        .c-section-portfolio-filter .c-section-portfolio-filter__body .c-section-portfolio-filter__body__right .c-section-portfolio-filter__body__right__column {
          margin: 24px auto; } }
      .c-section-portfolio-filter .c-section-portfolio-filter__body .c-section-portfolio-filter__body__right .c-section-portfolio-filter__body__right__column--lower {
        transform: translateY(50px); }
      .c-section-portfolio-filter .c-section-portfolio-filter__body .c-section-portfolio-filter__body__right .c-section-portfolio-filter__body__right__column .c-section-portfolio-filter__body__right__column__logo {
        width: 196px;
        height: 196px;
        background-color: #FFFFFF;
        background-position: center;
        background-size: contain;
        margin-bottom: 24px;
        position: relative;
        filter: saturate(0.5);
        transition: filter .2s ease-out; }
        @media (max-width: 1274.98px) {
          .c-section-portfolio-filter .c-section-portfolio-filter__body .c-section-portfolio-filter__body__right .c-section-portfolio-filter__body__right__column .c-section-portfolio-filter__body__right__column__logo {
            width: 150px;
            height: 150px; } }
        @media (max-width: 767.98px) {
          .c-section-portfolio-filter .c-section-portfolio-filter__body .c-section-portfolio-filter__body__right .c-section-portfolio-filter__body__right__column .c-section-portfolio-filter__body__right__column__logo {
            width: 196px;
            height: 196px; } }
        .c-section-portfolio-filter .c-section-portfolio-filter__body .c-section-portfolio-filter__body__right .c-section-portfolio-filter__body__right__column .c-section-portfolio-filter__body__right__column__logo:hover {
          filter: saturate(1); }
        .c-section-portfolio-filter .c-section-portfolio-filter__body .c-section-portfolio-filter__body__right .c-section-portfolio-filter__body__right__column .c-section-portfolio-filter__body__right__column__logo a {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0; }
  .c-section-portfolio-filter .c-section-portfolio-filter__body .c-section-portfolio-filter__body__bottom {
    display: flex;
    justify-content: center; }

.c-section-text-title {
  padding-top: 95px; }
  .c-section-text-title .c-section-text-title__title {
    color: #114D90;
    font-size: 40px;
    line-height: 50px;
    margin-bottom: 40px; }
  .c-section-text-title .c-section-text-title__text {
    color: #424242; }
    .c-section-text-title .c-section-text-title__text ol li, .c-section-text-title .c-section-text-title__text ul li {
      font-size: 16px; }

.c-section-image {
  padding-top: 95px; }
  .c-section-image img {
    display: block;
    margin: 0 auto;
    max-width: 100%; }

#page-cms .c-section-image {
  padding: 40px 0; }
  #page-cms .c-section-image img {
    display: block;
    margin: 0;
    max-width: 100%; }

.c-section-page-bottom .c-section-page-bottom__top {
  height: 300px; }
  @media (max-width: 1274.98px) {
    .c-section-page-bottom .c-section-page-bottom__top {
      height: auto; } }
  .c-section-page-bottom .c-section-page-bottom__top .c-section-page-bottom__top__left,
  .c-section-page-bottom .c-section-page-bottom__top .c-section-page-bottom__top__right {
    position: relative;
    height: 300px;
    background-size: cover;
    background-position: center; }
    .c-section-page-bottom .c-section-page-bottom__top .c-section-page-bottom__top__left .c-section-page-bottom__top__left__link,
    .c-section-page-bottom .c-section-page-bottom__top .c-section-page-bottom__top__left .c-section-page-bottom__top__right__link,
    .c-section-page-bottom .c-section-page-bottom__top .c-section-page-bottom__top__right .c-section-page-bottom__top__left__link,
    .c-section-page-bottom .c-section-page-bottom__top .c-section-page-bottom__top__right .c-section-page-bottom__top__right__link {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }
    .c-section-page-bottom .c-section-page-bottom__top .c-section-page-bottom__top__left .c-section-page-bottom__top__left__cover,
    .c-section-page-bottom .c-section-page-bottom__top .c-section-page-bottom__top__left .c-section-page-bottom__top__right__cover,
    .c-section-page-bottom .c-section-page-bottom__top .c-section-page-bottom__top__right .c-section-page-bottom__top__left__cover,
    .c-section-page-bottom .c-section-page-bottom__top .c-section-page-bottom__top__right .c-section-page-bottom__top__right__cover {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: #A51C34;
      transition: opacity .2s ease-out; }
      @media (max-width: 991.98px) {
        .c-section-page-bottom .c-section-page-bottom__top .c-section-page-bottom__top__left .c-section-page-bottom__top__left__cover,
        .c-section-page-bottom .c-section-page-bottom__top .c-section-page-bottom__top__left .c-section-page-bottom__top__right__cover,
        .c-section-page-bottom .c-section-page-bottom__top .c-section-page-bottom__top__right .c-section-page-bottom__top__left__cover,
        .c-section-page-bottom .c-section-page-bottom__top .c-section-page-bottom__top__right .c-section-page-bottom__top__right__cover {
          opacity: .5; } }
    .c-section-page-bottom .c-section-page-bottom__top .c-section-page-bottom__top__left .c-section-page-bottom__top__left__content,
    .c-section-page-bottom .c-section-page-bottom__top .c-section-page-bottom__top__left .c-section-page-bottom__top__right__content,
    .c-section-page-bottom .c-section-page-bottom__top .c-section-page-bottom__top__right .c-section-page-bottom__top__left__content,
    .c-section-page-bottom .c-section-page-bottom__top .c-section-page-bottom__top__right .c-section-page-bottom__top__right__content {
      position: relative;
      height: 300px;
      display: flex;
      flex-direction: column;
      justify-content: center; }
      .c-section-page-bottom .c-section-page-bottom__top .c-section-page-bottom__top__left .c-section-page-bottom__top__left__content .c-section-page-bottom__top__left__content__question,
      .c-section-page-bottom .c-section-page-bottom__top .c-section-page-bottom__top__left .c-section-page-bottom__top__left__content .c-section-page-bottom__top__right__content__question,
      .c-section-page-bottom .c-section-page-bottom__top .c-section-page-bottom__top__left .c-section-page-bottom__top__right__content .c-section-page-bottom__top__left__content__question,
      .c-section-page-bottom .c-section-page-bottom__top .c-section-page-bottom__top__left .c-section-page-bottom__top__right__content .c-section-page-bottom__top__right__content__question,
      .c-section-page-bottom .c-section-page-bottom__top .c-section-page-bottom__top__right .c-section-page-bottom__top__left__content .c-section-page-bottom__top__left__content__question,
      .c-section-page-bottom .c-section-page-bottom__top .c-section-page-bottom__top__right .c-section-page-bottom__top__left__content .c-section-page-bottom__top__right__content__question,
      .c-section-page-bottom .c-section-page-bottom__top .c-section-page-bottom__top__right .c-section-page-bottom__top__right__content .c-section-page-bottom__top__left__content__question,
      .c-section-page-bottom .c-section-page-bottom__top .c-section-page-bottom__top__right .c-section-page-bottom__top__right__content .c-section-page-bottom__top__right__content__question {
        color: #FFFFFF;
        font-weight: 500;
        line-height: 24px;
        font-size: 16px;
        margin-bottom: 5px; }
      .c-section-page-bottom .c-section-page-bottom__top .c-section-page-bottom__top__left .c-section-page-bottom__top__left__content .c-section-page-bottom__top__left__content__title,
      .c-section-page-bottom .c-section-page-bottom__top .c-section-page-bottom__top__left .c-section-page-bottom__top__left__content .c-section-page-bottom__top__right__content__title,
      .c-section-page-bottom .c-section-page-bottom__top .c-section-page-bottom__top__left .c-section-page-bottom__top__right__content .c-section-page-bottom__top__left__content__title,
      .c-section-page-bottom .c-section-page-bottom__top .c-section-page-bottom__top__left .c-section-page-bottom__top__right__content .c-section-page-bottom__top__right__content__title,
      .c-section-page-bottom .c-section-page-bottom__top .c-section-page-bottom__top__right .c-section-page-bottom__top__left__content .c-section-page-bottom__top__left__content__title,
      .c-section-page-bottom .c-section-page-bottom__top .c-section-page-bottom__top__right .c-section-page-bottom__top__left__content .c-section-page-bottom__top__right__content__title,
      .c-section-page-bottom .c-section-page-bottom__top .c-section-page-bottom__top__right .c-section-page-bottom__top__right__content .c-section-page-bottom__top__left__content__title,
      .c-section-page-bottom .c-section-page-bottom__top .c-section-page-bottom__top__right .c-section-page-bottom__top__right__content .c-section-page-bottom__top__right__content__title {
        color: #FFFFFF;
        margin-bottom: 17px; }
      .c-section-page-bottom .c-section-page-bottom__top .c-section-page-bottom__top__left .c-section-page-bottom__top__left__content .c-section-page-bottom__top__left__content__arrow,
      .c-section-page-bottom .c-section-page-bottom__top .c-section-page-bottom__top__left .c-section-page-bottom__top__left__content .c-section-page-bottom__top__right__content__arrow,
      .c-section-page-bottom .c-section-page-bottom__top .c-section-page-bottom__top__left .c-section-page-bottom__top__right__content .c-section-page-bottom__top__left__content__arrow,
      .c-section-page-bottom .c-section-page-bottom__top .c-section-page-bottom__top__left .c-section-page-bottom__top__right__content .c-section-page-bottom__top__right__content__arrow,
      .c-section-page-bottom .c-section-page-bottom__top .c-section-page-bottom__top__right .c-section-page-bottom__top__left__content .c-section-page-bottom__top__left__content__arrow,
      .c-section-page-bottom .c-section-page-bottom__top .c-section-page-bottom__top__right .c-section-page-bottom__top__left__content .c-section-page-bottom__top__right__content__arrow,
      .c-section-page-bottom .c-section-page-bottom__top .c-section-page-bottom__top__right .c-section-page-bottom__top__right__content .c-section-page-bottom__top__left__content__arrow,
      .c-section-page-bottom .c-section-page-bottom__top .c-section-page-bottom__top__right .c-section-page-bottom__top__right__content .c-section-page-bottom__top__right__content__arrow {
        height: 40px;
        width: 40px;
        border-radius: 100%;
        background-color: #FFFFFF;
        justify-content: center;
        align-items: center;
        display: flex; }
      .c-section-page-bottom .c-section-page-bottom__top .c-section-page-bottom__top__left .c-section-page-bottom__top__left__content .c-section-page-bottom__top__left__content__arrow,
      .c-section-page-bottom .c-section-page-bottom__top .c-section-page-bottom__top__left .c-section-page-bottom__top__right__content .c-section-page-bottom__top__left__content__arrow,
      .c-section-page-bottom .c-section-page-bottom__top .c-section-page-bottom__top__right .c-section-page-bottom__top__left__content .c-section-page-bottom__top__left__content__arrow,
      .c-section-page-bottom .c-section-page-bottom__top .c-section-page-bottom__top__right .c-section-page-bottom__top__right__content .c-section-page-bottom__top__left__content__arrow {
        transform: rotate(180deg); }
    .c-section-page-bottom .c-section-page-bottom__top .c-section-page-bottom__top__left .c-section-page-bottom__top__left__content,
    .c-section-page-bottom .c-section-page-bottom__top .c-section-page-bottom__top__right .c-section-page-bottom__top__left__content {
      margin-left: calc((100vw - 1300px) / 2); }
      @media screen and (max-width: 1300px) {
        .c-section-page-bottom .c-section-page-bottom__top .c-section-page-bottom__top__left .c-section-page-bottom__top__left__content,
        .c-section-page-bottom .c-section-page-bottom__top .c-section-page-bottom__top__right .c-section-page-bottom__top__left__content {
          margin-left: 10px; } }
    .c-section-page-bottom .c-section-page-bottom__top .c-section-page-bottom__top__left .c-section-page-bottom__top__right__content,
    .c-section-page-bottom .c-section-page-bottom__top .c-section-page-bottom__top__right .c-section-page-bottom__top__right__content {
      margin-right: calc((100vw - 1300px) / 2);
      align-items: flex-end; }
      @media screen and (max-width: 1300px) {
        .c-section-page-bottom .c-section-page-bottom__top .c-section-page-bottom__top__left .c-section-page-bottom__top__right__content,
        .c-section-page-bottom .c-section-page-bottom__top .c-section-page-bottom__top__right .c-section-page-bottom__top__right__content {
          margin-right: 10px; } }
  .c-section-page-bottom .c-section-page-bottom__top .c-section-page-bottom__top__right {
    border-left: 1px solid #FFFFFF; }
    @media (max-width: 1274.98px) {
      .c-section-page-bottom .c-section-page-bottom__top .c-section-page-bottom__top__right {
        border-left: none; } }
    .c-section-page-bottom .c-section-page-bottom__top .c-section-page-bottom__top__right:hover .c-section-page-bottom__top__right__cover {
      opacity: .5; }
  .c-section-page-bottom .c-section-page-bottom__top .c-section-page-bottom__top__left {
    border-right: 1px solid #FFFFFF; }
    @media (max-width: 1274.98px) {
      .c-section-page-bottom .c-section-page-bottom__top .c-section-page-bottom__top__left {
        border-right: none; } }
    .c-section-page-bottom .c-section-page-bottom__top .c-section-page-bottom__top__left:hover .c-section-page-bottom__top__left__cover {
      opacity: .5; }

.c-section-scaling-list-programs {
  padding-bottom: 80px; }
  .c-section-scaling-list-programs .c-section-scaling-list-programs__title {
    color: #114D90;
    margin-bottom: 32px; }
  .c-section-scaling-list-programs .c-section-scaling-list-programs__slider {
    margin-top: 120px;
    margin-bottom: 100px;
    position: relative; }
    .c-section-scaling-list-programs .c-section-scaling-list-programs__slider .swiper-container {
      overflow: unset !important; }
      @media (max-width: 1274.98px) {
        .c-section-scaling-list-programs .c-section-scaling-list-programs__slider .swiper-container {
          overflow: hidden !important; } }
      .c-section-scaling-list-programs .c-section-scaling-list-programs__slider .swiper-container:after {
        content: '';
        height: 100%;
        width: 100vw;
        background-color: #D7F2F0;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 2;
        transform: translateX(-100%); }
    .c-section-scaling-list-programs .c-section-scaling-list-programs__slider .swiper-slide {
      width: 470px !important; }
      @media (max-width: 991.98px) {
        .c-section-scaling-list-programs .c-section-scaling-list-programs__slider .swiper-slide {
          width: 300px !important; } }
      @media (max-width: 767.98px) {
        .c-section-scaling-list-programs .c-section-scaling-list-programs__slider .swiper-slide {
          width: 250px !important; } }
    .c-section-scaling-list-programs .c-section-scaling-list-programs__slider .c-section-scaling-list-programs__slider__nav {
      position: absolute;
      bottom: 0;
      right: 0;
      display: flex;
      z-index: 2;
      transform: translateY(calc(100% + 44px)); }
      .c-section-scaling-list-programs .c-section-scaling-list-programs__slider .c-section-scaling-list-programs__slider__nav .c-section-scaling-list-programs__slider__nav__prev,
      .c-section-scaling-list-programs .c-section-scaling-list-programs__slider .c-section-scaling-list-programs__slider__nav .c-section-scaling-list-programs__slider__nav__next {
        height: 64px;
        width: 64px;
        background-color: #A51C34;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border: 2px solid #A51C34;
        transition: background-color #FFFFFF 0.2s ease-out; }
        .c-section-scaling-list-programs .c-section-scaling-list-programs__slider .c-section-scaling-list-programs__slider__nav .c-section-scaling-list-programs__slider__nav__prev:hover,
        .c-section-scaling-list-programs .c-section-scaling-list-programs__slider .c-section-scaling-list-programs__slider__nav .c-section-scaling-list-programs__slider__nav__next:hover {
          background-color: #FFFFFF; }
          .c-section-scaling-list-programs .c-section-scaling-list-programs__slider .c-section-scaling-list-programs__slider__nav .c-section-scaling-list-programs__slider__nav__prev:hover .long-arrow-right,
          .c-section-scaling-list-programs .c-section-scaling-list-programs__slider .c-section-scaling-list-programs__slider__nav .c-section-scaling-list-programs__slider__nav__next:hover .long-arrow-right {
            border-color: #A51C34; }
            .c-section-scaling-list-programs .c-section-scaling-list-programs__slider .c-section-scaling-list-programs__slider__nav .c-section-scaling-list-programs__slider__nav__prev:hover .long-arrow-right:after,
            .c-section-scaling-list-programs .c-section-scaling-list-programs__slider .c-section-scaling-list-programs__slider__nav .c-section-scaling-list-programs__slider__nav__next:hover .long-arrow-right:after {
              background-color: #A51C34; }
      .c-section-scaling-list-programs .c-section-scaling-list-programs__slider .c-section-scaling-list-programs__slider__nav .c-section-scaling-list-programs__slider__nav__prev {
        margin-right: 5px;
        transform: rotate(-180deg); }

.c-section-scaling-team {
  padding-bottom: 110px; }
  .c-section-scaling-team .c-section-scaling-team__title {
    color: #114D90;
    margin-bottom: 32px;
    padding-top: 43px; }
  @media (max-width: 1274.98px) {
    .c-section-scaling-team .c-section-scaling-team__text {
      margin-bottom: 40px; } }

.c-section-scaling-benefits {
  position: relative;
  padding-bottom: 95px; }
  .c-section-scaling-benefits .c-section-scaling-benefits__decoration {
    position: absolute;
    bottom: -45px;
    left: 60%;
    height: calc(100% - 170px + 45px);
    width: 2000vw;
    background-color: #37BFB5; }
    @media (max-width: 991.98px) {
      .c-section-scaling-benefits .c-section-scaling-benefits__decoration {
        bottom: -100px; } }
    @media (max-width: 767.98px) {
      .c-section-scaling-benefits .c-section-scaling-benefits__decoration {
        display: none; } }
  .c-section-scaling-benefits .c-section-scaling-benefits__title {
    color: #114D90;
    margin-bottom: 32px; }
  .c-section-scaling-benefits .c-section-scaling-benefits__text {
    margin-bottom: 73px; }
  .c-section-scaling-benefits .c-section-scaling-benefits__card {
    position: relative;
    padding-top: 18px;
    margin-bottom: 40px; }
    .c-section-scaling-benefits .c-section-scaling-benefits__card .c-section-scaling-benefits__card__number {
      height: 86px;
      width: 86px;
      position: absolute;
      background-color: #37BFB5;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 24px;
      line-height: 28px;
      color: #FFFFFF; }
    .c-section-scaling-benefits .c-section-scaling-benefits__card .c-section-scaling-benefits__card__info {
      background-color: #FFFFFF;
      margin-left: 30px;
      padding-left: 80px;
      padding-top: 40px;
      padding-right: 50px;
      padding-bottom: 40px;
      min-height: 272px; }
      .c-section-scaling-benefits .c-section-scaling-benefits__card .c-section-scaling-benefits__card__info .c-section-scaling-benefits__card__info__title {
        color: #37BFB5;
        margin-bottom: 16px;
        font-size: 24px;
        line-height: 28px; }

.c-section_team_list_members {
  position: relative; }
  .c-section_team_list_members .c-section_team_list_members__cover {
    position: absolute;
    top: 227px;
    left: 0;
    width: 100vw;
    height: calc(100% - 227px);
    background-color: #37BFB5; }

.c-section-wysiwyg {
  padding-bottom: 64px; }
  .c-section-wysiwyg h2 {
    color: #114D90;
    font-size: 40px;
    line-height: 48px;
    margin-top: 44px;
    margin-bottom: 24px; }
  .c-section-wysiwyg p {
    margin-bottom: 20px;
    font-size: 18px; }
  .c-section-wysiwyg ul li {
    font-size: 18px; }
  .c-section-wysiwyg img {
    max-width: 100%;
    height: auto; }

.c-section-newsletter {
  margin-bottom: 80px; }
  .c-section-newsletter .c-section-newsletter__decoration {
    position: absolute;
    top: -80px;
    right: -60px;
    height: 806px;
    width: 323px;
    background-position: center;
    background-size: cover;
    z-index: 2; }
    @media (max-width: 1274.98px) {
      .c-section-newsletter .c-section-newsletter__decoration {
        display: none; } }
  .c-section-newsletter .c-section-newsletter__content {
    background-color: #37BFB5;
    padding: 80px 55px;
    color: #FFFFFF; }
    .c-section-newsletter .c-section-newsletter__content .c-section-newsletter__content__title {
      font-size: 60px;
      line-height: 72px;
      margin-bottom: 16px;
      max-width: 700px; }
    .c-section-newsletter .c-section-newsletter__content .c-section-newsletter__content__text {
      margin-bottom: 24px;
      max-width: 530px; }
    .c-section-newsletter .c-section-newsletter__content .c-section-newsletter__content__list {
      margin-bottom: 40px;
      display: flex; }
      .c-section-newsletter .c-section-newsletter__content .c-section-newsletter__content__list a {
        border-radius: 100%;
        border: 2px solid #000000;
        display: flex;
        height: 44px;
        width: 43px;
        background-color: #000000; }
        .c-section-newsletter .c-section-newsletter__content .c-section-newsletter__content__list a img {
          transition: filter .2s ease-out; }
        .c-section-newsletter .c-section-newsletter__content .c-section-newsletter__content__list a:not(:last-child) {
          margin-right: 16px; }
        .c-section-newsletter .c-section-newsletter__content .c-section-newsletter__content__list a:hover img {
          filter: invert(1); }

.c-section-content-list {
  padding-top: 72px; }
  .c-section-content-list .c-section-content-list__cover {
    height: calc(100% + 80px);
    width: 1000vw;
    position: absolute;
    right: 300px;
    top: -72px;
    background-color: #37BFB5; }
    @media (max-width: 1274.98px) {
      .c-section-content-list .c-section-content-list__cover {
        width: 100%;
        right: 0; } }
  .c-section-content-list .c-section-content-list__top {
    position: relative;
    padding-bottom: 84px;
    display: flex; }
    @media (max-width: 1274.98px) {
      .c-section-content-list .c-section-content-list__top {
        flex-direction: column; } }
    @media (max-width: 1274.98px) {
      .c-section-content-list .c-section-content-list__top .custom-select-wrapper {
        margin-bottom: 15px; } }
    .c-section-content-list .c-section-content-list__top select {
      background-color: #37BFB5;
      font-size: 24px;
      color: #FFFFFF;
      border: none;
      margin-right: 94px;
      margin-bottom: 10px;
      padding-right: 40px;
      line-height: 30px; }
      .c-section-content-list .c-section-content-list__top select optgroup {
        font-size: 16px; }
  .c-section-content-list .c-section-content-list__body {
    padding-bottom: 80px;
    position: relative; }
    .c-section-content-list .c-section-content-list__body .loaded-posts-no-result {
      color: #FFFFFF;
      font-size: 20px; }
  .c-section-content-list .c-section-content-list__cta {
    position: relative;
    display: table;
    margin: 0 auto;
    padding-bottom: 80px; }

.c-section-content-list-outro {
  padding: 80px 0; }
  .c-section-content-list-outro .c-section-content-list-outro__text {
    color: #808080;
    max-width: 965px; }

.c-section-jobs-join {
  margin: 140px 0;
  padding: 120px 0;
  background-color: #37BFB5;
  color: #FFFFFF;
  position: relative; }
  @media (max-width: 991.98px) {
    .c-section-jobs-join {
      margin: 40px 0; } }
  .c-section-jobs-join .c-section-jobs-join__image-container {
    position: absolute;
    left: 0;
    top: -140px;
    height: 806px;
    width: 323px;
    background-color: #114D90;
    background-position: center;
    background-size: cover; }
    @media (max-width: 1274.98px) {
      .c-section-jobs-join .c-section-jobs-join__image-container {
        display: none; } }
  .c-section-jobs-join .c-section-jobs-join__title {
    margin-bottom: 32px; }
  .c-section-jobs-join .c-section-jobs-join__text {
    margin-bottom: 40px;
    max-width: 600px; }

.c-section-jobs-offer {
  margin-bottom: 30px; }
  .c-section-jobs-offer .c-section-jobs-offer__title {
    color: #114D90;
    margin-bottom: 32px; }
  .c-section-jobs-offer .c-section-jobs-offer__text {
    margin-bottom: 40px; }
  .c-section-jobs-offer .welcomekit-jobs-list {
    margin-left: 0; }
  .c-section-jobs-offer .welcomekit-jobs-list-item {
    margin: 10px 0;
    padding: 0;
    border: 1px solid #A51C34; }
    .c-section-jobs-offer .welcomekit-jobs-list-item::before {
      display: none; }
  .c-section-jobs-offer .welcomekit-jobs-list-item-link {
    position: relative;
    display: block;
    margin: 0;
    padding: 30px 15px;
    border-bottom: 1px solid #eee;
    text-decoration: none;
    transition: all 0.2s; }
  .c-section-jobs-offer .welcomekit-jobs-list-item-link:after {
    position: absolute;
    top: 50%;
    right: 30px;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    content: ' ';
    border-top: 1px solid #A51C34;
    border-right: 1px solid #A51C34;
    transform: rotate(45deg);
    transition: all 0.2s; }
  .c-section-jobs-offer .welcomekit-jobs-list-item-link:hover {
    background: #A51C34;
    border-bottom-color: #A51C34; }
    .c-section-jobs-offer .welcomekit-jobs-list-item-link:hover h3 {
      color: #FFFFFF; }
  .c-section-jobs-offer .welcomekit-jobs-list-item-link:hover:after {
    right: 20px;
    border-top-color: #FFFFFF;
    border-right-color: #FFFFFF; }
  .c-section-jobs-offer .welcomekit-job-name {
    margin: 0 0 10px 0;
    padding: 0;
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    color: #A51C34; }
  .c-section-jobs-offer .welcomekit-job-infos {
    margin: 0;
    padding: 0; }
  .c-section-jobs-offer .welcomekit-job-infos > li {
    position: relative;
    display: inline-block;
    margin: 0 34px 0 0;
    color: #aaa; }
  .c-section-jobs-offer .welcomekit-job-infos > li:before {
    position: absolute;
    top: 6px;
    left: -20px;
    content: ' ';
    display: block;
    height: 6px;
    width: 6px;
    background: #ddd;
    border-radius: 50%; }
  .c-section-jobs-offer .welcomekit-job-infos > li:first-child:before {
    display: none; }
  .c-section-jobs-offer .welcomekit-job-infos > li.welcomekit-job-description {
    display: block;
    margin: 10px 0 0 0;
    padding: 10px 20px;
    border-left: 2px solid #eee; }
  .c-section-jobs-offer .welcomekit-job-infos > li.welcomekit-job-description p,
  .c-section-jobs-offer .welcomekit-job-infos > li.welcomekit-job-description ul {
    margin: 0;
    padding: 0 0 10px 0;
    color: #bbb; }
  .c-section-jobs-offer .welcomekit-job-infos > li.welcomekit-job-description ul li {
    border-left: 1px solid #eee;
    padding-left: 10px;
    margin-bottom: 10px; }
  .c-section-jobs-offer .welcomekit-job-infos > li.welcomekit-job-description pre {
    border-left: 1px solid #eee;
    padding-left: 10px;
    overflow: auto; }
  .c-section-jobs-offer .welcomekit-job-infos > li.welcomekit-job-description:before {
    display: none; }
  .c-section-jobs-offer .welcomekit-office-city,
  .c-section-jobs-offer .welcomekit-department-name {
    display: block;
    padding: 15px;
    background: #eee;
    border-top: 1px solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0;
    font-weight: 500;
    font-size: 17px;
    line-height: 18px;
    text-transform: uppercase; }
  .c-section-jobs-offer .welcomekit-warning {
    display: block;
    padding: 50px 0;
    text-align: center; }

.c-section-manifesto {
  margin-bottom: 80px; }
  .c-section-manifesto .c-section-manifesto__cover {
    position: absolute;
    left: -86px;
    top: -300px;
    background-color: #37BFB5;
    height: calc(100% + 300px);
    width: 2000vw; }
    @media (max-width: 991.98px) {
      .c-section-manifesto .c-section-manifesto__cover {
        top: -150px; } }
  .c-section-manifesto .c-section-manifesto__top {
    padding-top: 80px;
    color: #FFFFFF;
    position: relative; }
    .c-section-manifesto .c-section-manifesto__top .c-section-manifesto__top__title {
      margin-bottom: 32px; }
    .c-section-manifesto .c-section-manifesto__top .c-section-manifesto__top__text {
      margin-bottom: 40px; }
    .c-section-manifesto .c-section-manifesto__top .c-section-manifesto__top__cta {
      margin-bottom: 120px; }
  .c-section-manifesto .c-section-manifesto__content .c-section-manifesto__content__element {
    padding-bottom: 120px;
    color: #FFFFFF; }
    .c-section-manifesto .c-section-manifesto__content .c-section-manifesto__content__element .c-section-manifesto__content__element__image {
      height: 408px;
      min-height: 408px;
      background-color: #114D90;
      background-size: cover;
      background-position: center; }
      @media (max-width: 1274.98px) {
        .c-section-manifesto .c-section-manifesto__content .c-section-manifesto__content__element .c-section-manifesto__content__element__image {
          margin-bottom: 20px;
          max-width: 510px; } }
    .c-section-manifesto .c-section-manifesto__content .c-section-manifesto__content__element .c-section-manifesto__content__element__title {
      margin-bottom: 50px;
      font-size: 40px; }
    .c-section-manifesto .c-section-manifesto__content .c-section-manifesto__content__element .c-section-manifesto__content__element__text {
      margin-bottom: 96px;
      position: relative; }
      .c-section-manifesto .c-section-manifesto__content .c-section-manifesto__content__element .c-section-manifesto__content__element__text::after {
        content: "";
        position: absolute;
        width: 740px;
        height: 1px;
        background-color: #FFFFFF;
        left: 0;
        bottom: -40px; }
        @media (max-width: 991.98px) {
          .c-section-manifesto .c-section-manifesto__content .c-section-manifesto__content__element .c-section-manifesto__content__element__text::after {
            width: 100%; } }
      .c-section-manifesto .c-section-manifesto__content .c-section-manifesto__content__element .c-section-manifesto__content__element__text--right::after {
        right: 0;
        left: unset; }
    .c-section-manifesto .c-section-manifesto__content .c-section-manifesto__content__element .c-section-manifesto__content__element__cta {
      position: absolute;
      top: 0;
      right: 0; }
      @media (max-width: 991.98px) {
        .c-section-manifesto .c-section-manifesto__content .c-section-manifesto__content__element .c-section-manifesto__content__element__cta {
          right: 15px; } }
    .c-section-manifesto .c-section-manifesto__content .c-section-manifesto__content__element .c-section-manifesto__content__element__startups .c-section-manifesto__content__element__startups__top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 24px; }
      .c-section-manifesto .c-section-manifesto__content .c-section-manifesto__content__element .c-section-manifesto__content__element__startups .c-section-manifesto__content__element__startups__top .c-section-manifesto__content__element__startups__top__text {
        font-size: 24px;
        font-style: italic; }
      .c-section-manifesto .c-section-manifesto__content .c-section-manifesto__content__element .c-section-manifesto__content__element__startups .c-section-manifesto__content__element__startups__top .c-section-manifesto__content__element__startups__top__cta {
        position: relative;
        color: #FFFFFF;
        text-transform: uppercase;
        font-size: 12px;
        line-height: 14px;
        font-weight: bold; }
        .c-section-manifesto .c-section-manifesto__content .c-section-manifesto__content__element .c-section-manifesto__content__element__startups .c-section-manifesto__content__element__startups__top .c-section-manifesto__content__element__startups__top__cta .btn__line {
          position: absolute;
          left: 0;
          bottom: -2px;
          width: 100%;
          height: 1px;
          background-color: #FFFFFF;
          transform: scaleX(0);
          transform-origin: left;
          transition: transform .2s ease-out; }
        .c-section-manifesto .c-section-manifesto__content .c-section-manifesto__content__element .c-section-manifesto__content__element__startups .c-section-manifesto__content__element__startups__top .c-section-manifesto__content__element__startups__top__cta:hover .btn__line {
          transform: scaleX(1); }
    .c-section-manifesto .c-section-manifesto__content .c-section-manifesto__content__element .c-section-manifesto__content__element__startups .c-section-manifesto__content__element__startups__bottom {
      display: flex; }
      @media (max-width: 991.98px) {
        .c-section-manifesto .c-section-manifesto__content .c-section-manifesto__content__element .c-section-manifesto__content__element__startups .c-section-manifesto__content__element__startups__bottom {
          flex-wrap: wrap; } }
      .c-section-manifesto .c-section-manifesto__content .c-section-manifesto__content__element .c-section-manifesto__content__element__startups .c-section-manifesto__content__element__startups__bottom img {
        max-width: 150px;
        margin-right: 10px;
        object-fit: contain; }
        @media (max-width: 991.98px) {
          .c-section-manifesto .c-section-manifesto__content .c-section-manifesto__content__element .c-section-manifesto__content__element__startups .c-section-manifesto__content__element__startups__bottom img {
            margin-bottom: 10px; } }

.c-section-pitch-content {
  padding-bottom: 80px; }
  .c-section-pitch-content .c-section-pitch-content__form {
    position: relative; }
    .c-section-pitch-content .c-section-pitch-content__form .c-section-pitch-content__form__steps {
      background-color: #37BFB5; }
    .c-section-pitch-content .c-section-pitch-content__form .c-section-pitch-content__form__image {
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      width: 323px;
      background-position: center;
      background-size: cover;
      transform: translateX(100%);
      background-color: #114D90; }
      @media (max-width: 1274.98px) {
        .c-section-pitch-content .c-section-pitch-content__form .c-section-pitch-content__form__image {
          display: none; } }
    .c-section-pitch-content .c-section-pitch-content__form .c-section-pitch-content__form__background {
      background-color: #FFFFFF;
      position: absolute;
      top: 0;
      right: 0;
      width: 100%; }
    .c-section-pitch-content .c-section-pitch-content__form .c-section-pitch-content__form__title {
      color: #114D90;
      font-size: 60px;
      line-height: 80px;
      margin-bottom: 8px;
      padding-top: 56px; }
      @media (max-width: 991.98px) {
        .c-section-pitch-content .c-section-pitch-content__form .c-section-pitch-content__form__title {
          font-size: 32px;
          line-height: 38px; } }
    .c-section-pitch-content .c-section-pitch-content__form .c-section-pitch-content__form__mandatory {
      color: #37BFB5;
      padding-bottom: 36px; }
    .c-section-pitch-content .c-section-pitch-content__form .c-section-pitch-content__form__steps {
      position: relative;
      display: flex;
      justify-content: space-between;
      color: #FFFFFF;
      margin-bottom: 36px;
      padding: 40px 82px 54px 110px; }
      @media (max-width: 991.98px) {
        .c-section-pitch-content .c-section-pitch-content__form .c-section-pitch-content__form__steps {
          padding: 40px 20px 54px 20px; } }
      @media (max-width: 767.98px) {
        .c-section-pitch-content .c-section-pitch-content__form .c-section-pitch-content__form__steps {
          flex-direction: column; } }
      .c-section-pitch-content .c-section-pitch-content__form .c-section-pitch-content__form__steps .c-section-pitch-content__form__steps__element {
        opacity: .5;
        cursor: pointer;
        transition: opacity .2s ease-out; }
        @media (max-width: 767.98px) {
          .c-section-pitch-content .c-section-pitch-content__form .c-section-pitch-content__form__steps .c-section-pitch-content__form__steps__element {
            margin-bottom: 10px; } }
        .c-section-pitch-content .c-section-pitch-content__form .c-section-pitch-content__form__steps .c-section-pitch-content__form__steps__element:hover, .c-section-pitch-content .c-section-pitch-content__form .c-section-pitch-content__form__steps .c-section-pitch-content__form__steps__element.active {
          opacity: 1; }
      .c-section-pitch-content .c-section-pitch-content__form .c-section-pitch-content__form__steps .c-section-pitch-content__form__steps__nav {
        display: flex;
        position: absolute;
        left: 42px;
        bottom: 0;
        transform: translateY(50%); }
        .c-section-pitch-content .c-section-pitch-content__form .c-section-pitch-content__form__steps .c-section-pitch-content__form__steps__nav .c-section-pitch-content__form__steps__nav__prev,
        .c-section-pitch-content .c-section-pitch-content__form .c-section-pitch-content__form__steps .c-section-pitch-content__form__steps__nav .c-section-pitch-content__form__steps__nav__next {
          height: 64px;
          width: 64px;
          background-color: #A51C34;
          border-radius: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          border: 2px solid #A51C34;
          transition: background-color #FFFFFF 0.2s ease-out; }
          .c-section-pitch-content .c-section-pitch-content__form .c-section-pitch-content__form__steps .c-section-pitch-content__form__steps__nav .c-section-pitch-content__form__steps__nav__prev:hover,
          .c-section-pitch-content .c-section-pitch-content__form .c-section-pitch-content__form__steps .c-section-pitch-content__form__steps__nav .c-section-pitch-content__form__steps__nav__next:hover {
            background-color: #FFFFFF; }
            .c-section-pitch-content .c-section-pitch-content__form .c-section-pitch-content__form__steps .c-section-pitch-content__form__steps__nav .c-section-pitch-content__form__steps__nav__prev:hover .long-arrow-right,
            .c-section-pitch-content .c-section-pitch-content__form .c-section-pitch-content__form__steps .c-section-pitch-content__form__steps__nav .c-section-pitch-content__form__steps__nav__next:hover .long-arrow-right {
              border-color: #A51C34; }
              .c-section-pitch-content .c-section-pitch-content__form .c-section-pitch-content__form__steps .c-section-pitch-content__form__steps__nav .c-section-pitch-content__form__steps__nav__prev:hover .long-arrow-right:after,
              .c-section-pitch-content .c-section-pitch-content__form .c-section-pitch-content__form__steps .c-section-pitch-content__form__steps__nav .c-section-pitch-content__form__steps__nav__next:hover .long-arrow-right:after {
                background-color: #A51C34; }
        .c-section-pitch-content .c-section-pitch-content__form .c-section-pitch-content__form__steps .c-section-pitch-content__form__steps__nav .c-section-pitch-content__form__steps__nav__prev {
          margin-right: 10px;
          transform: rotate(-180deg); }
    .c-section-pitch-content .c-section-pitch-content__form .c-section-pitch-content__form__gravity {
      padding-bottom: 40px; }
      .c-section-pitch-content .c-section-pitch-content__form .c-section-pitch-content__form__gravity li {
        padding-left: 0; }
        .c-section-pitch-content .c-section-pitch-content__form .c-section-pitch-content__form__gravity li::before {
          content: ''; }
      .c-section-pitch-content .c-section-pitch-content__form .c-section-pitch-content__form__gravity ul {
        margin-left: 0; }
      .c-section-pitch-content .c-section-pitch-content__form .c-section-pitch-content__form__gravity .gform_submission_error {
        font-size: 28px;
        line-height: 32px;
        font-family: "Work Sans", sans-serif; }
      .c-section-pitch-content .c-section-pitch-content__form .c-section-pitch-content__form__gravity .gform_fields {
        display: flex;
        flex-wrap: wrap; }
      .c-section-pitch-content .c-section-pitch-content__form .c-section-pitch-content__form__gravity .gfield {
        margin-bottom: 20px; }
        .c-section-pitch-content .c-section-pitch-content__form .c-section-pitch-content__form__gravity .gfield.hide {
          display: none; }
        .c-section-pitch-content .c-section-pitch-content__form .c-section-pitch-content__form__gravity .gfield.fullwidth {
          width: 100%;
          margin-right: 24px; }
          @media (max-width: 991.98px) {
            .c-section-pitch-content .c-section-pitch-content__form .c-section-pitch-content__form__gravity .gfield.fullwidth {
              margin-right: 0; } }
          .c-section-pitch-content .c-section-pitch-content__form .c-section-pitch-content__form__gravity .gfield.fullwidth select {
            width: 684px !important; }
            @media (max-width: 991.98px) {
              .c-section-pitch-content .c-section-pitch-content__form .c-section-pitch-content__form__gravity .gfield.fullwidth select {
                width: 624px !important; } }
            @media (max-width: 767.98px) {
              .c-section-pitch-content .c-section-pitch-content__form .c-section-pitch-content__form__gravity .gfield.fullwidth select {
                width: 100% !important; } }
        @media (max-width: 767.98px) {
          .c-section-pitch-content .c-section-pitch-content__form .c-section-pitch-content__form__gravity .gfield {
            width: 100%; } }
      .c-section-pitch-content .c-section-pitch-content__form .c-section-pitch-content__form__gravity .gfield_label {
        text-transform: uppercase;
        font-size: 10px;
        color: #424242; }
      .c-section-pitch-content .c-section-pitch-content__form .c-section-pitch-content__form__gravity .ginput_counter {
        font-size: 12px;
        color: #114D90; }
      .c-section-pitch-content .c-section-pitch-content__form .c-section-pitch-content__form__gravity .gfield_validation_message {
        font-size: 12px;
        color: #A51C34; }
      .c-section-pitch-content .c-section-pitch-content__form .c-section-pitch-content__form__gravity input[type="email"],
      .c-section-pitch-content .c-section-pitch-content__form .c-section-pitch-content__form__gravity input[type="text"] {
        width: 330px;
        border: none;
        border-bottom: 2px solid #37BFB5;
        margin-right: 24px;
        height: 30px;
        padding-bottom: 10px;
        font-size: 14px;
        color: #114D90; }
        .c-section-pitch-content .c-section-pitch-content__form .c-section-pitch-content__form__gravity input[type="email"]::placeholder,
        .c-section-pitch-content .c-section-pitch-content__form .c-section-pitch-content__form__gravity input[type="text"]::placeholder {
          color: #114D90; }
        @media (max-width: 991.98px) {
          .c-section-pitch-content .c-section-pitch-content__form .c-section-pitch-content__form__gravity input[type="email"],
          .c-section-pitch-content .c-section-pitch-content__form .c-section-pitch-content__form__gravity input[type="text"] {
            width: 300px; } }
        @media (max-width: 767.98px) {
          .c-section-pitch-content .c-section-pitch-content__form .c-section-pitch-content__form__gravity input[type="email"],
          .c-section-pitch-content .c-section-pitch-content__form .c-section-pitch-content__form__gravity input[type="text"] {
            width: 100%; } }
      .c-section-pitch-content .c-section-pitch-content__form .c-section-pitch-content__form__gravity textarea {
        width: 330px;
        border: none;
        border-bottom: 2px solid #37BFB5;
        margin-right: 24px;
        height: 110px;
        padding-bottom: 10px;
        font-size: 14px;
        color: #114D90; }
        .c-section-pitch-content .c-section-pitch-content__form .c-section-pitch-content__form__gravity textarea::placeholder {
          color: #114D90; }
        @media (max-width: 991.98px) {
          .c-section-pitch-content .c-section-pitch-content__form .c-section-pitch-content__form__gravity textarea {
            width: 300px; } }
        @media (max-width: 767.98px) {
          .c-section-pitch-content .c-section-pitch-content__form .c-section-pitch-content__form__gravity textarea {
            width: 100%; } }
      .c-section-pitch-content .c-section-pitch-content__form .c-section-pitch-content__form__gravity select {
        width: 330px;
        border: none;
        border-bottom: 2px solid #37BFB5;
        height: 33px;
        font-size: 14px;
        color: #114D90;
        padding-bottom: 10px;
        margin-right: 24px;
        background-image: url("../img/icon/bottom-green.svg");
        background-repeat: no-repeat;
        background-size: 15px;
        background-position: calc(100%) center; }
        .c-section-pitch-content .c-section-pitch-content__form .c-section-pitch-content__form__gravity select::placeholder {
          color: #114D90; }
        @media (max-width: 991.98px) {
          .c-section-pitch-content .c-section-pitch-content__form .c-section-pitch-content__form__gravity select {
            width: 300px; } }
        @media (max-width: 767.98px) {
          .c-section-pitch-content .c-section-pitch-content__form .c-section-pitch-content__form__gravity select {
            width: 100%; } }
      .c-section-pitch-content .c-section-pitch-content__form .c-section-pitch-content__form__gravity input[type="submit"] {
        display: none; }
    .c-section-pitch-content .c-section-pitch-content__form .c-section-pitch-content__form__ctas {
      display: flex;
      justify-content: flex-end;
      padding-right: 60px;
      padding-bottom: 100px; }
      .c-section-pitch-content .c-section-pitch-content__form .c-section-pitch-content__form__ctas .c-section-pitch-content__form__ctas__next.hide {
        display: none; }
      .c-section-pitch-content .c-section-pitch-content__form .c-section-pitch-content__form__ctas .c-section-pitch-content__form__ctas__submit.hide {
        display: none; }
      .c-section-pitch-content .c-section-pitch-content__form .c-section-pitch-content__form__ctas .c-section-pitch-content__form__ctas__submit.disabled {
        pointer-events: none; }

.c-section-pitch-bottom {
  color: #808080;
  padding-bottom: 80px; }

.c-section-contact {
  padding-bottom: 80px; }
  .c-section-contact .c-section-contact__info {
    position: relative;
    background-color: #37BFB5;
    color: #FFFFFF;
    padding: 76px 0 76px 56px;
    margin-top: 60px; }
    @media (max-width: 1274.98px) {
      .c-section-contact .c-section-contact__info {
        margin-top: 0; } }
    @media (max-width: 767.98px) {
      .c-section-contact .c-section-contact__info {
        padding: 20px; } }
    .c-section-contact .c-section-contact__info .c-section-contact__info__line {
      position: absolute;
      width: 1px;
      height: 60px;
      top: 0;
      left: 55px;
      z-index: 1; }
      @media (max-width: 767.98px) {
        .c-section-contact .c-section-contact__info .c-section-contact__info__line {
          display: none; } }
      .c-section-contact .c-section-contact__info .c-section-contact__info__line--green {
        background-color: #37BFB5;
        transform: translateY(-100%); }
      .c-section-contact .c-section-contact__info .c-section-contact__info__line--white {
        background-color: #FFFFFF; }
    .c-section-contact .c-section-contact__info .c-section-contact__info__title {
      font-size: 24px;
      line-height: 28px;
      font-family: "Work Sans", sans-serif;
      margin-bottom: 24px; }
    .c-section-contact .c-section-contact__info .c-section-contact__info__list .c-section-contact__info__list__element:not(:last-child) {
      margin-bottom: 28px; }
    .c-section-contact .c-section-contact__info .c-section-contact__info__list .c-section-contact__info__list__element .c-section-contact__info__list__element__city {
      margin-bottom: 5px;
      text-transform: uppercase;
      letter-spacing: 2px;
      font-size: 12px; }
    .c-section-contact .c-section-contact__info::after {
      content: '';
      position: absolute;
      z-index: 2;
      top: 0;
      right: 1px;
      transform: translateX(100%);
      width: calc(4.16667% + 48px);
      height: 100%;
      background-color: #37BFB5; }
      @media (max-width: 1274.98px) {
        .c-section-contact .c-section-contact__info::after {
          display: none; } }
  .c-section-contact .c-section-contact__form {
    background-color: #FFFFFF;
    padding: 76px 55px 100px 110px; }
    @media (max-width: 991.98px) {
      .c-section-contact .c-section-contact__form {
        padding: 20px; } }
    .c-section-contact .c-section-contact__form .c-section-contact__form__gravity {
      padding-bottom: 40px; }
      .c-section-contact .c-section-contact__form .c-section-contact__form__gravity .gform_submission_error {
        font-size: 28px;
        line-height: 32px;
        font-family: "Work Sans", sans-serif; }
      .c-section-contact .c-section-contact__form .c-section-contact__form__gravity .gform_fields {
        display: flex;
        flex-wrap: wrap; }
      .c-section-contact .c-section-contact__form .c-section-contact__form__gravity ul {
        margin-left: 0; }
      .c-section-contact .c-section-contact__form .c-section-contact__form__gravity li.gfield {
        padding-left: 0; }
        .c-section-contact .c-section-contact__form .c-section-contact__form__gravity li.gfield::before {
          display: none; }
      .c-section-contact .c-section-contact__form .c-section-contact__form__gravity .gfield {
        margin-bottom: 20px; }
        .c-section-contact .c-section-contact__form .c-section-contact__form__gravity .gfield.hide {
          display: none; }
        .c-section-contact .c-section-contact__form .c-section-contact__form__gravity .gfield.fullwidth {
          width: 100%;
          margin-right: 24px; }
          @media (max-width: 991.98px) {
            .c-section-contact .c-section-contact__form .c-section-contact__form__gravity .gfield.fullwidth {
              margin-right: 0; } }
        @media (max-width: 991.98px) {
          .c-section-contact .c-section-contact__form .c-section-contact__form__gravity .gfield {
            width: 100%; } }
      .c-section-contact .c-section-contact__form .c-section-contact__form__gravity .gfield_label {
        text-transform: uppercase;
        font-size: 10px;
        color: #424242; }
      .c-section-contact .c-section-contact__form .c-section-contact__form__gravity .ginput_counter {
        font-size: 12px;
        color: #114D90; }
      .c-section-contact .c-section-contact__form .c-section-contact__form__gravity .gfield_validation_message {
        font-size: 12px;
        color: #A51C34; }
      .c-section-contact .c-section-contact__form .c-section-contact__form__gravity input[type="email"],
      .c-section-contact .c-section-contact__form .c-section-contact__form__gravity input[type="text"] {
        width: 330px;
        border: none;
        border-bottom: 2px solid #37BFB5;
        margin-right: 24px;
        height: 30px;
        padding-bottom: 10px;
        font-size: 14px;
        color: #114D90; }
        @media (max-width: 1274.98px) {
          .c-section-contact .c-section-contact__form .c-section-contact__form__gravity input[type="email"],
          .c-section-contact .c-section-contact__form .c-section-contact__form__gravity input[type="text"] {
            width: 360px; } }
        @media (max-width: 991.98px) {
          .c-section-contact .c-section-contact__form .c-section-contact__form__gravity input[type="email"],
          .c-section-contact .c-section-contact__form .c-section-contact__form__gravity input[type="text"] {
            width: 100%;
            margin-right: 24px; } }
        .c-section-contact .c-section-contact__form .c-section-contact__form__gravity input[type="email"]::placeholder,
        .c-section-contact .c-section-contact__form .c-section-contact__form__gravity input[type="text"]::placeholder {
          color: #114D90; }
      .c-section-contact .c-section-contact__form .c-section-contact__form__gravity .ginput_container_email {
        width: 100%; }
        .c-section-contact .c-section-contact__form .c-section-contact__form__gravity .ginput_container_email input[type="email"],
        .c-section-contact .c-section-contact__form .c-section-contact__form__gravity .ginput_container_email input[type="text"] {
          width: 100%; }
      .c-section-contact .c-section-contact__form .c-section-contact__form__gravity textarea {
        width: 100%;
        border: none;
        border-bottom: 2px solid #37BFB5;
        margin-right: 24px;
        height: 110px;
        padding-bottom: 10px;
        font-size: 14px;
        color: #114D90; }
        .c-section-contact .c-section-contact__form .c-section-contact__form__gravity textarea::placeholder {
          color: #114D90; }
      .c-section-contact .c-section-contact__form .c-section-contact__form__gravity select {
        width: 330px;
        border: none;
        border-bottom: 2px solid #37BFB5;
        height: 34px;
        font-size: 14px;
        color: #114D90;
        padding-bottom: 10px;
        margin-right: 24px;
        background-image: url("../img/icon/bottom-green.svg");
        background-repeat: no-repeat;
        background-size: 15px;
        background-position: calc(100%) center; }
        .c-section-contact .c-section-contact__form .c-section-contact__form__gravity select::placeholder {
          color: #114D90; }
      .c-section-contact .c-section-contact__form .c-section-contact__form__gravity input[type="submit"] {
        display: none; }
    .c-section-contact .c-section-contact__form .c-section-contact__form__title {
      color: #114D90;
      font-size: 60px;
      line-height: 80px;
      margin-bottom: 10px; }
      @media (max-width: 991.98px) {
        .c-section-contact .c-section-contact__form .c-section-contact__form__title {
          font-size: 32px;
          line-height: 38px; } }
    .c-section-contact .c-section-contact__form .c-section-contact__form__mandatory {
      color: #37BFB5;
      padding-bottom: 48px;
      font-size: 12px; }
    .c-section-contact .c-section-contact__form .c-section-contact__form__ctas {
      display: flex;
      justify-content: flex-end;
      padding-right: 24px; }
      @media (max-width: 991.98px) {
        .c-section-contact .c-section-contact__form .c-section-contact__form__ctas {
          padding-right: 0; } }

.c-popup-download-form {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 100000;
  justify-content: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  display: none; }
  .c-popup-download-form.active {
    opacity: 1;
    display: flex;
    visibility: visible; }
  .c-popup-download-form .c-popup-download-form__cover {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); }
  .c-popup-download-form .c-popup-download-form__content {
    position: relative;
    background-color: #FFFFFF;
    border-radius: 40px;
    padding: 60px;
    max-height: 90vh;
    min-width: 600px;
    max-width: 95vw; }
    .c-popup-download-form .c-popup-download-form__content .c-popup-download-form__content__toggle {
      position: absolute;
      top: 10px;
      right: 10px;
      width: 30px;
      height: 30px;
      cursor: pointer; }
      .c-popup-download-form .c-popup-download-form__content .c-popup-download-form__content__toggle:before {
        content: '';
        position: absolute;
        inset: 0;
        margin: auto;
        width: 20px;
        height: 2px;
        background-color: #A51C34;
        transform: rotate(-45deg);
        transition: top .8s, left .8s, width .8s; }
      .c-popup-download-form .c-popup-download-form__content .c-popup-download-form__content__toggle:after {
        content: '';
        position: absolute;
        inset: 0;
        margin: auto;
        width: 20px;
        height: 2px;
        background-color: #A51C34;
        transform: rotate(45deg);
        transition: top .8s, left .8s, width .8s; }
    .c-popup-download-form .c-popup-download-form__content .c-popup-download-form__content__btn {
      margin-top: 40px;
      margin-bottom: 20px; }
    .c-popup-download-form .c-popup-download-form__content .gfield_validation_message {
      font-size: 12px;
      color: #A51C34;
      margin-bottom: 30px; }
    .c-popup-download-form .c-popup-download-form__content .gform_title {
      display: none; }
    .c-popup-download-form .c-popup-download-form__content .gfield_label {
      font-weight: 300;
      text-transform: uppercase;
      font-size: 14px;
      letter-spacing: .2px;
      margin-bottom: 5px;
      display: none; }
    .c-popup-download-form .c-popup-download-form__content input[type="checkbox"] {
      -webkit-appearance: auto; }
    .c-popup-download-form .c-popup-download-form__content #label_4_7_1 {
      font-size: 14px;
      color: #114D90; }
    .c-popup-download-form .c-popup-download-form__content input[type="email"],
    .c-popup-download-form .c-popup-download-form__content input[type="text"] {
      margin-bottom: 15px;
      width: 100%;
      border: none;
      border-bottom: 2px solid #37BFB5;
      margin-right: 24px;
      height: 30px;
      padding-bottom: 10px;
      font-size: 14px;
      color: #114D90; }
      .c-popup-download-form .c-popup-download-form__content input[type="email"]::placeholder,
      .c-popup-download-form .c-popup-download-form__content input[type="text"]::placeholder {
        color: #114D90; }
      @media (max-width: 991.98px) {
        .c-popup-download-form .c-popup-download-form__content input[type="email"],
        .c-popup-download-form .c-popup-download-form__content input[type="text"] {
          width: 300px; } }
      @media (max-width: 767.98px) {
        .c-popup-download-form .c-popup-download-form__content input[type="email"],
        .c-popup-download-form .c-popup-download-form__content input[type="text"] {
          width: 100%; } }
    .c-popup-download-form .c-popup-download-form__content input[type="submit"] {
      display: none; }

/*
|--------------------
|      BUTTONS
|--------------------
*/
.btn, .btn-black, .btn-white, .btn-xs, .btn-sm, .btn-md, .btn-lg {
  background-color: #A51C34;
  border-radius: 30px;
  color: #FFFFFF;
  font-size: 12px;
  line-height: 14px;
  font-weight: 700;
  text-transform: uppercase;
  border: 2px solid #A51C34;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  padding: 4px 10px 4px 24px;
  transition: background-color .2s ease-out, color .2s ease-out; }
  .btn .btn__text, .btn-black .btn__text, .btn-white .btn__text, .btn-xs .btn__text, .btn-sm .btn__text, .btn-md .btn__text, .btn-lg .btn__text {
    margin-right: 20px; }
  .btn .btn__plus, .btn-black .btn__plus, .btn-white .btn__plus, .btn-xs .btn__plus, .btn-sm .btn__plus, .btn-md .btn__plus, .btn-lg .btn__plus,
  .btn .btn__arrow,
  .btn-black .btn__arrow,
  .btn-white .btn__arrow,
  .btn-xs .btn__arrow,
  .btn-sm .btn__arrow,
  .btn-md .btn__arrow,
  .btn-lg .btn__arrow {
    min-width: 40px;
    height: 40px;
    background-color: #FFFFFF;
    border-radius: 100%; }
  .btn .btn__plus, .btn-black .btn__plus, .btn-white .btn__plus, .btn-xs .btn__plus, .btn-sm .btn__plus, .btn-md .btn__plus, .btn-lg .btn__plus {
    display: flex;
    align-items: center;
    justify-content: center; }
  .btn:hover, .btn-black:hover, .btn-white:hover, .btn-xs:hover, .btn-sm:hover, .btn-md:hover, .btn-lg:hover {
    background-color: #FFFFFF;
    color: #A51C34; }
    .btn:hover .btn__arrow, .btn-black:hover .btn__arrow, .btn-white:hover .btn__arrow, .btn-xs:hover .btn__arrow, .btn-sm:hover .btn__arrow, .btn-md:hover .btn__arrow, .btn-lg:hover .btn__arrow {
      background-color: #A51C34; }
      .btn:hover .btn__arrow .long-arrow-right--red, .btn-black:hover .btn__arrow .long-arrow-right--red, .btn-white:hover .btn__arrow .long-arrow-right--red, .btn-xs:hover .btn__arrow .long-arrow-right--red, .btn-sm:hover .btn__arrow .long-arrow-right--red, .btn-md:hover .btn__arrow .long-arrow-right--red, .btn-lg:hover .btn__arrow .long-arrow-right--red {
        border-color: #FFFFFF; }
        .btn:hover .btn__arrow .long-arrow-right--red:after, .btn-black:hover .btn__arrow .long-arrow-right--red:after, .btn-white:hover .btn__arrow .long-arrow-right--red:after, .btn-xs:hover .btn__arrow .long-arrow-right--red:after, .btn-sm:hover .btn__arrow .long-arrow-right--red:after, .btn-md:hover .btn__arrow .long-arrow-right--red:after, .btn-lg:hover .btn__arrow .long-arrow-right--red:after {
          background-color: #FFFFFF; }
  .btn--reverse {
    background-color: #FFFFFF;
    color: #A51C34; }
    .btn--reverse .btn__arrow {
      background-color: #A51C34; }
      .btn--reverse .btn__arrow .long-arrow-right {
        border-color: #FFFFFF; }
        .btn--reverse .btn__arrow .long-arrow-right:after {
          background-color: #FFFFFF; }
    .btn--reverse:hover {
      background-color: #A51C34;
      color: #FFFFFF; }
      .btn--reverse:hover .btn__arrow {
        background-color: #FFFFFF; }
        .btn--reverse:hover .btn__arrow .long-arrow-right {
          border-color: #A51C34; }
          .btn--reverse:hover .btn__arrow .long-arrow-right:after {
            background-color: #A51C34; }
  .btn--lightblue {
    background-color: #D7F2F0;
    color: #A51C34; }
    .btn--lightblue .btn__plus,
    .btn--lightblue .btn__arrow {
      background-color: #A51C34; }
  .btn--green {
    background-color: #37BFB5;
    color: #FFFFFF;
    border: 2px solid #FFFFFF; }
    .btn--green:hover {
      background-color: #FFFFFF;
      color: #37BFB5; }
      .btn--green:hover .btn__arrow {
        background-color: #37BFB5; }
        .btn--green:hover .btn__arrow .long-arrow-right--green {
          border-color: #FFFFFF; }
          .btn--green:hover .btn__arrow .long-arrow-right--green:after {
            background-color: #FFFFFF; }

.long-arrow-right {
  display: block;
  margin: 14px auto;
  width: 11px;
  height: 10px;
  border-top: 2px solid #FFFFFF;
  border-left: 2px solid #FFFFFF;
  transform: rotate(135deg); }
  .long-arrow-right:after {
    content: "";
    display: block;
    width: 2px;
    height: 12px;
    background-color: #FFFFFF;
    transform: rotate(-45deg) translate(3.5px, 1px);
    left: 0;
    top: 0; }
  .long-arrow-right--red {
    border-top: 2px solid #A51C34;
    border-left: 2px solid #A51C34; }
    .long-arrow-right--red:after {
      background-color: #A51C34; }
  .long-arrow-right--green {
    border-top: 2px solid #37BFB5;
    border-left: 2px solid #37BFB5; }
    .long-arrow-right--green:after {
      background-color: #37BFB5; }

.plus {
  height: 14px;
  width: 14px;
  position: relative; }
  .plus .plus__horizontal,
  .plus .plus__vertical {
    position: absolute;
    width: 2px;
    height: inherit;
    background-color: #FFFFFF;
    left: 50%; }
  .plus .plus__vertical {
    left: 50%;
    transform: translateX(-50%); }
  .plus .plus__horizontal {
    transform-origin: center;
    transform: translateX(-50%) rotate(90deg); }

/*
|--------------------
|  Smooth Scrollbar
|--------------------
*/
/*
|
| Scroll area
|--------------
*/
.scroll-area .scrollbar-track.scrollbar-track-y {
  width: 4px !important; }
  .scroll-area .scrollbar-track.scrollbar-track-y .scrollbar-thumb {
    width: 100% !important;
    background: #000000 !important; }

/*
|-----------------------
| 	  Gravity Form
|-----------------------
|
*/
/*
|
| Reset form elements
|-------------------------
*/
input, textarea, select {
  -webkit-appearance: none;
  border-radius: 0; }
  input:focus, textarea:focus, select:focus {
    outline: none; }

.gform_validation_container {
  display: none !important; }

select {
  background-image: url("../img/icon/bottom.svg");
  background-repeat: no-repeat;
  background-size: 20px;
  background-position: calc(100% - 10px) center; }

/*
|
| Gravity form bootstrap
|-------------------------
*/
.gravity-form-bootstrap .gform_fields input, .gravity-form-bootstrap .gform_fields textarea {
  width: 100%;
  background-color: transparent; }

/*
|
| Materialize form
|-------------------
*/
.materialize-form .materialize-form-group {
  position: relative;
  margin-bottom: 25px; }
  .materialize-form .materialize-form-group label {
    position: absolute;
    top: 0;
    left: 15px;
    font-weight: 400;
    font-size: 18px; }
  .materialize-form .materialize-form-group input {
    margin-bottom: 35px;
    padding: 7px 0;
    border: 0;
    border-bottom: 2px solid #dedede; }
  .materialize-form .materialize-form-group.gfield_error label {
    top: -20px; }

/*
|
| Contact form
|---------------
*/
.contact-form label {
  color: #141414; }

.contact-form textarea {
  border: 2px #dedede solid;
  height: 200px;
  padding: 15px;
  color: #000000;
  font-size: 18px;
  border-radius: 2px; }
  .contact-form textarea::placeholder {
    color: #000000; }

.contact-form .gform_body {
  margin-bottom: 20px; }

.contact-form .gform_footer {
  text-align: right; }

.contact-form input[type="submit"] {
  position: relative;
  background-color: transparent;
  color: #424242;
  padding: 15px 30px;
  transition: all 0.3s ease-out;
  text-transform: uppercase;
  cursor: pointer;
  border: 2px solid #dedede;
  border-radius: 2px; }
  .contact-form input[type="submit"]:hover {
    color: #FFFFFF;
    background: #dedede; }

.contact-form .gform_confirmation_message {
  color: #141414; }

.contact-form .validation_message {
  margin-top: 5px;
  color: #A51C34;
  font-size: 14px; }

.validation_error {
  margin-bottom: 30px;
  color: #A51C34 !important; }

#gform_ajax_spinner_1 {
  margin-left: 10px;
  display: inline-block;
  width: 50px;
  height: 50px; }

/*
|
| Page Loader
|--------------
*/
.page-loader {
  position: fixed;
  z-index: 20000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: #FFFFFF;
  display: none; }
  .page-loader.active {
    display: flex; }
  .page-loader .item-content {
    width: 100%;
    color: #141414;
    text-align: center; }
    .page-loader .item-content img {
      width: 100px; }
  .page-loader .item-loadbar {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5px;
    margin: auto; }
    .page-loader .item-loadbar .item-loadbar-inner {
      width: 100%;
      height: 100%;
      border-radius: 1px;
      background-color: #141414;
      animation: loadbar 1.2s cubic-bezier(0.92, 0, 0.09, 1);
      transform-origin: left top; }

@keyframes loadbar {
  from {
    transform: scaleX(0); }
  to {
    transform: scaleX(0.7); } }

.c-loader-search {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: white;
  z-index: 10000;
  display: none;
  opacity: 0;
  visibility: hidden;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;
  transition: visibility .2s ease-out, opacity .2s ease-out .2s; }
  .c-loader-search.active {
    opacity: 1;
    display: flex;
    visibility: visible; }
  .c-loader-search .spinner-box {
    width: 300px;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent; }
  .c-loader-search .three-quarter-spinner {
    width: 50px;
    height: 50px;
    border: 3px solid #000000;
    border-top: 3px solid transparent;
    border-radius: 50%;
    animation: spin .5s linear 0s infinite; }

@keyframes spin {
  from {
    transform: rotate(0); }
  to {
    transform: rotate(359deg); } }

[data-splittext] {
  opacity: 0; }
  [data-splittext].split-ready {
    opacity: 1; }

[data-kira-item="splitline"] .item-line {
  overflow: hidden; }

[data-kira-item^="fadeIn"] {
  opacity: 0; }

[data-kira-item^="fadeInLeft.stagger"],
[data-kira-item^="fadeInUp.stagger"] {
  opacity: 1; }
  [data-kira-item^="fadeInLeft.stagger"] [data-stagger-item],
  [data-kira-item^="fadeInUp.stagger"] [data-stagger-item] {
    opacity: 0; }
