.c-loader-search {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color : rgba(255,255,255, 1);
    z-index: 10000;
    display: none;
    opacity: 0;
    visibility: hidden;
    justify-content: center;
    align-items: center;
    color: $white;

    transition: visibility .2s ease-out, opacity .2s ease-out .2s;

    &.active {
        opacity: 1;
        display: flex;
        visibility: visible;
    }

    .spinner-box {
        width: 300px;
        height: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: transparent;
    }

    .three-quarter-spinner {
        width: 50px;
        height: 50px;
        border: 3px solid $black;
        border-top: 3px solid transparent;
        border-radius: 50%;
        animation: spin .5s linear 0s infinite;
    }

    @keyframes spin {
        from {
          transform: rotate(0);
        }
        to{
          transform: rotate(359deg);
        }
    }
}