.c-header-contact {
    padding-top: 70px;
    padding-bottom: 80px;

    .c-header-contact__title {
        color: $blue;
        margin-bottom: 16px;
    }

    .c-header-contact__text {
        max-width: 640px;
    }
}