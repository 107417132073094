.c-header-news {
    margin-bottom: 80px;

    .c-header-news__back {
        position: relative;
        display: inline-flex;
        align-items: center;
        margin-bottom: 104px;

        .c-header-news__back__link {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        .c-header-news__back__arrow {
            height: 40px;
            width: 40px;
            border: 1px solid $red;
            margin-right: 20px;
            border-radius: 100%;
            transform: rotate(180deg);

            transition: border-color .2s ease-out;
        }

        .c-header-news__back__text {
            text-transform: uppercase;
            color: $red;

            transition: color .2s ease-out;
        }

        &:hover {
            .c-header-news__back__text {
                color: $blue;
            }

            .c-header-news__back__arrow {
                border-color: $blue;
            }

            .long-arrow-right {
                border-color: $blue;
        
                &:after {
                    background-color: $blue;
                }
            }
        }
    }

    .c-header-news__content-cover {
        height: 100%;
        width: 2000vw;
        position: absolute;
        top: 0;
        right: 0;
        background-color: $green;
    }

    .c-header-news__content-image {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 2;
        background-color: $black;
        height: 680px;
        width: 470px;

        background-size: cover;
        background-position: center;

        @include media-breakpoint-down(lg) {
            display: none;
        }
    }

    .c-header-news__content {
        position: relative;
        background-color: $green;
        padding-bottom: 142px;
        color: $white;

        .c-header-news__content__top {
            display: flex;
            margin-bottom: 40px;

            .c-header-news__content__top__terms {
                padding-top: 32px;
                .c-header-news__content__top__terms__element {
                    color: $white;
                    border: 1px solid $white;
                    border-radius: 20px;
                    text-transform: uppercase;
                    font-size: 12px;
                    line-height: 24px;
                    padding: 8px 24px;
                    margin-right: 50px;
                    margin-bottom: 10px;
                }
            }

            .c-header-news__content__top__separator {
                height: 100px;
                background-color: $white;
                width: 1px;

                &::before {
                    content: "";
                    position: absolute;
                    height: 100px;
                    transform: translateY(-100%);
                    width: 1px;
                    background-color: $green;
                }
            }

            .c-header-news__content__top__data {
                padding-top: 32px;
                margin-left: 50px;

                .c-header-news__content__top__data__author {
                    font-size: 24px;
                    line-height: 28px;
                    font-style: italic;
                    margin-bottom: 6px;
                    max-width: 400px;
                }
            }
        }

        .c-header-news__content__title {
            max-width: 800px;
            margin-bottom: 24px;
            font-size: 60px;
            line-height: 68px;
        }

        .c-header-news__content__text {
            max-width: 750px;
        }
    }
}