.c-header-jobs {
    padding-top: 88px;
    margin-top: 32px;
    padding-bottom: 80px;

    .c-header-jobs__image-container {
        position: absolute;
        top: -88px;
        left: 0;
        width: 352px;
        height: 361px;
        background-color: $blue;
        background-size: cover;
        background-position: center;
    }

    .c-header-jobs__content {
        position: relative;
        padding-top: 80px;
        padding-bottom: 120px;
        padding-left: 165px;
        color: $white;
        background-color: $green;

        @include media-breakpoint-down(md) {
            padding-left: 10px;
            padding-right: 10px;
        }

        .c-header-jobs__content__title {
            margin-bottom: 20px;
        }

        .c-header-jobs__content__text {
            max-width: 640px;
        }

        &::after,
        &::before {
            content: "";
            position: absolute;
            width: 1px;
            height: 80px;
            left: 160px;
            bottom: 0;
        }

        &::before {
            background-color: $white;
        }

        &::after {
            background-color: $green;
            transform: translateY(100%);
        }
    }
}