.c-section-news-latest {
    background-color: $white;

    &--related {
        padding: 80px 0;
    }

    .c-section-news-latest__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 70px;

        @include media-breakpoint-down(sm) {
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
        }
        
        .c-section-news-latest__header__title {
            color: $blue;

            @include media-breakpoint-down(sm) {
                margin-bottom: 20px;
            }
        }
    }
}