.c-section-contact {
    padding-bottom: 80px;

    .c-section-contact__info {
        position: relative;
        background-color: $green;
        color: $white;
        padding: 76px 0 76px 56px;
        margin-top: 60px;

        @include media-breakpoint-down(lg) {
            margin-top: 0;
        }

        @include media-breakpoint-down(sm) {
            padding: 20px;
        }

        .c-section-contact__info__line {
            position: absolute;
            width: 1px;
            height: 60px;
            top: 0;
            left: 55px;
            z-index: 1;

            @include media-breakpoint-down(sm) {
                display: none;
            }

            &--green {
                background-color: $green;
                transform: translateY(-100%);
            }

            &--white {
                background-color: $white;
            }
        }

        .c-section-contact__info__title {
            font-size: 24px;
            line-height: 28px;
            font-family: $font-family-default;
            margin-bottom: 24px;
        }

        .c-section-contact__info__list {
            .c-section-contact__info__list__element {
                &:not(:last-child) {
                    margin-bottom: 28px;
                }

                .c-section-contact__info__list__element__city {
                    margin-bottom: 5px;
                    text-transform: uppercase;
                    letter-spacing: 2px;
                    font-size: 12px;
                }
            }
        }

        &::after {
            content: '';
            position: absolute;
            z-index: 2;
            top: 0;
            right: 1px;
            transform: translateX(100%);
            width: calc(4.16667% + 48px);
            height: 100%;
            background-color: $green;

            @include media-breakpoint-down(lg) {
                display: none;
            }
        }
    }

    .c-section-contact__form {
        background-color: $white;
        padding: 76px 55px 100px 110px;

        @include media-breakpoint-down(md) {
            padding: 20px;
        }

        .c-section-contact__form__gravity {
            padding-bottom: 40px;

            .gform_submission_error {
                font-size: 28px;
                line-height: 32px;
                font-family: $font-family-default;
            }

            .gform_fields {
                display: flex;
                flex-wrap: wrap;
            }

            ul {
                margin-left: 0;
            }

            li.gfield {
                padding-left: 0;

                &::before {
                    display: none;
                }
            }

            .gfield {
                margin-bottom: 20px;

                &.hide {
                    display: none;
                }

                &.fullwidth {
                    width: 100%;
                    margin-right: 24px;

                    @include media-breakpoint-down(md) {
                        margin-right: 0;
                    }
                }

                @include media-breakpoint-down(md) {
                    width: 100%;
                }
            }

            .gfield_label {
                text-transform: uppercase;
                font-size: 10px;
                color: $dark-grey;
            }

            .ginput_counter {
                font-size: 12px;
                color: $blue;
            }

            .gfield_validation_message {
                font-size: 12px;
                color: $red;
            }

            input[type="email"],
            input[type="text"] {
                width: 330px;
                border: none;
                border-bottom: 2px solid $green;
                margin-right: 24px;
                height: 30px;
                padding-bottom: 10px;
                font-size: 14px;
                color: $blue;

                @include media-breakpoint-down(lg) {
                    width: 360px;
                }

                @include media-breakpoint-down(md) {
                    width: 100%;
                    margin-right: 24px;
                }

                &::placeholder {
                    color: $blue;
                }
            }

            .ginput_container_email {
                width: 100%;

                input[type="email"],
                input[type="text"] {
                    width: 100%;
                }
            }

            textarea {
                width: 100%;
                border: none;
                border-bottom: 2px solid $green;
                margin-right: 24px;
                height: 110px;
                padding-bottom: 10px;
                font-size: 14px;
                color: $blue;

                &::placeholder {
                    color: $blue;
                }
            }

            select {
                width: 330px;
                border: none;
                border-bottom: 2px solid $green;
                height: 34px;
                font-size: 14px;
                color: $blue;
                padding-bottom: 10px;
                margin-right: 24px;

                background-image: url('../img/icon/bottom-green.svg');
                background-repeat: no-repeat;
                background-size: 15px;
                background-position: calc(100%) center;

                &::placeholder {
                    color: $blue;
                }
            }

            input[type="submit"] {
                display: none;
            }
        }

        .c-section-contact__form__title {
            color: $blue;
            font-size: 60px;
            line-height: 80px;
            margin-bottom: 10px;

            @include media-breakpoint-down(md) {
                font-size: 32px;
                line-height: 38px;
            }
        }

        .c-section-contact__form__mandatory {
            color: $green;
            padding-bottom: 48px;
            font-size: 12px;
        }

        .c-section-contact__form__ctas {
            display: flex;
            justify-content: flex-end;
            padding-right: 24px;

            @include media-breakpoint-down(md) {
                padding-right: 0;
            }            
        }
    }
}