.c-card-team {
    .c-card-team__line {
        position: absolute;
        bottom: 65px;
        left: 30px;
        width: 1px;
        height: 140px;
        background-color: $green;
    }

    .c-card-team__line-green,
    .c-card-team__line-white {
        display: none;
    }

    .c-card-team__image {
        width: 100%;
        height: 400px;
        background-size: cover;
        background-position: center;
        margin-bottom: 24px;

        .c-card-team__image__hover {
            height: 100%;
            width: 100%;
            background-color: $white;
            position: absolute;
            top: 0;
            left: 0;
            color: $green;
            padding: 32px 32px;
            opacity: 0;

            transition: opacity .5s ease-out;
        }
    }

    .c-card-team__info {
        padding-left: 56px;

        .c-card-team__info__name {
            color: $green;
            font-size: 30px;
            font-weight: 500;
            line-height: 35px;
            margin-bottom: 4px;
        }

        .c-card-team__info__job {
            margin-bottom: 12px;
        }

        .c-card-team__info__socials {
            display: flex;

            a {
                border-radius: 100%;
                border: 2px solid $black;
                display: flex;
                height: 36px;
                width: 35px;
                background-color: $black;

                img {
                    width: 100%;
                    transition: filter .5s ease-out;
                }

                &:not(:last-child) {
                    margin-right: 16px;
                }

                &:hover {
                    img {
                        filter: invert(1);
                    }
                }
            }
        }
    }

    &.active,
    &:hover {
        .c-card-team__image {
            .c-card-team__image__hover {
                opacity: .9;
            }
        }

        .c-card-team__line-green {
            height: 20px;
        }
    }

    .c-card-team__line-green {
        position: absolute;
        bottom: 0;
        left: 30px;
        width: 1px;
        height: 70px;
        background-color: $green;
        display: block;
        transition: height .5s ease-out;
    }

    .c-card-team__line-white {
        position: absolute;
        top: -24px;
        left: 30px;
        width: 1px;
        height: 70px;
        background-color: $white;
        display: block;
    }

    .c-card-team__line {
        display: none;
    }

    &--alternate {
        .c-card-team__info {
            .c-card-team__info__job,
            .c-card-team__info__name {
                color: $white;
            }
        }
    }
}