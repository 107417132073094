.c-section-scaling-benefits {
    position: relative;
    padding-bottom: 95px;

    .c-section-scaling-benefits__decoration {
        position: absolute;
        bottom: -45px;
        left: 60%;
        height: calc(100% - 170px + 45px);
        width: 2000vw;
        background-color: $green;

        @include media-breakpoint-down(md) {
            bottom: -100px;
        }

        @include media-breakpoint-down(sm) {
            display: none;
        }
    }

    .c-section-scaling-benefits__title {
        color: $blue;
        margin-bottom: 32px;
    }

    .c-section-scaling-benefits__text {
        margin-bottom: 73px;
    }

    .c-section-scaling-benefits__card {
        position: relative;
        padding-top: 18px;
        margin-bottom: 40px;

        .c-section-scaling-benefits__card__number {
            height: 86px;
            width: 86px;
            position: absolute;
            background-color: $green;
            top: 0;
            left: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 24px;
            line-height: 28px;
            color: $white;
        }

        .c-section-scaling-benefits__card__info {
            background-color: $white;
            margin-left: 30px;
            padding-left: 80px;
            padding-top: 40px;
            padding-right: 50px;
            padding-bottom: 40px;
            min-height: 272px;

            .c-section-scaling-benefits__card__info__title {
                color: $green;
                margin-bottom: 16px;
                font-size: 24px;
                line-height: 28px;
            }
        }
    }
}