.c-section-newsletter {
    margin-bottom: 80px;

    .c-section-newsletter__decoration {
        position: absolute;
        top: -80px;
        right: -60px;
        height: 806px;
        width: 323px;
        background-position: center;
        background-size: cover;
        z-index: 2;

        @include media-breakpoint-down(lg) {
            display: none;
        }
    }

    .c-section-newsletter__content {
        background-color: $green;
        padding: 80px 55px;
        color: $white;

        .c-section-newsletter__content__title {
            font-size: 60px;
            line-height: 72px;
            margin-bottom: 16px;
            max-width: 700px;
        }

        .c-section-newsletter__content__text {
            margin-bottom: 24px;
            max-width: 530px;
        }

        .c-section-newsletter__content__list {
            margin-bottom: 40px;
            display: flex;

            a {
                border-radius: 100%;
                border: 2px solid $black;
                display: flex;
                height: 44px;
                width: 43px;
                background-color: $black;

                img {
                    transition: filter .2s ease-out;
                }

                &:not(:last-child) {
                    margin-right: 16px;
                }

                &:hover {
                    img {
                        filter: invert(1);
                    }
                }
            }
        }
    }
}