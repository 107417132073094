.c-section-wysiwyg {
    padding-bottom: 64px;
    
    h2 {
        color: $blue;
        font-size: 40px;
        line-height: 48px;
        margin-top: 44px;
        margin-bottom: 24px;
    }

    p {
        margin-bottom: 20px;
        font-size: 18px;
    }

    ul{

        li{

            font-size: 18px;

        }

    }

    img {
        max-width: 100%;
        height: auto;
    }
}