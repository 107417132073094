.c-section-jobs-offer {
    margin-bottom: 30px;
    
    .c-section-jobs-offer__title {
        color: $blue;
        margin-bottom: 32px;
    }

    .c-section-jobs-offer__text {
        margin-bottom: 40px;
    }

    .welcomekit-jobs-list {
      margin-left: 0;
    }

    .welcomekit-jobs-list-item {
        margin: 10px 0;
        padding: 0;
        // background-color: $white;
        border: 1px solid $red;

        &::before {
          display: none;
        }
    }
    .welcomekit-jobs-list-item-link {
      position: relative;
      display: block;
      margin: 0;
      padding: 30px 15px;
      border-bottom: 1px solid #eee;
      text-decoration: none;
      transition: all 0.2s;
    }
    .welcomekit-jobs-list-item-link:after {
      position: absolute;
      top: 50%;
      right: 30px;
      width: 20px;
      height: 20px;
      margin-top: -10px;
      content: ' ';
      border-top: 1px solid $red;
      border-right: 1px solid $red;
      transform: rotate(45deg);
      transition: all 0.2s;
    }
    .welcomekit-jobs-list-item-link:hover {
      background: $red;
      border-bottom-color: $red;

      h3 {
        color: $white;
      }
    }
    .welcomekit-jobs-list-item-link:hover:after {
      right: 20px;
      border-top-color: $white;
      border-right-color: $white;
    }
    .welcomekit-job-name {
      margin: 0 0 10px 0;
      padding: 0;
      font-weight: 500;
      font-size: 20px;
      line-height: 20px;
      color: $red;
    }
    .welcomekit-job-infos {
      margin: 0;
      padding: 0;
    }
    .welcomekit-job-infos > li {
      position: relative;
      display: inline-block;
      margin: 0 34px 0 0;
      color: #aaa;
    }
    .welcomekit-job-infos > li:before {
      position: absolute;
      top: 6px;
      left: -20px;
      content: ' ';
      display: block;
      height: 6px;
      width: 6px;
      background: #ddd;
      border-radius: 50%;
    }
    .welcomekit-job-infos > li:first-child:before {
      display: none;
    }
    .welcomekit-job-infos > li.welcomekit-job-description {
      display: block;
      margin: 10px 0 0 0;
      padding: 10px 20px;
      border-left: 2px solid #eee;
    }
    .welcomekit-job-infos > li.welcomekit-job-description p,
    .welcomekit-job-infos > li.welcomekit-job-description ul {
      margin: 0;
      padding: 0 0 10px 0;
      color: #bbb;
    }
    .welcomekit-job-infos > li.welcomekit-job-description ul li {
      border-left: 1px solid #eee;
      padding-left: 10px;
      margin-bottom: 10px;
    }
    .welcomekit-job-infos > li.welcomekit-job-description pre {
      border-left: 1px solid #eee;
      padding-left: 10px;
      overflow: auto;
    }
    .welcomekit-job-infos > li.welcomekit-job-description:before {
      display: none
    }
    .welcomekit-office-city,
    .welcomekit-department-name {
      display: block;
      padding: 15px;
      background: #eee;
      border-top: 1px solid #e0e0e0;
      border-bottom: 1px solid #e0e0e0;
      font-weight: 500;
      font-size: 17px;
      line-height: 18px;
      text-transform: uppercase;
    }
    .welcomekit-warning {
      display: block;
      padding: 50px 0;
      text-align: center;
    }      
}