.c-header-startups {

    .c-header-startups__back {
        position: relative;
        display: inline-flex;
        align-items: center;
        margin-bottom: 40px;

        .c-header-startups__back__link {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        .c-header-startups__back__arrow {
            height: 40px;
            width: 40px;
            border: 1px solid $red;
            margin-right: 20px;
            border-radius: 100%;
            transform: rotate(180deg);

            transition: border-color .2s ease-out;
        }

        .c-header-startups__back__text {
            text-transform: uppercase;
            color: $red;

            transition: color .2s ease-out;
        }

        &:hover {
            .c-header-startups__back__text {
                color: $blue;
            }

            .c-header-startups__back__arrow {
                border-color: $blue;
            }

            .long-arrow-right {
                border-color: $blue;
        
                &:after {
                    background-color: $blue;
                }
            }
        }
    }

    .c-header-startups__title {
        color: $blue;
        padding-bottom: 150px;

        @include media-breakpoint-down(lg) {
            padding-bottom: 40px;
        }
    }

    .c-header-startups__info {
        position: relative;
        padding-bottom: 20px;

        .c-header-startups__info__logo {
            height: 400px;
            background-color: $black;
            position: relative;
            z-index: 10;
            transform: translateY(-96px);
            background-size: cover;
            background-position: center;

            @include media-breakpoint-down(lg) {
                transform: translateY(0);
            }

            @include media-breakpoint-down(sm) {
                height: 200px;
            }
        }

        .c-header-startups__info__data {
            height: 420px;
            background-color: $green;
            position: absolute;
            top: 0;

            @include media-breakpoint-down(lg) {
                position: relative;
            }

            @include media-breakpoint-down(sm) {
                height: auto;
            }

            .c-header-startups__info__data__website {
                position: absolute;
                bottom: 32px;
                left: 72px;

                @include media-breakpoint-down(lg) {
                    top: 35px;
                    bottom: auto;
                    right: 12px;
                    left: auto;
                    max-width: 200px;
                }

                @include media-breakpoint-down(sm) {
                    padding-left: 14px;
                    max-width: 180px;

                    .btn__text {
                        margin-right: 14px;
                    }
                }
            }

            .c-header-startups__info__data__taxonomies {
                position: relative;
                padding-top: 40px;
                left: 330px;
                width: calc(100% - 330px);
                height: 100%;
                color: $white;

                &::after,
                &::before {
                    content: "";
                    position: absolute;
                    width: 1px;
                    height: 100px;
                    left: -24px;
                    top: 0;
                }
            
                &::before {
                    background-color: $green;
                    transform: translateY(-100%);
                }
            
                &::after {
                    background-color: $white;
                    transform: translateY(0%);
                }

                @include media-breakpoint-down(lg) {
                    width: auto;
                    left: 0;
                    padding-bottom: 40px;
                }

                @include media-breakpoint-down(sm) {
                    padding-bottom: 150px;
                }

                .c-header-startups__info__data__taxonomies__top {
                    display: flex;
                    flex-wrap: wrap;
                    margin-bottom: 28px;

                    @include media-breakpoint-down(lg) {
                        width: calc(100% - 200px);
                    }

                    .c-header-startups__info__data__taxonomies__top__element {
                        color: $white;
                        border: 1px solid $white;
                        border-radius: 20px;
                        text-transform: uppercase;
                        font-size: 12px;
                        line-height: 24px;
                        padding: 8px 24px;
                        margin-right: 10px;
                        margin-bottom: 5px;
                    }
                }

                .c-header-startups__info__data__taxonomies__body {
                    display: flex;
                    flex-wrap: wrap;

                    .c-header-startups__info__data__taxonomies__body__element {
                        margin-bottom: 30px;
                        width: 50%;

                        .c-header-startups__info__data__taxonomies__body__element__title {
                            font-size: 12px;
                            line-height: 24px;
                            letter-spacing: 2px;
                            margin-bottom: 5px;
                            font-family: $font-family-default;
                            text-transform: uppercase;
                        }
                    }
                }

                .c-header-startups__info__data__taxonomies__bottom {
                    position: absolute;
                    bottom: 0;
                    transform: translateY(40px);

                    .c-header-startups__info__data__taxonomies__bottom__title {
                        font-size: 24px;
                        line-height: 28px;
                        margin-bottom: 24px;
                        font-family: $font-family-default;
                    }

                    .c-header-startups__info__data__taxonomies__bottom__image-container {
                        display: flex;
                    }
                    
                    .c-header-startups__info__data__taxonomies__bottom__image {
                        width: 86px;
                        height: 112px;
                        background-color: $black;
                        background-size: cover;
                        background-position: center;

                        &:not(:last-child) {
                            margin-right: 24px;
                        }
                    }
                }
            }

            .c-header-startups__info__data__deco {
                background-color: $black;
                height: 420px;
                width: 420px;
                position: absolute;
                top: 0;
                right: 0;
                transform: translateX(100%);
                background-size: cover;
                background-position: center;

                @include media-breakpoint-down(lg) {
                    display: none;
                }
            }
        }        
    }
}