.c-section-passion {
    position: relative;

    @include media-breakpoint-down(lg) {
        background-color: $green;
    }

    .c-section-passion__line {
        &::after,
        &::before {
            content: '';
            height: 80px;
            width: 1px;
            position: absolute;
        }

        &--bottom::after,
        &--bottom::before {
            bottom: 0;
            left: 50%;
        }

        &--bottom::after {
            background-color: $white;
        }

        &--bottom::before {
            background-color: $green;
            transform: translateY(100%);
        }

        // &--top::after,
        // &--top::before {
        //     top: 0;
        //     left: 55px;
        // }

        // &--top::after {
        //     background-color: $white;
        //     z-index: 2;
        // }
 
        // &--top::before {
        //     background-color: $green;
        //     transform: translateY(-100%);
        // }

        &--top {
            span {
                position: absolute;
                top: -60px;
                left: 65px;
                color: $green;
                text-transform: uppercase;
                font-size: 10px;
            }

            &__green,
            &__white {
                height: 80px;
                width: 1px;
                position: absolute;
                top: 0;
                left: 55px;
                transform: scaleY(0);
            }

            &__green {
                background-color: $green;
                top: -80px;
                transform-origin: top;
                animation: grow_green 4s linear infinite;
            }

            &__white {
                background-color: $white;
                z-index: 2;
                transform-origin: top;
                animation: grow_white 4s linear infinite;
            }

            @keyframes grow_green {
                0% {
                  transform: scaleY(0);
                }
                50%{
                  transform: scaleY(1);
                }
                100% {
                  transform: scaleY(1);
                }
            }

            @keyframes grow_white {
                0% {
                  transform: scaleY(0);
                }
                50%{
                  transform: scaleY(0);
                }
                100% {
                  transform: scaleY(1);
                }
            }
        }
    }

    .c-section-passion__content {
        position: relative;
        padding-top: 120px;
        padding-bottom: 120px;

        @include media-breakpoint-down(md) {
            padding-top: 100px;
            padding-bottom: 50px;
        }

        .c-section-passion__content__background {
            position: absolute;
            height: 100%;
            width: 100vw;
            left: 0;
            top: 0;
            background-color: $green;
        }
        
        .c-section-passion__content__title {
            color: $white;
            margin-bottom: 64px;
        }
    }
}