.c-section-image {
    padding-top: 95px;

        img {
            display: block;
            margin: 0 auto;
            max-width: 100%;
        }
}

#page-cms {

    .c-section-image {
        padding: 40px 0;
    
            img {
                display: block;
                margin: 0;
                max-width: 100%;
            }
    }

}