.c-section-family {

    padding: 120px 0;

    @include media-breakpoint-down(md) {
        padding: 100px 0;
    }

    .c-section-family__title {
        color: $blue;
        margin-bottom: 24px;
    }

    .c-section-family__slider {
        position: relative;
        margin-top: 86px;

        .swiper-container {
            position: relative;
            overflow: unset !important;

            @include media-breakpoint-down(lg) {
                overflow: hidden !important;
            }

            &:after {
                content: '';
                height: 100%;
                width: 100vw;
                background-color: $white;
                position: absolute;
                left: 0;
                top: 0;
                z-index: 2;
                transform: translateX(-100%);
            }
        }

        .c-section-family__slider__slide {
            height: 330px;
            background-size: cover;
            background-position: center;
            position: relative;

            .c-section-family__slider__slide__cover {
                position: absolute;
                top: 0;
                left: 0;
                background-color: $blue;
                opacity: .5;
                width: 100%;
                height: 100%;
            }

            .c-section-family__slider__slide__image {
                position: absolute;
                left: 50%;
                bottom: 44px;
                transform: translate(-50%);
            }

            .c-section-family__slider__slide__hover {
                opacity: 0;
                visibility: hidden;

                position: absolute;
                top: 0;
                left: 0;
                background-color: $green;
                width: calc(100% - 84px);
                height: calc(100% - 80px);

                transition: visibility .5s ease-out, opacity .5s ease-out;

                display: flex;
                justify-content: flex-start;
                align-items: center;
                flex-direction: column;

                padding: 40px 42px;

                .c-section-family__slider__slide__hover__image {
                    margin-bottom: 32px;
                    display: none;
                }

                .c-section-family__slider__slide__hover__text {
                    color: $white;
                    text-align: left;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                }

                &.active {
                    opacity: 1;
                    visibility: visible;
                }
            }

        }

        .c-section-family__slider__nav {
            position: absolute;
            bottom: 0;
            right: 0;
            display: flex;
            z-index: 2;
            transform: translateY(50%);

            .c-section-family__slider__nav__prev,
            .c-section-family__slider__nav__next {
                height: 64px;
                width: 64px;
                background-color: $red;
                border-radius: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                border: 2px solid $red;

                transition: background-color $white .2s ease-out;

                &:hover {
                    background-color: $white;
                    .long-arrow-right {
                        border-color: $red;
                        &:after {
                            background-color: $red;
                        }
                    }
                }
            }

            .c-section-family__slider__nav__prev {
                margin-right: 5px;
                transform: rotate(-180deg);
            }
        }
    }
    

}