.c-card-program {
    height: 100%;
    position: relative;
    padding-top: 200px;

    .c-card-program__link {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }

    .c-card-program__image {
        position: absolute;
        left: 0;
        top: 0;
        width: calc(100% - 54px);
        height: 262px;
        background-position: center;
        background-size: cover;

        @include media-breakpoint-down(md) {
            width: 100%;
        }
    }

    .c-card-program__info {
        position: relative;
        margin-left: 55px;
        width: calc(416px - 110px);
        height: calc(326px - 80px);
        background-color: $green;
        padding: 40px 55px;
        color: $white;

        @include media-breakpoint-down(md) {
           margin-left: 0;
           width: 230px;
           height: calc(390px - 80px);
           padding: 10px;
        }

        transition: color .2s ease-out, background-color .2s ease-out;

        .c-card-program__info__terms {
            margin-bottom: 22px;
            display: flex;
            flex-wrap: wrap;

            .c-card-program__info__terms__element {
                color: $white;
                border: 1px solid $white;
                border-radius: 20px;
                text-transform: uppercase;
                font-size: 12px;
                line-height: 24px;
                padding: 8px 24px;
                margin-right: 10px;
                margin-bottom: 10px;
            }
        }

        .c-card-program__info__title {
            font-size: 24px;
            line-height: 28px;
            margin-bottom: 16px;
        }

        .c-card-program__info__text {
            margin-bottom: 52px;
        }

        .c-card-program__info__arrow {
            position: absolute;
            bottom: 40px;
            left: 55px;

            height: 40px;
            width: 40px;
            background-color: $white;
            border-radius: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            border: 2px solid $white;
            transition: background-color $white .2s ease-out;

            @include media-breakpoint-down(md) {
                left: 10px;
             }
        }
    }

    &:hover {
        .c-card-program__info {
            background-color: $white;
            color: $green;

            .c-card-program__info__terms__element {
                color: $green;
                border-color: $green;
            }
        }

        .c-card-program__info__arrow {
            background-color: $white;
            border-color: $green;

            .long-arrow-right {
                border-color: $green;
                &:after {
                    background-color: $green;
                }
            }
        }
    }

    &--no-slide {
        .c-card-program__info {
            height: auto;
        }

        &:hover {
            .c-card-program__info {
                background-color: $light-blue;
                color: $green;
            }
        }
    }
}