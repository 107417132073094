$i: !important;

body {
    --e-global-color-primary: #a41d34;
    --e-global-color-secondary: #114d90;
    --e-global-color-text: #141414;
    --e-global-color-accent: #37bfb5;
    --e-global-color-1a88f45: #37bfb5;
    --e-global-color-74e3cec: #d7f2f0;
    --e-global-color-6db9d6f: #ffffff;
    --e-global-color-22f671a: #edd1d6;
    --e-global-color-757b17c: #c97685;
    --e-global-color-82019cb: #cfdbe9;
    --e-global-color-2ef249d: #7094bc;
    --e-global-typography-6626967-font-family: "Nixie One";
    --e-global-typography-6626967-font-size: 70px;
    --e-global-typography-6626967-font-weight: 400;
    --e-global-typography-6626967-line-height: 95px;
    --e-global-typography-primary-font-family: "Nixie One";
    --e-global-typography-primary-font-weight: 400;
    --e-global-typography-secondary-font-family: "Work Sans";
    --e-global-typography-secondary-font-weight: 800;
    --e-global-typography-secondary-text-transform: uppercase;
    --e-global-typography-text-font-family: "Work Sans";
    --e-global-typography-text-font-size: 16px;
    --e-global-typography-text-font-weight: 400;
    --e-global-typography-text-line-height: 24px;
    --e-global-typography-accent-font-family: "Roboto";
    --e-global-typography-accent-font-weight: 500;
    --e-global-typography-26088f0-font-family: "Nixie One";
    --e-global-typography-26088f0-font-size: 40px;
    --e-global-typography-26088f0-font-weight: 700;
    --e-global-typography-26088f0-line-height: 48px;
    --e-global-typography-0e917a9-font-family: "Work Sans";
    --e-global-typography-0e917a9-font-size: 24px;
    --e-global-typography-0e917a9-font-weight: 600;
    --e-global-typography-0e917a9-line-height: 28px;
    --e-global-typography-935d655-font-family: "Work Sans";
    --e-global-typography-935d655-font-size: 24px;
    --e-global-typography-935d655-font-weight: 400;
    --e-global-typography-935d655-line-height: 28px;
    --e-global-typography-2942aeb-font-family: "Work Sans";
    --e-global-typography-2942aeb-font-size: 12px;
    --e-global-typography-2942aeb-font-weight: 700;
    --e-global-typography-2942aeb-text-transform: uppercase;
    --e-global-typography-2942aeb-line-height: 14px;
    --e-global-typography-3890ace-font-family: "Nixie One";
    --e-global-typography-3890ace-font-size: 24px;
    --e-global-typography-3890ace-font-weight: 600;
    --e-global-typography-3890ace-line-height: 28px;
    --e-global-typography-f20730e-font-family: "Work Sans";
    --e-global-typography-f20730e-font-size: 20px;
    --e-global-typography-f20730e-font-weight: 400;
    --e-global-typography-f20730e-line-height: 24px;
    --e-global-typography-0f47f69-font-family: "Nixie One";
    --e-global-typography-0f47f69-font-size: 20px;
    --e-global-typography-0f47f69-font-weight: 600;
    --e-global-typography-0f47f69-line-height: 24px;
    --e-global-typography-149b58e-font-family: "Work Sans";
    --e-global-typography-149b58e-font-size: 16px;
    --e-global-typography-149b58e-font-weight: 700;
    --e-global-typography-149b58e-line-height: 14px;
}

.page-template-elementor {
    overflow-x: hidden $i;

    #page-elementor {
        background-color: $light-blue $i;
        background-color: #d7f2f0 $i;

        > header {
            padding: {
                top: 80px $i;
            }
        }
    }

    .title-xxl {
        color: $blue $i;
    }

    .elementor-heading-title.elementor-size-xl {
        color: $red $i;
    }

    .elementor-heading-title.elementor-size-default {
        color: $blue $i;
    }

    .elementor-heading-title.elementor-size-medium {
        color: $green $i;
        font-weight: 600 $i;
        text-transform: uppercase $i;
    }

    .elementor-element.elementor-element-bdca838 {
        background-color: $green $i;

        .elementor-heading-title {
            color: $red $i;
        }

        &:before,
        &:after {
            content: "" $i;

            position: absolute $i;
            top: 0 $i;
            right: 100% $i;
            bottom: 0 $i;

            width: 100% $i;

            background-color: inherit $i;
        }

        &:after {
            left: 100% $i;
            right: auto $i;
        }
    }

    .elementor-section.elementor-element-6bd532e {
        background-color: $red $i;

        margin: {
            top: 0 $i;
            bottom: 0 $i;
        }

        .elementor-heading-title.elementor-size-default {
            color: $white $i;
        }

        &:before,
        &:after {
            content: "" $i;

            position: absolute $i;
            top: 0 $i;
            right: 100% $i;
            bottom: 0 $i;

            width: 100% $i;

            background-color: inherit $i;
        }

        &:after {
            left: 100% $i;
            right: auto $i;
        }

        .elementor-button-link.elementor-button {
            .elementor-button-content-wrapper {
                // display: block $i;
                // margin: 14px auto $i;
                width: 11px $i;
                height: 10px $i;
                border-top: 2px solid $red $i;
                border-left: 2px solid $red $i;
                transform: rotate(135deg) $i;

                &:after {
                    content: "" $i;
                    display: block $i;
                    width: 2px $i;
                    height: 12px $i;
                    background-color: $red $i;
                    transform: rotate(-45deg) translate(3.5px, 1px) $i;
                    left: 0 $i;
                    top: 0 $i;
                }
            }
        }
    }
    /* Text red */
    .elementor-element.elementor-element-012da88 {
        color: $red $i;
    }
    /* h1 */
    .elementor-element.elementor-element-75b7b61 > .elementor-widget-container {
        margin: 40px 0px 0px 0px;
        font-size: 60px;
        font-weight: 400;
        line-height: 80px;
        font-family: "Nixie One", Sans-serif;
    }
    .elementor-element.elementor-element-75b7b61 .elementor-heading-title {
        color: #114d90;
        font-family: "Nixie One", Sans-serif;
        font-size: 60px;
        font-weight: 400;
        line-height: 80px;
    }
    /* h2 */
    @media (min-width: 768px) {
        .elementor-column.elementor-col-50,
        .elementor-column[data-col="50"] {
            width: 50%;
        }
    }
    .elementor-element.elementor-element-f627e9e .elementor-heading-title,
    .elementor-element.elementor-element-121d01f .elementor-heading-title {
        font-size: 40px $i;
        font-weight: 700 $i;
        line-height: 48px $i;
        margin: 20px 0px 0px 0px $i;
    }

    .elementor-element.elementor-element-e19de74 {
        margin-top: 10px;
        margin-bottom: 0px;
    }

    .elementor-element.elementor-element-eebae0d .elementor-heading-title {
        font-family: "Nixie One", Sans-serif;
        font-size: 70px;
        font-weight: 400;
        line-height: 95px;
    }

    .elementor-element.elementor-element-75b7b61 > .elementor-widget-container {
        margin: 40px 0px 0px 0px $i;
    }
    .elementor-element.elementor-element-f627e9e > .elementor-widget-container,
    .elementor-element.elementor-element-75b7b61 > .elementor-widget-container {
        margin: 20px 0px 0px 0px;
    }

    .elementor-element.elementor-element-c0543a9 > .elementor-container,
    .elementor-element.elementor-element-cf37dd5 > .elementor-container,
    .elementor-element.elementor-element-c96cbbb > .elementor-container,
    .elementor-element.elementor-element-ace3078 > .elementor-container,
    .elementor-element.elementor-element-e19de74 > .elementor-container,
    .elementor-element.elementor-element-e55b53f > .elementor-container,
    .elementor-element.elementor-element-2d2ab33 > .elementor-container,
    .elementor-element.elementor-element-1e15c67 > .elementor-container,
    .elementor-element.elementor-element-e8aaf69 > .elementor-container,
    .elementor-element.elementor-element-63f2193 > .elementor-container {
        max-width: 900px;
    }

    .elementor-element.elementor-element-6428d36 > .elementor-widget-wrap,
    .elementor-element.elementor-element-3565c07 > .elementor-widget-wrap,
    .elementor-element.elementor-element-e6bbd6a > .elementor-widget-wrap {
        background-color: #37bfb5;
    }

    .elementor-element.elementor-element-d3c4845:not(.elementor-motion-effects-element-type-background)
        > .elementor-widget-wrap,
    .elementor-element.elementor-element-d3c4845
        > .elementor-widget-wrap
        > .elementor-motion-effects-container
        > .elementor-motion-effects-layer {
        background-color: #fff;
    }

    .elementor-element.elementor-element-8e607cd .elementor-divider {
        padding-top: 15px;
        padding-bottom: 15px;
    }
    .elementor-element.elementor-element-8e607cd .elementor-divider .elementor-divider-separator,
    .elementor-element.elementor-element-1676e54 .elementor-divider .elementor-divider-separator,
    .elementor-element.elementor-element-d491432 .elementor-divider .elementor-divider-separator,
    .elementor-element.elementor-element-8654859 .elementor-divider .elementor-divider-separator {
        border-top: var(--divider-border-width) var(--divider-border-style) #a41d34;
    }

    .elementor-element.elementor-element-1129b92,
    .elementor-element.elementor-element-2c84fe7,
    .elementor-element.elementor-element-d2f021a,
    .elementor-element.elementor-element-0668401,
    .elementor-element.elementor-element-ff26c6b,
    .elementor-element.elementor-element-31d564e,
    .elementor-element.elementor-element-45ca68e,
    .elementor-element.elementor-element-e4889d3,
    .elementor-element.elementor-element-5afce5c,
    .elementor-element.elementor-element-84d6708,
    .elementor-element.elementor-element-2a516be,
    .elementor-element.elementor-element-cfb9d59,
    .elementor-element.elementor-element-d2b7933,
    .elementor-element.elementor-element-1c45409,
    .elementor-element.elementor-element-21fbf36,
    .elementor-element.elementor-element-7998d49 {
        color: #a41d34;
    }

    .elementor-element.elementor-element-3e79179 .elementor-heading-title,
    .elementor-element.elementor-element-62be1b2 .elementor-heading-title,
    .elementor-element.elementor-element-246aebc .elementor-heading-title {
        color: var(--e-global-color-6db9d6f);
        font-family: "Work Sans", Sans-serif;
        font-size: 24px;
        font-weight: 400;
        line-height: 28px;
    }

    .elementor-element.elementor-element-e4f00a7 .elementor-heading-title,
    .elementor-element.elementor-element-55b82a0 .elementor-heading-title,
    .elementor-element.elementor-element-2e7097b .elementor-heading-title {
        color: var(--e-global-color-6db9d6f);
        font-family: var(--e-global-typography-0e917a9-font-family), Sans-serif;
        font-size: var(--e-global-typography-0e917a9-font-size);
        font-weight: var(--e-global-typography-0e917a9-font-weight);
        line-height: var(--e-global-typography-0e917a9-line-height);
        letter-spacing: var(--e-global-typography-0e917a9-letter-spacing);
        word-spacing: var(--e-global-typography-0e917a9-word-spacing);
    }

    .elementor-element.elementor-element-21783cf .elementor-heading-title,
    .elementor-element.elementor-element-aa605d4 .elementor-heading-title,
    .elementor-element.elementor-element-2511652 .elementor-heading-title {
        color: var(--e-global-color-6db9d6f);
        font-family: var(--e-global-typography-3890ace-font-family), Sans-serif;
        font-size: var(--e-global-typography-3890ace-font-size);
        font-weight: var(--e-global-typography-3890ace-font-weight);
        line-height: var(--e-global-typography-3890ace-line-height);
        letter-spacing: var(--e-global-typography-3890ace-letter-spacing);
        word-spacing: var(--e-global-typography-3890ace-word-spacing);
    }

    .elementor-element.elementor-element-f687bc3 .elementor-heading-title,
    .elementor-element.elementor-element-3097aaf .elementor-heading-title,
    .elementor-element.elementor-element-e934cba .elementor-heading-title {
        color: var(--e-global-color-6db9d6f);
        font-family: var(--e-global-typography-f20730e-font-family), Sans-serif;
        font-size: var(--e-global-typography-f20730e-font-size);
        font-weight: var(--e-global-typography-f20730e-font-weight);
        line-height: var(--e-global-typography-f20730e-line-height);
        letter-spacing: var(--e-global-typography-f20730e-letter-spacing);
        word-spacing: var(--e-global-typography-f20730e-word-spacing);
    }
    .elementor-element.elementor-element-6fc4389 .elementor-heading-title,
    .elementor-element.elementor-element-d613f42 .elementor-heading-title,
    .elementor-element.elementor-element-26dc4a2 .elementor-heading-title {
        color: var(--e-global-color-6db9d6f);
        font-family: var(--e-global-typography-0f47f69-font-family), Sans-serif;
        font-size: var(--e-global-typography-0f47f69-font-size);
        font-weight: var(--e-global-typography-0f47f69-font-weight);
        line-height: var(--e-global-typography-0f47f69-line-height);
        letter-spacing: var(--e-global-typography-0f47f69-letter-spacing);
        word-spacing: var(--e-global-typography-0f47f69-word-spacing);
    }

    .elementor-element.elementor-element-95cfb18 .elementor-heading-title,
    .elementor-element.elementor-element-4c219ce .elementor-heading-title,
    .elementor-element.elementor-element-c027490 .elementor-heading-title {
        color: var(--e-global-color-6db9d6f);
        font-family: var(--e-global-typography-149b58e-font-family), Sans-serif;
        font-size: var(--e-global-typography-149b58e-font-size);
        font-weight: var(--e-global-typography-149b58e-font-weight);
        line-height: var(--e-global-typography-149b58e-line-height);
        letter-spacing: var(--e-global-typography-149b58e-letter-spacing);
        word-spacing: var(--e-global-typography-149b58e-word-spacing);
    }

    .elementor-element.elementor-element-6b575bc .elementor-heading-title,
    .elementor-element.elementor-element-e061261 .elementor-heading-title,
    .elementor-element.elementor-element-42bdf65 .elementor-heading-title {
        color: var(--e-global-color-6db9d6f);
        font-family: var(--e-global-typography-2942aeb-font-family), Sans-serif;
        font-size: var(--e-global-typography-2942aeb-font-size);
        font-weight: var(--e-global-typography-2942aeb-font-weight);
        text-transform: var(--e-global-typography-2942aeb-text-transform);
        line-height: var(--e-global-typography-2942aeb-line-height);
        letter-spacing: var(--e-global-typography-2942aeb-letter-spacing);
        word-spacing: var(--e-global-typography-2942aeb-word-spacing);
    }

    .elementor-element.elementor-element-eeabc16 .elementor-button {
        font-family: "Work Sans", Sans-serif;
        font-size: 12px;
        font-weight: 700;
        text-transform: uppercase;
        line-height: 14px;
        fill: var(--e-global-color-6db9d6f);
        color: var(--e-global-color-6db9d6f);
        background-color: #37bfb500;
        border-style: solid;
        border-width: 2px 2px 2px 2px;
        border-color: var(--e-global-color-6db9d6f);
        border-radius: 31px 31px 31px 31px;
    }
    .elementor-element.elementor-element-eeabc16 .elementor-button:hover,
    .elementor-element.elementor-element-eeabc16 .elementor-button:focus {
        color: var(--e-global-color-accent);
        background-color: var(--e-global-color-6db9d6f);
    }

    .elementor-element.elementor-element-28e48c5 .elementor-button {
        font-family: "Work Sans", Sans-serif;
        font-size: 12px;
        font-weight: 700;
        line-height: 14px;
        fill: var(--e-global-color-1a88f45);
        color: var(--e-global-color-1a88f45);
        background-color: var(--e-global-color-6db9d6f);
        border-style: solid;
        border-width: 2px 2px 2px 2px;
        border-color: var(--e-global-color-6db9d6f);
        border-radius: 31px 31px 31px 31px;
    }

    .elementor-element.elementor-element-28e48c5 .elementor-button:hover,
    .elementor-element.elementor-element-28e48c5 .elementor-button:focus {
        color: var(--e-global-color-6db9d6f);
        background-color: #37bfb500;
    }

    .elementor-element.elementor-element-d574ea8 {
        background-color: var(--e-global-color-6db9d6f);
    }

    .elementor-element.elementor-element-68dd00a .elementor-button {
        font-family: "Work Sans", Sans-serif;
        font-size: 12px;
        font-weight: 700;
        line-height: 14px;
        fill: var(--e-global-color-primary);
        color: var(--e-global-color-primary);
        background-color: #ffffff00;
        border-style: solid;
        border-width: 2px 2px 2px 2px;
        border-color: var(--e-global-color-primary);
        border-radius: 31px 31px 31px 31px;
    }
    .elementor-element.elementor-element-68dd00a .elementor-button:hover,
    .elementor-element.elementor-element-68dd00a .elementor-button:focus {
        color: var(--e-global-color-6db9d6f);
        background-color: var(--e-global-color-primary);
    }

    .elementor-element.elementor-element-d6ba808 .elementor-button {
        font-family: "Work Sans", Sans-serif;
        font-size: 12px;
        font-weight: 700;
        line-height: 14px;
        fill: var(--e-global-color-6db9d6f);
        color: var(--e-global-color-6db9d6f);
        background-color: var(--e-global-color-primary);
        border-style: solid;
        border-width: 2px 2px 2px 2px;
        border-color: var(--e-global-color-primary);
        border-radius: 31px 31px 31px 31px;
    }
    .elementor-element.elementor-element-d6ba808 .elementor-button:hover,
    .elementor-element.elementor-element-d6ba808 .elementor-button:focus {
        color: var(--e-global-color-primary);
        background-color: #ffffff00;
    }

    .elementor-element.elementor-element-d73bcdf .elementor-button {
        font-family: "Work Sans", Sans-serif;
        font-size: 12px;
        font-weight: 700;
        line-height: 14px;
        fill: var(--e-global-color-secondary);
        color: var(--e-global-color-secondary);
        background-color: #ffffff00;
        border-style: solid;
        border-width: 2px 2px 2px 2px;
        border-color: var(--e-global-color-secondary);
        border-radius: 31px 31px 31px 31px;
    }
    .elementor-element.elementor-element-d73bcdf .elementor-button:hover,
    .elementor-element.elementor-element-d73bcdf .elementor-button:focus {
        color: var(--e-global-color-6db9d6f);
        background-color: var(--e-global-color-secondary);
    }

    .elementor-element.elementor-element-39ce5c6 .elementor-button {
        font-family: "Work Sans", Sans-serif;
        font-size: 12px;
        font-weight: 700;
        line-height: 14px;
        fill: var(--e-global-color-6db9d6f);
        color: var(--e-global-color-6db9d6f);
        background-color: var(--e-global-color-secondary);
        border-style: solid;
        border-width: 2px 2px 2px 2px;
        border-color: var(--e-global-color-secondary);
        border-radius: 31px 31px 31px 31px;
    }
    .elementor-element.elementor-element-39ce5c6 .elementor-button:hover,
    .elementor-element.elementor-element-39ce5c6 .elementor-button:focus {
        color: var(--e-global-color-secondary);
        background-color: #ffffff00;
    }

    .elementor-element.elementor-element-a93be93 .elementor-button {
        font-family: "Work Sans", Sans-serif;
        font-size: 12px;
        font-weight: 700;
        line-height: 14px;
        fill: var(--e-global-color-primary);
        color: var(--e-global-color-primary);
        background-color: #d7f2f000;
        border-style: solid;
        border-width: 2px 2px 2px 2px;
        border-color: var(--e-global-color-primary);
        border-radius: 31px 31px 31px 31px;
    }
    .elementor-element.elementor-element-a93be93 .elementor-button:hover,
    .elementor-element.elementor-element-a93be93 .elementor-button:focus {
        color: var(--e-global-color-primary);
        background-color: var(--e-global-color-6db9d6f);
    }

    .elementor-element.elementor-element-7fb8882 .elementor-button {
        font-family: "Work Sans", Sans-serif;
        font-size: 12px;
        font-weight: 700;
        line-height: 14px;
        fill: var(--e-global-color-secondary);
        color: var(--e-global-color-secondary);
        background-color: #d7f2f000;
        border-style: solid;
        border-width: 2px 2px 2px 2px;
        border-color: var(--e-global-color-secondary);
        border-radius: 31px 31px 31px 31px;
    }
    .elementor-element.elementor-element-7fb8882 .elementor-button:hover,
    .elementor-element.elementor-element-7fb8882 .elementor-button:focus {
        color: var(--e-global-color-secondary);
        background-color: var(--e-global-color-6db9d6f);
    }

    .elementor-element.elementor-element-f2534fb .elementor-button {
        font-family: "Work Sans", Sans-serif;
        font-size: 12px;
        font-weight: 700;
        line-height: 14px;
        fill: var(--e-global-color-accent);
        color: var(--e-global-color-accent);
        background-color: #d7f2f000;
        border-style: solid;
        border-width: 2px 2px 2px 2px;
        border-color: var(--e-global-color-accent);
        border-radius: 31px 31px 31px 31px;
    }
    .elementor-element.elementor-element-f2534fb .elementor-button:hover,
    .elementor-element.elementor-element-f2534fb .elementor-button:focus {
        color: var(--e-global-color-accent);
        background-color: var(--e-global-color-6db9d6f);
    }
    .elementor-element.elementor-element-63f2193 > .elementor-background-overlay {
        transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
    }

    .elementor-element.elementor-element-63f2193:not(.elementor-motion-effects-element-type-background),
    .elementor-element.elementor-element-63f2193
        > .elementor-motion-effects-container
        > .elementor-motion-effects-layer {
        background-color: var(--e-global-color-6db9d6f);
    }

    .elementor-element.elementor-element-706472f > .elementor-widget-container {
        margin: 0px 0px 0px 0px;
        padding: 20px 20px 20px 20px;
        background-color: #d7f2f0;
    }
    .elementor-element.elementor-element-a2c50ee .elementor-alert-title {
        color: var(--e-global-color-primary);
        font-family: "Work Sans", Sans-serif;
        font-size: 20px;
        font-weight: 400;
        line-height: 24px;
    }
    .elementor-element.elementor-element-f096b59 .elementor-alert {
        background-color: var(--e-global-color-82019cb);
        border-color: var(--e-global-color-2ef249d);
        border-left-width: 5px;
    }
    .elementor-element.elementor-element-1724f1f .elementor-alert {
        background-color: var(--e-global-color-74e3cec);
        border-color: var(--e-global-color-accent);
        border-left-width: 5px;
    }
    .elementor-element.elementor-element-a2c50ee .elementor-alert button.elementor-alert-dismiss {
        position: absolute;
        right: var(--dismiss-icon-horizontal-position, 10px);
        top: var(--dismiss-icon-vertical-position, 10px);
        padding: 3px;
        font-size: var(--dismiss-icon-size, 20px);
        line-height: 1;
        background: transparent;
        color: var(--dismiss-icon-normal-color, inherit);
        border: none;
        cursor: pointer;
        transition-duration: var(--dismiss-icon-hover-transition-duration, 0.3s);
    }
    .elementor-element.elementor-element-1724f1f .elementor-alert button.elementor-alert-dismiss {
        position: absolute;
        right: var(--dismiss-icon-horizontal-position, 10px);
        top: var(--dismiss-icon-vertical-position, 10px);
        padding: 3px;
        font-size: var(--dismiss-icon-size, 20px);
        line-height: 1;
        background: transparent;
        color: var(--dismiss-icon-normal-color, inherit);
        border: none;
        cursor: pointer;
        transition-duration: var(--dismiss-icon-hover-transition-duration, 0.3s);
    }
    .elementor-element.elementor-element-1724f1f .elementor-alert button.elementor-alert-dismiss {
        position: absolute;
        right: var(--dismiss-icon-horizontal-position, 10px);
        top: var(--dismiss-icon-vertical-position, 10px);
        padding: 3px;
        font-size: var(--dismiss-icon-size, 20px);
        line-height: 1;
        background: transparent;
        color: var(--dismiss-icon-normal-color, inherit);
        border: none;
        cursor: pointer;
        transition-duration: var(--dismiss-icon-hover-transition-duration, 0.3s);
    }
    .elementor-element.elementor-element-7587645 > .elementor-widget-container {
        margin: 0;
        padding: 20px 20px 20px 20px;
        background-color: var(--e-global-color-6db9d6f);
    }

    .elementor-element.elementor-element-8e607cd .elementor-divider .elementor-divider-separator {
        border-top: var(--divider-border-width) var(--divider-border-style) var(--divider-color);
    }
    .elementor-element.elementor-element-8654859,
    .elementor-element.elementor-element-012661e,
    .elementor-element.elementor-element-8baa63f,
    .elementor-element.elementor-element-61d1fcb,
    .elementor-element.elementor-element-685d753,
    .elementor-element.elementor-element-0f9c1a8,
    .elementor-element.elementor-element-bb3dbdd,
    .elementor-element.elementor-element-04e920c,
    .elementor-element.elementor-element-b1165bb {
        font-family: var(--e-global-typography-0f47f69-font-family), Sans-serif;
        font-size: var(--e-global-typography-0f47f69-font-size);
        font-weight: var(--e-global-typography-0f47f69-font-weight);
        line-height: var(--e-global-typography-0f47f69-line-height);
        letter-spacing: var(--e-global-typography-0f47f69-letter-spacing);
        word-spacing: var(--e-global-typography-0f47f69-word-spacing);
    }
    .elementor-element.elementor-element-8970fe3:not(.elementor-motion-effects-element-type-background)
        > .elementor-widget-wrap,
    .elementor-element.elementor-element-8970fe3
        > .elementor-widget-wrap
        > .elementor-motion-effects-container
        > .elementor-motion-effects-layer,
    .elementor-element.elementor-element-7fb16df:not(.elementor-motion-effects-element-type-background)
        > .elementor-widget-wrap,
    .elementor-element.elementor-element-7fb16df
        > .elementor-widget-wrap
        > .elementor-motion-effects-container
        > .elementor-motion-effects-layer,
    .elementor-element.elementor-element-32b0fb1:not(.elementor-motion-effects-element-type-background)
        > .elementor-widget-wrap,
    .elementor-element.elementor-element-32b0fb1
        > .elementor-widget-wrap
        > .elementor-motion-effects-container
        > .elementor-motion-effects-layer,
    .elementor-element.elementor-element-0dd8135:not(.elementor-motion-effects-element-type-background)
        > .elementor-widget-wrap,
    .elementor-element.elementor-element-0dd8135
        > .elementor-widget-wrap
        > .elementor-motion-effects-container
        > .elementor-motion-effects-layer,
    .elementor-element.elementor-element-fee20b2 > .elementor-element-populated,
    .elementor-element.elementor-element-089fd60 > .elementor-element-populated,
    .elementor-element.elementor-element-0804a8c > .elementor-element-populated,
    .elementor-element.elementor-element-2d96ecf
        .elementor-column-gap-custom
        .elementor-column
        > .elementor-element-populated,
    .elementor-element.elementor-element-81a43fc > .elementor-element-populated,
    .elementor-element.elementor-element-e2898e1 > .elementor-element-populated {
        background-color: #fff;
    }

    .elementor-element.elementor-element-65fd1f5:not(.elementor-motion-effects-element-type-background)
        > .elementor-widget-wrap,
    .elementor-element.elementor-element-65fd1f5
        > .elementor-widget-wrap
        > .elementor-motion-effects-container
        > .elementor-motion-effects-layer,
    .elementor-element.elementor-element-d0c1afa:not(.elementor-motion-effects-element-type-background)
        > .elementor-widget-wrap,
    .elementor-element.elementor-element-d0c1afa
        > .elementor-widget-wrap
        > .elementor-motion-effects-container
        > .elementor-motion-effects-layer {
        background-color: var(--e-global-color-1a88f45);
    }

    .elementor-element.elementor-element-78bb0ab,
    .elementor-element.elementor-element-a3faf3f,
    .elementor-element.elementor-element-33ab284,
    .elementor-element.elementor-element-04e920c,
    .elementor-element.elementor-element-8654859,
    .elementor-element.elementor-element-667d23b,
    .elementor-element.elementor-element-d0b726c,
    .elementor-element.elementor-element-9f127f8,
    .elementor-element.elementor-element-6418f6a {
        color: var(--e-global-color-6db9d6f);
    }

    .elementor-element.elementor-element-7061acc > .elementor-widget-container {
        color: var(--e-global-color-6db9d6f);
    }

    .elementor-element.elementor-element-39a3d76
        .elementor-icon-list-items.elementor-inline-items
        .elementor-icon-list-item:before,
    .elementor-element.elementor-element-fb9d01a
        .elementor-icon-list-items.elementor-inline-items
        .elementor-icon-list-item:before,
    .elementor-element.elementor-element-0f0bb56
        .elementor-icon-list-items.elementor-inline-items
        .elementor-icon-list-item:before,
    .elementor-element.elementor-element-47e3882
        .elementor-icon-list-items.elementor-inline-items
        .elementor-icon-list-item:before,
    .elementor-element.elementor-element-47e3882
        .elementor-icon-list-items.elementor-inline-items
        .elementor-icon-list-item:before,
    .elementor-element.elementor-element-26102a3
        .elementor-icon-list-items.elementor-inline-items
        .elementor-icon-list-item:before,
    .elementor-element.elementor-element-6ca352b
        .elementor-icon-list-items.elementor-inline-items
        .elementor-icon-list-item:before,
    .elementor-element.elementor-element-412dd2c
        .elementor-icon-list-items.elementor-inline-items
        .elementor-icon-list-item:before,
    .elementor-element.elementor-element-d465084
        .elementor-icon-list-items.elementor-inline-items
        .elementor-icon-list-item:before,
    .elementor-element.elementor-element-de33c17
        .elementor-icon-list-items.elementor-inline-items
        .elementor-icon-list-item:before {
        color: transparent;
    }

    .elementor-element.elementor-element-fb9d01a .elementor-icon-list-text,
    .elementor-element.elementor-element-6ca352b .elementor-icon-list-text,
    .elementor-element.elementor-element-412dd2c .elementor-icon-list-text,
    .elementor-element.elementor-element-0f0bb56 .elementor-icon-list-text {
        font-family: var(--e-global-typography-2942aeb-font-family), Sans-serif;
        font-size: var(--e-global-typography-2942aeb-font-size);
        font-weight: var(--e-global-typography-2942aeb-font-weight);
        text-transform: var(--e-global-typography-2942aeb-text-transform);
        line-height: var(--e-global-typography-2942aeb-line-height);
        letter-spacing: var(--e-global-typography-2942aeb-letter-spacing);
        word-spacing: var(--e-global-typography-2942aeb-word-spacing);
    }

    .elementor-element.elementor-element-39a3d76 .elementor-icon-list-item:not(:last-child):after,
    .elementor-element.elementor-element-47e3882 .elementor-icon-list-item:not(:last-child):after,
    .elementor-element.elementor-element-fb9d01a .elementor-icon-list-item:not(:last-child):after,
    .elementor-element.elementor-element-0f0bb56 .elementor-icon-list-item:not(:last-child):after,
    .elementor-element.elementor-element-26102a3 .elementor-icon-list-item:not(:last-child):after,
    .elementor-element.elementor-element-d465084 .elementor-icon-list-item:not(:last-child):after,
    .elementor-element.elementor-element-de33c17 .elementor-icon-list-item:not(:last-child):after {
        border-color: var(--e-global-color-74e3cec);
    }

    .elementor-element.elementor-element-39a3d76 .elementor-icon-list-icon i,
    .elementor-element.elementor-element-39a3d76 .elementor-icon-list-text,
    .elementor-element.elementor-element-fb9d01a .elementor-icon-list-icon i,
    .elementor-element.elementor-element-fb9d01a .elementor-icon-list-text,
    .elementor-element.elementor-element-0f0bb56 .elementor-icon-list-icon i,
    .elementor-element.elementor-element-0f0bb56 .elementor-icon-list-text,
    .elementor-element.elementor-element-47e3882 .elementor-icon-list-icon i,
    .elementor-element.elementor-element-47e3882 .elementor-icon-list-text,
    .elementor-element.elementor-element-5f30868 .elementor-icon-list-icon i,
    .elementor-element.elementor-element-5f30868 .elementor-icon-list-text,
    .elementor-element.elementor-element-6ca352b .elementor-icon-list-icon i,
    .elementor-element.elementor-element-6ca352b .elementor-icon-list-text,
    .elementor-element.elementor-element-26102a3 .elementor-icon-list-icon i,
    .elementor-element.elementor-element-26102a3 .elementor-icon-list-text,
    .elementor-element.elementor-element-d465084 .elementor-icon-list-icon i,
    .elementor-element.elementor-element-d465084 .elementor-icon-list-text,
    .elementor-element.elementor-element-de33c17 .elementor-icon-list-icon i,
    .elementor-element.elementor-element-de33c17 .elementor-icon-list-text {
        color: white;
    }

    .elementor-element.elementor-element-f25de74 .elementor-button,
    .elementor-element.elementor-element-2f798fd .elementor-button,
    .elementor-element.elementor-element-181611a .elementor-button,
    .elementor-element.elementor-element-659b72e .elementor-button,
    .elementor-element.elementor-element-8e21e75 .elementor-button,
    .elementor-element.elementor-element-1fa5685 .elementor-button,
    .elementor-element.elementor-element-ca1f697 .elementor-button {
        font-family: "Work Sans", Sans-serif;
        font-size: 12px;
        font-weight: 700;
        line-height: 14px;
        fill: var(--e-global-color-1a88f45);
        color: var(--e-global-color-1a88f45);
        background-color: var(--e-global-color-6db9d6f);
        border-style: solid;
        border-width: 2px 2px 2px 2px;
        border-color: var(--e-global-color-6db9d6f);
        border-radius: 31px 31px 31px 31px;
        &:hover,
        &:focus {
            color: var(--e-global-color-6db9d6f);
        }
    }

    .elementor-element.elementor-element-78bb0ab .elementor-title,
    .elementor-element.elementor-element-fe48926 .elementor-title,
    .elementor-element.elementor-element-ef06e5d .elementor-title,
    .elementor-element.elementor-element-4b270f1 .elementor-title,
    .elementor-element.elementor-element-9ddc152 .elementor-title,
    .elementor-element.elementor-element-b7da8e8 .elementor-title,
    .elementor-element.elementor-element-9a290d1 .elementor-title {
        font-family: var(--e-global-typography-f20730e-font-family), Sans-serif;
        font-size: var(--e-global-typography-f20730e-font-size);
        font-weight: var(--e-global-typography-f20730e-font-weight);
        line-height: var(--e-global-typography-f20730e-line-height);
        letter-spacing: var(--e-global-typography-f20730e-letter-spacing);
        word-spacing: var(--e-global-typography-f20730e-word-spacing);
    }

    .elementor-element.elementor-element-667d23b,
    .elementor-element.elementor-element-71fdef2,
    .elementor-element.elementor-element-4bc1bef,
    .elementor-element.elementor-element-b85d807,
    .elementor-element.elementor-element-1b2ecfa,
    .elementor-element.elementor-element-fc0e7fe,
    .elementor-element.elementor-element-90b31ca {
        font-weight: var(--e-global-typography-149b58e-font-weight);
    }

    .elementor-element.elementor-element-2b1b4f2 {
        --divider-color: var(--e-global-color-74e3cec);
    }

    .elementor-social-icon {
        --e-social-icon-icon-color: #fff;
        display: inline-flex;
        background-color: #69727d;
        align-items: center;
        justify-content: center;
        text-align: center;
        cursor: pointer;
    }

    .elementor-element.elementor-element-a942de0 .elementor-heading-title {
        font-family: var(--e-global-typography-26088f0-font-family), Sans-serif;
        font-size: var(--e-global-typography-26088f0-font-size);
        font-weight: var(--e-global-typography-26088f0-font-weight);
        line-height: var(--e-global-typography-26088f0-line-height);
        letter-spacing: var(--e-global-typography-26088f0-letter-spacing);
        word-spacing: var(--e-global-typography-26088f0-word-spacing);
    }

    .elementor-element.elementor-element-ec869f1 .elementor-heading-title,
    .elementor-element.elementor-element-f742e06 .elementor-heading-title,
    .elementor-element.elementor-element-9e4d242 .elementor-heading-title,
    .elementor-element.elementor-element-60b80b8 .elementor-heading-title {
        font-family: var(--e-global-typography-935d655-font-family), Sans-serif;
        font-size: var(--e-global-typography-935d655-font-size);
        font-weight: var(--e-global-typography-935d655-font-weight);
        line-height: var(--e-global-typography-935d655-line-height);
        letter-spacing: var(--e-global-typography-935d655-letter-spacing);
        word-spacing: var(--e-global-typography-935d655-word-spacing);
    }

    .elementor-element.elementor-element-7b056ce,
    .elementor-element.elementor-element-124110a,
    .elementor-element.elementor-element-5df08e8,
    .elementor-element.elementor-element-b40d9cc,
    .elementor-element.elementor-element-cb8af0d,
    .elementor-element.elementor-element-d19bfba,
    .elementor-element.elementor-element-a3ee66d,
    .elementor-element.elementor-element-6f61a39,
    .elementor-element.elementor-element-48541df,
    .elementor-element.elementor-element-0125a99,
    .elementor-element.elementor-element-ea33a97,
    .elementor-element.elementor-element-08b9efa {
        text-align: center;
        font-family: var(--e-global-typography-0e917a9-font-family), Sans-serif;
        font-size: var(--e-global-typography-0e917a9-font-size);
        font-weight: var(--e-global-typography-0e917a9-font-weight);
        line-height: var(--e-global-typography-0e917a9-line-height);
        letter-spacing: var(--e-global-typography-0e917a9-letter-spacing);
        word-spacing: var(--e-global-typography-0e917a9-word-spacing);
    }

    .elementor-element.elementor-element-46a5163:not(.elementor-motion-effects-element-type-background),
    .elementor-element.elementor-element-46a5163
        > .elementor-motion-effects-container
        > .elementor-motion-effects-layer,
    .elementor-element.elementor-element-4a3b1dd:not(.elementor-motion-effects-element-type-background),
    .elementor-element.elementor-element-4a3b1dd
        > .elementor-motion-effects-container
        > .elementor-motion-effects-layer,
    .elementor-element.elementor-element-920d85d:not(.elementor-motion-effects-element-type-background),
    .elementor-element.elementor-element-920d85d
        > .elementor-motion-effects-container
        > .elementor-motion-effects-layer,
    .elementor-element.elementor-element-0e3c8e9:not(.elementor-motion-effects-element-type-background),
    .elementor-element.elementor-element-0e3c8e9
        > .elementor-motion-effects-container
        > .elementor-motion-effects-layer {
        background-color: var(--e-global-color-1a88f45);
    }

    .elementor-element.elementor-element-147aeb6 .elementor-toggle-title {
        font-family: var(--e-global-typography-935d655-font-family), Sans-serif;
        font-size: var(--e-global-typography-935d655-font-size);
        font-weight: var(--e-global-typography-935d655-font-weight);
        line-height: var(--e-global-typography-935d655-line-height);
        letter-spacing: var(--e-global-typography-935d655-letter-spacing);
        word-spacing: var(--e-global-typography-935d655-word-spacing);
    }

    .elementor-element.elementor-element-5b87357 .elementor-heading-title {
        color: var(--e-global-color-6db9d6f);
        font-family: var(--e-global-typography-f20730e-font-family), Sans-serif;
        font-size: var(--e-global-typography-f20730e-font-size);
        font-weight: var(--e-global-typography-f20730e-font-weight);
        line-height: var(--e-global-typography-f20730e-line-height);
        letter-spacing: var(--e-global-typography-f20730e-letter-spacing);
        word-spacing: var(--e-global-typography-f20730e-word-spacing);
    }

    .elementor-element.elementor-element-8eb7aa7,
    .elementor-element.elementor-element-91be057 > .elementor-widget-container {
        color: #fff;
    }

    .elementor-element.elementor-element-b2cf3d4 .elementor-alert {
        background-color: var(--e-global-color-6db9d6f);
        border-color: var(--e-global-color-1a88f45);
        border-left-width: 5px;
    }

    .elementor-element.elementor-element-a2c50ee .elementor-alert button.elementor-alert-dismiss,
    .elementor-element.elementor-element-16236ab .elementor-alert button.elementor-alert-dismiss {
        color: var(--e-global-color-primary);
    }
    .elementor-element.elementor-element-f096b59 .elementor-alert button.elementor-alert-dismiss {
        color: var(--e-global-color-secondary);
    }
    .elementor-element.elementor-element-1724f1f .elementor-alert button.elementor-alert-dismiss,
    .elementor-element.elementor-element-b2cf3d4 .elementor-alert button.elementor-alert-dismiss {
        color: var(--e-global-color-accent);
    }
}


.elementor-star-rating {
    .elementor-star-full, .elementor-star-empty {
        width: 16px;

        font-size: 0;

        &:before {
            content: '★';

            font-size: 16px;
        }
    }

    .elementor-star-empty {
        &:before {
            content: '☆' $i;
        }
    }
}



//
// js
//
.elementor-accordion-item {
    &.--open {
        .elementor-tab-title {
            .elementor-accordion-icon-closed {
                display: none $i;
            }

            .elementor-accordion-icon-opened {
                display: block $i;
            }
        }
        .elementor-tab-content {
            display: block;
        }
    }
}

.elementor-toggle-item {
    &.--open {
        .elementor-toggle-title, .elementor-toggle-icon {
            color: white $i;
        }
        .elementor-tab-content {
            display: block;
        }
    }
}