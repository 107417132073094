.c-card-passion {
    position: relative;

    // border-bottom: 2px solid transparent;
    // transition: border-color .2s ease-out, color .2s ease-out;

    @include media-breakpoint-down(md) {
        padding-bottom: 40px;
    }

    .c-card-passion__link {
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .c-card-passion__top {
        position: relative;
        margin-bottom: 16px;
        height: 195px;
        width: 100%;
        overflow: hidden;

        @include media-breakpoint-down(md) {
            height: 260px;
        }

        .c-card-passion__top__cover {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background-color: $black;
            opacity: .2;
            transition: opacity .2s ease-out;
        }

        .c-card-passion__top__image {
            height: 100%;
            width: 100%;
            background-size: cover;
            background-position: center;
        }
    }

    .c-card-passion__bottom {
        color: $white;
        font-weight: 500;
        height: 234px;
        position: relative;

        @include media-breakpoint-down(md) {
            height: 210px;
        }

        .c-card-passion__bottom__title {
            font-size: 30px;
            line-height: 35px;
            font-family: $font-family-default;
            margin-bottom: 16px;
        }

        .c-card-passion__bottom__text {
            font-size: 16px;
            line-height: 24px;
        }

        .c-card-passion__bottom__arrow {
            background-color: transparent;
            border: 1px solid $white;
            border-radius: 100%;
            width: 40px;
            height: 40px;
            position: absolute;
            bottom: 10px;

            transition: background-color .2s ease-out;
        }

        .c-card-passion__bottom__line {
            position: absolute;
            height: 2px;
            width: 100%;
            bottom: -2px;
            background-color: $white;
            transform: scaleX(0);
            transform-origin: left;
            transition: transform .2s ease-out;
        }
    }

    &:hover {
        .c-card-passion__top__cover {
            opacity: .1;
        }

        .c-card-passion__bottom__arrow {
            background-color: $white;

            .long-arrow-right {
                border-color: $green;

                &:after {
                    background-color: $green;
                }
            }
        }

        .c-card-passion__bottom__line {
            transform: scaleX(1);
        }

        border-color: $white;
    }
}