.c-section-pitch-content {
    padding-bottom: 80px;

    .c-section-pitch-content__form {
        position: relative;

        .c-section-pitch-content__form__steps {
            background-color: $green;
        }

        .c-section-pitch-content__form__image {
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;
            width: 323px;
            background-position: center;
            background-size: cover;
            transform: translateX(100%);
            background-color: $blue;

            @include media-breakpoint-down(lg) {
                display: none;
            }
        }

        .c-section-pitch-content__form__background {
            background-color: $white;
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
        }

        .c-section-pitch-content__form__title {
            color: $blue;
            font-size: 60px;
            line-height: 80px;
            margin-bottom: 8px;
            padding-top: 56px;

            @include media-breakpoint-down(md) {
                font-size: 32px;
                line-height: 38px;
            }
        }

        .c-section-pitch-content__form__mandatory {
            color: $green;
            padding-bottom: 36px;
        }

        .c-section-pitch-content__form__steps {
            position: relative;
            display: flex;
            justify-content: space-between;
            color: $white;
            margin-bottom: 36px;
            padding: 40px 82px 54px 110px;

            @include media-breakpoint-down(md) {
                padding: 40px 20px 54px 20px;
            }

            @include media-breakpoint-down(sm) {
                flex-direction: column;
            }

            .c-section-pitch-content__form__steps__element {
                opacity: .5;
                cursor: pointer;

                @include media-breakpoint-down(sm) {
                    margin-bottom: 10px;
                }

                transition: opacity .2s ease-out;

                &:hover,
                &.active {
                    opacity: 1;
                }
            }

            .c-section-pitch-content__form__steps__nav {
                display: flex;
                position: absolute;
                left: 42px;
                bottom: 0;
                transform: translateY(50%);

                .c-section-pitch-content__form__steps__nav__prev,
                .c-section-pitch-content__form__steps__nav__next {
                    height: 64px;
                    width: 64px;
                    background-color: $red;
                    border-radius: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    border: 2px solid $red;

                    transition: background-color $white .2s ease-out;

                    &:hover {
                        background-color: $white;
                        .long-arrow-right {
                            border-color: $red;
                            &:after {
                                background-color: $red;
                            }
                        }
                    }
                }

                .c-section-pitch-content__form__steps__nav__prev {
                    margin-right: 10px;
                    transform: rotate(-180deg);
                }
            }            
        }

        .c-section-pitch-content__form__gravity {
            padding-bottom: 40px;

            li {
                padding-left: 0;
                
                &::before {
                    content: '';
                }
            }

            ul {
                margin-left: 0;
            }

            .gform_submission_error {
                font-size: 28px;
                line-height: 32px;
                font-family: $font-family-default;
            }

            .gform_fields {
                display: flex;
                flex-wrap: wrap;
            }

            .gfield {
                margin-bottom: 20px;

                &.hide {
                    display: none;
                }

                &.fullwidth {
                    width: 100%;
                    margin-right: 24px;

                    @include media-breakpoint-down(md) {
                        margin-right: 0;
                    }

                    select {
                        width: 684px !important;

                        @include media-breakpoint-down(md) {
                            width: 624px !important;
                        }

                        @include media-breakpoint-down(sm) {
                            width: 100% !important;
                        }
                    }
                }

                @include media-breakpoint-down(sm) {
                    width: 100%;
                }
            }

            .gfield_label {
                text-transform: uppercase;
                font-size: 10px;
                color: $dark-grey;
            }

            .ginput_counter {
                font-size: 12px;
                color: $blue;
            }

            .gfield_validation_message {
                font-size: 12px;
                color: $red;
            }

            input[type="email"],
            input[type="text"] {
                width: 330px;
                border: none;
                border-bottom: 2px solid $green;
                margin-right: 24px;
                height: 30px;
                padding-bottom: 10px;
                font-size: 14px;
                color: $blue;

                &::placeholder {
                    color: $blue;
                }

                @include media-breakpoint-down(md) {
                    width: 300px;
                }

                @include media-breakpoint-down(sm) {
                    width: 100%;
                }
            }

            textarea {
                width: 330px;
                border: none;
                border-bottom: 2px solid $green;
                margin-right: 24px;
                height: 110px;
                padding-bottom: 10px;
                font-size: 14px;
                color: $blue;

                &::placeholder {
                    color: $blue;
                }

                @include media-breakpoint-down(md) {
                    width: 300px;
                }

                @include media-breakpoint-down(sm) {
                    width: 100%;
                }
            }

            select {
                width: 330px;
                border: none;
                border-bottom: 2px solid $green;
                height: 33px;
                font-size: 14px;
                color: $blue;
                padding-bottom: 10px;
                margin-right: 24px;

                background-image: url('../img/icon/bottom-green.svg');
                background-repeat: no-repeat;
                background-size: 15px;
                background-position: calc(100%) center;

                &::placeholder {
                    color: $blue;
                }

                @include media-breakpoint-down(md) {
                    width: 300px;
                }

                @include media-breakpoint-down(sm) {
                    width: 100%;
                }
            }

            input[type="submit"] {
                display: none;
            }
        }

        .c-section-pitch-content__form__ctas {
            display: flex;
            justify-content: flex-end;
            padding-right: 60px;
            padding-bottom: 100px;

            .c-section-pitch-content__form__ctas__next {
                &.hide {
                    display: none;
                }

            }

            .c-section-pitch-content__form__ctas__submit {
                &.hide {
                    display: none;
                }

                &.disabled {
                    pointer-events: none;
                }
            }
        }
    }
}