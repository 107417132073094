.c-section-manifesto {
    margin-bottom: 80px;

    .c-section-manifesto__cover {
        position: absolute;
        left: -86px;
        top: -300px;
        background-color: $green;
        height: calc(100% + 300px);
        width: 2000vw;

        @include media-breakpoint-down(md) {
            top: -150px;
        }
    }

    .c-section-manifesto__top {
        padding-top: 80px;
        color: $white;
        position: relative;

        .c-section-manifesto__top__title {
            margin-bottom: 32px;
        }

        .c-section-manifesto__top__text {
            margin-bottom: 40px;
        }

        .c-section-manifesto__top__cta {
            margin-bottom: 120px;
        }
    }

    .c-section-manifesto__content {
        .c-section-manifesto__content__element {
            padding-bottom: 120px;
            color: $white;

            .c-section-manifesto__content__element__image {
                height: 408px;
                min-height: 408px;
                background-color: $blue;
                background-size: cover;
                background-position: center;

                @include media-breakpoint-down(lg) {
                    margin-bottom: 20px;
                    max-width: 510px;
                }
            }

            .c-section-manifesto__content__element__title {
                margin-bottom: 50px;
                font-size: 40px;
            }

            .c-section-manifesto__content__element__text {
                margin-bottom: 96px;
                position: relative;

                &::after {
                    content: "";
                    position: absolute;
                    width: 740px;
                    height: 1px;
                    background-color: $white;
                    left: 0;
                    bottom: -40px;

                    @include media-breakpoint-down(md) {
                        width: 100%;
                    }
                }

                &--right {
                    &::after {
                        right: 0;
                        left: unset;
                    }
                }
            }

            .c-section-manifesto__content__element__cta {
                position: absolute;
                top: 0;
                right: 0;

                @include media-breakpoint-down(md) {
                    right: 15px;
                }
            }

            .c-section-manifesto__content__element__startups {
                .c-section-manifesto__content__element__startups__top {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding-bottom: 24px;

                    .c-section-manifesto__content__element__startups__top__text {
                        font-size: 24px;
                        font-style: italic;
                    }

                    .c-section-manifesto__content__element__startups__top__cta {
                        position: relative;
                        color: $white;
                        // text-decoration: underline;
                        text-transform: uppercase;
                        font-size: 12px;
                        line-height: 14px;
                        font-weight: bold;

                        // &::after {
                        //     content: '';
                        //     position: absolute;
                        //     bottom: 0;
                        //     left: 0;
                        //     width: 100%;
                        //     height: 1px;
                        //     background-color: $white;
                        // }

                        // &:hover {
                        //     color: $blue;

                        //     &::after {
                        //         background-color: $blue;
                        //     }
                        // }

                        .btn__line {
                            position: absolute;
                            left: 0;
                            bottom: -2px;
                            width: 100%;
                            height: 1px;
                            background-color: $white;
                            transform: scaleX(0);
                            transform-origin: left;
                            transition: transform .2s ease-out;
                        }

                        &:hover {
                            .btn__line {
                                transform: scaleX(1);
                            }
                        }
                    }
                }

                .c-section-manifesto__content__element__startups__bottom {
                    display: flex;

                    @include media-breakpoint-down(md) {
                        flex-wrap: wrap;
                    }

                    img {
                        max-width: 150px;
                        margin-right: 10px;
                        object-fit: contain;

                        @include media-breakpoint-down(md) {
                            margin-bottom: 10px;
                        }
                    }
                }
            }
        }
    }
}