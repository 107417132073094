.c-header-faq {
    position: relative;
    padding-top: 70px;

    .c-header-faq__image-container {
        position: absolute;
        right: 0;
        top: 0;
        width: 323px;
        height: 806px;
        background-color: $blue;

        @include media-breakpoint-down(md) {
            display: none;
        }
    }

    .c-header-faq__content {
        .c-header-faq__content__title {
            color: $blue;
            margin-bottom: 20px;
            max-width: 660px;
        }

        .c-header-faq__content__text {
            max-width: 750px;
        }
    }
}