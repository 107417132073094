.c-header-content-list {
    padding-top: 70px;
    padding-bottom: 80px;
    
    .c-header-content-list__title {
        margin-bottom: 20px;
        color: $blue;
    }

    .c-header-content-list__text {
        max-width: 750px;
    }
}