.c-popup-download-form {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 100000;

    justify-content: center;
    align-items: center;

    opacity: 0;
    visibility: hidden;
    display: none;

    &.active {
        opacity: 1;
        display: flex;
        visibility: visible;
    }

    .c-popup-download-form__cover {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0, .5);
    }

    .c-popup-download-form__content {
        position: relative;
        background-color: $white;
        border-radius: 40px;
        padding: 60px;
        max-height: 90vh;
        min-width: 600px;
        max-width: 95vw;

        .c-popup-download-form__content__toggle {
            position: absolute;
            top: 10px;
            right: 10px;
    
            width: 30px;
            height: 30px;
    
            cursor: pointer;
    
            &:before {
                content: '';
    
                position: absolute;
                inset: 0;
    
                margin: auto;
    
                width: 20px;
                height: 2px;
    
                background-color: $red;
    
                transform: rotate(-45deg);
    
                transition: top .8s, left .8s, width .8s;
            }
    
            &:after {
                content: '';
    
                position: absolute;
                inset: 0;
    
                margin: auto;
    
                width: 20px;
                height: 2px;
    
                background-color: $red;
    
                transform: rotate(45deg);
    
                transition: top .8s, left .8s, width .8s;
            }
        }

        .c-popup-download-form__content__btn {
            margin-top: 40px;
            margin-bottom: 20px;
        }

        .gfield_validation_message {
            font-size: 12px;
            color: $red;
            margin-bottom: 30px;
        }

        .gform_title {
            display: none;
        }

        .gfield_label {
            font-weight: 300;
            text-transform: uppercase;
            font-size: 14px;
            letter-spacing: .2px;
            margin-bottom: 5px;
            display: none;
        }

        input[type="checkbox"]{
            -webkit-appearance: auto;
        }

        #label_4_7_1 {
            font-size: 14px;
            color: $blue;
        }

        input[type="email"],
        input[type="text"] {
            margin-bottom: 15px;
            width: 100%;
            border: none;
            border-bottom: 2px solid $green;
            margin-right: 24px;
            height: 30px;
            padding-bottom: 10px;
            font-size: 14px;
            color: $blue;

            &::placeholder {
                color: $blue;
            }

            @include media-breakpoint-down(md) {
                width: 300px;
            }

            @include media-breakpoint-down(sm) {
                width: 100%;
            }
        }

        input[type="submit"] {
            display: none;
        }
    }
}